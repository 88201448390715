import { mapIdCodeDescription } from '@hkm/types/reservation/mappers/mapIdCodeDescription';
import { UnifiedReservationGuestDetails } from '@hkm/types/reservation/models/UnifiedReservationGuestDetails';

import {
  AttendantAssignmentReservationGuestDetails,
  HousekeepingRoomReservationGuestDetailsDto,
  IdCodeDescriptionColor
} from '@ac/library-api';

export function mapAttendantReservationGuestDetails(
  guest: AttendantAssignmentReservationGuestDetails
): UnifiedReservationGuestDetails {
  return {
    id: guest.id,
    personalData: guest.personalData,
    vipCode: guest.vipStatus && mapIdCodeDescription(guest.vipStatus),
    disabilityStatusCodes: guest.disabilityStatuses.map(
      (disability: IdCodeDescriptionColor) => mapIdCodeDescription(disability)
    )
  };
}

export function mapHousekeepingReservationGuestDetails(
  guest: HousekeepingRoomReservationGuestDetailsDto
): UnifiedReservationGuestDetails {
  return guest;
}
