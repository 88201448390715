import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IdCodeDescriptionColor } from '@ac/library-api';
import {
  AcBadge,
  AcBadgeList
} from '@ac/mobile-components/dist/components/badge';
import { AcButton } from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader
} from '@ac/mobile-components/dist/components/modal';
import { Color } from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';

import './GuestDisabilitiesTemplate.css';

interface GuestDisabilitiesTemplateProps extends Testable {
  disabilities?: IdCodeDescriptionColor[];
  disableModal?: boolean;
}

const GuestDisabilitiesTemplate: FC<GuestDisabilitiesTemplateProps> = (
  props: GuestDisabilitiesTemplateProps
) => {
  const { t } = useTranslation();
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);

  function openModal(): void {
    if (!props.disableModal) {
      setModalVisibility(true);
    }
  }

  function closeModal(): void {
    setModalVisibility(false);
  }

  return (
    <>
      <AcBadgeList testSelector={props.testSelector}>
        {props.disabilities?.map(disability => (
          <AcBadge
            key={disability.id}
            badgeText={disability.code!}
            backgroundColor={disability.color || Color.Alert}
            onClick={openModal}
          />
        ))}
      </AcBadgeList>

      <AcModal
        className="disabilities-modal"
        isOpen={isModalVisible}
        onClose={closeModal}
      >
        <AcModalHeader
          className="disabilities-modal-header"
          showCloseButton={true}
        >
          {t('ROOM_DETAILS.DISABILITIES')}
        </AcModalHeader>

        <AcModalBody className="disabilities-modal-body">
          {props.disabilities?.map(disability => (
            <div key={disability.id} className="ac-spacing-top-sm">
              <AcBadge
                badgeText={disability.code!}
                backgroundColor={disability.color || Color.Alert}
                label={disability.description}
                testSelector={`${props.testSelector}-${disability.code}`}
              />
            </div>
          ))}
        </AcModalBody>

        <AcModalFooter>
          <AcButton onClick={closeModal} className="disabilities-modal-button">
            <AcButtonContent text={t('GLOBAL.CLOSE')} />
          </AcButton>
        </AcModalFooter>
      </AcModal>
    </>
  );
};

export default memo(GuestDisabilitiesTemplate);
