import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { UnifiedReservationGuestDetails } from '@hkm/types/reservation/models/UnifiedReservationGuestDetails';

export function findAccompanying(
  mainReservation: UnifiedReservationDetails,
  reservations?: UnifiedReservationDetails[]
): UnifiedReservationGuestDetails[] {

  const result: { [key: string]: UnifiedReservationGuestDetails } = {};

  mainReservation.accompanyingGuests.forEach((item: UnifiedReservationGuestDetails) => {
    result[item.id] = item;
  });

  (reservations || []).forEach((reservation: UnifiedReservationDetails) => {
    reservation.accompanyingGuests.forEach((item: UnifiedReservationGuestDetails) => {
      result[item.id] = item;
    });
  });

  return Object.values(result);
}
