import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VipOrMemberBadge } from '@hkm/shared/interfaces/vipOrMemberBadge';

import {
  AcBadge,
  AcBadgeList
} from '@ac/mobile-components/dist/components/badge';
import { AcButton } from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader
} from '@ac/mobile-components/dist/components/modal';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';

import './VipOrMemberTemplate.css';

interface VipOrMemberTemplateProps extends Testable {
  disableModal?: boolean;
  vipOrMemberBadges: VipOrMemberBadge[];
}

const VipOrMemberTemplate: FC<VipOrMemberTemplateProps> = (
  props: VipOrMemberTemplateProps
) => {
  const { t } = useTranslation();
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);

  function openModal(): void {
    if (!props.disableModal) {
      setModalVisibility(true);
    }
  }

  function closeModal(): void {
    setModalVisibility(false);
  }

  return (
    <>
      <AcBadgeList testSelector={props.testSelector}>
        {props.vipOrMemberBadges.map((data, index) => (
          <AcBadge
            key={index}
            badgeText={data.text}
            backgroundColor={data.color}
            onClick={openModal}
          />
        ))}
      </AcBadgeList>

      <AcModal
        className="vip-or-member-modal"
        isOpen={isModalVisible}
        onClose={closeModal}
      >
        <AcModalHeader
          className="vip-or-member-modal-header"
          showCloseButton={true}
        >
          {t('ROOM_DETAILS.VIP_OR_MEMBER')}
        </AcModalHeader>

        <AcModalBody className="vip-or-member-modal-body">
          {props.vipOrMemberBadges.map((data, index) => (
            <div key={index} className="ac-spacing-top-sm">
              <AcBadge
                badgeText={data.text}
                backgroundColor={data.color}
                label={data.description}
                testSelector={`${props.testSelector}-${data.text}`}
              />
            </div>
          ))}
        </AcModalBody>

        <AcModalFooter>
          <AcButton onClick={closeModal} style={{ width: '100%' }}>
            <AcButtonContent text={t('GLOBAL.CLOSE')} />
          </AcButton>
        </AcModalFooter>
      </AcModal>
    </>
  );
};

export default memo(VipOrMemberTemplate);
