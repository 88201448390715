import { useContext, useLayoutEffect } from 'react';
import { AppSpinnerContext } from '@hkm/components/shared/AppSpinner/appSpinnerContext';
import { useUid } from '@hkm/shared/hooks/useUid';

export function useAppSpinner(isLoading: boolean): void {
  const id = useUid();
  const context = useContext(AppSpinnerContext);

  useLayoutEffect(() => {
    isLoading ? context.registerLoading(id) : context.unregisterLoading(id);

    return () => {
      context.unregisterLoading(id);
    };
  }, [isLoading]);
}
