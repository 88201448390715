import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { generatePath, useParams } from 'react-router-dom';
import PostingReservationListBody from '@hkm/components/Posting/PostingReservation/Body/PostingReservationListBody';
import * as actions from '@hkm/components/Posting/shared/domain/actions';
import {
  selectHousekeepingIsLoadingRoom,
  selectHousekeepingPostingRoomState
} from '@hkm/components/Posting/shared/domain/selectors';
import { getPostingHousekeepingReservations } from '@hkm/components/Posting/shared/utils/getPostingReservation';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import HeaderTwoLineTitle from '@hkm/components/shared/LayoutComponents/HeaderTwoLineTitle';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { HousekeepingNotificationEnvelopeContent } from '@hkm/services/signalRClient/envelopeContent/HousekeepingNotificationEnvelopeContent';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { useNotification } from '@hkm/shared/hooks/useNotification';

import { NotificationEnvelope } from '@ac/library-api/dist/types/entities/signalr/notificationEnvelope';
import { AcBody } from '@ac/mobile-components/dist/components/layout';

const HousekeepingPostingReservations: FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();

  const roomState = useSelector(selectHousekeepingPostingRoomState);
  const showSpinner = useSelector(selectHousekeepingIsLoadingRoom);

  const roomId = params.roomId!;

  const notificationCallback = useCallback(
    (data: NotificationEnvelope<HousekeepingNotificationEnvelopeContent>) => {
      if (data.notification?.roomId === roomId) {
        dispatch(actions.fetchHousekeepingPostingRoom.trigger());
      }
    },
    [roomId]
  );

  useNotification(
    SignalREventType.HousekeepingRoomChanged,
    notificationCallback
  );

  useEffect(() => {
    if (!roomState.didInitialize || roomState.roomId !== roomId) {
      dispatch(actions.reset());
      dispatch(actions.initializeHousekeepingPostingRoom(params.roomId!));
    }
  }, []);

  const reservations = useMemo(
    () =>
      getPostingHousekeepingReservations(roomState.room?.currentReservations),
    [roomState]
  );

  // hack to not be redirected accidentally during swapping room data in store
  if (roomState.roomId !== roomId) {
    return null;
  }

  if (
    // Navigate to dashboard if we initialized, but no room is available
    (!roomState.room && roomState.didInitialize) ||
    // or there is no reservations with specific status in the room
    (roomState.room && reservations.length === 0)
  ) {
    return <Navigate to={APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.DASHBOARD} />;
  } else if (reservations.length === 1) {
    // if there is only one reservation with specific status go straight to the posting view
    return (
      <Navigate
        to={generatePath(APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.POSTING_VIEW, {
          roomId: roomState.roomId,
          reservationId: reservations[0].id
        })}
      />
    );
  }

  return (
    <>
      <ConnectedHeader
        showHelpButton={true}
        close={APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.DASHBOARD}
      >
        {roomState.room && roomState.room.roomNumber && (
          <HeaderTwoLineTitle
            upperText={t('POSTING.RESERVATION_LIST.TITLE')}
            lowerText={roomState.room.roomNumber.code || '\u00A0'}
          />
        )}
        {!roomState.room && t('POSTING.RESERVATION_LIST.TITLE')}
      </ConnectedHeader>
      <AcBody
        className="reservation-list-dashboard-body"
        showSpinner={showSpinner}
      >
        {roomState.room && (
          <PostingReservationListBody
            reservations={reservations}
            roomId={roomId}
          />
        )}
      </AcBody>
    </>
  );
};

export default memo(HousekeepingPostingReservations);
