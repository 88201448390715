import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { getReservationStatusColor } from '@hkm/utils/getReservationStatusColor';
import classNames from 'classnames';

import { ReservationStatus } from '@ac/library-api';
import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import {
  Childless,
  Styleable,
  Testable
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

import './ReservationStatusBadge.css';

interface ReservationStatusBadgeProps
  extends Styleable,
    Childless,
    Testable,
    Styleable {
  status: ReservationStatus;
  showLabel?: boolean;
}

const ReservationStatusBadge: FC<ReservationStatusBadgeProps> = (
  props: ReservationStatusBadgeProps
) => {
  const { t } = useTranslation();
  const labelText = props.showLabel
    ? t(`GLOBAL.RESERVATION_STATUS.STATUSES.${props.status}`)
    : undefined;
  const className = classNames('reservation-status-badge', props.className);

  return (
    <AcBadge
      style={props.style}
      testSelector={formatTestSelector(
        props.testSelector,
        'reservationStatusBadge'
      )}
      className={className}
      badgeText={props.status}
      label={labelText}
      backgroundColor={getReservationStatusColor(props.status)}
    />
  );
};

export default memo(ReservationStatusBadge);
