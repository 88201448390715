import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  Color,
  Icon,
  IconSize,
  TextSize
} from '@ac/mobile-components/dist/enums';

interface EscalatedFlagProps {
  class?: string;
  indexId?: string;
}

const EscalatedFlag: FC<EscalatedFlagProps> = props => {
  const testSelector = `${props.indexId}-escalated-flag`;
  const { t } = useTranslation();

  return (
    <AcFlex alignItems={AlignItems.center}>
      <AcIcon
        className={props.class}
        icon={Icon.Suspend}
        color={Color.Warning}
        size={IconSize.Small}
        testSelector={testSelector}
      />
      <AcText size={TextSize.Main2} className="ac-spacing-left-sm">
        {t('TASK_MANAGEMENT.DASHBOARD.LABELS.TASK_ESCALATED')}
      </AcText>
    </AcFlex>
  );
};

export default memo(EscalatedFlag);
