import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { HousekeepingBatchStatusChangeData } from '@hkm/components/Housekeeping/shared/models/housekeepingBatchStatusChangeData';
import ConnectedFooter from '@hkm/components/shared/LayoutComponents/ConnectedFooter';
import APP_ROUTES from '@hkm/constants/routing.constants';

import {
  AcButton,
  AcButtonPattern
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AlignItems,
  FlexDirection,
  Icon,
  JustifyContent
} from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { FormRenderProps } from '@ac/react-infrastructure';

import './BatchStatusChangeFooter.css';

interface BatchStatusChangeFooterProps extends Childless {
  formProps: FormRenderProps<HousekeepingBatchStatusChangeData>;
}

const BatchStatusChangeFooter: FC<BatchStatusChangeFooterProps> = (
  props: BatchStatusChangeFooterProps
) => {
  const prefix = 'batch-status-change';
  const navigate = useNavigate();
  const { t } = useTranslation();

  function onCancelChangeStatus(): void {
    navigate(APP_ROUTES.HOUSEKEEPING.DASHBOARD);
  }

  return (
    <ConnectedFooter className={`${prefix}-footer`}>
      <AcFlex
        direction={FlexDirection.column}
        justifyContent={JustifyContent.center}
        alignItems={AlignItems.center}
      >
        <AcButton
          disabled={props.formProps.invalid}
          onClick={props.formProps.handleSubmit}
          className="batch-status-change-footer-button"
          testSelector={`${prefix}-confirm-button`}
          fullwidth={true}
        >
          <AcButtonContent
            text={t('HOUSEKEEPING_BATCH.STATUS_CHANGE.CONFIRM_BUTTON')}
          />
        </AcButton>
        <AcButton
          onClick={onCancelChangeStatus}
          pattern={AcButtonPattern.Tertiary}
          testSelector={`${prefix}-cancel-button`}
          className="batch-status-change-footer-button"
        >
          <AcButtonContent icon={Icon.Cancel} text={t('GLOBAL.CANCEL')} />
        </AcButton>
      </AcFlex>
    </ConnectedFooter>
  );
};

export default memo(BatchStatusChangeFooter);
