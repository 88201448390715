import { selectMaintenanceDashboardPagination } from '@hkm/components/Maintenance/Dashboard/domain/selectors';
import { getStore } from '@hkm/store/configureStore';

import { RoomAssignmentMaintenanceDetails } from '@ac/library-api';
import { HousekeepingViewsApi } from '@ac/library-api/dist/api/v0/housekeeping';

interface GetMaintenanceStatusCodeById {
  maintenanceId: string;
}

export async function getMaintenanceStatusCodeById({
  maintenanceId
}: GetMaintenanceStatusCodeById) {
  try {
    const maintenancePagination = selectMaintenanceDashboardPagination(
      getStore()
    ).page?.results;
    const maintenanceFromState =
      maintenancePagination &&
      maintenancePagination.find(
        maintenance => maintenance.id === maintenanceId
      );

    if (maintenanceFromState?.status) {
      return maintenanceFromState.status.code;
    }

    const maintenanceById = (await HousekeepingViewsApi.getMaintenanceDashboardItem(
      { pathParams: { maintenanceId } }
    )) as RoomAssignmentMaintenanceDetails;

    return maintenanceById.status?.code ?? maintenanceId;
  } catch (e) {
    return maintenanceId;
  }
}
