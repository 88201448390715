import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';
import { TaskDetails } from '@hkm/components/TaskManagement/models/taskManagement/index';
import {
  TasksRelationsState,
  TaskTagDictionary
} from '@hkm/components/TaskManagement/relations/domain/interfaces';
import { SelectedObjectModel } from '@hkm/components/TaskManagement/shared/interfaces/selectedObjectModel';
import { TaskPreviewDetails } from '@hkm/components/TaskManagement/TaskPreview/domain/interfaces';
import DateTimeHelpers from '@hkm/components/TaskManagement/utils/dateTime';
import { getLocalizedString } from '@hkm/utils/getLocalizedString';

import {
  BodyTask,
  LocalizedString,
  PropertyDetails,
  RawTask
} from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';

export const normalizeTaskForPreview = (
  task: RawTask,
  relations: TasksRelationsState,
  timeZoneCode: string,
  taskRelatedUsers?: MappedConfigurationUser[]
): TaskDetails => {
  const assignedDepartment = relations.departments.find(
    ({ id }) => id === task.assignedDepartmentId
  )!;
  const responsibleDepartment = relations.responsibleDepartments.find(
    ({ id }) => id === task.responsibleDepartmentId
  );
  const assignedUser = taskRelatedUsers?.find(
    ({ id }) => id === task.assignedUserId
  );
  const reporterUser = taskRelatedUsers?.find(
    ({ id }) => id === task.reporterUserId
  );
  const taskType = relations.taskTypes.find(
    ({ id }) => id === task.taskTypeId
  )!;
  const taskPriority = relations.taskPriorities.find(
    ({ id }) => id === task.taskPriorityId
  )!;
  const taskStatus = relations.taskStatuses.find(
    ({ id }) => id === task.taskStatusId
  )!;
  const location = relations.locations.find(({ id }) => id === task.locationId);
  const taskTags = relations.taskTags.filter(({ id }: TaskTagDictionary) =>
    task.taskTagIds?.some(taskTagId => taskTagId === id)
  );
  const isAssignedToLoggedUser =
    task.assignedUserId === LoginService.authData()?.userId;

  return {
    id: task.id,
    number: task.number,
    seriesId: task.seriesId,
    version: task.version,
    name: task.name,
    description: task.description,
    history: task.history,
    assignedDepartment,
    responsibleDepartment,
    reporterUser,
    assignedUser,
    taskTags,
    taskType,
    taskPriority,
    taskStatus,
    location,
    dueDateTime: task.dueDateTime,
    isEscalated: task.isEscalated,
    isOverdue: DateTimeHelpers.isDateTimeOverdue(
      timeZoneCode,
      task.dueDateTime,
      taskStatus?.role
    ),
    linkedObject: task.linkedObject,
    hasAssignee: !!task.assignedUserId,
    isAssignedToLoggedUser,
    attachments: task.attachments
  };
};

export function mapTaskForPreview(
  task: TaskDetails,
  property: PropertyDetails,
  relations: TasksRelationsState
): TaskPreviewDetails {
  const separateTimeDate =
    task?.dueDateTime && DateTimeHelpers.separateTimeDate(task.dueDateTime);
  const dateFormat = property.shortDateFormat.toUpperCase();
  const dueDate =
    separateTimeDate &&
    separateTimeDate?.dueDate &&
    DateTimeHelpers.formatDateForField(separateTimeDate.dueDate, dateFormat);
  const dueTime = separateTimeDate && separateTimeDate.dueTime;
  const formattedDateTime = `${dueDate}, ${dueTime}`;

  let locationDescription: string | LocalizedString[] = '';
  let responsibleDepartmentDescription: string | LocalizedString[] = '';

  let taskStatusObject = task.taskStatus;
  let taskTypeObject = task.taskType;
  let departmentObject = task.assignedDepartment;
  let responsibleDepartmentObject = task.responsibleDepartment;
  let locationObject = task.location;

  taskStatusObject = relations.taskStatuses.find(
    ({ id }) => id === task.taskStatus.id
  )!;
  taskTypeObject = relations.taskTypes.find(
    ({ id }) => id === task.taskType.id
  )!;
  departmentObject = relations.departments.find(
    ({ id }) => id === task.assignedDepartment.id
  )!;
  responsibleDepartmentObject = relations.responsibleDepartments.find(
    ({ id }) => id === task.responsibleDepartment?.id
  );
  locationObject = relations.locations.find(
    ({ id }) => id === task.location?.id
  );

  const taskStatusDescription = getLocalizedString(
    taskStatusObject.description as LocalizedString[]
  );
  const taskTypeDescription: string = getLocalizedString(
    taskTypeObject.description as LocalizedString[]
  );
  const assigneeDepartmentDescription = getLocalizedString(
    departmentObject.description as LocalizedString[]
  );
  if (task.responsibleDepartment && responsibleDepartmentObject) {
    responsibleDepartmentDescription = getLocalizedString(
      responsibleDepartmentObject.description as LocalizedString[]
    );
  }
  if (task.location && locationObject) {
    locationDescription = getLocalizedString(
      locationObject.description as LocalizedString[]
    );
  }

  return {
    ...task,
    dueDate,
    dueTime,
    formattedDateTime,
    taskStatusDescription,
    assigneeDepartmentDescription,
    taskTypeDescription,
    locationDescription,
    responsibleDepartmentDescription
  } as TaskPreviewDetails;
}

export const getTaskForPreview = (
  task: RawTask,
  relations: TasksRelationsState,
  property: PropertyDetails,
  taskRelatedUsers?: MappedConfigurationUser[]
): TaskPreviewDetails => {
  const normalizedTask = normalizeTaskForPreview(
    task,
    relations,
    property.timeZoneCode!,
    taskRelatedUsers
  );

  return mapTaskForPreview(normalizedTask, property, relations);
};

// NOTE: We will propably can adjust this mapper to future update requests of whole task model
export const serializeTaskForRemoveObject = (
  task: RawTask,
  selectedObject?: SelectedObjectModel
): BodyTask => ({
  name: task.name,
  description: task.description,
  assignedDepartmentId: task.assignedDepartmentId,
  assignedUserId: task.assignedUserId,
  responsibleDepartmentId: task.responsibleDepartmentId,
  taskTypeId: task.taskTypeId,
  taskTagIds: task.taskTagIds,
  taskPriorityId: task.taskPriorityId,
  taskPriorityComment: task.taskPriorityComment,
  taskStatusId: task.taskStatusId,
  taskStatusComment: task.taskStatusComment,
  dueDateTime: task.dueDateTime,
  linkedObject: selectedObject
    ? {
        objectId: selectedObject.object.id,
        type: selectedObject.type,
        followObjectChanges: selectedObject.followObjectChanges
      }
    : undefined,
  scheduledDueDateTime: task.scheduledDueDateTime,
  baseTaskId: task.baseTaskId,
  escalation: task.escalation,
  locationId: task.locationId,
  occurrenceDefinition: task.occurrenceDefinition
});
