import * as appActions from '@hkm/components/App/domain/actions';
import * as notificationActions from '@hkm/components/App/notificationConsumer/domain/actions';
import * as actions from '@hkm/components/Attendant/shared/domain/actions';
import { AttendantAssignmentTaskData } from '@hkm/components/Attendant/shared/domain/interfaces/assignmentTask';
import { AttendantAssignmentTasksData } from '@hkm/components/Attendant/shared/domain/interfaces/assignmentTasksData';
import {
  selectAttendantSheetVersion,
  selectAttendantStaleRoomsIds,
  selectStaleSheetVersionId,
  selectViewingAttendantRoom
} from '@hkm/components/Attendant/shared/domain/selectors';
import { AttendantTaskActionType } from '@hkm/components/Attendant/shared/enum/attendantTaskActionType';
import { selectBusinessDate } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import * as maintenanceAttachmentActions from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/domain/actions';
import * as maintenanceUpdateActions from '@hkm/components/shared/Templates/Maintenance/shared/domain/actions';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { createAttendantGreenServiceToggleSagaFactory } from '@hkm/shared/domain/greenServiceToggle/greenServiceToggleSagaFactory';
import { createAttendantGuestServiceChangeSaga } from '@hkm/shared/domain/guestServiceChange/guestServiceChangeSagaFactory';
import { createAttendantHkStatusChangeSaga } from '@hkm/shared/domain/housekeepingStatusChange/housekeepingStatusChangeSagaFactory';
import { createAttendantRoomOccupancySaga } from '@hkm/shared/domain/occupancyChange/occupancyChangeSagaFactory';
import { createHousekeepingRoomConditionsChangeSaga } from '@hkm/shared/domain/roomConditionsChange/roomConditionsChangeSagaFactory';
import { createAttendantRoomStatusChangeSaga } from '@hkm/shared/domain/roomStatusChange/roomStatusChangeSagaFactory';
import { getAttendantRoomVersionId } from '@hkm/shared/services/getAttendantRoomVersionId';
import { createAttendantSheet } from '@hkm/types/attendant/factories/createAttendantSheet';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeLatest
} from '@redux-saga/core/effects';
import i18n from 'i18next';
import { IAction } from 'redux-saga-routines';
import { createSelector } from 'reselect';

import {
  AttendantAssignmentSheetDetails,
  getDataForAllPages,
  MaintenanceFile,
  PageResponse,
  RawMaintenanceFile
} from '@ac/library-api';
import { MobileAttendantApi } from '@ac/library-api/dist/api/v0/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';
import { repeatableCall } from '@ac/library-utils/dist/utils';
import { NewOverlayMessage } from '@ac/mobile-components/dist/components/overlay-message';

const attendantTaskApiMap = new Map<AttendantTaskActionType, any>()
  .set(AttendantTaskActionType.Start, MobileAttendantApi.postTaskStart)
  .set(AttendantTaskActionType.Pause, MobileAttendantApi.postTaskPause)
  .set(AttendantTaskActionType.Resume, MobileAttendantApi.postTaskResume)
  .set(AttendantTaskActionType.Skip, MobileAttendantApi.postTaskSkip)
  .set(AttendantTaskActionType.Cancel, MobileAttendantApi.postTaskCancel)
  .set(AttendantTaskActionType.Complete, MobileAttendantApi.postTaskComplete);

function* runAttendantTask(
  action: Action<AttendantAssignmentTaskData>
): Generator<any, void, [any] & number> {
  try {
    const {
      taskId,
      actionType,
      roomId,
      data,
      roomNumber,
      noMessage
    } = action.payload;
    const apiMethod = attendantTaskApiMap.get(actionType)!;

    if (data?.newOccupancy) {
      yield put(
        actions.roomOccupancy.setRoomOccupancy.trigger(data.newOccupancy)
      );

      const [failed] = yield race([
        take(actions.roomOccupancy.setRoomOccupancy.failure),
        take(actions.roomOccupancy.setRoomOccupancy.success)
      ]);

      if (failed) {
        return;
      }

      yield take(actions.fetchAssignedSheet.success);
    }

    if (data?.newRoomStatus) {
      yield put(
        actions.roomStatus.changeRoomStatus.trigger({
          housekeepingRoomVersion: yield getAttendantRoomVersionId(roomId),
          roomStatus: data.newRoomStatus,
          roomNumber,
          roomId
        })
      );
      // actions.roomStatus.changeRoomStatus.success runs fetchSheet
      yield take(actions.fetchAssignedSheet.success);
    }

    if (data?.newGuestServiceStatus) {
      yield put(
        actions.guestService.changeGuestServiceStatus.trigger({
          roomId,
          untilTime: data.untilTime,
          newStatus: data.newGuestServiceStatus,
          versionId: yield getAttendantRoomVersionId(roomId)
        })
      );
      // actions.roomStatus.changeRoomStatus.success runs fetchSheet
      yield take(actions.fetchAssignedSheet.success);
    }

    if (data?.newHousekeepingStatus) {
      yield put(
        actions.housekeepingStatus.changeHousekeepingStatus.trigger({
          roomId,
          roomNumber,
          housekeepingStatus: data?.newHousekeepingStatus,
          housekeepingRoomVersion: yield getAttendantRoomVersionId(roomId)
        })
      );
      // actions.roomStatus.changeRoomStatus.success runs fetchSheet
      yield take(actions.fetchAssignedSheet.success);
    }

    yield delay(500);

    yield apiMethod({
      pathParams: { taskId },
      customConfig: getCustomConfig({
        version: yield select(selectAttendantSheetVersion)
      })
    });

    yield put(actions.runAttendantTask.success());
    yield put(
      notificationActions.expectNotification(
        SignalREventType.AssignedTaskChanged
      )
    );
    if (!noMessage) {
      yield put(
        appActions.displaySuccess(
          i18n.t(
            `ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.TASKS.SUCCESS_MESSAGE.${actionType}`
          )
        )
      );
    }
  } catch (e) {
    console.log(e);
    yield put(appActions.displayExtractedError(e));
    yield put(actions.runAttendantTask.failure(e));
  }
}

function* runAttendantTasks(action: Action<AttendantAssignmentTasksData>) {
  try {
    const { tasks, successMessage } = action.payload;
    for (const task of tasks) {
      const attendantSheetVersion: number = yield select(
        selectAttendantSheetVersion
      );

      yield* runAttendantTask(
        actions.runAttendantTask.trigger({
          ...task,
          noMessage: !!successMessage
        })
      );
      yield put(actions.setStaleSheetVersionId(attendantSheetVersion));
      yield* fetchSheet();
    }

    if (successMessage) {
      yield put(appActions.displaySuccess(successMessage));
    }

    yield put(actions.runAttendantTasks.success());
  } catch (e) {
    yield put(appActions.displayExtractedError(e));
    yield put(actions.runAttendantTasks.failure(e));
  }
}

function* getFreshAttendantAssignment() {
  const staleRoomsIds: string[] = yield select(selectAttendantStaleRoomsIds);
  const staleSheetVersionId: number = yield select(selectStaleSheetVersionId);
  const versionIds: number[] = yield all(
    staleRoomsIds.map(id => getAttendantRoomVersionId(id).catch(() => null))
  );
  const versionIdMap = new Map<string, number>();

  staleRoomsIds
    .filter(id => !!id)
    .forEach((id, index) => versionIdMap.set(id, versionIds[index]));

  const checkIfAllRoomsAreFresh = (
    assignedSheet: AttendantAssignmentSheetDetails
  ) => {
    for (const room of assignedSheet.roomAssignments) {
      const neededVersion = versionIdMap.get(room.id);
      if (
        neededVersion &&
        neededVersion > (room.housekeepingRoomVersion || 0)
      ) {
        return false;
      }
    }

    return !staleSheetVersionId || assignedSheet.version > staleSheetVersionId;
  };

  const result: AttendantAssignmentSheetDetails = yield repeatableCall(
    () => MobileAttendantApi.getCurrent(),
    checkIfAllRoomsAreFresh
  );

  yield put(actions.clearAttendantStaleData());

  return result;
}

function* fetchSheet() {
  try {
    const businessDate: string = yield select(selectBusinessDate);
    const attendantAssignmentSheet: AttendantAssignmentSheetDetails = yield call(
      getFreshAttendantAssignment
    );

    const sheet = createAttendantSheet(attendantAssignmentSheet, businessDate);
    yield put(actions.fetchAssignedSheet.success(sheet));
  } catch (e) {
    yield put(appActions.displayExtractedError(e));
    yield put(actions.fetchAssignedSheet.failure(e));
  }
}

function* onChange(action: Action<string>) {
  yield put(actions.addStaleRoomIds([action.payload]));
  yield put(actions.fetchAssignedSheet.trigger());
}

function* fetchAttachments() {
  const attendantRoom: AttendantRoom | undefined = yield select(
    selectViewingAttendantRoom
  );
  try {
    const maintenanceId = attendantRoom?.currentMaintenance
      ? attendantRoom.currentMaintenance.id
      : undefined;

    if (!maintenanceId) {
      return ((yield put(
        actions.maintenanceAttachment.fetchAttachments.success([])
      )) as unknown) as IAction<MaintenanceFile[]>;
    }

    const response: PageResponse<
      RawMaintenanceFile,
      MaintenanceFile
    > = yield getDataForAllPages(
      ({ pageNumber, pageSize }) =>
        MobileAttendantApi.getRoomMaintenancesFileList({
          pathParams: {
            roomId: attendantRoom!.id,
            maintenanceId
          },
          queryParams: {
            pageNumber,
            pageSize
          }
        }) as Promise<PageResponse<RawMaintenanceFile, MaintenanceFile>>
    );
    const sorted = response.results.sort((a, b) =>
      a.metadata.createdAt > b.metadata.createdAt ? 1 : -1
    );

    return ((yield put(
      actions.maintenanceAttachment.fetchAttachments.success(sorted)
    )) as unknown) as IAction<MaintenanceFile[]>;
  } catch (e) {
    yield put(actions.maintenanceAttachment.fetchAttachments.failure(e));

    return ((yield put(
      appActions.displayError(e.message)
    )) as unknown) as IAction<string | NewOverlayMessage>;
  }
}

const selectRoomOccupancySagaRoomDetails = createSelector(
  selectViewingAttendantRoom,
  room => ({
    roomId: room!.id,
    roomVersion: room!.housekeepingRoomVersion!
  })
);

export default function* AttendantAssignmentSagas() {
  // Handle changes
  yield takeLatest(
    actions.guestService.changeGuestServiceStatus.success,
    onChange
  );
  yield takeLatest(actions.roomStatus.changeRoomStatus.success, onChange);
  yield takeLatest(
    actions.roomConditions.changeRoomConditions.success,
    onChange
  );
  yield takeLatest(
    actions.housekeepingStatus.changeHousekeepingStatus.success,
    onChange
  );
  yield takeLatest(actions.roomOccupancy.setRoomOccupancy.success, onChange);
  yield takeLatest(actions.roomOccupancy.removeRoomOccupancy.success, onChange);
  yield takeLatest(actions.greenService.toggleGreenService.success, onChange);

  yield takeLatest(
    maintenanceAttachmentActions.uploadAttendantAttachmentActionsSet
      .addAttachment.success,
    onChange
  );
  yield takeLatest(
    maintenanceAttachmentActions.uploadAttendantAttachmentActionsSet
      .updateAttachment.success,
    onChange
  );
  yield takeLatest(
    maintenanceAttachmentActions.uploadAttendantAttachmentActionsSet
      .removeAttachment.success,
    onChange
  );
  yield takeLatest(
    maintenanceUpdateActions.updateAttendantMaintenanceActionsSet
      .updateMaintenance.success,
    onChange
  );

  // Sagas
  yield fork(createAttendantRoomStatusChangeSaga(actions.roomStatus));
  yield fork(createAttendantGuestServiceChangeSaga(actions.guestService));
  yield fork(createAttendantHkStatusChangeSaga(actions.housekeepingStatus));
  yield fork(
    createAttendantGreenServiceToggleSagaFactory(actions.greenService)
  );
  yield fork(
    createHousekeepingRoomConditionsChangeSaga(actions.roomConditions)
  );
  yield fork(
    createAttendantRoomOccupancySaga(
      actions.roomOccupancy,
      selectRoomOccupancySagaRoomDetails
    )
  );

  yield takeLatest(actions.runAttendantTask.trigger, runAttendantTask);
  yield takeLatest(actions.runAttendantTasks.trigger, runAttendantTasks);
  yield takeLatest(actions.fetchAssignedSheet.trigger, fetchSheet);
  yield takeLatest(
    actions.maintenanceAttachment.fetchAttachments.trigger,
    fetchAttachments
  );
}
