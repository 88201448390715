import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import PostingDetailsBody from '@hkm/components/Posting/PostingView/Body/PostingDetailsBody';
import * as postingViewActions from '@hkm/components/Posting/PostingView/domain/actions';
import {
  selectIsLoadingPostingView,
  selectPostingReservationChargedState
} from '@hkm/components/Posting/PostingView/domain/selectors';
import { useFetchTransactionCodes } from '@hkm/components/Posting/PostingView/hooks/useFetchTransactionCodes';
import * as sharedActions from '@hkm/components/Posting/shared/domain/actions';
import {
  selectHousekeepingIsLoadingRoom,
  selectHousekeepingPostingRoomState
} from '@hkm/components/Posting/shared/domain/selectors';
import {
  getPostingHousekeepingReservation,
  getPostingHousekeepingReservations
} from '@hkm/components/Posting/shared/utils/getPostingReservation';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { HousekeepingNotificationEnvelopeContent } from '@hkm/services/signalRClient/envelopeContent/HousekeepingNotificationEnvelopeContent';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { ReservationChargesData } from '@hkm/shared/domain/reservationCharges/models/reservationChargesData';
import { useNotification } from '@hkm/shared/hooks/useNotification';

import { NotificationEnvelope } from '@ac/library-api/dist/types/entities/signalr/notificationEnvelope';
import { AcBody } from '@ac/mobile-components/dist/components/layout';

const HousekeepingPostingView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const { state } = location;

  const { roomId, reservationId } = params;
  const roomState = useSelector(selectHousekeepingPostingRoomState);
  const transactionCodesMap = useFetchTransactionCodes();
  const reservationChargedState = useSelector(
    selectPostingReservationChargedState
  );

  const isLoadingRoom = useSelector(selectHousekeepingIsLoadingRoom);
  const isLoadingPosting = useSelector(selectIsLoadingPostingView);
  const showSpinner = isLoadingRoom || isLoadingPosting;

  const notificationCallback = useCallback(
    (data: NotificationEnvelope<HousekeepingNotificationEnvelopeContent>) => {
      if (data.notification?.roomId === roomId) {
        dispatch(sharedActions.fetchHousekeepingPostingRoom.trigger());
      }
    },
    [roomId]
  );

  useNotification(
    SignalREventType.HousekeepingRoomChanged,
    notificationCallback
  );

  useEffect(() => {
    if (!roomState.didInitialize || roomState.roomId !== roomId) {
      dispatch(sharedActions.reset());
      dispatch(sharedActions.initializeHousekeepingPostingRoom(params.roomId!));
    }
    dispatch(
      postingViewActions.housekeepingReservationChargesActionsSet.fetchReservationRecentCharges.trigger(
        reservationId!
      )
    );

    return () => {
      dispatch(postingViewActions.resetReservationRecentCharges());
    };
  }, []);

  const postingReservation = useMemo(
    () =>
      getPostingHousekeepingReservation(
        reservationId!,
        roomState.room?.currentReservations
      ),
    [roomState]
  );

  const closePathHandler = useCallback(() => {
    const pathname = state?.returnPath
      ? state.returnPath
      : getPostingHousekeepingReservations(roomState.room?.currentReservations)
          .length > 1
      ? generatePath(APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.RESERVATIONS, {
          roomId
        })
      : generatePath(APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.DASHBOARD, {
          roomId
        });

    navigate(pathname);
  }, [roomState]);

  const onSubmitHandler = useCallback((data: ReservationChargesData) => {
    dispatch(
      postingViewActions.housekeepingReservationChargesActionsSet.postReservationCharges.trigger(
        data
      )
    );
  }, []);

  // hack to not be redirected accidentally during swapping room data in store
  if (roomState.roomId !== roomId) {
    return null;
  }

  if (
    // Navigate to dashboard if we initialized, but no room is available
    (!roomState.room && roomState.didInitialize) ||
    // or reservation doesn't exist or do not have the appropriate status
    (roomState.room && !postingReservation) ||
    // or reservation charged cannot be fetched
    (reservationChargedState.didInitialize && !reservationChargedState.data)
  ) {
    return <Navigate to={APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.DASHBOARD} />;
  }

  return (
    <>
      <ConnectedHeader showHelpButton={true} close={closePathHandler}>
        {t('POSTING.POSTING_DETAILS.TITLE')}
      </ConnectedHeader>
      <AcBody
        className="posting-details-dashboard-body"
        showSpinner={showSpinner}
      >
        {postingReservation && (
          <PostingDetailsBody
            onSubmitHandler={onSubmitHandler}
            onCloseHandler={closePathHandler}
            reservation={postingReservation}
            transactionCodesMap={transactionCodesMap}
            unifiedRoomDetails={roomState.room!.unifiedRoomDetails}
          />
        )}
      </AcBody>
    </>
  );
};

export default memo(HousekeepingPostingView);
