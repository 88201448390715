import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import * as postingViewActions from '@hkm/components/Posting/PostingView/domain/actions';
import { selectPostingTransactionCodesState } from '@hkm/components/Posting/PostingView/domain/selectors';

import { TransactionCode2 } from '@ac/library-api';

export function useFetchTransactionCodes(): Map<string, TransactionCode2> {
  const dispatch = useDispatch();
  const postingViewState = useSelector(selectPostingTransactionCodesState);
  const mobilePostingItems = useSelector(selectEffectiveValues)!
    .mobilePostingItems;

  useEffect(() => {
    if (!postingViewState.didInitialize) {
      dispatch(postingViewActions.fetchTransactionCodes.trigger());
    }
  }, []);

  return useMemo(() => {
    const transactionArray =
      postingViewState.data && mobilePostingItems
        ? postingViewState.data.filter((transactionCode: TransactionCode2) =>
            mobilePostingItems.includes(transactionCode.id)
          )
        : [];

    return new Map<string, TransactionCode2>(
      transactionArray.map((transactionCode: TransactionCode2) => [
        transactionCode.id,
        transactionCode
      ])
    );
  }, [postingViewState]);
}
