import * as actions from '@hkm/components/Menu/PropertySelector/domain/actions';
import { createEffectiveValuesFromSettings } from '@hkm/shared/effectiveValues/effectiveValuesFactory';
import {
  Permission,
  PermissionIds
} from '@hkm/shared/permissions/enum/Permission';
import { getAppRelevantPermissionIds } from '@hkm/shared/permissions/helper/getAppRelevantPermissionIds';
import { createDefaultConfigProvider } from '@hkm/utils/api';
import { getCurrentRegionCode } from '@hkm/utils/region/getCurrentRegionCode';
import { redirectToProperRegionIfNecessary } from '@hkm/utils/region/redirectToPropertyRegionIfNecessary';
import { setProperty } from '@hkm/utils/region/setProperty';
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  BaseApi,
  buildFIQLFilter,
  combineFilters,
  CurrentDate,
  EffectiveSettingDetails,
  EffectiveUserPermissionIdData,
  FIQLOperators,
  getDataForAllPages,
  OrganizationUnitType,
  PageQueryParams,
  PageResponse,
  PropertyDetails,
  RawEffectiveUserPermissionIdData,
  RawUnitAssignmentListItem,
  UnitAssignmentListItem
} from '@ac/library-api';
import { PropertiesApi } from '@ac/library-api/dist/api/v0/configuration';
import { SettingsPropertiesApi } from '@ac/library-api/dist/api/v0/configuration/settings';
import { UnitAccessApi } from '@ac/library-api/dist/api/v0/permissionManagement';
import { EffectiveUserPermissionApi } from '@ac/library-api/dist/api/v0/permissionManagement/users';
import { CurrentDateApi } from '@ac/library-api/dist/api/v0/propertyManagement';
import { Action } from '@ac/library-utils/dist/declarations';
import { SessionService } from '@ac/library-utils/dist/services';

function* fetchProperty(action: Action<string>) {
  try {
    // Setup
    const propertyId: string = action.payload;
    const permissionsPromise = ({ pageNumber, pageSize }: PageQueryParams) =>
      EffectiveUserPermissionApi.getMyInUnit({
        pathParams: { unitId: propertyId },
        queryParams: {
          pageNumber,
          pageSize,
          filter: buildFIQLFilter(
            'permissionId',
            FIQLOperators.in,
            getAppRelevantPermissionIds()
          )
        }
      }) as Promise<
        PageResponse<
          RawEffectiveUserPermissionIdData,
          EffectiveUserPermissionIdData
        >
      >;

    // Gather all data about property
    const [activeProperty, permissions, effectiveSettings]: [
      PropertyDetails,
      PageResponse<
        RawEffectiveUserPermissionIdData,
        EffectiveUserPermissionIdData
      >,
      EffectiveSettingDetails[]
    ] = yield all([
      PropertiesApi.getById({
        pathParams: { propertyId }
      }),
      getDataForAllPages(permissionsPromise),
      SettingsPropertiesApi.getEffectiveValues({
        pathParams: { propertyId }
      })
    ]);

    // Create permissions data
    const permissionsMap = new Map<string, string>(
      Object.entries(PermissionIds).map(([key, value]) => [value, key])
    );
    const mappedPermissions = (permissions.results || [])
      .map(({ permissionId }) => permissionsMap.get(permissionId))
      .filter(Boolean) as Permission[];

    // Create effective values data
    const effectiveValues = createEffectiveValuesFromSettings(
      effectiveSettings
    );

    // Finish
    yield put(
      actions.property.success({
        activeProperty,
        permissions: mappedPermissions,
        effectiveValues
      })
    );
  } catch (e) {
    yield put(actions.property.failure(e));
  }
}

function* fetchProperties() {
  try {
    const propertiesResponse: PageResponse<
      RawUnitAssignmentListItem,
      UnitAssignmentListItem
    > = yield getDataForAllPages(
      ({ pageNumber, pageSize }) =>
        UnitAccessApi.getMyUnits({
          queryParams: {
            filter: combineFilters([
              buildFIQLFilter(
                'type',
                FIQLOperators.equal,
                OrganizationUnitType.Property
              ),
              buildFIQLFilter('isPublished', FIQLOperators.equal, true)
            ]),
            pageNumber,
            pageSize
          }
        }) as Promise<
          PageResponse<RawUnitAssignmentListItem, UnitAssignmentListItem>
        >
    );
    yield put(actions.properties.success(propertiesResponse.results));
  } catch (e) {
    yield put(actions.properties.failure(e));
  }
}

function* fetchPropertiesDone(action: Action<UnitAssignmentListItem[]>) {
  const properties = action.payload;
  const defaultPropertyId = SessionService.getPropertyId();
  const firstPropertyInCurrentRegion = properties.find(
    ({ regionCode }) =>
      regionCode?.toLowerCase() === getCurrentRegionCode().toLowerCase()
  );
  const propertyToFetch =
    properties.find(({ unitId }) => unitId === defaultPropertyId) ||
    firstPropertyInCurrentRegion ||
    action.payload[0];

  // Redirect to different region if property has different regionCode than present
  redirectToProperRegionIfNecessary(propertyToFetch);

  yield put(actions.propertySelect(propertyToFetch));
}

function* fetchBusinessDate() {
  try {
    const businessDate: CurrentDate = yield CurrentDateApi.getCurrentDate({});

    yield put(actions.businessDate.success(businessDate.businessDate));
  } catch (e) {
    yield put(actions.businessDate.failure(e));
  }
}

function* handlePropertySelect(action: Action<UnitAssignmentListItem>) {
  const property = action.payload;

  setProperty(property);

  BaseApi.defaultConfig = createDefaultConfigProvider({
    propertyId: SessionService.getPropertyId()
  });
  BaseApi.clearAllCache();

  yield put(actions.property.trigger(property.unitId));
}

function* handlePropertyDone() {
  yield put(actions.businessDate.trigger());
}

export default function* propertySagas() {
  yield takeLatest(actions.property.trigger, fetchProperty);
  yield takeLatest(actions.properties.trigger, fetchProperties);
  yield takeLatest(actions.properties.success, fetchPropertiesDone);
  yield takeLatest(actions.propertySelect, handlePropertySelect);
  yield takeLatest(actions.businessDate.trigger, fetchBusinessDate);
  yield takeLatest(actions.property.success, handlePropertyDone);
}
