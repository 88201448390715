import * as appActions from '@hkm/components/App/domain/actions';
import { RoomOccupancyActions } from '@hkm/shared/domain/occupancyChange/occupancyChangeActions';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';
import { Store } from '@hkm/store/interfaces/store';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import { put, select, takeLatest } from '@redux-saga/core/effects';
import i18n from 'i18next';

import {
  HousekeepingRoomsApi,
  MobileAttendantApi
} from '@ac/library-api/dist/api/v0/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';

interface RoomDetails {
  roomId: string;
  roomVersion: number;
}

interface OccupancyApi {
  postSetOccupancy: any;
  postClearOccupancy: any;
}

export function createHousekeepingRoomOccupancySaga(
  actions: RoomOccupancyActions,
  roomDetailsSelector: (store: Store) => RoomDetails
) {
  return createRoomOccupancySaga(
    actions,
    roomDetailsSelector,
    HousekeepingRoomsApi
  );
}

export function createAttendantRoomOccupancySaga(
  actions: RoomOccupancyActions,
  roomDetailsSelector: (store: Store) => RoomDetails
) {
  return createRoomOccupancySaga(
    actions,
    roomDetailsSelector,
    MobileAttendantApi
  );
}

function createRoomOccupancySaga(
  actions: RoomOccupancyActions,
  roomDetailsSelector: (store: Store) => RoomDetails,
  occupancyApi: OccupancyApi
) {
  function* setRoomOccupancy(action: Action<GuestCount>) {
    try {
      const room: RoomDetails = yield select(roomDetailsSelector);

      yield occupancyApi.postSetOccupancy({
        pathParams: { roomId: room.roomId },
        data: action.payload,
        customConfig: getCustomConfig({
          version: room.roomVersion
        })
      });

      yield put(
        appActions.displaySuccess(
          i18n.t('ROOM_DETAILS.HOUSEKEEPING_OCCUPANCY.SET_OCCUPANCY_SUCCESS')
        )
      );
      yield put(actions.setRoomOccupancy.success(room.roomId));
    } catch (e) {
      yield put(appActions.displayExtractedError(e));
      yield put(actions.setRoomOccupancy.failure(e));
    }
  }

  function* removeRoomOccupancy() {
    try {
      const room: RoomDetails = yield select(roomDetailsSelector);

      yield occupancyApi.postClearOccupancy({
        pathParams: { roomId: room.roomId },
        customConfig: getCustomConfig({
          version: room.roomVersion
        })
      });

      yield put(
        appActions.displaySuccess(
          i18n.t('ROOM_DETAILS.HOUSEKEEPING_OCCUPANCY.REMOVE_OCCUPANCY_SUCCESS')
        )
      );
      yield put(actions.setRoomOccupancy.success(room.roomId));
    } catch (e) {
      yield put(appActions.displayExtractedError(e));
      yield put(actions.setRoomOccupancy.failure(e));
    }
  }

  return function*() {
    yield takeLatest(actions.setRoomOccupancy.trigger, setRoomOccupancy);
    yield takeLatest(actions.removeRoomOccupancy.trigger, removeRoomOccupancy);
  };
}
