import { MenuBehaviour } from '@hkm/components/Menu/domain/interfaces';
import { LanguageDefinition } from '@hkm/components/Menu/LanguageSelector/domain/interfaces/languageDefinition';

import { createAction, getOneAction } from '@ac/library-utils/dist/redux-utils';

export const setMenuState = createAction<boolean>('setMenuState');
export const setMenuBehaviour = createAction<MenuBehaviour>('setMenuBehaviour');
export const languageSelect = getOneAction<LanguageDefinition, boolean>(
  'languageSelect'
);
