import APP_CONSTANTS from '@hkm/constants/app.constants';

import { LoginService } from '@ac/library-utils/dist/services';

const getContainerName = (regionCode: string) =>
  LoginService.domain() === 'localhost'
    ? 'https://housekeeping-mobile'
    : `https://${
        regionCode ? regionCode.toLowerCase() + '.' : ''
      }housekeeping-mobile`;

export const getOriginForNewRegion = (regionCode: string, domain: string) =>
  `${getContainerName(regionCode)}.${domain}`;

export const getUrlForNewRegion = (
  regionCode: string,
  domain: string,
  propertyId?: string,
  customPathname?: string
) =>
  `${getOriginForNewRegion(
    regionCode,
    domain
  )}${getRelativeUrlWithPropertyIdParam(customPathname, propertyId)}`;

const getRelativeUrlWithPropertyIdParam = (
  customPathname?: string,
  propertyId?: string
): string => {
  const basePath = customPathname || window.location.pathname;

  return getUrlWithPropertyIdParam(
    `${basePath}${window.location.search}`,
    propertyId
  );
};

const getUrlWithPropertyIdParam = (url: string, propertyId?: string) => {
  const [fullPath, queryString] = url.split('?');
  const searchParams = new URLSearchParams(queryString);

  if (propertyId) {
    searchParams.append(APP_CONSTANTS.NEW_PROPERTY_ID, propertyId);
  }

  const newQueryString = searchParams.toString();
  const newUrl = `${fullPath}${newQueryString ? `?${newQueryString}` : ''}`;

  return newUrl;
};
