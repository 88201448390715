import { DictionaryEntry } from '@hkm/components/App/domain/interfaces/DictionariesStore';
import { getLocalizedString } from '@hkm/utils/getLocalizedString';
import i18n from 'i18next';

import {
  BaseConfigurationEntity,
  DictionaryEntity,
  RoomType
} from '@ac/library-api';

export enum DictionaryLabelLength {
  Short,
  Long,
  Full
}
export function extractDictionaryEntryLabel(
  entry: DictionaryEntry | undefined,
  length: DictionaryLabelLength
): string {
  if (!entry) {
    return '';
  }

  const item = entry as any;

  let short: string | undefined;
  let long: string | undefined;

  const useShort: boolean = length !== DictionaryLabelLength.Long;
  const useLong: boolean = length !== DictionaryLabelLength.Short;

  if (useLong) {
    const extractor = getExtractor(entry);
    long = extractor(entry);
  }
  if (useShort) {
    short = item ? item.code : fallbackExtractor();
  }

  if (useLong && useShort) {
    return i18n.t('GLOBAL.FULL_LABEL_FORMAT', { long, short });
  }

  return useLong ? long! : short!;
}

function getExtractor(
  entry: DictionaryEntry
): (entry: DictionaryEntry) => string {
  if (entry instanceof RoomType) {
    return extractRoomTypeLabel;
  }
  if (entry instanceof BaseConfigurationEntity) {
    return extractConfigurationEntityLabel;
  }
  if (entry instanceof DictionaryEntity) {
    return extractDictionaryEntityLabel;
  }

  return fallbackExtractor;
}

function extractRoomTypeLabel(type: RoomType): string {
  const localizedString = getLocalizedString(type.name);

  return localizedString || type.name[0].content;
}

function extractConfigurationEntityLabel(
  entity: BaseConfigurationEntity
): string {
  const localizedString = getLocalizedString(entity.description);

  return localizedString || entity.description[0].content;
}

function extractDictionaryEntityLabel(entity: DictionaryEntity): string {
  return entity.name!;
}

export function fallbackExtractor(): string {
  return i18n.t('GLOBAL.NOT_FOUND_FALLBACK');
}
