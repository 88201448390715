import {
  AssignmentUpdate,
  ConfigBuilder,
  TaskStatusDashboardUpdate
} from '@ac/library-api';
import { TasksApi } from '@ac/library-api/dist/api/v0/taskManagement';

export const changeTaskStatus = (
  taskId: string,
  taskStatusId: string,
  version: number,
  comment?: string
): Promise<TaskStatusDashboardUpdate> => {
  try {
    const config = new ConfigBuilder()
      .setHeaders({
        'IF-Match': version
      })
      .getConfig();

    return TasksApi.putStatusById({
      pathParams: { id: taskId },
      customConfig: config,
      data: { taskStatusId, comment }
    }) as Promise<TaskStatusDashboardUpdate>;
  } catch (e) {
    throw new Error(e);
  }
};

export const getTaskById = async (taskId: string) => {
  return await TasksApi.getById({
    pathParams: { id: taskId }
  });
};

export const changeTaskAssignee = (
  taskId: string,
  version: number,
  userId?: string
): Promise<AssignmentUpdate> => {
  const config = new ConfigBuilder()
    .setHeaders({
      'IF-Match': version
    })
    .getConfig();

  return TasksApi.putAssignmentById({
    pathParams: { id: taskId },
    customConfig: config,
    data: { userId }
  }) as Promise<AssignmentUpdate>;
};
