import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { getRoomStatesMap } from '@hkm/components/App/domain/selectors';
import classNames from 'classnames';

import { RoomStatus } from '@ac/library-api';
import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { Color } from '@ac/mobile-components/dist/enums';
import {
  Childless,
  Styleable,
  Testable
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

interface RoomStatusBadgeProps extends Styleable, Childless, Testable {
  status: RoomStatus;
  showLabel?: boolean;
}

const colorMap = new Map<RoomStatus, Color>()
  .set(RoomStatus.CL, Color.RoomStatusClean)
  .set(RoomStatus.DI, Color.RoomStatusDirty)
  .set(RoomStatus.IN, Color.RoomStatusInspected)
  .set(RoomStatus.PU, Color.RoomStatusPickup);

const RoomStatusBadge: FC<RoomStatusBadgeProps> = (
  props: RoomStatusBadgeProps
) => {
  const color: Color = colorMap.get(props.status) || Color.Gray5;
  const status = useSelector(getRoomStatesMap).get(props.status)!;

  const labelText = props.showLabel ? status.name! : undefined;
  const badgeText = status.code!;

  const className: string = classNames('room-status-badge', props.className);

  return (
    <AcBadge
      style={props.style}
      testSelector={formatTestSelector(props.testSelector, 'roomStatusBadge')}
      className={className}
      badgeText={badgeText}
      label={labelText}
      backgroundColor={color}
    />
  );
};

export default memo(RoomStatusBadge);
