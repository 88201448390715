import React, { ComponentType, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as appActions from '@hkm/components/App/domain/actions';
import { getDictionaries } from '@hkm/components/App/domain/selectors';
import AppSpinner from '@hkm/components/shared/AppSpinner/AppSpinner';
import { CustomDictionaryConfig } from '@hkm/shared/dictionaries/dictionariesConfig';
import { Dictionary } from '@hkm/shared/dictionaries/dictionary';

export const requireDictionaries = (
  requiredDictionaries: Set<Dictionary | CustomDictionaryConfig>
): (<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>
) => ComponentType<P>) => <P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>
  // eslint-disable-next-line react/display-name
): ComponentType<P> => (props: P) => {
  const dispatch = useDispatch();
  const dictionaries = useSelector(getDictionaries);

  useEffect(() => {
    if (dictionaries) {
      dispatch(appActions.fetchDictionaries.trigger(requiredDictionaries));
    }
  }, []);

  const hasDictionaries = useMemo(
    () =>
      Array.from(requiredDictionaries).every(dictionary =>
        typeof dictionary === 'string'
          ? Array.isArray(dictionaries[dictionary])
          : Array.isArray(dictionaries[dictionary.dictionaryName!])
      ),
    [requiredDictionaries, dictionaries]
  );

  return hasDictionaries ? <WrappedComponent {...props} /> : <AppSpinner />;
};
