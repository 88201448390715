import i18n from 'i18next';

import { HousekeepingInoperationStatus } from '@ac/library-api';

export function getShortStatusDescription(
  statusCode?: HousekeepingInoperationStatus
): string {
  switch (statusCode) {
    case HousekeepingInoperationStatus.OutOfOrder:
      return i18n.t('GLOBAL.MAINTENANCE_STATUS.SHORT.OutOfOrder');
    case HousekeepingInoperationStatus.OutOfService:
      return i18n.t('GLOBAL.MAINTENANCE_STATUS.SHORT.OutOfService');
  }

  return '';
}
