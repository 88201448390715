import { isNextUseReservationInActiveStatus } from '@hkm/shared/reservations/isNextUseReservationInActiveStatus';
import { getCurrentAndNextMaintenances } from '@hkm/shared/reservedKind/getCurrentAndNextMaintenances';
import { getCurrentMainReservation } from '@hkm/shared/reservedKind/getCurrentMainReservation';
import { getNextMainReservation } from '@hkm/shared/reservedKind/getNextMainReservation';
import { getNextReservedKind } from '@hkm/shared/reservedKind/getNextReservedKind';
import { ReservedKindProps } from '@hkm/shared/reservedKind/getReservedKind';
import { GroupedReservedKinds } from '@hkm/shared/reservedKind/groupedReservedKinds';
import { groupSharedReservations } from '@hkm/shared/reservedKind/groupSharedReservations';
import { ReservedKind } from '@hkm/shared/reservedKind/reservedKind';
import { isDefined } from '@hkm/shared/validation/validators';
import { UnifiedMaintenanceDetails } from '@hkm/types/maintenance/models/UnifiedMaintenanceDetails';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { ReservationStatus, RoomMaintenanceState } from '@ac/library-api';

export interface ReservedKindMaintenance {
  id: string;
  fromTime: string;
  state: RoomMaintenanceState;
}

export interface ReservedKindReservation {
  id: string;
  isShared: boolean;
  arrivalDate: string;
  departureDate: string;
  status: ReservationStatus;
}

interface Options {
  businessDate: string;
  reservations?: UnifiedReservationDetails[];
  maintenances?: UnifiedMaintenanceDetails[];
  reservationKindResolver: (props: ReservedKindProps) => ReservedKind;
}

export function groupReservedKinds(options: Options): GroupedReservedKinds {
  const { businessDate } = options;

  const futureActiveReservations = options.reservations?.filter(reservation => {
    return isNextUseReservationInActiveStatus(
      reservation.status?.code as ReservationStatus | undefined
    );
  });

  const unsortedReservations = (options.reservations || []).map(reservation =>
    transformReservation(reservation)
  );
  const unsortedMaintenances = (options.maintenances || []).map(maintenance =>
    transformMaintenance(maintenance)
  );

  const reservationsMap = new Map(
    (unsortedReservations || []).map((transformed, index) => [
      transformed.id,
      options.reservations?.[index]
    ])
  );
  const maintenancesMap = new Map(
    (unsortedMaintenances || []).map((transformed, index) => [
      transformed.id,
      options.maintenances?.[index]
    ])
  );

  const reservations = (unsortedReservations || []).sort((a, b) =>
    a.arrivalDate.localeCompare(b.arrivalDate)
  );
  const maintenances = (unsortedMaintenances || []).sort((a, b) =>
    a.fromTime.localeCompare(b.fromTime)
  );

  const { currentMaintenance, nextMaintenance } = getCurrentAndNextMaintenances(
    maintenances,
    businessDate
  );

  const allSharedReservations = groupSharedReservations(reservations);

  const currentMainReservation = getCurrentMainReservation(
    reservations,
    businessDate
  );
  const currentSharedReservationGroup = (
    allSharedReservations || []
  ).find(group => group.includes(currentMainReservation!));
  const currentSharedReservations = currentSharedReservationGroup?.filter(
    reservation => reservation !== currentMainReservation
  );
  const currentReservations = currentSharedReservationGroup
    ? [currentMainReservation, ...currentSharedReservations!]
    : undefined;

  const nextMainReservation = getNextMainReservation(
    reservations,
    currentSharedReservationGroup
  );
  const nextSharedReservationGroup =
    (allSharedReservations || []).find(group =>
      group.includes(nextMainReservation!)
    ) ?? [];
  const nextSharedReservations = nextSharedReservationGroup?.filter(
    reservation => reservation !== nextMainReservation
  );
  const nextReservations = nextSharedReservationGroup
    ? [
        ...(nextMainReservation ? [nextMainReservation] : []),
        ...(nextSharedReservations ?? [])
      ].filter(isDefined)
    : [];

  const currentReservedKind = options.reservationKindResolver({
    reservation: currentMainReservation,
    maintenance: currentMaintenance
  });
  const nextReservedKind = getNextReservedKind({
    maintenance: nextMaintenance,
    reservation: nextMainReservation
  });

  const hasCurrentReservation =
    currentReservedKind === ReservedKind.Reservation;
  const hasCurrentMaintenance =
    currentReservedKind === ReservedKind.Maintenance;
  const hasNextReservation = nextReservedKind === ReservedKind.Reservation;
  const hasNextMaintenance = nextReservedKind === ReservedKind.Maintenance;

  const currentNextReservations:
    | UnifiedReservationDetails[]
    | undefined = hasNextReservation
    ? ((nextReservations ?? [])
        .map(r => reservationsMap.get(r.id))
        .filter(isDefined) as UnifiedReservationDetails[])
    : undefined;

  const currentNextMaintenance = hasNextMaintenance
    ? maintenancesMap.get(nextMaintenance?.id ?? '')
    : undefined;

  const inUseMaintenance =
    hasCurrentMaintenance ||
    currentMainReservation?.status === ReservationStatus.IH ||
    currentMainReservation?.status === ReservationStatus.DO ||
    currentMainReservation?.status === ReservationStatus.DI
      ? maintenances.find(mtc => mtc.state === RoomMaintenanceState.Active)
      : undefined;

  return {
    currentMainReservation: hasCurrentReservation
      ? reservationsMap.get(currentMainReservation?.id ?? '')
      : undefined,
    currentSharedReservations: hasCurrentReservation
      ? (currentSharedReservations || []).map(r => reservationsMap.get(r.id)!)
      : undefined,
    currentReservations: hasCurrentReservation
      ? (currentReservations || []).map(r => reservationsMap.get(r?.id ?? '')!)
      : undefined,
    currentReservationsIds: hasCurrentReservation
      ? (currentReservations || []).map(
          r => reservationsMap.get(r?.id ?? '')?.id || ''
        )
      : undefined,
    currentMaintenance: maintenancesMap.get(inUseMaintenance?.id ?? ''),
    currentReservedKind,
    nextMainReservation: hasNextReservation
      ? reservationsMap.get(nextMainReservation?.id ?? '')
      : undefined,
    nextSharedReservations: hasNextReservation
      ? (nextSharedReservations || []).map(r => reservationsMap.get(r.id)!)
      : undefined,
    nextReservations: currentNextReservations,
    nextMaintenance: currentNextMaintenance,
    nextReservedKind,
    futureActiveReservations
  };
}

function transformReservation(
  source: UnifiedReservationDetails
): ReservedKindReservation {
  return {
    id: source.id,
    arrivalDate: source.eta! || source.arrivalDate!,
    status: source.status?.code as ReservationStatus,
    departureDate: source.etd! || source.departureDate!,
    isShared: source.isRoomShared
  };
}

function transformMaintenance(
  source: UnifiedMaintenanceDetails
): ReservedKindMaintenance {
  return {
    id: source.id,
    fromTime: source.fromTime!,
    state: source.state?.code as RoomMaintenanceState
  };
}
