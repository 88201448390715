import React, { ReactNode } from 'react';
import { Route } from 'react-router-dom';
import { MenuBehaviour } from '@hkm/components/Menu/domain/interfaces';
import { AppRouteContent } from '@hkm/components/shared/Routes/AppRouteContent/AppRouteContent';
import RouteComposeWrapper from '@hkm/components/shared/Routes/RouteComposeWrapper/RouteComposeWrapper';
import TaskManagementDashboardFilters from '@hkm/components/TaskManagement/Dashboard/DashboardFilters/DashboardFilters';
import TaskManagementDashboard from '@hkm/components/TaskManagement/Dashboard/TaskManagementDashboard';
import { TaskManagementFeatureToggles } from '@hkm/components/TaskManagement/shared/config/taskManagementFeatureToggles';
import { TaskManagementViewPermissionsConfig } from '@hkm/components/TaskManagement/shared/config/taskManagementViewPermissionsConfig';
import { requireTasksDictionaries } from '@hkm/components/TaskManagement/shared/routeGuards/requireTasksDictionaries';
import TaskPreview from '@hkm/components/TaskManagement/TaskPreview/TaskPreview';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { SourceModuleType } from '@hkm/shared/enum/SourceModuleType';
import { requireAuth } from '@hkm/utils/routeGuards/requireAuth';
import { requireFeatureToggles } from '@hkm/utils/routeGuards/requireFeatureToggles';
import { requirePermission } from '@hkm/utils/routeGuards/requirePermission';
import { compose } from 'redux';

export const TaskManagementRoutes: ReactNode[] = [
  <Route
    key="task-management-dashboard-route"
    path={APP_ROUTES.TASK_MANAGEMENT.DASHBOARD}
    element={
      <AppRouteContent
        menuBehaviour={MenuBehaviour.Enable}
        sourceModuleType={SourceModuleType.TaskManagement}
      >
        <RouteComposeWrapper
          component={compose(
            requireAuth,
            requirePermission(...TaskManagementViewPermissionsConfig),
            requireFeatureToggles(TaskManagementFeatureToggles),
            requireTasksDictionaries()
          )(TaskManagementDashboard)}
        />
      </AppRouteContent>
    }
  />,
  <Route
    key="task-management-dashboard-filters-route"
    path={APP_ROUTES.TASK_MANAGEMENT.FILTERS}
    element={
      <AppRouteContent
        menuBehaviour={MenuBehaviour.Disable}
        sourceModuleType={SourceModuleType.TaskManagement}
      >
        <RouteComposeWrapper
          component={compose(
            requireAuth,
            requirePermission(...TaskManagementViewPermissionsConfig),
            requireFeatureToggles(TaskManagementFeatureToggles),
            requireTasksDictionaries()
          )(TaskManagementDashboardFilters)}
        />
      </AppRouteContent>
    }
  />,
  <Route
    key="task-management-task-details"
    path={APP_ROUTES.TASK_MANAGEMENT.TASK_PREVIEW}
    element={
      <AppRouteContent
        menuBehaviour={MenuBehaviour.Disable}
        sourceModuleType={SourceModuleType.TaskManagement}
      >
        <RouteComposeWrapper
          component={compose(
            requireAuth,
            requirePermission(...TaskManagementViewPermissionsConfig),
            requireFeatureToggles(TaskManagementFeatureToggles),
            requireTasksDictionaries()
          )(TaskPreview)}
        />
      </AppRouteContent>
    }
  />
];
