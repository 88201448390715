import { getGlobalRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const getCurrentRegionCode = () => {
  const index = window.location.hostname.indexOf('.housekeeping-mobile');
  if (index > 0) {
    return window.location.hostname.substring(0, index).toLowerCase();
  }

  return getGlobalRegionCode();
};
