import { useContext, useEffect, useRef } from 'react';
import NotificationConsumerContext from '@hkm/components/App/notificationConsumer/NotificationConsumerContext';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { useUid } from '@hkm/shared/hooks/useUid';

import { NotificationEnvelope } from '@ac/library-api/dist/types/entities/signalr/notificationEnvelope';

export function useNotification<T>(
  signal: SignalREventType,
  action: (envelope: NotificationEnvelope<T>) => any
): void {
  const notificationConsumerContext = useContext(NotificationConsumerContext)!;
  const id = useUid();
  const didSubscribe = useRef<boolean>(false);
  const actionRef = useRef<(envelope: NotificationEnvelope<T>) => any>(action);

  useEffect(() => {
    actionRef.current = action;
  }, [action]);

  useEffect(() => {
    if (didSubscribe.current) {
      notificationConsumerContext.unSubscribe(signal, id);
    }
    notificationConsumerContext.subscribe(signal, {
      consumerName: id,
      actionAfterConsume: (...args) => actionRef.current(...args)
    });
    didSubscribe.current = true;

    return () => {
      notificationConsumerContext.unSubscribe(signal, id);
    };
  }, [
    signal,
    notificationConsumerContext.notificationConsumerMap[signal]?.length
  ]);
}
