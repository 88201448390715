import React, { FC, memo, Ref, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import MaintenanceDashboardRoomDropdownItems from '@hkm/components/Maintenance/Dashboard/RoomItem/DropdownItems/MaintenanceDashboardRoomDropdownItems';
import { selectPropertyDateFormats } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import MaintenanceStateDotBadge from '@hkm/components/shared/MaintenanceStateDotBadge/MaintenanceStateDotBadge';
import MaintenanceStatusBadge from '@hkm/components/shared/MaintenanceStatusBadge/MaintenanceStatusBadge';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';
import { dayjs } from '@hkm/utils/dayjs-extended';

import { RoomMaintenanceState, RoomMaintenanceStatus } from '@ac/library-api';
import {
  AcButton,
  AcButtonPattern
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcButtonDropdown } from '@ac/mobile-components/dist/components/button-dropdown';
import { AcDropdownList } from '@ac/mobile-components/dist/components/dropdown-list';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcTileShell } from '@ac/mobile-components/dist/components/tile';
import { Icon } from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

import './MaintenanceDashboardRoom.css';

interface Props extends Testable {
  room: MaintenanceRoom;
}

const MaintenanceDashboardRoom: FC<Props> = ({ room, testSelector }) => {
  const { t } = useTranslation();
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const formats = useSelector(selectPropertyDateFormats);

  const onTileLinkClick = useCallback((event: React.MouseEvent) => {
    const isOptionsClick = optionsContainerRef.current?.contains(
      event.target as any
    );
    if (isOptionsClick) {
      event.preventDefault();
    }
  }, []);

  return (
    <AcTileShell
      className="maintenance-dashboard-room"
      testSelector={testSelector}
    >
      <Link
        to={generatePath(APP_ROUTES.MAINTENANCE.DETAILS, { id: room.id })}
        className="unstyled-link maintenance-dashboard-room-link"
        onClick={onTileLinkClick}
      >
        <MaintenanceStatusBadge
          status={room.status!.code! as RoomMaintenanceStatus}
          testSelector={testSelector}
        />

        <AcFormElement
          smallLabel={true}
          label={t('GLOBAL.ROOM_NUMBER.SHORT')}
          testSelector={formatTestSelector(testSelector, 'roomNumber')}
          ellipsis={true}
        >
          {room.room!.code}
        </AcFormElement>

        <AcFormElement
          smallLabel={true}
          label={t('ROOM_DETAILS.REASON')}
          testSelector={formatTestSelector(testSelector, 'reasonCode')}
          ellipsis={true}
        >
          {room.reason!.code}
        </AcFormElement>

        <div
          ref={optionsContainerRef}
          className="maintenance-dashboard-room-options"
        >
          <AcButtonDropdown
            /* tslint:disable-next-line:jsx-no-lambda */
            button={ref => (
              <AcButton
                pattern={AcButtonPattern.Tertiary}
                ref={ref as Ref<HTMLButtonElement>}
                testSelector={formatTestSelector(testSelector, 'optionsButton')}
              >
                <AcButtonContent icon={Icon.ActionMenu} />
              </AcButton>
            )}
            /* tslint:disable-next-line:jsx-no-lambda */
            popover={ref => (
              <AcDropdownList ref={ref as Ref<HTMLDivElement>}>
                <MaintenanceDashboardRoomDropdownItems
                  room={room}
                  testSelector={formatTestSelector(testSelector, 'option')}
                />
              </AcDropdownList>
            )}
          />
        </div>

        <AcFormElement
          smallLabel={true}
          label={t('ROOM_DETAILS.MAINTENANCE_STATE')}
          testSelector={formatTestSelector(testSelector, 'maintenanceState')}
        >
          <MaintenanceStateDotBadge
            state={room.state!.code as RoomMaintenanceState}
            testSelector={testSelector}
          />
        </AcFormElement>

        <AcFormElement
          smallLabel={true}
          label={t('GLOBAL.START_DATE.SHORT')}
          testSelector={formatTestSelector(testSelector, 'startDate')}
          ellipsis={true}
        >
          {dayjs(room.fromTime).format(formats.shortDate)}
        </AcFormElement>

        <AcFormElement
          smallLabel={true}
          label={t('GLOBAL.END_DATE.SHORT')}
          testSelector={formatTestSelector(testSelector, 'endDate')}
          ellipsis={true}
        >
          {dayjs(room.toTime).format(formats.shortDate)}
        </AcFormElement>
      </Link>
    </AcTileShell>
  );
};

export default memo(MaintenanceDashboardRoom);
