import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { selectActiveProperty } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import { useTaskDashboardManager } from '@hkm/components/TaskManagement/Dashboard/useTaskDashboardManager';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import OverflowEllipsis from '@hkm/components/TaskManagement/shared/components/OverflowEllipsis/OverflowEllipsis';
import TaskOverview from '@hkm/components/TaskManagement/shared/components/TaskOverview/TaskOverview';
import Comments from '@hkm/components/TaskManagement/TaskPreview/Comments/Comments';
import Details from '@hkm/components/TaskManagement/TaskPreview/Details/Details';
import {
  clearCurrentTask,
  clearLinkedObject,
  loadTask
} from '@hkm/components/TaskManagement/TaskPreview/domain/actions';
import { TaskPreviewDetails } from '@hkm/components/TaskManagement/TaskPreview/domain/interfaces';
import { getTaskForPreview } from '@hkm/components/TaskManagement/TaskPreview/domain/mappers';
import {
  getIsLoading,
  getIsLoadingLinkedObject,
  getLinkedObject,
  getTask,
  getTaskRelatedUsers
} from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import LinkedObject from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/LinkedObject';
import APP_ROUTES from '@hkm/constants/routing.constants';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { AcTileShell } from '@ac/mobile-components/dist/components/tile';
import {
  FlexDirection,
  Icon,
  TextSize,
  TextWeight
} from '@ac/mobile-components/dist/enums';

import './TaskPreview.css';

const TaskPreview = () => {
  const { initialize } = useTaskDashboardManager();
  const [mappedTask, setMappedTask] = useState<TaskPreviewDetails>();
  const { taskId } = useParams();
  const task = useSelector(getTask);
  const property = useSelector(selectActiveProperty);
  const linkedObject = useSelector(getLinkedObject);
  const relations = useSelector(getRelationsState);
  const taskRelatedUsers = useSelector(getTaskRelatedUsers);
  const isLoading = useSelector(getIsLoading);
  const isLoadingLinkedObject = useSelector(getIsLoadingLinkedObject);
  const dispatch = useDispatch();

  const backIcon = useMemo(
    () => (
      <Link className="unstyled-link" to={APP_ROUTES.TASK_MANAGEMENT.DASHBOARD}>
        <AcIcon icon={Icon.ArrowLeft} testSelector="task-preview-back-icon" />
      </Link>
    ),
    []
  );

  const hasComments = useMemo(
    () =>
      !!mappedTask?.history?.entries.filter(({ comment }) => comment).length,
    [mappedTask]
  );

  const header = useMemo(
    () => (
      <AcFlex
        direction={FlexDirection.column}
        className="ac-spacing-left-xs ac-spacing-right-sm"
      >
        <OverflowEllipsis
          text={mappedTask?.name || ''}
          size={TextSize.Main2}
          weight={TextWeight.Semibold}
          testSelector="task-name"
        />
        <OverflowEllipsis
          text={mappedTask?.number || ''}
          size={TextSize.Main1}
          testSelector="task-id"
        />
      </AcFlex>
    ),
    [mappedTask]
  );

  useEffect(() => {
    if (!task) {
      dispatch(loadTask.trigger(taskId!));
    }
  }, [task, taskId]);

  useEffect(() => {
    if (!relations.areRelationsLoaded) {
      initialize();
    }
  }, []);

  useEffect(() => {
    if (task && relations.areRelationsLoaded && relations.taskStatuses.length) {
      setMappedTask(
        getTaskForPreview(task, relations, property, taskRelatedUsers)
      );
    }
  }, [task, relations, property, taskRelatedUsers]);

  useEffect(() => {
    return () => {
      setMappedTask(undefined);
      dispatch(clearCurrentTask());
      dispatch(clearLinkedObject());
    };
  }, []);

  return (
    <>
      <ConnectedHeader className="task-preview-header" left={backIcon}>
        {header}
      </ConnectedHeader>
      <AcBody
        showSpinner={
          isLoading || relations.isLoadingRelations || isLoadingLinkedObject
        }
      >
        {mappedTask &&
          !isLoading &&
          relations.areRelationsLoaded &&
          !isLoadingLinkedObject && (
            <>
              <AcTileShell className="ac-padding-sm">
                <TaskOverview task={mappedTask} index={1} />
              </AcTileShell>
              {linkedObject?.type && <LinkedObject />}
              <Details task={mappedTask} />
              {hasComments && <Comments task={mappedTask} />}
            </>
          )}
      </AcBody>
    </>
  );
};

export default TaskPreview;
