import React, { ComponentType, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppSpinner from '@hkm/components/shared/AppSpinner/AppSpinner';
import { fetchTasksRelations } from '@hkm/components/TaskManagement/relations/domain/actions';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import { TaskManagementRequiredDictionaries } from '@hkm/components/TaskManagement/shared/config/taskManagementDictionaries';

export const requireTasksDictionaries = (): (<P extends Record<
  string,
  unknown
>>(
  WrappedComponent: ComponentType<P>
) => ComponentType<P>) => <P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>
  // eslint-disable-next-line react/display-name
): ComponentType<P> => (props: P) => {
  const dispatch = useDispatch();
  const dictionaries = useSelector(getRelationsState);

  useEffect(() => {
    if (dictionaries.areRelationsLoaded) {
      return;
    }
    dispatch(fetchTasksRelations.trigger());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictionaries.areRelationsLoaded]);

  const hasDictionaries = useMemo(() => {
    return Array.from(TaskManagementRequiredDictionaries).every(dictionary =>
      Array.isArray(dictionaries[dictionary])
    );
  }, [dictionaries]);

  return hasDictionaries ? <WrappedComponent {...props} /> : <AppSpinner />;
};
