import { ReservationStatus } from '@ac/library-api';

export function isReservationInActiveStatus(
  status?: ReservationStatus
): boolean {
  return [
    ReservationStatus.IH,
    ReservationStatus.DO,
    ReservationStatus.CO
  ].includes(status ?? ReservationStatus.CX);
}
