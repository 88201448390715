import React, { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPropertyDateFormats } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { DateTimeFiltersConfigItem } from '@hkm/components/TaskManagement/Dashboard/config/dateFiltersConfig';
import { DateTimeFilters } from '@hkm/components/TaskManagement/Dashboard/domain/enums';
import { Filters } from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import { getDashboardQuickFilter } from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';

import {
  AcFieldDate,
  AcFieldTime
} from '@ac/mobile-components/dist/components/field';

interface ComponentProps {
  filterItem: DateTimeFiltersConfigItem;
  filtersState: Filters;
}

const DateTimeFilterItem: FC<ComponentProps> = props => {
  const { filterItem, filtersState } = props;
  const { shortDate: shortDateFormat, time: timeFormat } = useSelector(
    selectPropertyDateFormats
  );
  const currentQuickFilter = useSelector(getDashboardQuickFilter);

  const isDateField = useMemo(
    () =>
      filterItem.name === DateTimeFilters.DateFrom ||
      filterItem.name === DateTimeFilters.DateTo,
    [filterItem.name]
  );

  const handleChange = (value?: string): void => {
    filterItem.updateValue(value);
  };

  const isTimeFieldDisabled = useMemo(() => {
    return filterItem.name === DateTimeFilters.TimeTo
      ? currentQuickFilter === TaskQuickFilter.DUE_TODAY || !filtersState.dateTo
      : currentQuickFilter === TaskQuickFilter.DUE_TODAY ||
          !filtersState.dateFrom;
  }, [filtersState, currentQuickFilter]);

  const borderTimeValues = useMemo(() => {
    return filterItem.name === DateTimeFilters.DateFrom
      ? { dateFrom: undefined, dateTo: filtersState.dateTo }
      : { dateFrom: filtersState.dateFrom, dateTo: undefined };
  }, [filterItem.name, filtersState]);

  return isDateField ? (
    <AcFieldDate
      className="ac-spacing-top-md"
      placeholder={shortDateFormat}
      label={filterItem.label}
      format={shortDateFormat}
      disabled={currentQuickFilter === TaskQuickFilter.DUE_TODAY}
      value={filterItem.value}
      min={borderTimeValues.dateFrom}
      max={borderTimeValues.dateTo}
      testSelector={`task-dashboard-filters-${filterItem.name}-field`}
      onChange={handleChange}
    />
  ) : (
    <AcFieldTime
      className="ac-spacing-top-md"
      label={filterItem.label}
      format={timeFormat}
      disabled={isTimeFieldDisabled}
      value={filterItem.value}
      testSelector={`task-dashboard-filters-${filterItem.name}-field`}
      onChange={handleChange}
    />
  );
};

export default memo(DateTimeFilterItem);
