import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatNumberRanges,
  parseNumberRanges
} from '@hkm/shared/numberRange/numberRangesParser';
import { getFieldErrorMessage } from '@hkm/shared/validation/errorProvider';

import { AcFieldText } from '@ac/mobile-components/dist/components/field';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps/childless';
import { Styleable } from '@ac/mobile-components/dist/interfaces/componentProps/styleable';
import { genericMemo } from '@ac/mobile-components/dist/utils';
import { formFieldFactory, FormRenderProps } from '@ac/react-infrastructure';

interface AdvancedFiltersMultiSelectsProps<T> extends Childless, Styleable {
  testSelectorPrefix: string;
  formProps: FormRenderProps<T>;
  field: keyof T;
  label: string;
}

function AdvancedFiltersNumberRange<T>(
  props: AdvancedFiltersMultiSelectsProps<T>
): ReactElement {
  const { t } = useTranslation();
  const FormField = useMemo(() => formFieldFactory<T>(), []);

  function onBlur() {
    const parsed = parseNumberRanges(
      props.formProps.values[props.field as any]
    );
    if (parsed) {
      props.formProps.form.change(
        props.field as any,
        formatNumberRanges(parsed) as any
      );
    }
  }

  return (
    <FormField valuePath={props.field as any}>
      {fieldRenderProps => (
        <AcFieldText
          {...(fieldRenderProps.input as any)}
          className={props.className}
          style={props.style}
          onBlur={onBlur}
          validation={getFieldErrorMessage(fieldRenderProps)}
          placeholder={t('GLOBAL.FILL')}
          label={props.label}
          testSelector={`${props.testSelectorPrefix}-${String(props.field)}`}
        />
      )}
    </FormField>
  );
}

export default genericMemo(AdvancedFiltersNumberRange);
