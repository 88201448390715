import React, { ComponentType } from 'react';
import { Navigate } from 'react-router';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { FeatureToggle } from '@hkm/shared/enum/featureToggle';
import { useHasRequiredFeatureToggles } from '@hkm/shared/hooks/useHasRequiredFeatureToggles';
import { UnknownObject } from '@hkm/types/shared/unknownObject';

export const requireFeatureToggles = (
  requiredFeatureToggles: Set<FeatureToggle>
): (<P extends UnknownObject>(
  WrappedComponent: ComponentType<P>
) => ComponentType<P>) => <P extends UnknownObject>(
  WrappedComponent: ComponentType<P>
  // eslint-disable-next-line react/display-name
): ComponentType<P> => (props: P) => {
  const hasAccess = useHasRequiredFeatureToggles(requiredFeatureToggles);

  return hasAccess ? (
    <WrappedComponent {...props} />
  ) : (
    <Navigate to={APP_ROUTES.ACCESS_DENIED} />
  );
};
