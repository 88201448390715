import { getConfigurationWithLanguage } from '@hkm/utils/api';

import {
  getDtoForAllPages,
  HousekeepingRoomDetailsDto,
  LibraryApiResponse,
  PageQueryParams,
  QueryQueryParams,
  RawPageResponse
} from '@ac/library-api';
import { HousekeepingViewsApi } from '@ac/library-api/dist/api/v0/housekeeping';

export const getRoomDashboard = async (
  filter?: QueryQueryParams
): Promise<HousekeepingRoomDetailsDto[]> => {
  const customConfig = getConfigurationWithLanguage();

  return (
    await getDtoForAllPages(
      ({ pageNumber, pageSize }: PageQueryParams) =>
        HousekeepingViewsApi.getHousekeepingDashboard({
          queryParams: { pageNumber, pageSize, ...filter },
          customConfig
        }) as Promise<
          LibraryApiResponse<RawPageResponse<HousekeepingRoomDetailsDto>>
        >
    )
  ).results;
};
