import { MaintenanceUpdateFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/models/maintenanceFormData';
import { UnifiedMaintenanceDetails } from '@hkm/types/maintenance/models/UnifiedMaintenanceDetails';
import { UnifiedRoomDetails } from '@hkm/types/room/model/UnifiedRoomDetails';

import {
  RoomMaintenanceState,
  RoomMaintenanceStatus,
  RoomStatus
} from '@ac/library-api';

export function mapToMaintenanceFormData(
  maintenanceDetails: UnifiedMaintenanceDetails,
  unifiedRoomDetails: UnifiedRoomDetails,
  businessDate: string
): MaintenanceUpdateFormData {
  return {
    id: maintenanceDetails.id,
    roomNumber: unifiedRoomDetails.roomNumber,
    roomType: unifiedRoomDetails.roomType?.code,
    comment: maintenanceDetails.comment,
    fromTime: maintenanceDetails.fromTime,
    toTime: maintenanceDetails.toTime,
    returnStatusCode: maintenanceDetails.returnRoomStatus!.code as RoomStatus,
    housekeepingRoomVersion: unifiedRoomDetails.housekeepingRoomVersion,
    statusCode: maintenanceDetails.status!.code as RoomMaintenanceStatus,
    reasonCode: maintenanceDetails.reason!.code,
    state: maintenanceDetails.state?.code as RoomMaintenanceState,
    businessDate
  };
}
