import React, { FC, memo } from 'react';
import { getStringByteLength } from '@hkm/utils/getStringByteLength';

import {
  getInitials,
  HousekeepingRoomReservationGuestPersonalDataDetailsDto,
  IdCodeDescriptionColor
} from '@ac/library-api';
import { AcAvatar } from '@ac/mobile-components/dist/components/avatar';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import { Color, Icon } from '@ac/mobile-components/dist/enums';
import {
  Clickable,
  Styleable,
  Testable
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

interface GuestAvatarTemplateProps extends Clickable, Testable, Styleable {
  hasDisabilities: boolean;
  guestPersonalData?: HousekeepingRoomReservationGuestPersonalDataDetailsDto;
  guestVipCode?: IdCodeDescriptionColor;
  getAvatar?: Promise<string>;
}

const GuestAvatarTemplate: FC<GuestAvatarTemplateProps> = (
  props: GuestAvatarTemplateProps
) => {
  const { firstName, lastName } = props.guestPersonalData?.name ?? {
    firstName: '',
    lastName: ''
  };
  const initials = getInitials(firstName ?? '', lastName ?? '');
  const hasTwoBytesCharacters = getStringByteLength(initials) > 2;

  const vipColor = props.guestVipCode ? props.guestVipCode.color : undefined;

  return (
    <AcAvatar
      onClick={props.onClick}
      getImage={props.getAvatar}
      contractedTouchArea={true}
      testSelector={formatTestSelector(props.testSelector, 'guestAvatar')}
      indicator={
        vipColor && <AcIndicator backgroundColor={vipColor} icon={Icon.Vip} />
      }
      className={props.className}
    >
      {props.hasDisabilities && (
        <AcIcon icon={Icon.Disability} color={Color.Alert} />
      )}
      {!props.hasDisabilities &&
        (hasTwoBytesCharacters ? (
          <AcIcon icon={Icon.Profile} color={Color.Black} />
        ) : (
          initials
        ))}
    </AcAvatar>
  );
};

export default memo(GuestAvatarTemplate);
