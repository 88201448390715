import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as appActions from '@hkm/components/App/domain/actions';
import {
  selectOverlayMessages,
  selectOverlayMessagesOffsetRefs
} from '@hkm/components/App/domain/selectors';
import { selectMenuState } from '@hkm/components/Menu/domain/selectors';

import {
  AcOverlayMessageContainer,
  OverlayMessageState
} from '@ac/mobile-components/dist/components/overlay-message';

const ConnectedOverlayMessageContainer: FC = () => {
  const dispatch = useDispatch();
  const messages = useSelector(selectOverlayMessages);
  const offsetRefs = useSelector(selectOverlayMessagesOffsetRefs);
  const isMenuVisible = useSelector(selectMenuState);

  const offsetRefsToUse = isMenuVisible ? [] : offsetRefs;

  const onMessageChange = useCallback((newMessages: OverlayMessageState[]) => {
    dispatch(appActions.setMessages(newMessages));
  }, []);

  return (
    <AcOverlayMessageContainer
      onMessagesChange={onMessageChange}
      bottomOffsetRefs={offsetRefsToUse}
      messages={messages}
    />
  );
};

export default memo(ConnectedOverlayMessageContainer);
