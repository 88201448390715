import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { AttendantTaskProgressType } from '@ac/library-api';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { Color, TextSize, TextWeight } from '@ac/mobile-components/dist/enums';
import {
  Styleable,
  Testable
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorAttribute } from '@ac/mobile-components/dist/utils';

import './NoRoomItem.css';

interface NoRoomItemProps extends Styleable, Testable {
  roomStatus: AttendantTaskProgressType;
}
const NoRoomItem: FC<NoRoomItemProps> = (props: NoRoomItemProps) => {
  const { t } = useTranslation();
  const prefix = 'attendant-no-room-item';
  const classes = classNames(prefix, props.className);

  return (
    <div className={classes} {...getTestSelectorAttribute(props.testSelector)}>
      <AcText
        color={Color.Gray3}
        size={TextSize.Main2}
        weight={TextWeight.Semibold}
      >
        {t(
          `ATTENDANT_ASSIGNMENTS.ROOM_LIST.NO_ROOM_MESSAGE.${props.roomStatus}`
        )}
      </AcText>
    </div>
  );
};

export default memo(NoRoomItem);
