import { initReactI18next } from 'react-i18next';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import { dayjs } from '@hkm/utils/dayjs-extended';
import i18n, { TFunction } from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';

import { setUpI18next } from '@ac/library-utils/dist/i18next-config';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
    returnDetails: false;
    returnObjects: false;
  }
}

const detection = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain'
  ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
};

const dateLocaleMap = {
  'zh-Hans': 'zh-cn',
  'zh-Hant': 'zh-cn'
};

registerI18nListeners();

export const initI18n = async (
  namespaces?: string[] | string
): Promise<TFunction> => {
  return setUpI18next(i18n, {
    plugins: {
      backend,
      initReactI18next,
      custom: [I18nextBrowserLanguageDetector]
    },
    moduleName: 'housekeepingMobile',
    useRemoteTranslations: true,
    repositoryName: 'frontend-housekeeping-mobile',
    initOptions: {
      returnNull: false,
      returnDetails: false,
      returnObjects: false,
      detection,
      fallbackLng: {
        'zh-Hans': ['zh', 'zh-Hant', APP_CONSTANTS.FALLBACK_LANGUAGE],
        default: [APP_CONSTANTS.FALLBACK_LANGUAGE]
      },
      ns: namespaces,
      debug: true,
      lng:
        localStorage.getItem(detection.lookupLocalStorage) ||
        APP_CONSTANTS.FALLBACK_LANGUAGE,

      interpolation: {
        escapeValue: false
      }
    }
  });
};

function registerI18nListeners() {
  i18n.on('languageChanged', lng => {
    dayjs.locale(dateLocaleMap[lng] ?? lng);
  });
}

export default i18n;
