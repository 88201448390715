import React, { FC, memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router';
import * as maintenanceDashboardAction from '@hkm/components/Maintenance/Dashboard/domain/actions';
import * as detailsActions from '@hkm/components/Maintenance/Details/domain/actions';
import { selectMaintenanceDetailsState } from '@hkm/components/Maintenance/Details/domain/selectors';
import { selectBusinessDate } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import * as createMaintenanceActions from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/actions';
import { selectMaintenanceCreateState } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/selectors';
import MaintenanceForm from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/MaintenanceCreateForm';
import { getInitialData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/utils/utils';
import APP_ROUTES from '@hkm/constants/routing.constants';

import { SetMaintenances } from '@ac/library-api';
import { AcSpinnerCover } from '@ac/mobile-components/dist/components/spinner';
import { useComponentDidUpdateEffect } from '@ac/mobile-components/dist/hooks';

const CopyMaintenance: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();

  const maintenanceCreateState = useSelector(selectMaintenanceCreateState);

  const businessDate = useSelector(selectBusinessDate);
  const roomState = useSelector(selectMaintenanceDetailsState);

  const initialValues = getInitialData(roomState.room! || {}, businessDate);

  const showSpinner =
    !roomState.didInitialize ||
    roomState.isChanging ||
    roomState.isFetching ||
    maintenanceCreateState.isPending;

  useEffect(() => {
    dispatch(detailsActions.reset());
    dispatch(detailsActions.initialize(params.id!));

    return () => {
      dispatch(maintenanceDashboardAction.pagination.refreshPage());
    };
  }, []);

  const onSubmit = useCallback((data: SetMaintenances) => {
    dispatch(
      createMaintenanceActions.setMaintenanceActionsSet.createMaintenance.trigger(
        {
          data
        }
      )
    );
  }, []);

  const onValidateMaintenance = useCallback((data: SetMaintenances) => {
    dispatch(
      createMaintenanceActions.setMaintenanceActionsSet.validateMaintenance.trigger(
        {
          data
        }
      )
    );
  }, []);

  useComponentDidUpdateEffect(() => {
    backward();
  }, [maintenanceCreateState.version]);

  function backward(): void {
    navigate(APP_ROUTES.MAINTENANCE.DASHBOARD);
  }

  // Navigate to dashboard if we initialized, but no room is available
  if (!roomState.room && roomState.didInitialize) {
    return <Navigate to={APP_ROUTES.MAINTENANCE.DASHBOARD} />;
  }

  return (
    <>
      {roomState.room && (
        <MaintenanceForm
          backward={backward}
          initialValues={initialValues}
          onSubmit={onSubmit}
          onValidateMaintenance={onValidateMaintenance}
          title={t('MAINTENANCE_CREATE.TITLE.COPY')}
        />
      )}
      <AcSpinnerCover
        fixed={true}
        testSelector="create-maintenance-spinner"
        disabled={!showSpinner}
      />
    </>
  );
};

export default memo(CopyMaintenance);
