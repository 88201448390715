import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AttendantEffectiveValues } from '@hkm/components/Attendant/shared/config/attendantEffeectiveValues';
import { AttendantViewPermissionsConfig } from '@hkm/components/Attendant/shared/config/attendantViewPermissionsConfig';
import { HousekeepingEffectiveValues } from '@hkm/components/Housekeeping/shared/config/housekeepingEffectiveValues';
import { HousekeepingViewPermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingViewPermissionsConfig';
import { selectUserDetails } from '@hkm/components/Login/domain/selectors';
import { MaintenanceViewPermissionsConfig } from '@hkm/components/Maintenance/config/maintenanceViewPermissionsConfig';
import { MaintenanceEffectiveValues } from '@hkm/components/Maintenance/shared/config/maintenanceEffectiveValues';
import { setMenuState } from '@hkm/components/Menu/domain/actions';
import { MenuBehaviour } from '@hkm/components/Menu/domain/interfaces';
import { selectMenuBehaviour } from '@hkm/components/Menu/domain/selectors';
import LanguageSelector from '@hkm/components/Menu/LanguageSelector/LanguageSelector';
import { selectPropertyLoadingState } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import PropertySelector from '@hkm/components/Menu/PropertySelector/PropertySelector';
import MenuLinkItem from '@hkm/components/Menu/SidebarMenu/MenuLinkItem';
import MenuModuleItem from '@hkm/components/Menu/SidebarMenu/MenuModuleItem';
import { HousekeepingPostingEffectiveValues } from '@hkm/components/Posting/shared/config/postingEffeectiveValues';
import { HousekeepingPostingPermissionsConfig } from '@hkm/components/Posting/shared/config/postingPermissionsConfig';
import LanguageFlag from '@hkm/components/shared/LanguageFlag/LanguageFlag';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import { TaskManagementEffectiveValues } from '@hkm/components/TaskManagement/shared/config/taskManagementEffectiveValues';
import { TaskManagementFeatureToggles } from '@hkm/components/TaskManagement/shared/config/taskManagementFeatureToggles';
import { TaskManagementViewPermissionsConfig } from '@hkm/components/TaskManagement/shared/config/taskManagementViewPermissionsConfig';
import APP_ROUTES from '@hkm/constants/routing.constants';
import {
  ExpectedEffectiveValuesResult,
  useHasExpectedEffectiveValues
} from '@hkm/shared/hooks/useHasRequiredEffectiveValues';
import { useHasRequiredFeatureToggles } from '@hkm/shared/hooks/useHasRequiredFeatureToggles';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';

import { getInitials, UserDetails } from '@ac/library-api';
import { LoginService, SessionDataHost } from '@ac/library-utils/dist/services';
import {
  AcButton,
  AcButtonPattern
} from '@ac/mobile-components/dist/components/button';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcBody, AcLayout } from '@ac/mobile-components/dist/components/layout';
import { AcSpinnerCover } from '@ac/mobile-components/dist/components/spinner';
import { AcSystemUser } from '@ac/mobile-components/dist/components/system-user';
import {
  Color,
  Icon,
  LoadingState,
  Theme
} from '@ac/mobile-components/dist/enums';

import './SidebarMenu.css';

const SidebarMenu: FC = () => {
  const { t } = useTranslation();
  const userDetails: UserDetails | null = useSelector(selectUserDetails);
  const dispatch = useDispatch();
  const location = useLocation();
  const testSelectorPrefix = 'navigation';

  const isLoading =
    useSelector(selectPropertyLoadingState) === LoadingState.Loading;

  const enableAttendantMobileApplication =
    useHasExpectedEffectiveValues(AttendantEffectiveValues) ===
    ExpectedEffectiveValuesResult.POSITIVE;
  const enableMaintenanceMobileApplication =
    useHasExpectedEffectiveValues(MaintenanceEffectiveValues) ===
    ExpectedEffectiveValuesResult.POSITIVE;
  const enableHousekeepingMobileApplication =
    useHasExpectedEffectiveValues(HousekeepingEffectiveValues) ===
    ExpectedEffectiveValuesResult.POSITIVE;
  const enableHousekeepingMobilePosting =
    useHasExpectedEffectiveValues(HousekeepingPostingEffectiveValues) ===
    ExpectedEffectiveValuesResult.POSITIVE;
  const isTaskManagementMobileSettingOn =
    useHasExpectedEffectiveValues(TaskManagementEffectiveValues) ===
    ExpectedEffectiveValuesResult.POSITIVE;
  const enableTaskManagementMobileApplication =
    useHasRequiredFeatureToggles(TaskManagementFeatureToggles) &&
    isTaskManagementMobileSettingOn;

  const hasPostingPermissions = usePermission(
    ...HousekeepingPostingPermissionsConfig
  );
  const hasHousekeepingPermissions = usePermission(
    ...HousekeepingViewPermissionsConfig
  );
  const hasMaintenancePermissions = usePermission(
    ...MaintenanceViewPermissionsConfig
  );
  const hasAttendantPermissions = usePermission(
    ...AttendantViewPermissionsConfig
  );
  const hasTaskManagementPermissions = usePermission(
    ...TaskManagementViewPermissionsConfig
  );

  const closeMenu = useCallback(() => {
    dispatch(setMenuState(false));
  }, []);

  const handleLogout = useCallback(() => {
    SessionDataHost.clearSessionData();
    LoginService.logout();
  }, []);

  const isRouteActive = (activePath: string): boolean => {
    return location.pathname === activePath;
  };

  const getUserName = (): string => {
    return userDetails && userDetails.userName ? userDetails.userName : '';
  };

  const getEmail = (): string => {
    return userDetails && userDetails.email ? userDetails.email : '';
  };

  const showCloseButton =
    useSelector(selectMenuBehaviour) !== MenuBehaviour.Force;

  const initials =
    userDetails &&
    getInitials(
      userDetails!.personalData!.firstName!,
      userDetails!.personalData!.lastName!
    );

  const logo = useMemo(
    () => <AcIcon icon={Icon.AcProject} className="app-logo" />,
    []
  );

  return (
    <AcLayout className="off-canvas-menu">
      <ConnectedHeader
        forceHideMenu={true}
        left={logo}
        close={showCloseButton ? closeMenu : undefined}
        showHelpButton={true}
      >
        {t('MENU.WELCOME')}
      </ConnectedHeader>

      <AcBody contentClassName="app-menu" noPadding={true}>
        <div className="top-wrapper">
          <div className="active-module-container">
            <div className="module-icon-wrapper">
              <MenuModuleItem icon={Icon.Housekeeping} isActive={true} />
            </div>
            <div className="active-module-content-wrapper">
              <div className="active-module-title">
                {t('MENU.HOUSEKEEPING')}
              </div>

              <div className="active-module-content">
                {hasHousekeepingPermissions &&
                  enableHousekeepingMobileApplication && (
                    <MenuLinkItem
                      text={t('MENU.LINKS.HOUSEKEEPING_DASHBOARD')}
                      link={APP_ROUTES.HOUSEKEEPING.DASHBOARD}
                      isActive={isRouteActive(
                        APP_ROUTES.HOUSEKEEPING.DASHBOARD
                      )}
                      testSelector={`${testSelectorPrefix}-link-housekeeping-dashboard`}
                      onClick={closeMenu}
                    />
                  )}

                {hasMaintenancePermissions &&
                  enableMaintenanceMobileApplication && (
                    <MenuLinkItem
                      text={t('MENU.LINKS.MAINTENANCE_DASHBOARD')}
                      link={APP_ROUTES.MAINTENANCE.DASHBOARD}
                      isActive={isRouteActive(APP_ROUTES.MAINTENANCE.DASHBOARD)}
                      testSelector={`${testSelectorPrefix}-link-maintenance-dashboard`}
                      onClick={closeMenu}
                    />
                  )}

                {hasAttendantPermissions &&
                  enableAttendantMobileApplication && (
                    <MenuLinkItem
                      text={t('MENU.LINKS.ATTENDANT_ASSIGNMENTS')}
                      link={APP_ROUTES.ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY}
                      isActive={isRouteActive(
                        APP_ROUTES.ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY
                      )}
                      testSelector={`${testSelectorPrefix}-link-attendant-assignments`}
                      onClick={closeMenu}
                    />
                  )}

                {hasPostingPermissions && enableHousekeepingMobilePosting && (
                  <MenuLinkItem
                    text={t('MENU.LINKS.POSTING')}
                    link={APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.DASHBOARD}
                    isActive={isRouteActive(
                      APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.DASHBOARD
                    )}
                    testSelector={`${testSelectorPrefix}-posting-dashboard`}
                    onClick={closeMenu}
                  />
                )}
              </div>
            </div>
          </div>
          {hasTaskManagementPermissions &&
            enableTaskManagementMobileApplication && (
              <div className="active-module-container">
                <div className="module-icon-wrapper">
                  <MenuModuleItem icon={Icon.Task} isActive={true} />
                </div>
                <div className="active-module-content-wrapper">
                  <div className="active-module-title">
                    {t('MENU.TASK_MANAGEMENT')}
                  </div>

                  <div className="active-module-content">
                    <MenuLinkItem
                      text={t('MENU.LINKS.TASK_MANAGEMENT_DASHBOARD')}
                      link={APP_ROUTES.TASK_MANAGEMENT.DASHBOARD}
                      isActive={isRouteActive(
                        APP_ROUTES.TASK_MANAGEMENT.DASHBOARD
                      )}
                      testSelector={`${testSelectorPrefix}-task-management-dashboard`}
                      onClick={closeMenu}
                    />
                  </div>
                </div>
              </div>
            )}
        </div>

        <div className="bottom-wrapper">
          <div className="item-property">
            <div className="item-icon">
              <LanguageFlag />
            </div>
            <div className="item-content">
              <LanguageSelector />
            </div>
          </div>

          <div className="item-property">
            <div className="item-icon">
              <MenuModuleItem icon={Icon.Home} />
            </div>
            <div className="item-content">
              <>
                <PropertySelector />
              </>
            </div>
          </div>

          <div className="item-user-data">
            <div className="item-icon">
              <AcSystemUser className="ac-spacing-top-xs" theme={Theme.Light}>
                {initials}
              </AcSystemUser>
            </div>

            <div className="item-content">
              <div className="user-data">
                <div className="username">{getUserName()}</div>
                <div className="email">{getEmail()}</div>
              </div>
              <div className="item-logout">
                <AcButton
                  pattern={AcButtonPattern.Tertiary}
                  onClick={handleLogout}
                  testSelector={`${testSelectorPrefix}-button-logout`}
                >
                  <AcIcon icon={Icon.Power} color={Color.PrimaryDark} />
                </AcButton>
              </div>
            </div>
          </div>
        </div>
      </AcBody>
      {isLoading && <AcSpinnerCover coverScreen={true} />}
    </AcLayout>
  );
};

export default memo(SidebarMenu);
