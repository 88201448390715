import React, { FC, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate } from 'react-router';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import PostingReservationDetails from '@hkm/components/Posting/shared/components/PostingReservationDetails';
import ConnectGuestAvatarTemplate from '@hkm/components/shared/Templates/Guest/GuestAvatar/ConnectGuestAvatarTemplate';
import GuestNameTemplate from '@hkm/components/shared/Templates/Guest/GuestNameTemplate';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { SourceModuleType } from '@hkm/shared/enum/SourceModuleType';
import { PostChargeLocationState } from '@hkm/shared/interfaces/locationStates';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { AcButton } from '@ac/mobile-components/dist/components/button';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AcFormElement,
  AcFormGroup
} from '@ac/mobile-components/dist/components/form-element';
import { AcTile } from '@ac/mobile-components/dist/components/tile';
import AcMobileContext from '@ac/mobile-components/dist/config';
import {
  AlignItems,
  Icon,
  JustifyContent
} from '@ac/mobile-components/dist/enums';
import {
  Styleable,
  Testable
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

export interface PostingReservationTileProps extends Styleable, Testable {
  roomId: string;
  showGuestNames?: boolean;
  reservation: UnifiedReservationDetails;
}

const defaultProps: Partial<PostingReservationTileProps> = {
  showGuestNames: true
};

const PostingReservationTile: FC<PostingReservationTileProps> = (
  props: PostingReservationTileProps
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const appContext = useContext(AcMobileContext)!;

  const guest = props.reservation.guest!;
  const effectiveValues = useSelector(selectEffectiveValues)!;
  const guestVipColor = guest.vipCode ? guest.vipCode.color : undefined;

  const postButtonHandler = useCallback(() => {
    const locationState = location.state || ({} as PostChargeLocationState);
    const modulePath =
      appContext.currentModuleName === SourceModuleType.AttendantPosting
        ? APP_ROUTES.MOBILE_POSTING.ATTENDANT.POSTING_VIEW
        : APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.POSTING_VIEW;

    navigate(
      generatePath(modulePath, {
        roomId: props.roomId,
        reservationId: props.reservation.id
      }),
      {
        state: locationState
      }
    );
  }, [props.reservation, appContext.currentModuleName]);

  return (
    <AcTile
      style={props.style}
      icon={Icon.Sharers}
      className={props.className}
      title={t('ROOM_DETAILS.SHARED_RESERVATION_GUEST')}
    >
      <AcFormGroup>
        {props.showGuestNames && guest && (
          <AcFlex alignItems={AlignItems.center}>
            {effectiveValues.displayGuestAvatar && (
              <ConnectGuestAvatarTemplate
                className="ac-spacing-right-sm"
                guest={guest}
              />
            )}
            <AcFormElement
              label={t('ROOM_DETAILS.GUEST')}
              testSelector={formatTestSelector(props.testSelector, 'guest')}
            >
              <GuestNameTemplate
                vipBadgeColor={guestVipColor}
                guestPersonalData={guest.personalData}
                hasDisabilities={!!guest.disabilityStatusCodes?.length}
              />
            </AcFormElement>
          </AcFlex>
        )}

        <PostingReservationDetails reservation={props.reservation} />

        {props.roomId && (
          <AcFlex
            alignItems={AlignItems.center}
            justifyContent={JustifyContent.center}
          >
            <AcButton onClick={postButtonHandler} fullwidth={true}>
              {t('POSTING.RESERVATION_LIST.CHOOSE_TO_POST')}
            </AcButton>
          </AcFlex>
        )}
      </AcFormGroup>
    </AcTile>
  );
};

PostingReservationTile.defaultProps = defaultProps;

export default memo(PostingReservationTile);
