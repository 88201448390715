export enum Permission {
  HousekeepingViewRooms = 'housekeepingViewRooms',
  HousekeepingViewSummary = 'housekeepingViewSummary',
  HousekeepingViewRoomHistory = 'housekeepingViewRoomHistory',
  HousekeepingManageRoomStatuses = 'housekeepingManageRoomStatuses',
  HousekeepingManageRoomMaintenances = 'housekeepingManageRoomMaintenances',
  HousekeepingViewAttendantAssignment = 'housekeepingViewAttendantAssignment',
  HousekeepingManageAttendantAssignment = 'housekeepingManageAttendantAssignment',
  HousekeepingSetRoomStatusInspected = 'housekeepingSetRoomStatusInspected',
  HousekeepingReservationsViews = 'housekeepingReservationsViews',
  HousekeepingPostChargesUsingMobilePosting = 'housekeepingPostChargesUsingMobilePosting',
  HousekeepingPostReservationCharges = 'housekeepingPostReservationCharges',
  HousekeepingViewReservationCharges = 'housekeepingViewReservationCharges',
  HousekeepingViewMaintenance = 'housekeepingViewMaintenance',
  HousekeepingViewAndManageMaintenanceWithOverbooking = 'housekeepingManageMaintenanceWithOverbooking',
  HousekeepingUseMobileAttendantApplication = 'housekeepingUseMobileAttendantApplication',
  HousekeepingAttendantCreateMaintenanceForAssignedRoom = 'housekeepingAttendantCreateMaintenanceForAssignedRoom',

  TaskViewAndUpdateAll = 'taskViewAndUpdateAll',
  TaskAssignUser = 'taskAssignUser',
  TaskCancel = 'taskCancel',
  TaskManualEscalationDeescalation = 'taskManualEscalationDeescalation',
  UseMobileTaskManagementApplication = 'useMobileTaskManagementApplication'
}

export const TaskPermissionIds = {
  taskViewAndUpdateAll: '7ed28828-1663-43c7-ac70-1a6690b7aefb',
  taskAssignUser: '4390f1ec-efb3-479d-ba43-c46ad4b8a01a',
  taskManualEscalationDeescalation: 'f5650b78-ab87-46d6-9712-d83702b29cba',
  useMobileTaskManagementApplication: 'b822137c-e4ed-403f-a1e5-fc4ac6cdc8e4',
  taskCancel: '8a85ba39-f8be-42a1-9ab8-475e382953ad'
};

export const PermissionIds: Record<Permission, string> = {
  housekeepingViewRooms: '4c10481b-ac98-42de-a89e-b723035da6e2',
  housekeepingViewSummary: '1276bab4-4d3f-43f9-b771-bfb080f365f5',
  housekeepingViewRoomHistory: '977f20b2-99f4-438d-b716-c223ee3c08ac',
  housekeepingManageRoomStatuses: '1e928390-c7d7-49e8-b58b-e34b29f6ba3b',
  housekeepingManageRoomMaintenances: 'b78cbc50-c3b3-49d5-ba91-1ca8ff0e0d4c',
  housekeepingViewAttendantAssignment: '7f1c4b01-ac8e-4ad6-83ab-3ffa8fa657ef',
  housekeepingManageAttendantAssignment: '70e5b43c-a1ac-48b3-b4da-51b28fe6a2f7',
  housekeepingSetRoomStatusInspected: '74ec5e73-f646-42d9-8b7a-c06812742601',
  housekeepingReservationsViews: 'b6796503-59f4-4658-a0f1-b81473e40abe',
  housekeepingPostChargesUsingMobilePosting:
    '0f07d7bb-386d-4f0c-ac7a-ddbbe4a1559c',
  housekeepingPostReservationCharges: '91998d30-fa05-4b65-b68d-e62cbbfc2612',
  housekeepingViewReservationCharges: 'b6658ed8-eac2-4ded-bbc4-0422f63dfbdd',
  housekeepingViewMaintenance: '4c434c9d-e42d-4dcf-9639-b58ae9b484dd',
  housekeepingManageMaintenanceWithOverbooking:
    '6aa86dae-4a5f-44ae-954b-8d3a71f85313',
  housekeepingUseMobileAttendantApplication:
    'a4afb135-2ef1-42d4-adf7-ba4fc1abf619',
  housekeepingAttendantCreateMaintenanceForAssignedRoom:
    '46c1e277-f801-4971-8188-afe57f342ac6',
  ...TaskPermissionIds
};
