import React from 'react';
import { MultiElementAlertBar } from '@hkm/components/Attendant/shared/components/alertBar/alertBarContent/MultiElementAlertBar';
import { QueueRoomsAlertBar } from '@hkm/components/Attendant/shared/components/alertBar/alertBarContent/QueueRoomsAlertBar';
import { ServiceRequestedRoomsAlertBar } from '@hkm/components/Attendant/shared/components/alertBar/alertBarContent/ServiceRequestedRoomsAlertBar';
import { noop } from '@hkm/shared/helpers/noop';

interface Props {
  queueRoomsCount?: number;
  serviceRequestedRoomsCount?: number;
  showLink?: boolean;
  onShow?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const AlertBar = ({
  queueRoomsCount,
  serviceRequestedRoomsCount,
  showLink,
  onShow = noop
}: Props): JSX.Element | null => {
  if (serviceRequestedRoomsCount || queueRoomsCount) {
    if (serviceRequestedRoomsCount && !queueRoomsCount) {
      return (
        <ServiceRequestedRoomsAlertBar
          serviceRequestedRoomsCount={serviceRequestedRoomsCount}
          showLink={showLink}
          onShow={onShow}
        />
      );
    }

    if (!serviceRequestedRoomsCount && queueRoomsCount) {
      return (
        <QueueRoomsAlertBar
          queueRoomsCount={queueRoomsCount}
          showLink={showLink}
          onShow={onShow}
        />
      );
    }

    if (serviceRequestedRoomsCount && queueRoomsCount) {
      return (
        <MultiElementAlertBar
          queueRoomsCount={queueRoomsCount}
          serviceRequestedRoomsCount={serviceRequestedRoomsCount}
          showLink={showLink}
          onShow={onShow}
        />
      );
    }
  }

  return null;
};
