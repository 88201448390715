import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';

import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  Icon,
  IconSize,
  TextSize,
  TextWeight
} from '@ac/mobile-components/dist/enums';

interface GuestNumbersTemplateProps {
  count: GuestCount;
}

const GuestNumbersTemplate: FC<GuestNumbersTemplateProps> = (
  props: GuestNumbersTemplateProps
) => {
  const { t } = useTranslation();

  return (
    <>
      <AcText
        weight={TextWeight.Semibold}
        size={TextSize.Main2}
        className="ac-spacing-right-lg"
      >
        <AcIcon
          size={IconSize.Small}
          icon={Icon.Adults}
          className="ac-spacing-right-xs"
        />
        {t('GLOBAL.ADULTS', { count: props.count.adultCount })}
      </AcText>
      <AcText weight={TextWeight.Semibold} size={TextSize.Main2}>
        <AcIcon
          size={IconSize.Small}
          icon={Icon.Children}
          className="ac-spacing-right-xs"
        />
        {t('GLOBAL.CHILDREN', { count: props.count.childCount })}
      </AcText>
    </>
  );
};

export default memo(GuestNumbersTemplate);
