import flagChina from './flag-china.svg';
import flagDutch from './flag-dutch.svg';
import flagFrance from './flag-france.svg';
import flagGermany from './flag-germany.svg';
import flagHongKong from './flag-hong-kong.svg';
import flagItaly from './flag-italy.svg';
import flagJapan from './flag-japan.svg';
import flagKorea from './flag-korea.svg';
import flagPoland from './flag-poland.svg';
import flagSpain from './flag-spain.svg';
import flagThailand from './flag-thailand.svg';
import flagTurkish from './flag-turkiye.svg';
import flagUK from './flag-united-kingdom.svg';

const icons: { [key: string]: string } = {
  DE: flagGermany,
  ES: flagSpain,
  FR: flagFrance,
  EN: flagUK,
  KO: flagKorea,
  NL: flagDutch,
  ZH: flagChina,
  'ZH-HANT': flagHongKong,
  'ZH-HANS': flagChina,
  IT: flagItaly,
  JA: flagJapan,
  PL: flagPoland,
  TH: flagThailand,
  TR: flagTurkish
};

export default icons;
