import * as appActions from '@hkm/components/App/domain/actions';
import * as notificationActions from '@hkm/components/App/notificationConsumer/domain/actions';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { GuestServiceChangeActions } from '@hkm/shared/domain/guestServiceChange/guestServiceChangeActions';
import { GuestServiceChangeRequest } from '@hkm/shared/domain/guestServiceChange/guestServiceChangeRequest';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import { put, takeLatest } from '@redux-saga/core/effects';
import i18n from 'i18next';

import {
  HousekeepingRoomsApi,
  MobileAttendantApi
} from '@ac/library-api/dist/api/v0/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';

const setHousekeepingStatusesApiMap = new Map<GuestServiceStatusType, any>([
  [GuestServiceStatusType.DoNotDisturb, HousekeepingRoomsApi.postDoNotDisturb],
  [
    GuestServiceStatusType.ServiceDeferred,
    HousekeepingRoomsApi.postDeferService
  ],
  [
    GuestServiceStatusType.ServiceRefused,
    HousekeepingRoomsApi.postRefuseService
  ],
  [
    GuestServiceStatusType.ServiceRequested,
    HousekeepingRoomsApi.postRequestService
  ],
  [
    GuestServiceStatusType.ServicePreferred,
    HousekeepingRoomsApi.postPreferService
  ]
]);

const setAttendantStatusesApiMap = new Map<GuestServiceStatusType, any>([
  [GuestServiceStatusType.DoNotDisturb, MobileAttendantApi.postDoNotDisturb],
  [GuestServiceStatusType.ServiceDeferred, MobileAttendantApi.postDeferService],
  [GuestServiceStatusType.ServiceRefused, MobileAttendantApi.postRefuseService],
  [
    GuestServiceStatusType.ServiceRequested,
    MobileAttendantApi.postRequestService
  ],
  [
    GuestServiceStatusType.ServicePreferred,
    MobileAttendantApi.postPreferService
  ]
]);

const cancelHousekeepingStatusesApiMap = new Map<GuestServiceStatusType, any>([
  [
    GuestServiceStatusType.DoNotDisturb,
    HousekeepingRoomsApi.postCancelDoNotDisturb
  ],
  [
    GuestServiceStatusType.ServiceDeferred,
    HousekeepingRoomsApi.postCancelServiceDeferral
  ],
  [
    GuestServiceStatusType.ServiceRefused,
    HousekeepingRoomsApi.postCancelServiceRefusal
  ],
  [
    GuestServiceStatusType.ServiceRequested,
    HousekeepingRoomsApi.postCancelServiceRequest
  ],
  [
    GuestServiceStatusType.ServicePreferred,
    HousekeepingRoomsApi.postCancelServicePreferred
  ]
]);

const cancelAttendantStatusesApiMap = new Map<GuestServiceStatusType, any>([
  [
    GuestServiceStatusType.DoNotDisturb,
    MobileAttendantApi.postCancelDoNotDisturb
  ],
  [
    GuestServiceStatusType.ServiceDeferred,
    MobileAttendantApi.postCancelServiceDeferral
  ],
  [
    GuestServiceStatusType.ServiceRefused,
    MobileAttendantApi.postCancelServiceRefusal
  ],
  [
    GuestServiceStatusType.ServiceRequested,
    MobileAttendantApi.postCancelServiceRequest
  ],
  [
    GuestServiceStatusType.ServicePreferred,
    MobileAttendantApi.postCancelPreferService
  ]
]);

export function createHousekeepingGuestServiceChangeSaga(
  actions: GuestServiceChangeActions
) {
  return createGuestServiceChangeSaga(
    actions,
    setHousekeepingStatusesApiMap,
    cancelHousekeepingStatusesApiMap
  );
}

export function createAttendantGuestServiceChangeSaga(
  actions: GuestServiceChangeActions
) {
  return createGuestServiceChangeSaga(
    actions,
    setAttendantStatusesApiMap,
    cancelAttendantStatusesApiMap
  );
}

function createGuestServiceChangeSaga(
  actions: GuestServiceChangeActions,
  setStatusApiMap: Map<GuestServiceStatusType, any>,
  cancelStatusApiMap: Map<GuestServiceStatusType, any>
) {
  function* changeStatus(action: Action<GuestServiceChangeRequest>) {
    try {
      const {
        newStatus,
        currentStatus,
        roomId,
        afterTime,
        versionId,
        untilTime,
        instruction
      } = action.payload;

      const shouldUseCancelAPI: boolean = !newStatus;
      const status = (newStatus || currentStatus)!;
      const apiMAP = shouldUseCancelAPI ? cancelStatusApiMap : setStatusApiMap;
      const apiMethod = apiMAP.get(status)!;

      yield apiMethod({
        pathParams: { roomId },
        customConfig: getCustomConfig({
          version: versionId
        }),
        data: {
          ...(afterTime ? { afterTime } : {}),
          ...(untilTime ? { untilTime } : {}),
          ...(instruction ? { instruction } : {})
        }
      });

      yield put(actions.changeGuestServiceStatus.success(roomId));
      yield put(
        notificationActions.expectNotification(
          SignalREventType.HousekeepingRoomChanged
        )
      );
      yield put(
        appActions.displaySuccess(
          i18n.t(
            `GUEST_SERVICE_STATUS_CHANGE.${
              shouldUseCancelAPI ? 'CANCEL_SUCCESS' : 'SET_SUCCESS'
            }`
          )
        )
      );
    } catch (e) {
      yield put(appActions.displayExtractedError(e));
      yield put(actions.changeGuestServiceStatus.failure(e));
    }
  }

  return function*() {
    yield takeLatest(actions.changeGuestServiceStatus.trigger, changeStatus);
  };
}
