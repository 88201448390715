import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OccupancyDiscrepancyModalBody, {
  OccupancyDiscrepancyModalBodyChange
} from '@hkm/components/shared/OccupancyDiscrepancy/Modal/Body/OccupancyDiscrepancyModalBody';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';
import { RoomOccupancy } from '@hkm/shared/roomOccupancy/roomOccupancy';

import {
  AcButton,
  AcButtonPattern
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AcModalBody,
  AcModalFooter,
  AcModalHeader
} from '@ac/mobile-components/dist/components/modal';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FlexDirection,
  Icon,
  JustifyContent,
  TextSize,
  TextWeight
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

export interface OccupancyConfirmationProps extends Testable {
  occupancy: RoomOccupancy;
  onConfirm: (reportedGuestCount?: GuestCount) => void;
}
const OccupancyConfirmation: FC<OccupancyConfirmationProps> = ({
  occupancy,
  onConfirm,
  testSelector
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<OccupancyDiscrepancyModalBodyChange>();
  const [isOccupancyIncorrect, setIsOccupancyIncorrect] = useState<boolean>(
    false
  );

  const onCorrect = useCallback(() => {
    onConfirm();
  }, [onConfirm]);
  const onIncorrect = useCallback(() => setIsOccupancyIncorrect(true), []);

  const onSave = useCallback(() => {
    onConfirm({
      adultCount: state!.adultCount,
      childCount: state!.childCount
    });
  }, [onConfirm, state]);

  const onBack = useCallback(() => {
    setState(undefined);
    setIsOccupancyIncorrect(false);
  }, []);

  useEffect(() => setIsOccupancyIncorrect(false), []);

  return (
    <>
      <AcModalHeader
        showCloseButton={true}
        testSelector={formatTestSelector(testSelector, 'modalHeader')}
      >
        {t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.CONFIRM_OCCUPANCY_MODAL.TITLE')}
      </AcModalHeader>

      {isOccupancyIncorrect ? (
        <OccupancyDiscrepancyModalBody
          occupancy={occupancy}
          onChange={setState}
          testSelector={formatTestSelector(testSelector, 'modalBody')}
        />
      ) : (
        <AcModalBody>
          <AcText size={TextSize.Main2}>
            {t(
              'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.CONFIRM_OCCUPANCY_MODAL.QUESTION'
            )}
          </AcText>
          <br />
          <AcText
            size={TextSize.Main2}
            weight={TextWeight.Semibold}
            testSelector={formatTestSelector(testSelector, 'counter')}
          >
            {t(
              'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.CONFIRM_OCCUPANCY_MODAL.COUNT_TEMPLATE',
              {
                adults: t('GLOBAL.ADULTS', { count: occupancy.totalAdults }),
                children: t('GLOBAL.CHILDREN', {
                  count: occupancy.totalChildren
                })
              }
            )}
          </AcText>
        </AcModalBody>
      )}

      <AcModalFooter>
        <AcFlex
          direction={FlexDirection.column}
          grow={true}
          justifyContent={JustifyContent.center}
          alignItems={AlignItems.center}
        >
          {isOccupancyIncorrect ? (
            <React.Fragment key="incorrect">
              <AcButton
                onClick={onSave}
                fullwidth={true}
                disabled={!state?.valid}
                testSelector={formatTestSelector(testSelector, 'save')}
              >
                <AcButtonContent text={t('GLOBAL.SAVE')} />
              </AcButton>
              <AcButton
                pattern={AcButtonPattern.Tertiary}
                onClick={onBack}
                testSelector={formatTestSelector(testSelector, 'back')}
              >
                <AcButtonContent
                  icon={Icon.ArrowLeft}
                  text={t(
                    'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.CONFIRM_OCCUPANCY_MODAL.BACK'
                  )}
                />
              </AcButton>
            </React.Fragment>
          ) : (
            <React.Fragment key="question">
              <AcButton
                onClick={onCorrect}
                fullwidth={true}
                testSelector={formatTestSelector(testSelector, 'correct')}
              >
                <AcButtonContent
                  text={t(
                    'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.CONFIRM_OCCUPANCY_MODAL.CORRECT'
                  )}
                />
              </AcButton>
              <AcButton
                pattern={AcButtonPattern.Tertiary}
                onClick={onIncorrect}
                testSelector={formatTestSelector(testSelector, 'incorrect')}
              >
                <AcButtonContent
                  icon={Icon.Cancel}
                  text={t(
                    'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.CONFIRM_OCCUPANCY_MODAL.INCORRECT'
                  )}
                />
              </AcButton>
            </React.Fragment>
          )}
        </AcFlex>
      </AcModalFooter>
    </>
  );
};

export default memo(OccupancyConfirmation);
