import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import DatetimeFormElement, {
  DatetimeVariant
} from '@hkm/components/shared/DatetimeFormElement/DatetimeFormElement';
import { useDetectedReservationMovement } from '@hkm/shared/reservations/reservationMovementHooks';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { ReservationStatus } from '@ac/library-api';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';

import './ReservationMovementDashboardItem.css';

interface ReservationMovementDashboardItemProps extends Testable {
  reservation: UnifiedReservationDetails;
}

const ReservationMovementDetailsItem: FC<ReservationMovementDashboardItemProps> = ({
  reservation
}) => {
  const { t } = useTranslation();
  const prefix = 'reservation-movements';
  const { moveIn, moveOut } = useDetectedReservationMovement(
    [reservation],
    r => r.status!.code as ReservationStatus
  );

  const hasReservationMovement = moveIn || moveOut;
  const hasDayUse = hasReservationMovement && reservation.isDayUse;
  const isInhouse = reservation?.status?.code === ReservationStatus.IH;

  return (
    <div className={prefix}>
      {isInhouse && (
        <div className="ac-spacing-top-sm">
          <>
            <DatetimeFormElement
              className="ac-spacing-top-md"
              label={t('ROOM_DETAILS.ARRIVAL_DATE')}
              timeLabel={t('GLOBAL.ETA')}
              datetime={reservation.eta || reservation.arrivalDate!}
              variant={
                reservation.eta
                  ? DatetimeVariant.datetime
                  : DatetimeVariant.onlyDate
              }
            />
            <DatetimeFormElement
              className="ac-spacing-top-md"
              label={t('ROOM_DETAILS.DEPARTURE_DATE')}
              timeLabel={t('GLOBAL.ETD')}
              datetime={reservation.etd || reservation.departureDate!}
              variant={
                reservation.etd
                  ? DatetimeVariant.datetime
                  : DatetimeVariant.onlyDate
              }
            />
          </>
        </div>
      )}

      {hasDayUse && !isInhouse && (
        <>
          <DatetimeFormElement
            className="ac-spacing-top-md"
            label={t('ROOM_DETAILS.ARRIVAL_DATE')}
            timeLabel={t('GLOBAL.ETA')}
            datetime={reservation.eta!}
          />
          <DatetimeFormElement
            className="ac-spacing-top-md"
            label={t('ROOM_DETAILS.DEPARTURE_DATE')}
            timeLabel={t('GLOBAL.ETD')}
            datetime={reservation.etd!}
          />
        </>
      )}

      {hasReservationMovement && !hasDayUse && !isInhouse && (
        <div className="ac-spacing-top-sm">
          {moveOut && (
            <DatetimeFormElement
              {...moveOut!}
              label={t('ROOM_DETAILS.DEPARTURE_DATE')}
            />
          )}
          {moveIn && (
            <DatetimeFormElement
              {...moveIn!}
              label={t('ROOM_DETAILS.ARRIVAL_DATE')}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default memo(ReservationMovementDetailsItem);
