import * as appActions from '@hkm/components/App/domain/actions';
import * as attendantActions from '@hkm/components/Attendant/shared/domain/actions';
import { AttendantSheetState } from '@hkm/components/Attendant/shared/domain/interfaces/assignmentData';
import { selectAssignedSheetState } from '@hkm/components/Attendant/shared/domain/selectors';
import { selectBusinessDate } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { PostingPagination } from '@hkm/components/Posting/Dashboard/domain/interfaces';
import { selectPostingDashboardPagination } from '@hkm/components/Posting/Dashboard/domain/selectors';
import { getPostingReservedKind } from '@hkm/components/Posting/Dashboard/domain/utils/getPostingReservedKind';
import * as actions from '@hkm/components/Posting/shared/domain/actions';
import {
  AttendantPostingRoomState,
  HousekeepingPostingRoomState
} from '@hkm/components/Posting/shared/domain/interfaces';
import {
  selectAttendantPostingRoomState,
  selectHousekeepingPostingRoomState
} from '@hkm/components/Posting/shared/domain/selectors';
import { createHousekeepingRoom } from '@hkm/types/housekeeping/factories/createHousekeepingRoom';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import {
  call,
  cancel,
  put,
  select,
  take,
  takeLatest
} from '@redux-saga/core/effects';
import { Task } from '@redux-saga/types';
import i18n from 'i18next';

import {
  HousekeepingRoomDetailsDto,
  LibraryApiResponse
} from '@ac/library-api';
import { HousekeepingViewsApi } from '@ac/library-api/dist/api/v0/housekeeping';

function* fetchHousekeepingPostingRoom() {
  try {
    const businessDate: string = yield select(selectBusinessDate);
    const roomState: HousekeepingPostingRoomState = yield select(
      selectHousekeepingPostingRoomState
    );

    const room: LibraryApiResponse<HousekeepingRoomDetailsDto> = yield HousekeepingViewsApi.getHousekeepingRoomDetails(
      {
        pathParams: { roomId: roomState.roomId! }
      }
    );

    const postingRoom = createHousekeepingRoom(
      room.data,
      businessDate,
      getPostingReservedKind
    );

    yield put(actions.fetchHousekeepingPostingRoom.success(postingRoom));
  } catch (e) {
    yield put(appActions.displayError(i18n.t('GLOBAL.ERRORS.ROOMS_NOT_FOUND')));
    yield put(actions.fetchHousekeepingPostingRoom.failure(e));
  }
}

function* handleAttendantInitialize() {
  yield takeLatest(
    attendantActions.fetchAssignedSheet.success,
    copyAttendantRoomFromAssignedSheet
  );
  yield call(copyAttendantRoomFromAssignedSheet);
}

function* copyAttendantRoomFromAssignedSheet() {
  // fetching sheet and checking if user is attendant and has sheet assigned is done by
  // requireAttendant routeGuard, so here we only check if we have data in redux or not
  const roomState: AttendantPostingRoomState = yield select(
    selectAttendantPostingRoomState
  );
  const attendantSheetState: AttendantSheetState = yield select(
    selectAssignedSheetState
  );

  const roomId: string = roomState.roomId!;
  const attendantRoom = attendantSheetState.data?.roomAssignments.find(
    room => room.id === roomId
  );

  if (attendantRoom) {
    yield put(actions.setRoomFromAttendantSheet(attendantRoom));
  } else {
    yield put(appActions.displayError(i18n.t('GLOBAL.ERRORS.ROOMS_NOT_FOUND')));
  }
}

function* handleHousekeepingInitialize() {
  // Base tasks
  yield takeLatest(
    actions.fetchHousekeepingPostingRoom.trigger,
    fetchHousekeepingPostingRoom
  );

  // Clone from dashboard
  const dashboardState: PostingPagination = yield select(
    selectPostingDashboardPagination
  );
  const postingRoomState: HousekeepingPostingRoomState = yield select(
    selectHousekeepingPostingRoomState
  );
  const foundRoom: HousekeepingRoom | undefined = (dashboardState.page
    ? dashboardState.page.results
    : []
  ).find(dashboardRoom => dashboardRoom.id === postingRoomState.roomId);

  if (foundRoom) {
    yield put(actions.setRoomFromDashboard(foundRoom));
  } else {
    yield put(actions.fetchHousekeepingPostingRoom.trigger());
  }
}

export default function* postingRoomSagas() {
  while (true) {
    const hkTask: Task = yield takeLatest(
      actions.initializeHousekeepingPostingRoom,
      handleHousekeepingInitialize
    );
    const attTask: Task = yield takeLatest(
      actions.initializeAttendantPostingRoom,
      handleAttendantInitialize
    );
    yield take(actions.reset);
    yield cancel([hkTask, attTask]);
  }
}
