import { activeReservationStates } from '@hkm/shared/reservations/activeReservationStatuses';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { ReservationStatus } from '@ac/library-api';

export function getPostingHousekeepingReservations(
  reservations?: UnifiedReservationDetails[]
): UnifiedReservationDetails[] {
  return reservations
    ? (reservations
        .map((reservation: UnifiedReservationDetails) => {
          return activeReservationStates.has(
            reservation.status?.code as ReservationStatus
          )
            ? reservation
            : undefined;
        })
        .filter(Boolean) as UnifiedReservationDetails[])
    : [];
}

export function getPostingHousekeepingReservation(
  reservationId: string,
  reservations?: UnifiedReservationDetails[]
): UnifiedReservationDetails | undefined {
  return getPostingHousekeepingReservations(reservations).find(
    reservation => reservation.id === reservationId
  );
}

export function getPostingAttendantReservations(
  reservations?: UnifiedReservationDetails[]
): UnifiedReservationDetails[] {
  return reservations
    ? (reservations
        .map((reservation: UnifiedReservationDetails) => {
          return activeReservationStates.has(
            reservation.status?.code as ReservationStatus
          )
            ? reservation
            : undefined;
        })
        .filter(Boolean) as UnifiedReservationDetails[])
    : [];
}

export function getPostingAttendantReservation(
  reservationId: string,
  reservations?: UnifiedReservationDetails[]
): UnifiedReservationDetails | undefined {
  return getPostingAttendantReservations(reservations).find(reservation => {
    return reservation.id === reservationId;
  });
}
