import { PropertyStore } from '@hkm/components/Menu/PropertySelector/domain/interfaces';
import { apiToDateFormat } from '@hkm/shared/helpers/apiToDateFormat';
import { PropertyDateFormats } from '@hkm/store/interfaces/propertyDateFormats';
import { Store } from '@hkm/store/interfaces/store';
import i18n from 'i18next';
import { createSelector } from 'reselect';

import { TimeFormat } from '@ac/library-api';

export const selectPropertyStore = (state: Store): PropertyStore =>
  state.property;
export const selectPropertiesList = createSelector(
  selectPropertyStore,
  property => property.propertiesList
);
export const selectBusinessDate = createSelector(
  selectPropertyStore,
  property => property.businessDate!
);
export const selectPermissions = createSelector(
  selectPropertyStore,
  property => property.permissions || []
);
export const selectEffectiveValues = createSelector(
  selectPropertyStore,
  property => property.effectiveValues
);
export const selectPermissionsSet = createSelector(
  selectPermissions,
  permissions => new Set(permissions)
);
export const selectCheckInTime = createSelector(
  selectPropertyStore,
  property =>
    property && property.activeProperty && property.activeProperty.checkInTime
);
export const selectCheckOutTime = createSelector(
  selectPropertyStore,
  property =>
    property && property.activeProperty && property.activeProperty.checkOutTime
);
export const selectPropertyLoadingState = createSelector(
  selectPropertyStore,
  property => property.loadingState
);
export const selectActiveProperty = createSelector(
  selectPropertyStore,
  property => property.activeProperty!
);

export const selectPropertyDateFormats = createSelector(
  selectActiveProperty,
  property => {
    const time =
      property.timeFormatCode === TimeFormat.H12 ? 'hh:mm A' : 'HH:mm';
    const longDate = apiToDateFormat(property.longDateFormat!);
    const shortDate = apiToDateFormat(property.shortDateFormat!);
    const longDateTime = i18n.t('GLOBAL.DATE_TIME_FORMAT', {
      date: longDate,
      time,
      interpolation: { escapeValue: false }
    });
    const shortDateTime = i18n.t('GLOBAL.DATE_TIME_FORMAT', {
      date: shortDate,
      time,
      interpolation: { escapeValue: false }
    });

    return {
      time,
      longDate,
      longDateTime,
      shortDate,
      shortDateTime
    } as PropertyDateFormats;
  }
);
