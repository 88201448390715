import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AcButton,
  AcButtonPattern
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AcModalBody,
  AcModalFooter,
  AcModalHeader
} from '@ac/mobile-components/dist/components/modal';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FlexDirection,
  Icon,
  JustifyContent,
  TextSize
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

export interface PostChargeModalProps extends Testable {
  onConfirm(): void;
  onDecline(): void;
}

const ChargePostingConfirmation: FC<PostChargeModalProps> = ({
  onConfirm,
  onDecline,
  testSelector
}) => {
  const { t } = useTranslation();
  const prefix = 'post-charge-modal';

  return (
    <>
      <AcModalHeader showCloseButton={true}>
        {t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.POST_CHARGE_MODAL.TITLE')}
      </AcModalHeader>

      <AcModalBody className={`${prefix}-body`}>
        <AcText size={TextSize.Main2}>
          {t(
            'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.POST_CHARGE_MODAL.DESCRIPTION'
          )}
        </AcText>
      </AcModalBody>

      <AcModalFooter>
        <AcFlex
          direction={FlexDirection.column}
          grow={true}
          justifyContent={JustifyContent.center}
          alignItems={AlignItems.center}
        >
          <AcButton
            fullwidth={true}
            onClick={onConfirm}
            testSelector={formatTestSelector(testSelector, 'confirm')}
          >
            <AcButtonContent
              text={t(
                'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.POST_CHARGE_MODAL.YES'
              )}
            />
          </AcButton>
          <AcButton
            fullwidth={true}
            onClick={onDecline}
            pattern={AcButtonPattern.Tertiary}
            testSelector={formatTestSelector(testSelector, 'decline')}
          >
            <AcButtonContent
              icon={Icon.Cancel}
              text={t(
                'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.POST_CHARGE_MODAL.NO'
              )}
            />
          </AcButton>
        </AcFlex>
      </AcModalFooter>
    </>
  );
};

export default memo(ChargePostingConfirmation);
