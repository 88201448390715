import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import IncognitoBadge from '@hkm/components/shared/IncognitoBadge/IncognitoBadge';
import GuestDisabilitiesTemplate from '@hkm/components/shared/Templates/Guest/Disabilities/GuestDisabilitiesTemplate';
import ConnectGuestAvatarTemplate from '@hkm/components/shared/Templates/Guest/GuestAvatar/ConnectGuestAvatarTemplate';
import GuestNameTemplate from '@hkm/components/shared/Templates/Guest/GuestNameTemplate';
import VipOrMemberTemplate from '@hkm/components/shared/Templates/Guest/VipOrMember/VipOrMemberTemplate';
import {
  getVipBadge,
  hasReservationDisabilities
} from '@hkm/components/shared/Templates/Reservation/utils/reservationUtils';
import { VipOrMemberBadge } from '@hkm/shared/interfaces/vipOrMemberBadge';
import { UnifiedReservationGuestDetails } from '@hkm/types/reservation/models/UnifiedReservationGuestDetails';
import classNames from 'classnames';

import {
  AcAccordion,
  AcAccordionContent,
  AcAccordionHeader,
  AcAccordionItem,
  AcAccordionsElementProps
} from '@ac/mobile-components/dist/components/accordion';
import { AcBox } from '@ac/mobile-components/dist/components/box';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  Color,
  FlexDirection,
  Icon,
  IconSize,
  TextSize,
  TextWeight
} from '@ac/mobile-components/dist/enums';
import { Styleable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

import './AccompanyingGuestsDetailsTemplate.css';

export interface AccompanyingGuestsDetailsTemplateProps extends Styleable {
  disableModals?: boolean;
  showGuestNames?: boolean;
  accompanyingGuests: UnifiedReservationGuestDetails[];
}

const defaultProps: Partial<AccompanyingGuestsDetailsTemplateProps> = {
  showGuestNames: true
};

// tslint:disable:jsx-no-lambda
const AccompanyingGuestsDetailsTemplate: FC<AccompanyingGuestsDetailsTemplateProps> = (
  props: AccompanyingGuestsDetailsTemplateProps
) => {
  const { t } = useTranslation();
  const testSelectorPrefix = 'accompanying-guests';
  const effectiveValues = useSelector(selectEffectiveValues)!;
  const classes = classNames(
    'housekeeping-details-accompanying-guests',
    props.className
  );

  return (
    <div className={classes} style={props.style}>
      <div className="accompany-header">
        <AcIcon
          icon={Icon.Accompanying}
          size={IconSize.Small}
          className="accompany-guest-icon"
        />
        <AcText color={Color.Gray1} size={TextSize.Main2}>
          {t('ROOM_DETAILS.ACCOMPANYING_GUESTS')}
        </AcText>
      </div>
      <div className="accompany-content">
        <AcAccordion className="accordion-accompanying-guests">
          {props.accompanyingGuests.map(
            (
              accompanyingGuest: UnifiedReservationGuestDetails,
              index: number
            ) => {
              const guestPersonalData = props.showGuestNames
                ? accompanyingGuest.personalData
                : undefined;
              const hasDisabilities: boolean = hasReservationDisabilities(
                accompanyingGuest
              );
              const vipBadges: VipOrMemberBadge | undefined = getVipBadge(
                accompanyingGuest.vipCode
              );
              const hasAdditionalData: boolean = hasDisabilities || !!vipBadges;
              const vipBadgeColor = vipBadges ? vipBadges.color : undefined;

              return (
                <AcAccordionItem
                  id={accompanyingGuest.id}
                  key={accompanyingGuest.id}
                  disabled={!hasAdditionalData}
                  render={(accordionProps: AcAccordionsElementProps) => (
                    <>
                      <IncognitoBadge
                        testSelector={testSelectorPrefix}
                        details={
                          accompanyingGuest.personalData?.incognitoDetails
                        }
                        className="ac-spacing-bottom-sm"
                        disableModal={props.disableModals}
                      />

                      <AcFlex alignItems={AlignItems.center}>
                        {props.showGuestNames &&
                          effectiveValues.displayGuestAvatar && (
                            <ConnectGuestAvatarTemplate
                              className="ac-spacing-right-sm"
                              guest={accompanyingGuest}
                            />
                          )}
                        <AcBox grow={true}>
                          <AcAccordionHeader
                            {...accordionProps}
                            testSelector={`${testSelectorPrefix}-${index}-accordionHeader`}
                          >
                            <AcFormElement
                              label={t('ROOM_DETAILS.GUEST')}
                              testSelector={formatTestSelector(
                                testSelectorPrefix,
                                'guest'
                              )}
                            >
                              <GuestNameTemplate
                                vipBadgeColor={vipBadgeColor}
                                guestPersonalData={guestPersonalData}
                                hasDisabilities={hasDisabilities}
                                customGuestName={t(
                                  'ROOM_DETAILS.ACCOMPANY_GUEST_CUSTOM_NAME',
                                  {
                                    number: index + 1
                                  }
                                )}
                              />
                            </AcFormElement>
                          </AcAccordionHeader>
                        </AcBox>
                      </AcFlex>

                      <AcAccordionContent>
                        {hasDisabilities && (
                          <AcFlex direction={FlexDirection.column}>
                            <AcText
                              color={Color.Gray1}
                              size={TextSize.Main2}
                              weight={TextWeight.Semibold}
                            >
                              {t('ROOM_DETAILS.DISABILITIES')}
                            </AcText>
                            <AcText color={Color.Black} size={TextSize.Main1}>
                              <GuestDisabilitiesTemplate
                                disableModal={props.disableModals}
                                disabilities={
                                  accompanyingGuest.disabilityStatusCodes
                                }
                                testSelector={`${testSelectorPrefix}-${index}-specialNeeds`}
                              />
                            </AcText>
                          </AcFlex>
                        )}

                        {!!vipBadges && (
                          <AcFlex
                            direction={FlexDirection.column}
                            className="ac-spacing-top-md"
                          >
                            <AcText
                              color={Color.Gray1}
                              size={TextSize.Main2}
                              weight={TextWeight.Semibold}
                            >
                              {t('ROOM_DETAILS.VIP_OR_MEMBER')}
                            </AcText>
                            <VipOrMemberTemplate
                              disableModal={props.disableModals}
                              vipOrMemberBadges={[vipBadges]}
                              testSelector={`${testSelectorPrefix}-${index}-vip`}
                            />
                          </AcFlex>
                        )}
                      </AcAccordionContent>
                    </>
                  )}
                />
              );
            }
          )}
        </AcAccordion>
      </div>
    </div>
  );
};

AccompanyingGuestsDetailsTemplate.defaultProps = defaultProps;

export default memo(AccompanyingGuestsDetailsTemplate);
