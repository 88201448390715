import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';

import { RoomMaintenanceState } from '@ac/library-api';

export function getMaintenanceState(
  maintenanceRoom?: MaintenanceRoom
): RoomMaintenanceState | undefined {
  return maintenanceRoom
    ? (maintenanceRoom.state!.code as RoomMaintenanceState)
    : undefined;
}
