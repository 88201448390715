import * as appActions from '@hkm/components/App/domain/actions';
import * as housekeepingDashboardActions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import * as maintenanceDashboardActions from '@hkm/components/Maintenance/Dashboard/domain/actions';
import { MaintenanceReleaseFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceReleaseForm/models/maintenanceReleaseData';
import * as actions from '@hkm/components/shared/Templates/Maintenance/shared/domain/actions';
import { ReleaseMaintenanceProcess } from '@hkm/components/shared/Templates/Maintenance/shared/domain/process/releaseMaintenance';
import { getShortStatusDescription } from '@hkm/components/shared/Templates/Maintenance/shared/utils/getShortStatusDescription';
import {
  createMaintenanceUpdateSaga,
  MaintenanceUpdateSagaApi
} from '@hkm/shared/domain/maintenanceUpdate/maintenanceUpdateSagaFactory';
import { MaintenanceUpdateData } from '@hkm/shared/domain/maintenanceUpdate/models/maintenanceUpdateData';
import { isDashboardUpdated } from '@hkm/shared/helpers/versionChecker';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import i18n from 'i18next';
import { fork, put, takeLatest } from 'redux-saga/effects';

import { ReleaseMaintenances, RoomMaintenanceState } from '@ac/library-api';
import { MobileAttendantApi } from '@ac/library-api/dist/api/v0/housekeeping';
import { HousekeepingRoomsApiV1 } from '@ac/library-api/dist/api/v1/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';

const attendantMaintenanceUpdateApi: MaintenanceUpdateSagaApi = {
  postUpdateMaintenance: async (
    maintenanceUpdateData: MaintenanceUpdateData
  ): Promise<any> => {
    const {
      data: { comment, reasonCode, maintenanceId }
    } = maintenanceUpdateData;

    return await MobileAttendantApi.postUpdateMaintenance({
      pathParams: { roomId: maintenanceUpdateData.roomId },
      data: { comment, reasonCode, maintenanceId },
      customConfig: getCustomConfig({
        version: maintenanceUpdateData.housekeepingRoomVersion
      })
    });
  }
};

const housekeepingMaintenanceUpdateApi: MaintenanceUpdateSagaApi = {
  postUpdateMaintenance: async (
    maintenanceUpdateData: MaintenanceUpdateData
  ): Promise<any> => {
    return await HousekeepingRoomsApiV1.postUpdateMaintenance({
      pathParams: { roomNumber: maintenanceUpdateData.roomNumber },
      data: maintenanceUpdateData.data,
      customConfig: getCustomConfig({
        version: maintenanceUpdateData.housekeepingRoomVersion
      })
    });
  }
};

function* releaseMaintenance(action: Action<MaintenanceReleaseFormData>) {
  try {
    const data: ReleaseMaintenances = {
      maintenanceId: action.payload.maintenanceId,
      comment: action.payload.comment,
      returnStatusCode: action.payload.returnStatusCode
    };

    const process = new ReleaseMaintenanceProcess(
      { roomNumber: action.payload.roomNumber },
      data,
      action.payload.housekeepingRoomVersion
    );

    yield process.runProcess();

    // wait until data on backend will be up to date for specific dashboards
    const refreshed = yield* isDashboardUpdated(
      [action.payload.roomNumber],
      action.payload.dashboardType
    );
    const shortStatus = getShortStatusDescription(action.payload.statusCode);

    if (action.payload.state === RoomMaintenanceState.Pending) {
      yield put(
        appActions.displaySuccess(
          i18n.t('MAINTENANCE_RELEASE.CANCEL_SUCCESS', { shortStatus })
        )
      );
    } else {
      yield put(
        appActions.displaySuccess(
          i18n.t('MAINTENANCE_RELEASE.RELEASE_SUCCESS', { shortStatus })
        )
      );
    }

    yield put(actions.releaseMaintenance.success());
    yield put(actions.notifyMaintenanceUpdate());

    if (!refreshed) {
      yield put(
        appActions.displayError(i18n.t('MAINTENANCE_RELEASE.REFRESH_FAIL'))
      );
    }
  } catch (e) {
    yield put(actions.releaseMaintenance.failure(e));
    yield put(appActions.displayError(i18n.t('GLOBAL.ERRORS.GENERAL')));
  }
}

function* onChange() {
  yield put(housekeepingDashboardActions.pagination.clearPage());
  yield put(maintenanceDashboardActions.pagination.clearPage());
}

export default function* maintenanceUpdateSagas() {
  yield fork(
    createMaintenanceUpdateSaga(
      actions.updateAttendantMaintenanceActionsSet,
      attendantMaintenanceUpdateApi
    )
  );
  yield fork(
    createMaintenanceUpdateSaga(
      actions.updateMaintenanceActionsSet,
      housekeepingMaintenanceUpdateApi
    )
  );
  yield takeLatest(actions.releaseMaintenance.trigger, releaseMaintenance);

  yield takeLatest(actions.releaseMaintenance.success, onChange);
  yield takeLatest(
    actions.updateMaintenanceActionsSet.updateMaintenance.success,
    onChange
  );
  yield takeLatest(
    actions.updateAttendantMaintenanceActionsSet.updateMaintenance.success,
    onChange
  );
}
