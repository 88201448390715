import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  fetchCurrentCustomer,
  fetchFeatureToggles
} from '@hkm/components/App/domain/actions';
import { properties } from '@hkm/components/Menu/PropertySelector/domain/actions';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { createDefaultConfigProvider } from '@hkm/utils/api';
import getLoginUrl from '@hkm/utils/moduleNavigator';

import { BaseApi } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import { deserializeUrlSearchString } from '@ac/library-utils/dist/utils';
import { AcSpinnerCover } from '@ac/mobile-components/dist/components/spinner';

import { userDetails, userLogin } from './domain/actions';

interface LoginSearchParams {
  returnUrl: string;
}

const componentWillMount = (callback: () => void) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useState(callback);
};

const LoginContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = deserializeUrlSearchString<LoginSearchParams>(
    location.search
  );

  componentWillMount(() => {
    const handleSuccess = (routeName: string): void => {
      BaseApi.defaultConfig = createDefaultConfigProvider();
      dispatch(userLogin.success(true));
      dispatch(userDetails.trigger());
      dispatch(fetchCurrentCustomer.trigger());
      dispatch(properties.trigger());
      dispatch(fetchFeatureToggles.trigger());
      if (!routeName || routeName === APP_ROUTES.LOGIN) {
        navigate(APP_ROUTES.HOME);
      } else {
        navigate(routeName);
      }
    };

    const loginUrl = getLoginUrl();
    const returnUrl = searchParams.returnUrl;

    LoginService.overrideIdentityClientId(APP_CONSTANTS.IDENTITY_CLIENT_ID);
    LoginService.setRedirectUri(loginUrl);
    LoginService.setOnSuccessHandler(handleSuccess);

    LoginService.handleModuleCallBack({
      moduleLoginUrl: loginUrl,
      returnUrl
    });
  });

  return <AcSpinnerCover coverScreen={true} />;
};

export default LoginContainer;
