import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import AttendantRoomListBody from '@hkm/components/Attendant/RoomList/Body/AttendantRoomListBody';
import {
  selectAssignedSheetState,
  selectIsLoadingOrEditingSheets,
  selectSheetGroupedRooms
} from '@hkm/components/Attendant/shared/domain/selectors';
import { getNextRoomId } from '@hkm/components/Attendant/shared/utils/getNextRoomId';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { useFetchAssignedSheetOnNotification } from '@hkm/shared/hooks/useFetchAssignedSheetOnNotification';

import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { TextSize } from '@ac/mobile-components/dist/enums';

import './AttendantRoomList.css';

const AttendantRoomList: FC = () => {
  const { t } = useTranslation();

  const sheetGroupedRooms = useSelector(selectSheetGroupedRooms);
  const showSpinner = useSelector(selectIsLoadingOrEditingSheets);
  const assignedSheetState = useSelector(selectAssignedSheetState);
  useFetchAssignedSheetOnNotification();

  // check if next room id exists
  // if yes then attendant shouldn't have access to the list
  const nextRoomId = getNextRoomId();

  if (nextRoomId) {
    return <Navigate to={APP_ROUTES.ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY} />;
  }

  return (
    <>
      <ConnectedHeader
        className="attendant-room-list-header"
        close={APP_ROUTES.ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY}
        showHelpButton={true}
      >
        <AcText size={TextSize.H2}>
          {t('ATTENDANT_ASSIGNMENTS.ROOM_LIST.TITLE')}
        </AcText>
      </ConnectedHeader>
      <AcBody className="attendant-room-list-body" showSpinner={showSpinner}>
        {assignedSheetState.data && (
          <AttendantRoomListBody groupedRooms={sheetGroupedRooms} />
        )}
      </AcBody>
    </>
  );
};

export default memo(AttendantRoomList);
