import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import {
  AdvancedFilterChipsRenderProp,
  AdvancedFiltersBodyRenderProp
} from '@hkm/components/shared/AdvancedFilters/advancedFilterChipsRenderProp';
import AdvancedFiltersBody from '@hkm/components/shared/AdvancedFilters/Body/AdvancedFiltersBody';
import AdvancedFiltersFooter from '@hkm/components/shared/AdvancedFilters/Footer/AdvancedFiltersFooter';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import { Validator } from '@hkm/shared/validation/validator';
import { PaginationActionSet } from '@hkm/store/pagination/paginationActionsFactory';
import { UnknownObject } from '@hkm/types/shared/unknownObject';

import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { genericMemo } from '@ac/mobile-components/dist/utils';
import { Form } from '@ac/react-infrastructure';

export interface AdvancedFiltersProps<T extends UnknownObject>
  extends Childless {
  backPath: string;
  dashboardPaginationActions: PaginationActionSet<any, any, any, T>;
  validator: Validator<T, any>;
  initialFilters: T;
  body: AdvancedFiltersBodyRenderProp<T>;
  chips: AdvancedFilterChipsRenderProp<T>;
  testSelectorPrefix: string;
}

function AdvancedFilters<T extends UnknownObject>(
  props: AdvancedFiltersProps<T>
): ReactElement {
  const [redirect, setRedirect] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validate = useCallback(
    (values: T) => props.validator.validate(values),
    []
  );

  const onSubmit = useCallback((filters: T) => {
    dispatch(props.dashboardPaginationActions.setFilters(filters));
    setRedirect(true);
  }, []);

  if (redirect) {
    return <Navigate to={props.backPath} />;
  }

  return (
    <Form
      initialValues={props.initialFilters as any}
      validate={validate}
      onSubmit={onSubmit}
    >
      {formRenderProps => (
        <>
          <ConnectedHeader close={props.backPath}>
            {t('ADVANCED_FILTERS.TITLE')}
          </ConnectedHeader>
          <AdvancedFiltersBody<T>
            formProps={formRenderProps}
            body={props.body}
          />
          <AdvancedFiltersFooter<T>
            formProps={formRenderProps}
            backPath={props.backPath}
            chips={props.chips}
            testSelectorPrefix={props.testSelectorPrefix}
          />
        </>
      )}
    </Form>
  );
}

export default genericMemo(AdvancedFilters);
