import React, { ComponentType, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/Attendant/shared/domain/actions';
import { selectAssignedSheetState } from '@hkm/components/Attendant/shared/domain/selectors';
import AttendantSheetNotFound from '@hkm/components/Attendant/SheetNotFound/AttendantSheetNotFound';
import AppSpinner from '@hkm/components/shared/AppSpinner/AppSpinner';

export const requireAttendantSheet = <P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>
): ComponentType<P> => {
  // eslint-disable-next-line react/display-name
  return (props: P) => {
    const dispatch = useDispatch();
    const selectedAssignedSheetState = useSelector(selectAssignedSheetState);

    useEffect(() => {
      if (!selectedAssignedSheetState.data) {
        dispatch(actions.fetchAssignedSheet.trigger());
      }
    }, []);

    if (!selectedAssignedSheetState.didInitialize) {
      return <AppSpinner />;
    }

    if (!selectedAssignedSheetState.data) {
      return <AttendantSheetNotFound />;
    }

    return <WrappedComponent {...props} />;
  };
};
