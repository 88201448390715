import { DictionaryEntry } from '@hkm/components/App/domain/interfaces/DictionariesStore';
import { HousekeepingFiltersData } from '@hkm/components/Housekeeping/shared/models/housekeepingFiltersData';
import {
  DictionaryLabelLength,
  extractDictionaryEntryLabel
} from '@hkm/shared/dictionaries/dictionaryItemsLabelExtractor';
import i18n from 'i18next';

import { DictionaryEntity } from '@ac/library-api/dist/types/entities/configuration/systemDictionaries/systemDictionary';

export interface HousekeepingAdvancedFiltersConfig {
  labelKey: string;
  longFormatter: (entry: DictionaryEntry | string | boolean) => string;
  shortFormatter: (entry: DictionaryEntry | string | boolean) => string;
}

const fullFormatter = (entry: DictionaryEntry) =>
  extractDictionaryEntryLabel(entry, DictionaryLabelLength.Full);
const shortFormatter = (entry: DictionaryEntry) =>
  extractDictionaryEntryLabel(entry, DictionaryLabelLength.Short);
const booleanFormatter = (x: boolean) =>
  x ? i18n.t('GLOBAL.ON') : i18n.t('GLOBAL.OFF');

export const HousekeepingAdvancedFiltersConfigs = new Map<
  keyof HousekeepingFiltersData,
  HousekeepingAdvancedFiltersConfig
>()
  .set('roomRanges', {
    labelKey: 'GLOBAL.ROOM_NUMBER.LONG',
    longFormatter: (value: string) => value,
    shortFormatter: (value: string) => value
  })
  .set('query', {
    labelKey: 'ADVANCED_FILTERS.QUERY_LABEL',
    longFormatter: (value: string) => value,
    shortFormatter: (value: string) => value
  })
  .set('roomTypes', {
    labelKey: 'GLOBAL.ROOM_TYPE.LONG',
    longFormatter: fullFormatter,
    shortFormatter
  })
  .set('roomCategories', {
    labelKey: 'GLOBAL.ROOM_CATEGORY.LONG',
    longFormatter: fullFormatter,
    shortFormatter
  })
  .set('floors', {
    labelKey: 'GLOBAL.FLOOR.LONG',
    longFormatter: fullFormatter,
    shortFormatter
  })
  .set('sections', {
    labelKey: 'GLOBAL.SECTION.LONG',
    longFormatter: fullFormatter,
    shortFormatter
  })
  .set('locations', {
    labelKey: 'GLOBAL.LOCATION.LONG',
    longFormatter: fullFormatter,
    shortFormatter
  })
  .set('housekeepingStates', {
    labelKey: 'GLOBAL.HOUSEKEEPING_STATUS.MEDIUM',
    longFormatter: fullFormatter,
    shortFormatter
  })
  .set('frontDeskStates', {
    labelKey: 'GLOBAL.FRONT_DESK_STATUS.SHORT',
    longFormatter: fullFormatter,
    shortFormatter
  })
  .set('guestServiceStatuses', {
    labelKey: 'GLOBAL.GUEST_SERVICE_STATUS.TITLE_LONG',
    longFormatter: (entry: DictionaryEntity) =>
      i18n.t(`GLOBAL.GUEST_SERVICE_STATUS.VALUES.${entry.code}`),
    shortFormatter: (entry: DictionaryEntity) =>
      i18n.t(`GLOBAL.GUEST_SERVICE_STATUS.VALUES.${entry.code}`)
  })
  .set('roomConditions', {
    labelKey: 'ROOM_DETAILS.ROOM_CONDITIONS',
    longFormatter: fullFormatter,
    shortFormatter
  })
  .set('inoperationStates', {
    labelKey: 'GLOBAL.STATUS',
    longFormatter: (status: string) =>
      i18n.t(`GLOBAL.MAINTENANCE_STATUS.SHORT.${status}`),
    shortFormatter: (status: string) =>
      i18n.t(`GLOBAL.MAINTENANCE_STATUS.SHORT.${status}`)
  })
  .set('greenService', {
    labelKey: 'GLOBAL.GREEN_SERVICE.TITLE',
    longFormatter: booleanFormatter,
    shortFormatter: booleanFormatter
  });
