import React, { FC, memo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getOutOfOrderReasons } from '@hkm/components/App/domain/selectors';
import {
  selectBusinessDate,
  selectPropertyDateFormats
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import RoomStatusBadge from '@hkm/components/shared/RoomStatusBadge/RoomStatusBadge';
import * as actions from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/actions';
import {
  selectMaintenanceCreateValidationConflicts,
  selectMaintenanceCreateValidationLoading
} from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/selectors';
import { MaintenanceCreateFieldDisability } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/model/MaintenanceCreateFieldDisability';
import { MaintenanceFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/model/maintenanceFormData';
import { getMaintenanceFormValidationRelevantData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/Validation/getMaintenanceFormValidationRelevantData';
import MaintenanceFormValidationButton from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/Validation/MaintenanceFormValidationButton';
import {
  DictionaryLabelLength,
  extractDictionaryEntryLabel
} from '@hkm/shared/dictionaries/dictionaryItemsLabelExtractor';
import { useEnabledRoomStates } from '@hkm/shared/hooks/useEnabledRoomStates';
import {
  formatNumberRanges,
  parseNumberRanges
} from '@hkm/shared/numberRange/numberRangesParser';
import { getFieldErrorMessage } from '@hkm/shared/validation/errorProvider';
import { UnifiedRoomDetails } from '@hkm/types/room/model/UnifiedRoomDetails';
import { isEqual } from 'lodash';

import {
  BaseConfigurationEntity,
  DictionaryEntity,
  HousekeepingInoperationStatus,
  RoomStatus
} from '@ac/library-api';
import { AcBox } from '@ac/mobile-components/dist/components/box';
import {
  AcFieldDate,
  AcFieldText
} from '@ac/mobile-components/dist/components/field';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AcFormElement,
  AcFormGroup
} from '@ac/mobile-components/dist/components/form-element';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { AcRadioGroup } from '@ac/mobile-components/dist/components/radio-group';
import { SingleRadioProps } from '@ac/mobile-components/dist/components/radio-group/AcRadioGroup';
import { AcSelect } from '@ac/mobile-components/dist/components/select';
import { AcSelectValue } from '@ac/mobile-components/dist/components/select/interfaces/AcSelectValue';
import { AcTextarea } from '@ac/mobile-components/dist/components/textarea';
import {
  AcTile,
  AcTileGroup
} from '@ac/mobile-components/dist/components/tile';
import { Icon } from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { formFieldFactory, FormRenderProps } from '@ac/react-infrastructure';

import './MaintenanceFormBody.css';

export interface MaintenanceCreateBodyProps extends Childless {
  roomDetails?: UnifiedRoomDetails;
  formProps: FormRenderProps<MaintenanceFormData>;
  onValidateRequest: (fromData: MaintenanceFormData) => void;
  disableFields?: MaintenanceCreateFieldDisability;
}

const FormField = formFieldFactory<MaintenanceFormData>();

/* tslint:disable:jsx-no-lambda */
const MaintenanceFormBody: FC<MaintenanceCreateBodyProps> = (
  props: MaintenanceCreateBodyProps
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const prefix = 'maintenance-create-body';

  const formats = useSelector(selectPropertyDateFormats);
  const businessDate = useSelector(selectBusinessDate);

  const conflicts = useSelector(selectMaintenanceCreateValidationConflicts);
  const isLoadingConflicts = useSelector(
    selectMaintenanceCreateValidationLoading
  );

  const states = useEnabledRoomStates();
  const roomStates: AcSelectValue[] = states.map((state: DictionaryEntity) => ({
    value: state.code!,
    itemLabel: extractDictionaryEntryLabel(state, DictionaryLabelLength.Long)
  }));

  const statusCodeMap: SingleRadioProps[] = Object.values(
    HousekeepingInoperationStatus
  ).map(status => ({
    value: status,
    label: t(`GLOBAL.MAINTENANCE_STATUS.SHORT.${status}`)
  })) as SingleRadioProps[];

  const reasons = useSelector(getOutOfOrderReasons)!;
  const roomReasons: AcSelectValue[] = reasons.map(
    (state: BaseConfigurationEntity) => ({
      value: state.code!,
      itemLabel: `${state.code!} - ${extractDictionaryEntryLabel(
        state,
        DictionaryLabelLength.Long
      )}`
    })
  );

  // Clear validation conflicts if any relevant data is changed
  const currentValidationValues = getMaintenanceFormValidationRelevantData(
    props.formProps.values
  );
  const lastValidationValues = useRef(currentValidationValues);
  if (!isEqual(lastValidationValues.current, currentValidationValues)) {
    lastValidationValues.current = currentValidationValues;
    dispatch(actions.clearConflicts());
  }

  function onRoomRangeBlur() {
    const parsed = parseNumberRanges(props.formProps.values.roomNumbers);
    if (parsed) {
      props.formProps.form.change('roomNumbers', formatNumberRanges(parsed));
    }
  }

  function onReturnStatusChange(newStatus: RoomStatus) {
    props.formProps.form.change('returnStatusCode', newStatus);
  }

  function onReasonsChange(newStatus: RoomStatus) {
    props.formProps.form.change('reasonCode', newStatus);
  }

  const onValidateRequest = useCallback(() => {
    props.onValidateRequest(props.formProps.values);
  }, [props.onValidateRequest, props.formProps]);

  return (
    <AcBody className={prefix}>
      <AcTileGroup>
        {props.roomDetails && (
          <AcTile title={t('ROOM_DETAILS.ROOM_INFORMATION')} icon={Icon.Room}>
            <AcFormGroup>
              <AcFormElement
                label={t('GLOBAL.ROOM_NUMBER.LONG')}
                testSelector={formatTestSelector(prefix, 'roomNumber')}
              >
                {props.roomDetails.roomNumber}
              </AcFormElement>
              <AcFormElement
                label={t('GLOBAL.ROOM_TYPE.LONG')}
                testSelector={formatTestSelector(prefix, 'roomType')}
              >
                {t('ROOM_DETAILS.ROOM_TYPE_FORMAT', {
                  code: props.roomDetails.roomType?.code,
                  name: props.roomDetails.roomType?.description
                })}
              </AcFormElement>
            </AcFormGroup>
          </AcTile>
        )}

        <AcTile title={t('ROOM_DETAILS.MAINTENANCE')} icon={Icon.Eod}>
          <AcFormGroup>
            {!props.roomDetails && (
              <FormField valuePath="roomNumbers">
                {fieldRenderProps => (
                  <AcFieldText
                    {...fieldRenderProps.input}
                    required={true}
                    onBlur={onRoomRangeBlur}
                    placeholder={t('GLOBAL.FILL')}
                    testSelector={`${prefix}-room-list`}
                    validation={getFieldErrorMessage(fieldRenderProps)}
                    label={t('MAINTENANCE_CREATE.ROOM_LIST')}
                    disabled={props.disableFields?.roomNumbers}
                  />
                )}
              </FormField>
            )}

            <AcFlex className="ac-spacing-top-lg">
              <FormField valuePath="statusCode">
                {fieldRenderProps => (
                  <AcRadioGroup
                    {...fieldRenderProps.input}
                    name="statusCode"
                    required={true}
                    radioList={statusCodeMap}
                    label={t('GLOBAL.SELECT')}
                    validation={getFieldErrorMessage(fieldRenderProps)}
                    testSelector={`${prefix}-statusCode`}
                    disabled={props.disableFields?.statusCode}
                  />
                )}
              </FormField>
            </AcFlex>

            <AcFlex>
              <AcBox size={6} className="ac-spacing-right-xs">
                <FormField valuePath="fromTime">
                  {fieldRenderProps => (
                    <AcFieldDate
                      {...fieldRenderProps.input}
                      required={true}
                      format={formats.shortDate}
                      placeholder={formats.shortDate}
                      label={t('GLOBAL.START_DATE.LONG')}
                      validation={getFieldErrorMessage(fieldRenderProps)}
                      testSelector={`${prefix}-fromTime`}
                      min={businessDate}
                      value={props.formProps.values.fromTime}
                      disabled={props.disableFields?.fromTime}
                      className={`maintenance-details-date-header`}
                    />
                  )}
                </FormField>
              </AcBox>

              <AcBox size={6} className="ac-spacing-left-xs">
                <FormField valuePath="toTime">
                  {fieldRenderProps => (
                    <AcFieldDate
                      {...fieldRenderProps.input}
                      required={true}
                      format={formats.shortDate}
                      label={t('GLOBAL.END_DATE.LONG')}
                      placeholder={formats.shortDate}
                      validation={getFieldErrorMessage(fieldRenderProps)}
                      testSelector={`${prefix}-toTime`}
                      value={props.formProps.values.toTime}
                      disabled={props.disableFields?.toTime}
                      className={`maintenance-details-date-header`}
                    />
                  )}
                </FormField>
              </AcBox>
            </AcFlex>

            <FormField valuePath="reasonCode">
              {fieldRenderProps => (
                <AcSelect
                  showInputs={true}
                  label={t('MAINTENANCE_CREATE.REASON')}
                  onChange={onReasonsChange}
                  itemsList={roomReasons}
                  selectedItem={fieldRenderProps.input.value as string}
                  testSelector={`${prefix}-return-status`}
                  validation={getFieldErrorMessage(fieldRenderProps)}
                  required={true}
                  placeholder={t('GLOBAL.SELECT')}
                  disabled={props.disableFields?.reasonCode}
                />
              )}
            </FormField>

            <FormField valuePath="returnStatusCode">
              {fieldRenderProps => (
                <AcSelect
                  showInputs={true}
                  label={t('GLOBAL.RETURN_STATUS.LONG')}
                  onChange={onReturnStatusChange}
                  itemsList={roomStates}
                  selectedItem={fieldRenderProps.input.value as string}
                  testSelector={`${prefix}-return-status`}
                  itemTemplate={item => (
                    <RoomStatusBadge
                      status={item.value as RoomStatus}
                      showLabel={true}
                    />
                  )}
                  validation={getFieldErrorMessage(fieldRenderProps)}
                  required={true}
                  placeholder={t('GLOBAL.SELECT')}
                  disabled={props.disableFields?.returnStatusCode}
                />
              )}
            </FormField>

            <FormField valuePath="comment">
              {fieldRenderProps => (
                <AcTextarea
                  {...fieldRenderProps.input}
                  placeholder={t('GLOBAL.FILL')}
                  testSelector={`${prefix}-comment`}
                  validation={getFieldErrorMessage(fieldRenderProps)}
                  label={t('MAINTENANCE_CREATE.COMMENT')}
                  maxlength={255}
                  required={true}
                  disabled={props.disableFields?.comment}
                />
              )}
            </FormField>
          </AcFormGroup>
        </AcTile>

        <MaintenanceFormValidationButton
          className="maintenance-create-body-validate-button"
          canValidate={props.formProps.valid}
          conflicts={conflicts}
          isLoading={isLoadingConflicts}
          testSelector={formatTestSelector(prefix, 'validateButton')}
          onValidateRequest={onValidateRequest}
        />
      </AcTileGroup>
    </AcBody>
  );
};

export default memo(MaintenanceFormBody);
