import * as appActions from '@hkm/components/App/domain/actions';
import { ReservationChargesData } from '@hkm/shared/domain/reservationCharges/models/reservationChargesData';
import { ReservationChargesActions } from '@hkm/shared/domain/reservationCharges/reservationChargesActions';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import { takeLatest } from '@redux-saga/core/effects';
import i18n from 'i18next';
import { delay, put, race, take } from 'redux-saga/effects';

import {
  HousekeepingReservationCharges,
  LibraryApiResponse,
  MethodPostParams,
  PostHousekeepingReservationCharges,
  ReservationIdPathParam
} from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';

interface ReservationChargesSagaApi {
  postChargeReservation: (
    ...params: MethodPostParams<
      ReservationIdPathParam,
      undefined,
      PostHousekeepingReservationCharges
    >
  ) => Promise<any>;
  getReservationRecentCharges: (
    ...params: MethodPostParams<ReservationIdPathParam>
  ) => Promise<
    | HousekeepingReservationCharges
    | LibraryApiResponse<HousekeepingReservationCharges>
  >;
}

export function createReservationChargesSaga(
  actions: ReservationChargesActions,
  reservationChargeApi: ReservationChargesSagaApi
) {
  function* postReservationCharges(action: Action<ReservationChargesData>) {
    try {
      const { reservationId, data, version } = action.payload;

      yield reservationChargeApi.postChargeReservation({
        pathParams: { reservationId },
        data: {
          charges: data
        },
        customConfig: getCustomConfig({ version })
      });

      /* TODO - delay is temporary. Need fix on BE side */
      yield delay(2000);

      // fetch charged transaction for reservation
      yield put(actions.fetchReservationRecentCharges.trigger(reservationId));
      yield race([
        take(actions.fetchReservationRecentCharges.success),
        take(actions.fetchReservationRecentCharges.failure)
      ]);

      yield put(actions.postReservationCharges.success());
      yield put(
        appActions.displaySuccess(
          i18n.t('POSTING.POSTING_DETAILS.CHARGES_POSTED')
        )
      );
    } catch (e) {
      yield put(actions.postReservationCharges.failure(e));
      yield put(appActions.displayExtractedError(e));
    }
  }

  function* fetchReservationRecentCharges(action: Action<string>) {
    try {
      const reservationCharges: HousekeepingReservationCharges = yield reservationChargeApi.getReservationRecentCharges(
        {
          pathParams: {
            reservationId: action.payload
          }
        }
      );

      yield put(
        actions.fetchReservationRecentCharges.success(reservationCharges)
      );
    } catch (e) {
      yield put(actions.fetchReservationRecentCharges.failure(e));
      yield put(appActions.displayExtractedError(e));
    }
  }

  return function*() {
    yield takeLatest(
      actions.postReservationCharges.trigger,
      postReservationCharges
    );
    yield takeLatest(
      actions.fetchReservationRecentCharges.trigger,
      fetchReservationRecentCharges
    );
  };
}
