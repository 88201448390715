import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFeatureToggles } from '@hkm/components/App/domain/selectors';
import { FeatureToggle } from '@hkm/shared/enum/featureToggle';

export function useHasRequiredFeatureToggles(
  requiredFeatureToggles: Set<FeatureToggle>
): boolean {
  const featureToggles = useSelector(getFeatureToggles);
  const mappedFeatureToggles = featureToggles.map(
    ({ featureName }) => featureName
  );

  return useMemo(() => {
    let result: boolean = true;
    if (requiredFeatureToggles) {
      if (!featureToggles) {
        return false;
      }

      result = Array.from(requiredFeatureToggles).every(featureToggle =>
        mappedFeatureToggles.includes(featureToggle)
      );
    }

    return result;
  }, [requiredFeatureToggles, featureToggles]);
}
