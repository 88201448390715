import * as actions from '@hkm/components/Login/domain/actions';
import { selectSystemUser } from '@hkm/components/Login/domain/selectors';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import { SystemUser } from '@hkm/shared/entities/SystemUser';
import { createDefaultConfigProvider } from '@hkm/utils/api';
import getModuleLoginUrl from '@hkm/utils/moduleNavigator';
import { initUnauthorizationMiddleware } from '@hkm/utils/unauthorizationMiddleware';
import { TakeableChannel } from '@redux-saga/core';
import { eventChannel } from 'redux-saga';
import {
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import { BaseApi, UserDetails } from '@ac/library-api';
import { UsersApi } from '@ac/library-api/dist/api/v0/identity';
import { LoginService } from '@ac/library-utils/dist/services';

const UNAUTHORIZATION_CHECK_INTERVAL = 2000;

function* handleUnauthorization() {
  yield put(actions.clearUserContext());
}

// eslint-disable-next-line require-yield
function* createUnauthorizationChannel() {
  return eventChannel(emitter => {
    initUnauthorizationMiddleware(() => emitter(true));

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });
}

function* unauthorizationInterval() {
  while (true) {
    yield delay(UNAUTHORIZATION_CHECK_INTERVAL);
    const systemUser: SystemUser = yield select(selectSystemUser);
    const isAuthenticated = systemUser && systemUser.type > 0;
    if (isAuthenticated && !LoginService.isLoggedIn()) {
      yield call(handleUnauthorization);
    }
  }
}

function* unauthorizationSaga() {
  const unauthorizationChannel: TakeableChannel<unknown> = yield call(
    createUnauthorizationChannel
  );
  yield takeEvery(unauthorizationChannel, handleUnauthorization);
  yield fork(unauthorizationInterval);
}

function* initUser() {
  LoginService.overrideIdentityClientId(APP_CONSTANTS.IDENTITY_CLIENT_ID);
  LoginService.setRedirectUri(getModuleLoginUrl());

  const isLoggedIn: boolean = yield LoginService.init();

  if (isLoggedIn) {
    BaseApi.defaultConfig = createDefaultConfigProvider();
  }

  yield put(actions.userLogin.success(isLoggedIn));
}

function* getUserData() {
  try {
    const userDetailsResponse: UserDetails = yield UsersApi.getMe();
    yield put(actions.userDetails.success(userDetailsResponse));
  } catch (e) {
    yield put(actions.userDetails.failure(e));
  }
}

export default function* systemUserSagas() {
  yield takeEvery(actions.userLogin.trigger, initUser);
  yield takeLatest(actions.userDetails.trigger, getUserData);
  yield fork(unauthorizationSaga);
}
