import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  clearAllViews,
  clearDictionaries,
  fetchFeatureToggles
} from '@hkm/components/App/domain/actions';
import { propertySelect } from '@hkm/components/Menu/PropertySelector/domain/actions';
import {
  selectActiveProperty,
  selectPropertiesList,
  selectPropertyLoadingState
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import APP_ROUTES from '@hkm/constants/routing.constants';
import {
  setSignalRClient,
  signalrCurrentPropertyId
} from '@hkm/services/signalRClient/signalRClient';
import { ReduxProps } from '@hkm/shared/interfaces/reduxProps';
import { Store } from '@hkm/store/interfaces/store';
import { findRegionData } from '@hkm/utils/region/findRegion';
import { getUrlForNewRegion } from '@hkm/utils/region/newRegionUrl';
import { willRegionChangeForProperty } from '@hkm/utils/region/willRegionChangeForProperty';
import { History } from 'history';
import { compose } from 'redux';

import { PropertyDetails, UnitAssignmentListItem } from '@ac/library-api';
import { SessionService } from '@ac/library-utils/dist/services';
import { getGlobalRegionCode } from '@ac/library-utils/dist/utils/multi-region';
import {
  AcSelect,
  AcSelectPattern,
  AcSelectValue
} from '@ac/mobile-components/dist/components/select';
import { LoadingState } from '@ac/mobile-components/dist/enums';

import './PropertySelector.css';

interface PropertySelectorReduxProps extends ReduxProps {
  propertiesList: UnitAssignmentListItem[];
  selectedProperty?: PropertyDetails;
  loadingState: LoadingState;
}

interface RouterProps {
  history: History;
}

type Props = RouterProps & PropertySelectorReduxProps & WithTranslation;

class PropertySelector extends React.Component<Props> {
  public getSelectList = (): AcSelectValue[] => {
    return this.props.propertiesList.map((item: UnitAssignmentListItem) => ({
      value: item.unitId,
      itemLabel: item.name
    })) as AcSelectValue[];
  };

  public render() {
    const optionsList: AcSelectValue[] = this.getSelectList();
    const selectedItem: string = this.props.selectedProperty
      ? this.props.selectedProperty.id
      : '';
    const { i18n } = this.props;

    let readonlyState = true;
    let placeholder = '';

    if (selectedItem && signalrCurrentPropertyId !== selectedItem) {
      setSignalRClient(selectedItem);
    }

    if (
      this.props.loadingState !== LoadingState.Loading &&
      !!this.props.selectedProperty
    ) {
      readonlyState =
        optionsList.length > 1 ||
        (optionsList.length === 1 && !this.props.selectedProperty);
      placeholder =
        optionsList.length === 0
          ? i18n.t('MENU.PROPERTY.PROPERTIES_NOT_FOUND')
          : i18n.t('MENU.PROPERTY.CHOOSE_PROPERTY');
    }

    return (
      <AcSelect
        selectedItem={selectedItem}
        placeholder={placeholder}
        itemsList={optionsList}
        label={i18n.t('MENU.PROPERTY.LABEL')}
        onChange={this.handlePropertyChange}
        showSearch={false}
        clearable={false}
        readonly={!readonlyState}
        loading={this.props.loadingState}
        pattern={AcSelectPattern.Borderless}
        testSelector="property"
      />
    );
  }

  private handlePropertyChange = (unitId: string) => {
    const property = this.props.propertiesList?.find(
      ({ unitId: id }) => unitId === id
    );

    if (!property) {
      return;
    }

    const isPropertyFromDifferentRegion = willRegionChangeForProperty(property);
    if (isPropertyFromDifferentRegion) {
      this.props.history.push(APP_ROUTES.HOME);

      return this.handlePropertyRegion(property);
    }

    SessionService.setPropertyId(undefined);
    this.props.dispatch!(propertySelect(property));
    this.props.dispatch!(clearDictionaries({ force: false }));
    this.props.dispatch!(clearAllViews());
    this.props.dispatch!(fetchFeatureToggles.trigger());
    this.props.history.push(APP_ROUTES.HOME);
  };

  private handlePropertyRegion = (property: UnitAssignmentListItem) => {
    const region = findRegionData(
      property?.regionCode ?? getGlobalRegionCode()
    );

    return window.location.replace(
      getUrlForNewRegion(
        region?.code ?? '',
        region?.domain ?? '',
        property?.unitId ?? ''
      )
    );
  };
}

const mapStateToProps = (state: Store): PropertySelectorReduxProps => ({
  propertiesList: selectPropertiesList(state),
  selectedProperty: selectActiveProperty(state),
  loadingState: selectPropertyLoadingState(state)
});

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(PropertySelector);
