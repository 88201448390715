import * as appActions from '@hkm/components/App/domain/actions';
import { HousekeepingPagination } from '@hkm/components/Housekeeping/Dashboard/domain/interfaces';
import { selectHousekeepingDashboardPagination } from '@hkm/components/Housekeeping/Dashboard/domain/selectors';
import * as detailsActions from '@hkm/components/Housekeeping/Details/domain/actions';
import { HousekeepingDetailsState } from '@hkm/components/Housekeeping/Details/domain/interfaces';
import { selectHousekeepingDetailsState } from '@hkm/components/Housekeeping/Details/domain/selectors';
import { selectBusinessDate } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { createHousekeepingGreenServiceToggleSagaFactory } from '@hkm/shared/domain/greenServiceToggle/greenServiceToggleSagaFactory';
import { createHousekeepingGuestServiceChangeSaga } from '@hkm/shared/domain/guestServiceChange/guestServiceChangeSagaFactory';
import { createHousekeepingHkStatusChangeSaga } from '@hkm/shared/domain/housekeepingStatusChange/housekeepingStatusChangeSagaFactory';
import { createHousekeepingRoomOccupancySaga } from '@hkm/shared/domain/occupancyChange/occupancyChangeSagaFactory';
import { createHousekeepingRoomConditionsChangeSaga } from '@hkm/shared/domain/roomConditionsChange/roomConditionsChangeSagaFactory';
import { createHousekeepingRoomStatusChangeSaga } from '@hkm/shared/domain/roomStatusChange/roomStatusChangeSagaFactory';
import { getReservedKind } from '@hkm/shared/reservedKind/getReservedKind';
import { createHousekeepingRoom } from '@hkm/types/housekeeping/factories/createHousekeepingRoom';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import {
  cancel,
  fork,
  put,
  select,
  take,
  takeLatest
} from '@redux-saga/core/effects';
import { Task } from '@redux-saga/types';
import i18n from 'i18next';
import { IAction } from 'redux-saga-routines';
import { createSelector } from 'reselect';

import {
  getDataForAllPages,
  HousekeepingRoomDetailsDto,
  LibraryApiResponse,
  MaintenanceFile,
  PageResponse,
  RawMaintenanceFile
} from '@ac/library-api';
import {
  HousekeepingRoomsApi,
  HousekeepingViewsApi
} from '@ac/library-api/dist/api/v0/housekeeping';
import { NewOverlayMessage } from '@ac/mobile-components/dist/components/overlay-message';

function* fetchRoom() {
  try {
    const detailsState: HousekeepingDetailsState = yield select(
      selectHousekeepingDetailsState
    );
    const roomId: string = detailsState.roomId!;
    const businessDate: string = yield select(selectBusinessDate);

    const room: LibraryApiResponse<HousekeepingRoomDetailsDto> = yield HousekeepingViewsApi.getHousekeepingRoomDetails(
      {
        pathParams: { roomId }
      }
    );

    const housekeepingRoom = createHousekeepingRoom(
      room.data,
      businessDate,
      getReservedKind
    );

    yield put(detailsActions.fetchRoom.success(housekeepingRoom));
    yield put(detailsActions.fetchAttachments.trigger());
  } catch (e) {
    yield put(appActions.displayError(i18n.t('ROOM_DETAILS.REFRESH_FAIL')));
    yield put(detailsActions.fetchRoom.failure(e));
  }
}

function* fetchAttachments() {
  const detailsState: HousekeepingDetailsState = yield select(
    selectHousekeepingDetailsState
  );
  try {
    const maintenanceId = detailsState.room!.currentMaintenance
      ? detailsState.room!.currentMaintenance.id
      : undefined;

    if (!maintenanceId) {
      return (yield put(
        detailsActions.fetchAttachments.success([])
      )) as IAction<MaintenanceFile[]>;
    }

    const response: PageResponse<
      RawMaintenanceFile,
      MaintenanceFile
    > = yield getDataForAllPages(
      ({ pageNumber, pageSize }) =>
        HousekeepingRoomsApi.getRoomMaintenancesFileList({
          pathParams: {
            roomId: detailsState.roomId!,
            maintenanceId
          },
          queryParams: {
            pageNumber,
            pageSize
          }
        }) as Promise<PageResponse<RawMaintenanceFile, MaintenanceFile>>
    );
    const sorted = response.results.sort((a, b) =>
      a.metadata.createdAt > b.metadata.createdAt ? 1 : -1
    );

    return (yield put(
      detailsActions.fetchAttachments.success(sorted)
    )) as IAction<MaintenanceFile[]>;
  } catch (e) {
    yield put(detailsActions.fetchAttachments.failure(e));

    return ((yield put(
      appActions.displayError(e.message)
    )) as unknown) as IAction<string | NewOverlayMessage>;
  }
}

function* onChangeFail() {
  yield put(detailsActions.fetchRoom.trigger());
}

const selectRoomOccupancyRoomDetails = createSelector(
  selectHousekeepingDetailsState,
  state => ({
    roomId: state.room!.id,
    roomVersion: state.room!.housekeepingRoomVersion!
  })
);

function* handleInitialize() {
  // Base tasks
  yield takeLatest(detailsActions.fetchRoom.trigger, fetchRoom);
  yield takeLatest(detailsActions.fetchAttachments.trigger, fetchAttachments);

  yield fork(createHousekeepingRoomStatusChangeSaga(detailsActions.roomStatus));
  yield fork(
    createHousekeepingRoomConditionsChangeSaga(detailsActions.roomConditions)
  );
  yield fork(
    createHousekeepingGuestServiceChangeSaga(detailsActions.guestService)
  );
  yield fork(
    createHousekeepingHkStatusChangeSaga(detailsActions.housekeepingStatus)
  );
  yield fork(
    createHousekeepingGreenServiceToggleSagaFactory(detailsActions.greenService)
  );
  yield fork(
    createHousekeepingRoomOccupancySaga(
      detailsActions.roomOccupancy,
      selectRoomOccupancyRoomDetails
    )
  );

  // onFail
  yield takeLatest(
    detailsActions.greenService.toggleGreenService.failure,
    onChangeFail
  );
  yield takeLatest(
    detailsActions.roomOccupancy.setRoomOccupancy.failure,
    onChangeFail
  );
  yield takeLatest(
    detailsActions.roomOccupancy.removeRoomOccupancy.failure,
    onChangeFail
  );
  yield takeLatest(
    detailsActions.roomStatus.changeRoomStatus.failure,
    onChangeFail
  );
  yield takeLatest(
    detailsActions.roomConditions.changeRoomConditions.failure,
    onChangeFail
  );
  yield takeLatest(
    detailsActions.guestService.changeGuestServiceStatus.failure,
    onChangeFail
  );
  yield takeLatest(
    detailsActions.housekeepingStatus.changeHousekeepingStatus.failure,
    onChangeFail
  );

  // Clone from dashboard
  const detailsState: HousekeepingDetailsState = yield select(
    selectHousekeepingDetailsState
  );
  const dashboardState: HousekeepingPagination = yield select(
    selectHousekeepingDashboardPagination
  );
  const foundRoom: HousekeepingRoom | undefined = (dashboardState.page
    ? dashboardState.page.results
    : []
  ).find(dashboardRoom => dashboardRoom.id === detailsState.roomId);

  if (foundRoom) {
    yield put(detailsActions.setRoomFromDashboard(foundRoom));
    yield put(detailsActions.fetchAttachments.trigger());
  } else {
    yield put(detailsActions.fetchRoom.trigger());
  }
}

export default function* housekeepingDetailsSagas() {
  while (true) {
    const task: Task = yield takeLatest(
      detailsActions.initialize,
      handleInitialize
    );
    yield take(detailsActions.reset);
    yield cancel(task);
  }
}
