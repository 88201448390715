import * as actions from '@hkm/components/Maintenance/Details/domain/actions';
import { MaintenanceDetailsState } from '@hkm/components/Maintenance/Details/domain/interfaces';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';
import { ReducerMap } from 'redux-actions';

import { MaintenanceFile } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: MaintenanceDetailsState = {};

const reducerMap: ReducerMap<MaintenanceDetailsState, unknown> = {
  [actions.initialize]: (state, action: Action<string>) => ({
    ...state,
    roomId: action.payload
  }),

  [actions.fetchMaintenanceRoom.trigger]: state => ({
    ...state,
    isFetching: true
  }),
  [actions.fetchMaintenanceRoom.failure]: state => ({
    ...state,
    isFetching: false,
    didInitialize: true
  }),
  [actions.fetchMaintenanceRoom.success]: (
    state,
    action: Action<MaintenanceRoom>
  ) => ({
    ...state,
    room: {
      ...action.payload
    },
    isFetching: false,
    didInitialize: true,
    isChanging: false
  }),

  [actions.fetchAttachments.trigger]: state => ({
    ...state,
    isFetchingAttachments: true
  }),
  [actions.fetchAttachments.failure]: state => ({
    ...state,
    isFetchingAttachments: false,
    didInitializeAttachments: true
  }),
  [actions.fetchAttachments.success]: (
    state,
    action: Action<MaintenanceFile[]>
  ) => ({
    ...state,
    attachments: action.payload,
    isFetchingAttachments: false,
    didInitializeAttachments: true
  }),

  [actions.setMaintenanceRoomFromDashboard]: (
    state,
    action: Action<MaintenanceRoom>
  ) => ({
    ...state,
    room: action.payload,
    didInitialize: true
  }),
  [actions.reset]: () => ({
    ...initialState
  })
};

export default handleActions(reducerMap, initialState);
