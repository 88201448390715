import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as housekeepingDashboardActions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import { HousekeepingSort } from '@hkm/components/Housekeeping/shared/enum/housekeepingSort';
import * as maintenanceDashboardActions from '@hkm/components/Maintenance/Dashboard/domain/actions';
import { MaintenanceSort } from '@hkm/components/Maintenance/shared/enum/maintenanceSort';
import * as housekeepingPostingDashboardActions from '@hkm/components/Posting/Dashboard/domain/actions';
import { PostingSort } from '@hkm/components/Posting/Dashboard/enum/postingSort';
import * as taskManagementDashboardActions from '@hkm/components/TaskManagement/Dashboard/domain/actions';
import { TaskStatusDictionary } from '@hkm/components/TaskManagement/relations/domain/interfaces';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { DashboardMode } from '@hkm/shared/enum/dashboardMode';
import classNames from 'classnames';
import { Dispatch } from 'redux';

import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorAttribute } from '@ac/mobile-components/dist/utils';

import './SidebarMenu.css';

const LINK_TO_RESET_STATE_CALLBACK = {
  [APP_ROUTES.HOUSEKEEPING.DASHBOARD]: (dispatch: Dispatch) => {
    dispatch(housekeepingDashboardActions.pagination.setFilters({}));
    dispatch(
      housekeepingDashboardActions.setHousekeepingMode(DashboardMode.Default)
    );
    dispatch(
      housekeepingDashboardActions.pagination.setSort(HousekeepingSort.Sequence)
    );
  },
  [APP_ROUTES.MAINTENANCE.DASHBOARD]: (dispatch: Dispatch) => {
    dispatch(maintenanceDashboardActions.pagination.setFilters({}));
    dispatch(
      maintenanceDashboardActions.pagination.setSort(MaintenanceSort.RoomNumber)
    );
  },
  [APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.DASHBOARD]: (dispatch: Dispatch) => {
    dispatch(housekeepingPostingDashboardActions.pagination.setFilters({}));
    dispatch(
      housekeepingPostingDashboardActions.pagination.setSort(
        PostingSort.RoomNumber
      )
    );
  },
  [APP_ROUTES.TASK_MANAGEMENT.DASHBOARD]: (
    dispatch: Dispatch,
    taskStatusDictionary: TaskStatusDictionary[]
  ) => {
    dispatch(
      taskManagementDashboardActions.clearFilteringState(taskStatusDictionary)
    );
  }
};

interface MenuLinkItemProps extends Testable {
  text: string;
  link: string;
  isActive: boolean;

  onClick?(): void;
}

const MenuLinkItem: FC<MenuLinkItemProps> = (props: MenuLinkItemProps) => {
  const dispatch = useDispatch();
  const tasksRelations = useSelector(getRelationsState);
  const classes = classNames('link-item', {
    active: props.isActive
  });

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      props.onClick?.();
      const requireFiltersReset = Object.keys(
        LINK_TO_RESET_STATE_CALLBACK
      ).some(link => link === props.link);

      if (props.isActive && requireFiltersReset) {
        const resetCallback = LINK_TO_RESET_STATE_CALLBACK[props.link];
        resetCallback(dispatch, tasksRelations.taskStatuses);
        e.preventDefault();
      }
    },
    [props.isActive, props.link, tasksRelations]
  );

  return (
    <div className={classes} {...getTestSelectorAttribute(props.testSelector)}>
      <Link to={props.link} onClick={onClick} className="link">
        {props.text}
      </Link>
    </div>
  );
};

export default MenuLinkItem;
