import {
  MaintenanceCreateActions,
  MaintenanceCreateCustomActions
} from '@hkm/shared/domain/maintenanceCreate/maintenanceCreateActions';
import { MaintenanceCreateData } from '@hkm/shared/domain/maintenanceCreate/models/maintenanceCreateData';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { ConflictDetails } from '@ac/library-api';
import {
  createInApiAction,
  getListAction
} from '@ac/library-utils/dist/redux-utils';

export function createMaintenanceCreateActions(
  prefixResourceName: string,
  customActions: MaintenanceCreateCustomActions
): MaintenanceCreateActions {
  return {
    clearConflicts: customActions.clearConflicts,
    createMaintenance: createInApiAction<MaintenanceCreateData, undefined>(
      `${prefixResourceName}/create/createMaintenance`
    ),
    validateMaintenance: getListAction<
      MaintenanceCreateData,
      ConflictDetails[]
    >(`${prefixResourceName}/create/validate`),
    fetchReservations: getListAction<string[], UnifiedReservationDetails[]>(
      `${prefixResourceName}/create//fetchReservations`
    )
  };
}
