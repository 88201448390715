import * as appActions from '@hkm/components/App/domain/actions';
import * as actions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import { HousekeepingDashboardState } from '@hkm/components/Housekeeping/Dashboard/domain/interfaces';
import { HousekeepingSort } from '@hkm/components/Housekeeping/shared/enum/housekeepingSort';
import { HousekeepingFiltersData } from '@hkm/components/Housekeeping/shared/models/housekeepingFiltersData';
import { SelectedRoom } from '@hkm/components/Housekeeping/shared/models/selectedRoom';
import { DashboardMode } from '@hkm/shared/enum/dashboardMode';
import { createPaginationReducers } from '@hkm/store/pagination/paginationReducersFactory';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import { xorBy } from 'lodash';
import { ReducerMap } from 'redux-actions';

import { HousekeepingRoomDetailsDto } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: HousekeepingDashboardState = {
  paginationState: {
    sort: HousekeepingSort.Sequence,
    filters: {}
  },
  selectedMode: DashboardMode.Default,
  selectedRooms: []
};

const reducerMap: ReducerMap<HousekeepingDashboardState, unknown> = {
  ...createPaginationReducers<
    HousekeepingDashboardState,
    HousekeepingRoomDetailsDto,
    HousekeepingRoom,
    HousekeepingSort,
    HousekeepingFiltersData
  >('paginationState', actions.pagination),
  [appActions.clearAllViews]: () => ({ ...initialState }),
  [actions.replaceChangedRoom]: (state, action: Action<HousekeepingRoom>) => ({
    ...state,
    paginationState: {
      ...state.paginationState,
      page: !state.paginationState.page
        ? undefined
        : {
            ...state.paginationState.page,
            results: state.paginationState.page!.results.map(room =>
              room.id === action.payload.id ? action.payload : room
            )
          }
    }
  }),
  [actions.setHousekeepingMode]: (state, action: Action<DashboardMode>) => ({
    ...state,
    selectedMode: action.payload
  }),
  [actions.toggleRoomsSelection]: (state, action: Action<SelectedRoom>) => ({
    ...state,
    selectedRooms: xorBy(state.selectedRooms, [action.payload], 'id')
  }),
  [actions.setRoomsSelections]: (state, action: Action<SelectedRoom[]>) => ({
    ...state,
    selectedRooms: action.payload
  }),
  [actions.clearHousekeepingSelection]: state => ({
    ...state,
    selectedRooms: []
  })
};

export default handleActions(reducerMap, initialState);
