import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HousekeepingStatisticsBody from '@hkm/components/Housekeeping/Statistics/Body/HousekeepingStatisticsBody';
import * as actions from '@hkm/components/Housekeeping/Statistics/domain/actions';
import { selectHousekeepingStatisticsState } from '@hkm/components/Housekeeping/Statistics/domain/selectors';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import APP_ROUTES from '@hkm/constants/routing.constants';

import { AcBody } from '@ac/mobile-components/dist/components/layout';

import './HousekeepingStatistics.css';

const HousekeepingStatistics: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector(selectHousekeepingStatisticsState);

  useEffect(() => {
    dispatch(actions.enableRefresh());

    return () => {
      dispatch(actions.disableRefresh());
    };
  }, []);

  const showSpinner = !state.data;

  return (
    <>
      <ConnectedHeader close={APP_ROUTES.HOUSEKEEPING.DASHBOARD}>
        {t('HOUSEKEEPING_ROOM_STATISTICS.TITLE')}
      </ConnectedHeader>
      {showSpinner ? (
        <AcBody showSpinner={true} />
      ) : (
        <HousekeepingStatisticsBody data={state.data!} />
      )}
    </>
  );
};

export default memo(HousekeepingStatistics);
