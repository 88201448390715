import { Config, ConfigBuilder } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

export interface CustomConfigOptions {
  version?: string | number;
}

export function getCustomConfig(options?: CustomConfigOptions): Config<any> {

  options = options || {};
  const { version } = options;

  const combinedHeaders: any = {};

  if (isDefined(version)) {
    combinedHeaders['If-Match'] = version;
  }

  return new ConfigBuilder()
    .setHeaders(combinedHeaders)
    .getConfig();
}
