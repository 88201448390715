import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { Color, TextSize } from '@ac/mobile-components/dist/enums';
import {
  Styleable,
  Testable
} from '@ac/mobile-components/dist/interfaces/componentProps';

interface DayUseBadgeProps extends Styleable, Testable {}

const DayUseBadge: FC<DayUseBadgeProps> = (props: DayUseBadgeProps) => {
  const { t } = useTranslation();
  const className = classNames('day-use-badge', props.className);

  return (
    <AcBadge
      style={props.style}
      className={className}
      badgeText={t('GLOBAL.DAY_USE')}
      backgroundColor={Color.White}
      labelUppercase={false}
      borderColor={Color.Gray4}
      testSelector={props.testSelector}
      badgeTextSize={TextSize.Main3}
      fit={true}
    />
  );
};

export default memo(DayUseBadge);
