import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { MaintenanceManagePermissionsConfig } from '@hkm/components/Maintenance/config/maintenanceManagePermissionsConfig';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { ReturnPathLocationStates } from '@hkm/shared/interfaces/locationStates';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';

import { RoomMaintenanceState } from '@ac/library-api';
import { AcDropdownItem } from '@ac/mobile-components/dist/components/dropdown-list';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

interface Props extends Testable {
  room: HousekeepingRoom;
}

const HousekeepingDashboardRoomDropdownItems: FC<Props> = ({
  room,
  testSelector
}) => {
  const { t } = useTranslation();
  const maintenanceState = room.currentMaintenance?.status?.code;
  const hasManageMaintenancePermission = usePermission(
    ...MaintenanceManagePermissionsConfig
  );

  return (
    <>
      <AcDropdownItem
        link={generatePath(APP_ROUTES.HOUSEKEEPING.DETAILS, { id: room.id })}
        testSelector={formatTestSelector(testSelector, 'details')}
      >
        {t('ROOM_DETAILS.TITLE')}
      </AcDropdownItem>

      {maintenanceState === RoomMaintenanceState.Active && (
        <AcDropdownItem
          link={generatePath(APP_ROUTES.MAINTENANCE.RELEASE, {
            id: room.currentMaintenance!.id
          })}
          testSelector={formatTestSelector(testSelector, 'releaseMaintenance')}
        >
          {t('MAINTENANCE_RELEASE.Active.TITLE.SHORT')}
        </AcDropdownItem>
      )}

      {hasManageMaintenancePermission && (
        <AcDropdownItem
          link={{
            pathname: generatePath(APP_ROUTES.HOUSEKEEPING.ADD_MAINTENANCE, {
              roomId: room.id
            }),
            state: {
              returnPath: APP_ROUTES.HOUSEKEEPING.DASHBOARD
            } as ReturnPathLocationStates
          }}
          testSelector={formatTestSelector(testSelector, 'newMaintenance')}
        >
          {t('ROOM_LIST.NEW_OOS_OOO')}
        </AcDropdownItem>
      )}
    </>
  );
};

export default memo(HousekeepingDashboardRoomDropdownItems);
