import React, { FC } from 'react';
import classNames from 'classnames';

import { AcIconButton } from '@ac/mobile-components/dist/components/icon-button';
import { Color, Icon, IconSize } from '@ac/mobile-components/dist/enums';

import './SidebarMenu.css';

interface MenuModuleItemProps {
  isActive?: boolean;
  icon: Icon;

  onClick?(): void;
}

const MenuModuleItem: FC<MenuModuleItemProps> = (
  props: MenuModuleItemProps
) => {
  const classes = classNames('module-item', {
    active: props.isActive
  });

  return (
    <div className={classes}>
      <AcIconButton
        icon={props.icon}
        color={Color.White}
        onClick={props.onClick}
        size={IconSize.Medium}
      />
    </div>
  );
};

export default MenuModuleItem;
