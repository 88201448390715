import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as actionBatchStatus from '@hkm/components/Housekeeping/Batch/domain/actions';
import BatchStatusChangeBody from '@hkm/components/Housekeeping/Batch/statusChange/Body/BatchStatusChangeBody';
import BatchStatusChangeFooter from '@hkm/components/Housekeeping/Batch/statusChange/Footer/BatchStatusChangeFooter';
import { HousekeepingBatchStatusChangeData } from '@hkm/components/Housekeeping/shared/models/housekeepingBatchStatusChangeData';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { ValidationStatuses } from '@hkm/shared/interfaces/validationStatuses';
import { formatNumberRanges } from '@hkm/shared/numberRange/numberRangesParser';
import { Validator } from '@hkm/shared/validation/validator';
import {
  isNonEmptyString,
  isValidNumberRange
} from '@hkm/shared/validation/validators';

import { AcText } from '@ac/mobile-components/dist/components/text';
import { TextSize } from '@ac/mobile-components/dist/enums';
import {
  Form,
  ValidationResult,
  ValidationSchema
} from '@ac/react-infrastructure';

interface Statuses extends ValidationStatuses {
  roomRanges?: ValidationResult[];
  roomStates?: ValidationResult[];
}

const Schema: ValidationSchema<Statuses> = {
  roomRanges: [isValidNumberRange, isNonEmptyString],
  roomStates: isNonEmptyString
};

const BatchStatusChange: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const prefix = 'batch-status-change';
  const [validator] = useState<
    Validator<HousekeepingBatchStatusChangeData, Statuses>
  >(new Validator(Schema));
  const { state } = location;

  const roomRanges = useMemo(() => {
    if (!state) {
      return undefined;
    }
    const formattedRoomRanges = Object.keys(state)
      .map(key => {
        return formatNumberRanges(state[key]);
      })
      .toString();

    return formattedRoomRanges;
  }, [state]);

  const initialFilters: HousekeepingBatchStatusChangeData = { roomRanges };

  const validate = useCallback(
    (values: HousekeepingBatchStatusChangeData) => validator.validate(values),
    [validator]
  );

  const onSubmit = useCallback(
    (formData: HousekeepingBatchStatusChangeData) => {
      dispatch(actionBatchStatus.changeBatchRoomsStatus.trigger(formData));
    },
    [dispatch]
  );

  return (
    <Form
      initialValues={initialFilters}
      validate={validate}
      onSubmit={onSubmit}
    >
      {formRenderProps => (
        <>
          <ConnectedHeader
            close={APP_ROUTES.HOUSEKEEPING.DASHBOARD}
            testSelector={prefix}
          >
            <AcText size={TextSize.Main1}>
              {t('HOUSEKEEPING_BATCH.STATUS_CHANGE.TITLE')}
            </AcText>
          </ConnectedHeader>
          <BatchStatusChangeBody
            formProps={formRenderProps}
            disableRoomList={!!state}
          />
          <BatchStatusChangeFooter formProps={formRenderProps} />
        </>
      )}
    </Form>
  );
};

export default memo(BatchStatusChange);
