import React, { ReactElement, useMemo } from 'react';
import { AdvancedFiltersCheckboxData } from '@hkm/components/shared/AdvancedFilters/Checkboxes/advancedFiltersCheckboxData';

import { AcCheckbox } from '@ac/mobile-components/dist/components/checkbox';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import {
  Childless,
  Styleable
} from '@ac/mobile-components/dist/interfaces/componentProps';
import {
  formatTestSelector,
  genericMemo
} from '@ac/mobile-components/dist/utils';
import { formFieldFactory, FormRenderProps } from '@ac/react-infrastructure';

interface AdvancedFiltersCheckboxesProps<T> extends Childless, Styleable {
  testSelectorPrefix: string;
  formProps: FormRenderProps<T>;
  field: keyof T;
  label: string;
  data: Array<AdvancedFiltersCheckboxData<any>>;
}

function AdvancedFiltersCheckboxes<T>(
  props: AdvancedFiltersCheckboxesProps<T>
): ReactElement {
  const FormField = useMemo(() => formFieldFactory<T>(), []);

  function onChange(value: any, checked: boolean) {
    const current = new Set(
      (props.formProps.values[props.field] || []) as
        | Iterable<never>
        | null
        | undefined
    );
    checked ? current.add(value as never) : current.delete(value as never);
    const newArray = Array.from(current);
    props.formProps.form.change(
      props.field as Extract<keyof T, string>,
      ((newArray.length > 0 ? newArray : undefined) as unknown) as T[Extract<
        keyof T,
        string
      >]
    );
  }

  function isChecked(value: T): boolean {
    return ((props.formProps.values[props.field] || []) as Array<
      T[keyof T]
    >).includes(value as any);
  }

  return (
    <FormField valuePath={props.field as any}>
      <AcFormElement
        label={props.label}
        className={props.className}
        style={props.style}
      >
        {props.data.map(data => (
          <AcCheckbox
            key={data.value as any}
            checked={isChecked(data.value)}
            /* tslint:disable-next-line:jsx-no-lambda */
            onChange={checked => onChange(data.value, checked)}
            testSelector={formatTestSelector(
              props.testSelectorPrefix,
              data.value as any
            )}
            label={data.label}
          />
        ))}
      </AcFormElement>
    </FormField>
  );
}

export default genericMemo(AdvancedFiltersCheckboxes);
