import React, { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@hkm/components/Menu/domain/actions';
import * as H from 'history';

import { AcIconButton } from '@ac/mobile-components/dist/components/icon-button';
import { AcWrappedLink } from '@ac/mobile-components/dist/components/wrapped-link';
import { Icon } from '@ac/mobile-components/dist/enums';
import {
  Childless,
  Clickable
} from '@ac/mobile-components/dist/interfaces/componentProps';

interface Props extends Childless, Clickable {
  link?: H.Location | string;
}

const HeaderCloseButton: FC<Props> = ({ onClick, link }) => {
  const dispatch = useDispatch();

  const onButtonClick = useCallback(() => {
    dispatch(actions.setMenuState(false));
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <AcWrappedLink link={link}>
      <AcIconButton icon={Icon.Cancel} onClick={onButtonClick} />
    </AcWrappedLink>
  );
};

export default memo(HeaderCloseButton);
