import { SubscribeNotificationData } from '@hkm/components/App/notificationConsumer/domain/interface';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';

import { createAction } from '@ac/library-utils/dist/redux-utils';

export const subscribeNotification = createAction<SubscribeNotificationData>(
  'signalR/subscribe'
);
export const expectNotification = createAction<SignalREventType>(
  'signalR/expect'
);
