import { DashboardSort } from '@hkm/components/TaskManagement/Dashboard/domain/enums';
import {
  Filters,
  SuccessDashboardFetch,
  UpdateFiltersAndSearchPayload,
  UpdatePagingAndFiltersParams
} from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import { LinkObjectDependentSettings } from '@hkm/components/TaskManagement/domain/interfaces/linkObjectDependentSettings';
import {
  TaskStatusDictionary,
  TaskTagDictionary
} from '@hkm/components/TaskManagement/relations/domain/interfaces';

import {
  createAction,
  getListAction
} from '@ac/library-utils/dist/redux-utils';

export const fetchDashboard = getListAction<undefined, SuccessDashboardFetch>(
  '@taskManagement/dashboard/fetchDashboard'
);

export const fetchLinkObjectDependentSettings = getListAction<
  undefined,
  LinkObjectDependentSettings,
  undefined
>('@taskManagement/dashboard/fetchLinkObjectDependentSettings');

export const fetchTaskTags = getListAction<
  undefined,
  TaskTagDictionary[],
  undefined
>('@taskManagement/dashboard/fetchTaskTags');

export const updateSelectedFilters = createAction<Filters>(
  '@taskManagement/dashboard/updateSelectedFilters'
);

export const updatePaginationPage = createAction<number>(
  '@taskManagement/dashboard/updatePaginationPage'
);

export const updateSort = createAction<DashboardSort>(
  '@taskManagement/dashboard/updateSort'
);

export const updatePagingAndFilters = createAction<
  UpdatePagingAndFiltersParams
>('@taskManagement/dashboard/updatePagingAndFilters');

export const updateQuickFilter = createAction<TaskQuickFilter>(
  '@taskManagement/dashboard/updateQuickFilter'
);

export const updateSearchString = createAction<string>(
  '@taskManagement/dashboard/updateSearchString'
);

export const updateFiltersAndSearch = createAction<
  UpdateFiltersAndSearchPayload
>('@taskManagement/dashboard/UpdateFiltersAndSearchPayload');

export const clearFilteringState = createAction<TaskStatusDictionary[]>(
  '@taskManagement/dashboard/clearFilteringState'
);
