import { IndividualApi } from '@ac/library-api/dist/api/v0/profiles';

interface GetCommunicationChannelParameters {
  profileId: string;
  communicationChannelId: string;
}

export async function getCommunicationChannelModeById(
  parameters: GetCommunicationChannelParameters
) {
  const { profileId, communicationChannelId: channelId } = parameters;
  try {
    if (!profileId) {
      return channelId;
    }

    const profile = await IndividualApi.getById({
      pathParams: { profileId }
    });

    const communicationChannels = profile.data.communicationDetails;

    const mode = communicationChannels?.find(
      channel => channel.id === channelId
    )?.mode;

    return mode ?? channelId;
  } catch (e) {
    return channelId;
  }
}
