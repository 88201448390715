import { mapIdCodeDescription } from '@hkm/types/reservation/mappers/mapIdCodeDescription';
import { UnifiedRoomDetails } from '@hkm/types/room/model/UnifiedRoomDetails';

import {
  AttendantAssignmentRoomDetails,
  HousekeepingRoomDetailsDto,
  IdCodeDescriptionName,
  RoomAssignmentMaintenanceDetails
} from '@ac/library-api';

export function mapHousekeepingRoomDetails(
  roomDetails: HousekeepingRoomDetailsDto
): UnifiedRoomDetails {
  return {
    id: roomDetails.id,
    roomClass: roomDetails.roomClass,
    roomCategory: roomDetails.roomCategory,
    roomType: roomDetails.roomType,
    floor: roomDetails.floor,
    roomStatus: roomDetails.roomStatus,
    roomNumber: roomDetails.roomNumber?.code,
    housekeepingRoomVersion: roomDetails.housekeepingRoomVersion
  };
}

export function mapAttendantRoomDetails(
  roomDetails: AttendantAssignmentRoomDetails
): UnifiedRoomDetails {
  return {
    id: roomDetails.id,
    roomType: roomDetails.roomType,
    roomStatus:
      roomDetails.roomStatus && mapIdCodeDescription(roomDetails.roomStatus),
    roomNumber: roomDetails.roomNumber,
    housekeepingRoomVersion: roomDetails.housekeepingRoomVersion
  };
}

export function mapMaintenanceRoomDetails(
  roomDetails: RoomAssignmentMaintenanceDetails
): UnifiedRoomDetails {
  return {
    id: roomDetails.id,
    roomClass: roomDetails.roomClass,
    roomCategory: roomDetails.roomCategory,
    roomType: roomDetails.roomType as IdCodeDescriptionName,
    floor: roomDetails.roomFloor,
    roomStatus: roomDetails.status,
    roomNumber: roomDetails.room?.code,
    housekeepingRoomVersion: roomDetails.housekeepingRoomVersion
  };
}
