import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  Color,
  Icon,
  IconSize,
  TextSize
} from '@ac/mobile-components/dist/enums';

interface OverdueFlagProps {
  class?: string;
  indexId?: string;
}

const OverdueFlag: FC<OverdueFlagProps> = (props: OverdueFlagProps) => {
  const testSelector = `${props.indexId}-overdue-flag`;
  const { t } = useTranslation();

  return (
    <AcFlex alignItems={AlignItems.center}>
      <AcIcon
        className={props.class}
        icon={Icon.Todo}
        color={Color.Alert}
        size={IconSize.Small}
        testSelector={testSelector}
      />
      <AcText size={TextSize.Main2} className="ac-spacing-left-sm">
        {t('TASK_MANAGEMENT.DASHBOARD.LABELS.TASK_OVERDUE')}
      </AcText>
    </AcFlex>
  );
};

export default memo(OverdueFlag);
