import * as appActions from '@hkm/components/App/domain/actions';
import {
  TasksAccount,
  TasksArAccount
} from '@hkm/components/TaskManagement/models/cashiering';
import { TasksGroup } from '@hkm/components/TaskManagement/models/groups';
import { TasksHousekeepingRoom } from '@hkm/components/TaskManagement/models/housekeeping';
import {
  TasksCompanyProfile,
  TasksIndividualProfile,
  TasksTravelAgentProfile
} from '@hkm/components/TaskManagement/models/profiles';
import { TasksReservation } from '@hkm/components/TaskManagement/models/reservations/index';
import {
  getAccountDashboard,
  getARDashboard
} from '@hkm/components/TaskManagement/repositories/cashiering';
import { getAllRooms } from '@hkm/components/TaskManagement/repositories/configuration/entities/room';
import { findGroups } from '@hkm/components/TaskManagement/repositories/groups';
import { getRoomDashboard } from '@hkm/components/TaskManagement/repositories/housekeeping';
import {
  getCompanyProfile,
  getIndividualProfile,
  getTravelAgentProfile
} from '@hkm/components/TaskManagement/repositories/profiles';
import { getReservationsDashboard } from '@hkm/components/TaskManagement/repositories/reservations';
import { SelectedObjectModel } from '@hkm/components/TaskManagement/shared/interfaces/selectedObjectModel';
import { getErrorMessage } from '@hkm/components/TaskManagement/utils/getErrorMessage';
import { trimString } from '@hkm/components/TaskManagement/utils/trimString';
import { store } from '@hkm/index';
import i18next from 'i18next';

import {
  buildFIQLFilter,
  FIQLOperators,
  LinkedObjectInfo,
  ProfileRole,
  RawLinkedObject,
  TaskObjectType,
  TitleOrders
} from '@ac/library-api';

export default class LinkedObjectManager {
  private nameSetting = TitleOrders.LastNameFirstNameTitle;

  constructor(nameSetting?: TitleOrders) {
    this.nameSetting = nameSetting || TitleOrders.LastNameFirstNameTitle;
  }

  public getSelectedObject = async (
    linkedObject: LinkedObjectInfo | RawLinkedObject
  ) => {
    if (linkedObject.type === TaskObjectType.Room) {
      return await this.getSelectedRoom(linkedObject.objectId);
    } else if (linkedObject.type === TaskObjectType.ARAccount) {
      return await this.getSelectedAR(linkedObject.objectId);
    } else if (linkedObject.type === TaskObjectType.Reservation) {
      return await this.getSelectedReservation(linkedObject.objectId);
    } else if (linkedObject.type === TaskObjectType.Profile) {
      return await this.getSelectedProfile(linkedObject);
    } else if (linkedObject.type === TaskObjectType.Account) {
      return await this.getSelectedAccount(linkedObject.objectId);
    } else {
      return await this.getSelectedGroup(linkedObject.objectId);
    }
  };

  public async loadRooms(objectId: string): Promise<TasksHousekeepingRoom[]> {
    const filter = buildFIQLFilter('id', FIQLOperators.equal, objectId);

    const rooms = await this.handlerErrors(
      async () => await getAllRooms({ filter })
    );
    const housekeeping = await this.handlerErrors(
      async () => await getRoomDashboard({})
    );

    if (!rooms || !housekeeping) {
      this.handleError();

      return [];
    }

    const mappedRooms = rooms.map(room => {
      const housekeepingRoom = housekeeping.find(
        roomNumber => roomNumber.id === room.id
      );

      return {
        ...room,
        ...housekeepingRoom
      };
    }) as TasksHousekeepingRoom[];

    return mappedRooms.map(room => this.mapToTasksHousekeepingRoomModel(room));
  }

  public async loadProfiles(
    linkedObject: LinkedObjectInfo | RawLinkedObject
  ): Promise<
    Array<
      TasksIndividualProfile | TasksCompanyProfile | TasksTravelAgentProfile
    >
  > {
    const linkedObjectType = linkedObject.profile!.role;

    if (linkedObjectType === ProfileRole.IND) {
      return this.handlerErrors(
        async () =>
          await getIndividualProfile(this.nameSetting, linkedObject.objectId)
      );
    } else if (linkedObjectType === ProfileRole.CMP) {
      return this.handlerErrors(
        async () =>
          await getCompanyProfile(this.nameSetting, linkedObject.objectId)
      );
    } else {
      return this.handlerErrors(
        async () =>
          await getTravelAgentProfile(this.nameSetting, linkedObject.objectId)
      );
    }
  }

  private async loadReservations(filter: string): Promise<TasksReservation[]> {
    return this.handlerErrors(
      async () => await getReservationsDashboard({ filter })
    );
  }

  private async loadGroups(objectId: string): Promise<TasksGroup[]> {
    const filter = buildFIQLFilter('id', FIQLOperators.equal, objectId);

    return this.handlerErrors(async () => await findGroups({ filter }));
  }

  private async loadARs(objectId: string): Promise<TasksArAccount[]> {
    const filter = buildFIQLFilter('id', FIQLOperators.equal, objectId);

    return this.handlerErrors(async () => await getARDashboard({ filter }));
  }

  private async loadAccounts(objectId: string): Promise<TasksAccount[]> {
    const filter = buildFIQLFilter('id', FIQLOperators.equal, objectId);

    return this.handlerErrors(
      async () => await getAccountDashboard(this.nameSetting, { filter })
    );
  }

  private async getSelectedGroup(
    objectId: string
  ): Promise<SelectedObjectModel | undefined> {
    const groups = await this.loadGroups(objectId);
    const selectedGroup = groups.find(({ id }) => id === objectId)!;

    if (!selectedGroup) {
      this.handleError();

      return;
    }

    return {
      object: selectedGroup,
      type: TaskObjectType.Group,
      id: selectedGroup.id,
      displayName: selectedGroup.displayName
    };
  }

  private async getSelectedRoom(
    objectId: string
  ): Promise<SelectedObjectModel | undefined> {
    const rooms = await this.loadRooms(objectId);
    const selectedRoom = rooms.find(({ id }) => id === objectId);

    if (!selectedRoom) {
      this.handleError();

      return;
    }

    return {
      object: selectedRoom,
      type: TaskObjectType.Room,
      displayName: selectedRoom.displayName,
      id: selectedRoom.id
    };
  }

  private async getSelectedProfile(
    linkedObject: LinkedObjectInfo | RawLinkedObject
  ): Promise<SelectedObjectModel | undefined> {
    const profiles = await this.loadProfiles(linkedObject);
    const selectedProfile = profiles.find(
      ({ id }) => id === linkedObject.objectId
    );

    if (!selectedProfile) {
      this.handleError(
        i18next.t('TASK_MANAGEMENT.TASK_PREVIEW.INACTIVE_OBJECT')
      );

      return;
    }

    return {
      object: selectedProfile,
      type: TaskObjectType.Profile,
      id: selectedProfile.id,
      displayName: selectedProfile.displayName
    };
  }

  private async getSelectedReservation(
    objectId: string
  ): Promise<SelectedObjectModel | undefined> {
    const mainReservationFilter = buildFIQLFilter(
      'id',
      FIQLOperators.equal,
      objectId
    );
    const reservations = await this.loadReservations(mainReservationFilter!);
    const selectedReservation = reservations.find(({ id }) => id === objectId);
    let sharedReservations: TasksReservation[] = [];

    if (!selectedReservation) {
      this.handleError();

      return;
    }

    if (selectedReservation.sharingId) {
      const sharedReservationsFilter = buildFIQLFilter(
        'sharingId',
        FIQLOperators.equal,
        selectedReservation.sharingId
      );
      sharedReservations = await this.loadReservations(
        sharedReservationsFilter!
      );
    }

    return {
      id: selectedReservation?.id,
      object: {
        ...selectedReservation,
        sharedReservations
      } as TasksReservation,
      type: TaskObjectType.Reservation,
      displayName: selectedReservation.displayName
    };
  }

  private async getSelectedAR(
    objectId: string
  ): Promise<SelectedObjectModel | undefined> {
    const ars = await this.loadARs(objectId);
    const selectedAR = ars.find(({ id }) => id === objectId);

    if (!selectedAR) {
      this.handleError(
        i18next.t('TASK_MANAGEMENT.TASK_PREVIEW.INACTIVE_OBJECT')
      );

      return;
    }

    return {
      id: selectedAR.id,
      object: selectedAR,
      type: TaskObjectType.ARAccount,
      displayName: selectedAR.displayName
    };
  }

  private async getSelectedAccount(
    objectId: string
  ): Promise<SelectedObjectModel | undefined> {
    const accounts = await this.loadAccounts(objectId);
    const selectedAccount = accounts.find(({ id }) => id === objectId);

    if (!selectedAccount) {
      this.handleError(
        i18next.t('TASK_MANAGEMENT.TASK_PREVIEW.INACTIVE_OBJECT')
      );

      return;
    }

    return {
      id: selectedAccount.id,
      object: selectedAccount,
      type: TaskObjectType.Account,
      displayName: selectedAccount.displayName
    };
  }

  private handleError(objectSpecificError?: string): void {
    const message = objectSpecificError
      ? objectSpecificError
      : i18next.t('TASK_MANAGEMENT.TASK_PREVIEW.CANNOT_LOAD_TASK');

    store.dispatch(appActions.displayError(message));
    throw new Error(message);
  }

  private mapToTasksHousekeepingRoomModel(
    room: TasksHousekeepingRoom
  ): TasksHousekeepingRoom {
    const roomNumber: string = room.roomNumber
      ? `${i18next.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.ROOM')} ${
          room.roomNumber?.code
        },`
      : '';
    const roomStatus: string = room.roomStatus
      ? `${i18next.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.STATUS')} ${
          room.roomStatus?.code
        },`
      : '';
    const roomType: string = room.roomType
      ? `${i18next.t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.TYPE')} ${
          room.roomType?.code
        },`
      : '';

    return {
      ...room,
      displayName: trimString(`${roomNumber} ${roomStatus} ${roomType}`)
    } as TasksHousekeepingRoom;
  }

  private async handlerErrors<Element>(
    callToApi: () => Promise<Element[]>
  ): Promise<Element[]> {
    try {
      return await callToApi();
    } catch (error) {
      store.dispatch(appActions.displayError(getErrorMessage(error)));

      return [];
    }
  }
}
