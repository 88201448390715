import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DateTimeFiltersConfig } from '@hkm/components/TaskManagement/Dashboard/config/dateFiltersConfig';
import { MultiselectFiltersConfig } from '@hkm/components/TaskManagement/Dashboard/config/multiselectFiltersConfig';
import DateTimeFilterItem from '@hkm/components/TaskManagement/Dashboard/DashboardFilters/DashboardFiltersBody/DateTimeFilterItem/DateTimeFilterItem';
import MultiselectFilterItem from '@hkm/components/TaskManagement/Dashboard/DashboardFilters/DashboardFiltersBody/MultiselectFilterItem/MultiselectFilterItem';
import { Filters } from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import { getIsLoading } from '@hkm/components/TaskManagement/Dashboard/domain/selectors';

import { AcFieldText } from '@ac/mobile-components/dist/components/field';
import { AcBody } from '@ac/mobile-components/dist/components/layout';

import './DashboardFiltersBody.css';

interface ComponentProps {
  filtersState: Filters;
  dateTimeFiltersConfig: DateTimeFiltersConfig;
  multiselectFiltersConfig: MultiselectFiltersConfig;
  setSearchValue: (value?: string) => void;
  searchValue?: string;
}

const DashboardFiltersBody: FC<ComponentProps> = props => {
  const isLoading = useSelector(getIsLoading);
  const { t } = useTranslation();

  return (
    <AcBody showSpinner={isLoading} className="filters-wrapper">
      <AcFieldText
        label={t('GLOBAL.SEARCH')}
        placeholder={t('GLOBAL.FILL')}
        value={props.searchValue}
        testSelector="task-dashboard-filters-search-field"
        onChange={props.setSearchValue}
      />
      <div className="date-time-filters-wrapper">
        {props.dateTimeFiltersConfig.map((filterItem, index) => (
          <DateTimeFilterItem
            key={`${filterItem.name}-${index}`}
            filterItem={filterItem}
            filtersState={props.filtersState}
          />
        ))}
      </div>
      {props.multiselectFiltersConfig.map((filterItem, index) => (
        <MultiselectFilterItem
          key={`${filterItem.name}-${index}`}
          filterItem={filterItem}
        />
      ))}
    </AcBody>
  );
};

export default memo(DashboardFiltersBody);
