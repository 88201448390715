import React, { FC, memo, ReactNode } from 'react';
import EtaEtdGuaranteedBadge from '@hkm/components/shared/EtaEtdGuaranteedBadge/EtaEtdGuaranteedBadge';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  FlexDirection,
  JustifyContent
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';

interface Props extends Testable {
  label: string | ReactNode;
  isEtaEtdGuaranteed: boolean;
}

const ReservationsMovementsLabel: FC<Props> = ({
  label,
  isEtaEtdGuaranteed,
  testSelector
}) => {
  if (isEtaEtdGuaranteed) {
    return (
      <AcFlex
        justifyContent={JustifyContent.spaceBetween}
        direction={FlexDirection.column}
        testSelector={testSelector}
      >
        <AcText>{label}</AcText>
        <EtaEtdGuaranteedBadge />
      </AcFlex>
    );
  }

  return <>{label}</>;
};

export default memo(ReservationsMovementsLabel);
