import { Dictionary } from '@hkm/shared/dictionaries/dictionary';

export const HousekeepingDetailsDictionaries: Set<Dictionary> = new Set<
  Dictionary
>()
  .add(Dictionary.RoomStatus)
  .add(Dictionary.HousekeepingStatus)
  .add(Dictionary.NoteTypes)
  .add(Dictionary.Attribute)
  .add(Dictionary.RoomCondition);
