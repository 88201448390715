import { GuestServiceChangeActions } from '@hkm/shared/domain/guestServiceChange/guestServiceChangeActions';
import { GuestServiceChangeRequest } from '@hkm/shared/domain/guestServiceChange/guestServiceChangeRequest';

import { updateAction } from '@ac/library-utils/dist/redux-utils';

export function createGuestServiceChangeActions(
  prefix: string
): GuestServiceChangeActions {
  return {
    changeGuestServiceStatus: updateAction<GuestServiceChangeRequest, string>(
      `${prefix}/guestService/change`
    )
  };
}
