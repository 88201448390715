import { PostingFiltersData } from '@hkm/components/Posting/Dashboard/filters/postingFiltersData';
import { createCombinedApiFilters } from '@hkm/shared/helpers/apiFilter/apiCombinedFiltersFactory';
import {
  ApiFiltersFactoryConfig,
  createApiFilters
} from '@hkm/shared/helpers/apiFilter/apiFiltersFactory';
import { createNumberRangesFilter } from '@hkm/shared/helpers/apiFilter/apiNumberRangesFiltersFactory';

import { FIQLOperators, HousekeepingRoomDetailsDto } from '@ac/library-api';

const apiRoomNumber: string = 'roomNumber.numberNumeric';
const apiRoomCode: string = 'roomNumber.code';

const filterMap = new Map<
  keyof PostingFiltersData,
  ApiFiltersFactoryConfig<HousekeepingRoomDetailsDto>
>().set('reservationStatus', {
  field: 'reservations',
  subField: 'status',
  customOperator: FIQLOperators.contains
});

export function createPostingApiFilter(
  filters: PostingFiltersData
): string | undefined {
  const { roomRanges, ...codeFilters } = filters;

  return createCombinedApiFilters(
    ...createApiFilters<PostingFiltersData, HousekeepingRoomDetailsDto>(
      codeFilters,
      filterMap
    ),
    createNumberRangesFilter(roomRanges, apiRoomNumber, apiRoomCode)
  );
}
