import { usersSelectorLoadUsers } from '@hkm/components/shared/features/UsersSelector/reducer/actions';
import { useUsersSelectorContext } from '@hkm/components/shared/features/UsersSelector/store/context';
import { getConfigurationUsers } from '@hkm/components/TaskManagement/apis/configuration';

import {
  buildFIQLFilter,
  combineFilters,
  FIQLOperators
} from '@ac/library-api';

export const useUsersSelectorManager = () => {
  const { userSelectorDispatch } = useUsersSelectorContext();

  const loadAndGetInitialUsers = async (userIds: string[]) => {
    const users = await getConfigurationUsers({
      filter: buildFIQLFilter('id', FIQLOperators.in, userIds)
    });
    userSelectorDispatch(usersSelectorLoadUsers({ usersList: users }));

    return users;
  };

  const findUsers = async (filter?: string) => {
    if (!filter) {
      userSelectorDispatch(usersSelectorLoadUsers({ usersList: [] }));

      return;
    }

    const users = await getConfigurationUsers({
      filter: combineFilters(
        [
          buildFIQLFilter(
            'personalData.lastName',
            FIQLOperators.stringContains,
            filter
          ),
          buildFIQLFilter(
            'personalData.firstName',
            FIQLOperators.stringContains,
            filter
          ),
          buildFIQLFilter('userName', FIQLOperators.stringContains, filter)
        ],
        FIQLOperators.or
      )
    });

    userSelectorDispatch(usersSelectorLoadUsers({ usersList: users }));
  };

  return {
    findUsers,
    loadAndGetInitialUsers
  };
};
