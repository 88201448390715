import { getCurrentRegionCode } from '@hkm/utils/region/getCurrentRegionCode';

import { UnitAssignmentListItem } from '@ac/library-api';

export const willRegionChangeForProperty = (
  unit?: UnitAssignmentListItem
): boolean => {
  if (!unit || !unit.regionCode) {
    return false;
  }

  const propertyRegionCode = unit.regionCode.toLowerCase();
  const currentRegion = getCurrentRegionCode().toLowerCase();

  return currentRegion !== propertyRegionCode;
};
