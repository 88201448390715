import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/Maintenance/Dashboard/domain/actions';
import {
  selectMaintenanceDashboardIsLoading,
  selectMaintenanceDashboardSort
} from '@hkm/components/Maintenance/Dashboard/domain/selectors';
import { MaintenanceSort } from '@hkm/components/Maintenance/shared/enum/maintenanceSort';
import { toCapitalSnakeCase } from '@hkm/shared/helpers/toCapitalSnakeCase';

import {
  AcSelect,
  AcSelectPattern,
  AcSelectValue
} from '@ac/mobile-components/dist/components/select';

const sorts: MaintenanceSort[] = [
  MaintenanceSort.RoomNumber,
  MaintenanceSort.Status,
  MaintenanceSort.State,
  MaintenanceSort.StartTime,
  MaintenanceSort.EndTime,
  MaintenanceSort.Reason
];

const MaintenanceDashboardSortSelect: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeSort = useSelector(selectMaintenanceDashboardSort);
  const isLoading = useSelector(selectMaintenanceDashboardIsLoading);

  const selectItems: AcSelectValue[] = useMemo(
    () =>
      sorts.map(sort => {
        const translationTag = toCapitalSnakeCase(sort);

        return {
          value: sort as string,
          itemLabel: t(`MAINTENANCE_TABLE.SORT.LIST.${translationTag}`),
          inputLabel: t(`MAINTENANCE_TABLE.SORT.INPUT.${translationTag}`)
        };
      }),
    []
  );

  const onChange = useCallback((selectValue: MaintenanceSort) => {
    dispatch(actions.pagination.setSort(selectValue));
  }, []);

  return (
    <AcSelect
      label={t('COMMON.SORT_BY')}
      showInputs={true}
      onChange={onChange}
      itemsList={selectItems}
      selectedItem={activeSort}
      disabled={isLoading}
      pattern={AcSelectPattern.Dropdown}
      testSelector="maintenanceDashboard-sort"
    />
  );
};

export default memo(MaintenanceDashboardSortSelect);
