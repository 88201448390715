import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIsLoading } from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import APP_ROUTES from '@hkm/constants/routing.constants';

import {
  AcFab,
  AcFabChild,
  AcFabContainer
} from '@ac/mobile-components/dist/components/fab';
import { Icon } from '@ac/mobile-components/dist/enums';

const DashboardFabContainer: FC = () => {
  const { t } = useTranslation();
  const isLoading = useSelector(getIsLoading);

  return (
    <AcFabContainer>
      <AcFab
        icon={Icon.ActionMenu}
        expandedIcon={Icon.Cancel}
        id="task-management-floating-menu"
        testSelector={'task-management-fab'}
      >
        <AcFabChild
          disabled={isLoading}
          icon={Icon.Filter}
          text={t('ADVANCED_FILTERS.TITLE')}
          link={APP_ROUTES.TASK_MANAGEMENT.FILTERS}
          testSelector={'task-management-fab-advanced-search'}
        />
      </AcFab>
    </AcFabContainer>
  );
};

export default DashboardFabContainer;
