import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceAttachmentEditChange } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/EditModal/maintenanceAttachmentEditChange';

import { AcButton } from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFieldText } from '@ac/mobile-components/dist/components/field';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader
} from '@ac/mobile-components/dist/components/modal';
import { Changeable } from '@ac/mobile-components/dist/interfaces/componentProps/changeable';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps/testable';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

import './MaintenanceAttachmentEditModal.css';

export interface MaintenanceAttachmentEditModalProps
  extends Testable,
    Changeable<MaintenanceAttachmentEditChange> {
  attachmentName: string;
  attachmentDescription?: string;
  isEditing: boolean;
  onClose: () => void;
}
const MaintenanceAttachmentEditModal: FC<MaintenanceAttachmentEditModalProps> = props => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();

  useEffect(() => {
    setName(props.attachmentName);
    setDescription(props.attachmentDescription);
  }, []);

  function onClose(): void {
    props.onClose();
  }

  function didChange(): boolean {
    return (
      name !== props.attachmentName ||
      description !== props.attachmentDescription
    );
  }

  function onSave() {
    if (props.onChange) {
      props.onChange({
        name: name!,
        description: description!
      });
    }
    onClose();
  }

  function isFilled(): boolean {
    return !!name;
  }

  function canSave(): boolean {
    return isFilled() && (didChange() || !props.isEditing);
  }

  return (
    <AcModal
      className="maintenance-attachment-edit-modal"
      isOpen={true}
      onClose={onClose}
      testSelector={props.testSelector}
    >
      <AcModalHeader showCloseButton={true}>
        {t(
          props.isEditing ? 'ATTACHMENTS.EDIT_TITLE' : 'ATTACHMENTS.ADD_TITLE'
        )}
      </AcModalHeader>

      <AcModalBody className="maintenance-attachment-edit-modal-body">
        <AcFieldText
          label={t('ATTACHMENTS.NAME_LABEL')}
          required={true}
          value={name}
          onChange={setName}
          placeholder={t('GLOBAL.FILL')}
          testSelector={formatTestSelector(props.testSelector, 'nameField')}
        />

        <AcFieldText
          className="ac-spacing-top-md"
          label={t('ATTACHMENTS.DESCRIPTION_LABEL')}
          value={description}
          onChange={setDescription}
          placeholder={t('GLOBAL.FILL')}
          testSelector={formatTestSelector(
            props.testSelector,
            'descriptionField'
          )}
        />
      </AcModalBody>

      <AcModalFooter>
        <AcButton
          onClick={onSave}
          style={{ width: '100%' }}
          disabled={!canSave()}
          testSelector={formatTestSelector(props.testSelector, 'saveButton')}
        >
          <AcButtonContent text={t('GLOBAL.SAVE')} />
        </AcButton>
      </AcModalFooter>
    </AcModal>
  );
};

export default memo(MaintenanceAttachmentEditModal);
