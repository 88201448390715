import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import MaintenanceReleaseBody from '@hkm/components/shared/Templates/Maintenance/MaintenanceReleaseForm/Body/MaintenanceReleaseBody';
import MaintenanceReleaseFooter from '@hkm/components/shared/Templates/Maintenance/MaintenanceReleaseForm/Footer/MaintenanceReleaseFooter';
import { MaintenanceReleaseFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceReleaseForm/models/maintenanceReleaseData';
import { mapToMaintenanceReleaseFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceReleaseForm/models/mappers/mapToMaintenanceReleaseFormData';
import * as actions from '@hkm/components/shared/Templates/Maintenance/shared/domain/actions';
import { selectMaintenanceUpdateState } from '@hkm/components/shared/Templates/Maintenance/shared/domain/selectors';
import { SourceModuleType } from '@hkm/shared/enum/SourceModuleType';
import { ValidationStatuses } from '@hkm/shared/interfaces/validationStatuses';
import { Validator } from '@hkm/shared/validation/validator';
import { isNonEmptyString } from '@hkm/shared/validation/validators';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';

import { RoomMaintenanceState } from '@ac/library-api';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { AcSpinnerCover } from '@ac/mobile-components/dist/components/spinner';
import { useComponentDidUpdateEffect } from '@ac/mobile-components/dist/hooks';
import {
  Form,
  ValidationResult,
  ValidationSchema
} from '@ac/react-infrastructure';

interface Statuses extends ValidationStatuses {
  roomNumber?: ValidationResult[];
  comment?: ValidationResult[];
  returnStatusCode?: ValidationResult[];
}

interface MaintenanceReleaseFormProps {
  returnPath: string;
  maintenanceRoom: MaintenanceRoom;
  dashboardType: SourceModuleType;
}

const MaintenanceReleaseForm: FC<MaintenanceReleaseFormProps> = (
  props: MaintenanceReleaseFormProps
) => {
  const prefix = 'maintenance-release';
  const roomState = props.maintenanceRoom.state!.code;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const maintenanceDetailsDataState = useSelector(selectMaintenanceUpdateState);

  const Schema: ValidationSchema<Statuses> = useMemo(
    () => ({
      roomNumber: [isNonEmptyString],
      comment: [isNonEmptyString],
      returnStatusCode:
        props.maintenanceRoom.state?.code === RoomMaintenanceState.Active
          ? [isNonEmptyString]
          : []
    }),
    [props.maintenanceRoom]
  );

  const [validator] = useState<Validator<MaintenanceReleaseFormData, Statuses>>(
    new Validator(Schema)
  );
  const initialValues: MaintenanceReleaseFormData = mapToMaintenanceReleaseFormData(
    props.maintenanceRoom,
    props.dashboardType
  );

  const validate = useCallback(
    (values: MaintenanceReleaseFormData) => validator.validate(values),
    []
  );

  const goBack = () => {
    navigate(props.returnPath);
  };

  const onSubmit = (formData: MaintenanceReleaseFormData) => {
    dispatch(actions.releaseMaintenance.trigger(formData));
  };

  useComponentDidUpdateEffect(goBack, [maintenanceDetailsDataState.version]);

  return (
    <>
      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {formRenderProps => (
          <>
            <ConnectedHeader close={goBack} testSelector={prefix}>
              {t(`MAINTENANCE_RELEASE.${roomState}.TITLE.LONG`)}
            </ConnectedHeader>
            <AcBody testSelector="maintenanceRelease">
              <MaintenanceReleaseBody
                formProps={formRenderProps}
                roomDetails={props.maintenanceRoom.unifiedRoomDetails}
              />
            </AcBody>
            <MaintenanceReleaseFooter
              formProps={formRenderProps}
              onCancel={goBack}
            />
          </>
        )}
      </Form>
      <AcSpinnerCover
        coverScreen={true}
        testSelector="maintenance-release-spinner"
        disabled={!maintenanceDetailsDataState.isPending}
      />
    </>
  );
};

export default memo(MaintenanceReleaseForm);
