import React, { FC, memo, PropsWithChildren, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as appActions from '@hkm/components/App/domain/actions';

import {
  AcFooter,
  AcFooterProps
} from '@ac/mobile-components/dist/components/layout';

const ConnectedFooter: FC<PropsWithChildren<AcFooterProps>> = ({
  children,
  ...divProps
}) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(appActions.registerBottomMessageOffsetRef(ref));

    return () => {
      dispatch(appActions.unregisterBottomMessageOffsetRef(ref));
    };
  }, []);

  return (
    <AcFooter ref={ref} {...divProps}>
      {children}
    </AcFooter>
  );
};

export default memo(ConnectedFooter);
