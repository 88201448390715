import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import PaginatedTileList from '@hkm/components/shared/PaginatedList/PaginatedTileList';
import DashboardFab from '@hkm/components/TaskManagement/Dashboard/DashboardFab/DashboardFab';
import DashboardFiltersChips from '@hkm/components/TaskManagement/Dashboard/DashboardFiltersChips/DashboardFiltersChips';
import DashboardItem from '@hkm/components/TaskManagement/Dashboard/DashboardItem/DashboardItem';
import DashboardSubheader from '@hkm/components/TaskManagement/Dashboard/DashboardSubheader/DashboardSubheader';
import {
  getDashboardList,
  getIsLoading
} from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import { useTaskDashboardManager } from '@hkm/components/TaskManagement/Dashboard/useTaskDashboardManager';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';

import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { AcPress } from '@ac/mobile-components/dist/components/press';

const TaskManagementDashboard: FC = () => {
  const { initialize, updatePaginationPage } = useTaskDashboardManager();
  const relations = useSelector(getRelationsState);
  const isLoading = useSelector(getIsLoading);
  const { paging, results } = useSelector(getDashboardList);
  const { t } = useTranslation();
  const bodyScrollRef = useRef<HTMLDivElement>(null);

  const onPageRequest = useCallback(
    (pageNumber: number) => {
      updatePaginationPage(pageNumber);
    },
    [paging.pageNumber]
  );

  const tasks: ReactNode[] = useMemo(
    (): ReactNode[] =>
      results.map((task, index) => (
        <DashboardItem key={task.id} task={task} index={index} />
      )),
    [results]
  );

  useEffect(() => {
    if (!relations.areRelationsLoaded) {
      initialize();
    }
  }, []);

  useLayoutEffect(() => {
    if (paging.pageNumber) {
      bodyScrollRef.current!.scrollTo(0, 0);
    }
  }, [bodyScrollRef.current, paging.pageNumber]);

  const taskDashboardSubHeader = useMemo(() => <DashboardSubheader />, []);
  const taskDashboardFab = useMemo(() => <DashboardFab />, []);

  return (
    <>
      <ConnectedHeader>{t('TASK_MANAGEMENT.DASHBOARD.TITLE')}</ConnectedHeader>
      <AcBody
        showSpinner={isLoading}
        scrollRef={bodyScrollRef}
        subheader={taskDashboardSubHeader}
        fabs={taskDashboardFab}
      >
        <AcPress time={1000}>
          <PaginatedTileList
            noContentTitle={t('TASK_MANAGEMENT.DASHBOARD.NO_CONTENT_TITLE')}
            onPageRequest={onPageRequest}
            paging={paging}
          >
            {tasks}
          </PaginatedTileList>
        </AcPress>
      </AcBody>
      <DashboardFiltersChips />
    </>
  );
};

export default TaskManagementDashboard;
