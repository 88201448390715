import React, { FC, HTMLAttributes, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { Icon, IconSize, TextSize } from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorAttribute } from '@ac/mobile-components/dist/utils';

interface Props extends HTMLAttributes<HTMLDivElement>, Testable {
  small?: boolean;
}

const ReservationsMovementsInfo: FC<Props> = ({
  testSelector,
  small,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <div
      {...rest}
      {...getTestSelectorAttribute(testSelector, 'reservationsMovementsInfo')}
    >
      <AcIcon
        className="reservations-movements-info-icon ac-spacing-right-s"
        icon={Icon.Transfer}
        size={small ? IconSize.Small : IconSize.Standard}
      />
      <AcText size={small ? TextSize.Main3 : TextSize.Main2}>
        {t('GLOBAL.RESERVATIONS_MOVEMENTS')}
      </AcText>
    </div>
  );
};

export default memo(ReservationsMovementsInfo);
