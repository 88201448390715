import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';
import { SuccessTasksRelationsFetch } from '@hkm/components/TaskManagement/relations/domain/interfaces';

import {
  getListAction,
  updateAction
} from '@ac/library-utils/dist/redux-utils';

export const fetchTasksRelations = getListAction<
  undefined,
  SuccessTasksRelationsFetch,
  undefined
>('@taskManagement/dashboard/fetchAggregatedRelations');

export const setTasksSelectedFilterUser = updateAction<
  MappedConfigurationUser | undefined,
  undefined,
  undefined
>('@taskManagement/dashboard/setTasksSelectedFilterUser');
