import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { selectCurrentCustomer } from '@hkm/components/App/domain/selectors';
import { selectSystemUser } from '@hkm/components/Login/domain/selectors';
import {
  selectActiveProperty,
  selectBusinessDate
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import AppSpinner from '@hkm/components/shared/AppSpinner/AppSpinner';
import APP_ROUTES from '@hkm/constants/routing.constants';

export const requireAuth = <P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>
): ComponentType<P> => {
  // eslint-disable-next-line react/display-name
  return (props: P) => {
    const systemUser = useSelector(selectSystemUser);
    const customer = useSelector(selectCurrentCustomer);
    const businessDate = useSelector(selectBusinessDate);
    const activeProperty = useSelector(selectActiveProperty);

    const hasProperty = activeProperty && !!activeProperty.id && !!businessDate;
    const isAuthenticated = systemUser && systemUser.type > 0;

    const returnUrl = window.location.search.includes('?returnUrl')
      ? window.location.search
      : `?returnUrl=${window.location.pathname}${window.location.search}`;

    if (!isAuthenticated) {
      return <Navigate to={`${APP_ROUTES.LOGIN}${returnUrl}`} />;
    }

    if (customer && hasProperty) {
      return <WrappedComponent {...props} />;
    }

    return <AppSpinner />;
  };
};
