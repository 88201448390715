import { fetchDictionaries } from '@hkm/components/App/domain/actions';
import { getConfigurationUserById } from '@hkm/components/TaskManagement/apis/configuration';
import * as dashboardActions from '@hkm/components/TaskManagement/Dashboard/domain/actions';
import { Filters } from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import { getDashboardFilters } from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import {
  MappedConfigurationUser,
  mapToLocalizedDescriptionDisplayName
} from '@hkm/components/TaskManagement/Dashboard/mappers';
import * as actions from '@hkm/components/TaskManagement/relations/domain/actions';
import {
  RequiredAggregatedEntityRelations,
  RequiredAggregatedSystemDictionaryRelations
} from '@hkm/components/TaskManagement/relations/domain/enums';
import { MappedAggregatedRelations } from '@hkm/components/TaskManagement/relations/domain/interfaces';
import { put, select, takeLatest } from 'redux-saga/effects';

import {
  AggregatedResults,
  Attribute,
  getDataForAllPages,
  PageQueryParams,
  PageResponse,
  RawAttribute,
  SupportedUiLanguageDto,
  TaskStatus,
  TaskStatusRole
} from '@ac/library-api';
import { AggregatorConfigurationApi } from '@ac/library-api/dist/api/v0/aggregator';
import { AttributeApi } from '@ac/library-api/dist/api/v0/configuration/genericEntities';
import { LoginService } from '@ac/library-utils/dist/services';

function* fetchRelations() {
  const requiredEntityRelations = Object.keys(
    RequiredAggregatedEntityRelations
  );
  const requiredSystemDictionaryRelations = Object.keys(
    RequiredAggregatedSystemDictionaryRelations
  );
  const requiredRelations = [
    ...requiredEntityRelations,
    ...requiredSystemDictionaryRelations
  ];
  const assigneeId = LoginService.authData()?.userId;
  const assigneeFilter = assigneeId ? [assigneeId] : undefined;

  try {
    const aggregatedRelations: AggregatedResults = yield AggregatorConfigurationApi.getConfigurationAggregatedDataForAllPages(
      {
        types: requiredRelations.join(',')
      }
    );

    const attributes: PageResponse<
      RawAttribute,
      Attribute
    > = yield getDataForAllPages(
      ({ pageNumber, pageSize }: PageQueryParams) =>
        AttributeApi.getList({
          queryParams: {
            includes: 'ref_attributeGroupId',
            pageSize,
            pageNumber
          }
        }) as Promise<PageResponse<RawAttribute, Attribute>>
    );
    const currentUserId = LoginService.authData()?.userId;
    const user: MappedConfigurationUser = yield getConfigurationUserById(
      currentUserId || ''
    ) as Promise<MappedConfigurationUser>;
    const mappedAggregatedRelations: MappedAggregatedRelations = (Object.values(
      RequiredAggregatedEntityRelations
    ).reduce(
      (acc, relationName) => ({
        ...acc,
        [relationName]: mapToLocalizedDescriptionDisplayName(
          aggregatedRelations[relationName]
        )
      }),
      {}
    ) as unknown) as MappedAggregatedRelations;

    const nonCompletedTaskStatuseIds: string[] = mappedAggregatedRelations.taskStatus
      .filter(
        ({ role }: TaskStatus) =>
          role !== TaskStatusRole.Cancellation &&
          role !== TaskStatusRole.Completion
      )
      .map((status: TaskStatus) => status.id);

    const filters: Filters = yield select(getDashboardFilters);
    yield put(
      dashboardActions.updateSelectedFilters({
        ...filters,
        taskStatuses: filters.taskStatuses || nonCompletedTaskStatuseIds,
        assignees: filters.assignees || assigneeFilter
      })
    );
    yield put(
      actions.fetchTasksRelations.success({
        aggregatedRelations: {
          ...mappedAggregatedRelations,
          attribute: mapToLocalizedDescriptionDisplayName(attributes.results)
        } as MappedAggregatedRelations,
        currentUser: user
      })
    );
    //TODO: Fix type in aggregator to avoid as unknown
    yield put(
      fetchDictionaries.success({
        supportedUiLanguages: (aggregatedRelations[
          RequiredAggregatedSystemDictionaryRelations.SupportedUiLanguage
        ] as unknown) as SupportedUiLanguageDto[]
      })
    );
  } catch (e) {
    yield put(actions.fetchTasksRelations.failure());
  }
}

export default function* dashboardSagas() {
  yield takeLatest(actions.fetchTasksRelations.trigger, fetchRelations);
}
