const APP_CONSTANTS = {
  IDENTITY_CLIENT_ID: 'MobileHousekeeping',
  MODULE_CLASS: 'housekeeping-mobile-module',
  DATA_LANGUAGE: 'EN',
  FALLBACK_LANGUAGE: 'EN',
  DEBOUNCE_TIMEOUT: 700,
  MAX_REQUEST_REPEAT: 20,
  API_DATE_FORMAT: 'YYYY-MM-DD',
  DEFAULT_TIMEZONE_DATE_FORMAT: 'YYYY-MM-DD HH:mm',
  DEFAULT_OUTPUT_DATE_FORMAT: 'YYYY-MM-DD[T]HH:mm:ss',
  DEFAULT_UTC_OUTPUT_DATE_FORMAT: 'YYYY-MM-DD[T]HH:mm:ss[Z]',
  NOTE_ROLES: ['GEN', 'HK'],
  MAX_FETCH_SIZE: 200,
  MAX_QUANTITY_SINGLE_CHARGE: 999,
  ACCOUNT_STATUSES: ['RS', 'DI', 'IH', 'DO', 'OP', 'CO'],
  NEW_PROPERTY_ID: 'newPropertyId'
};

export default APP_CONSTANTS;
