import { SheetSummaryGuestPreferences } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryGuestPreferences';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { pickAllBySelector } from '@hkm/utils/pickAllBySelector';
import { reduceToUnique } from '@hkm/utils/reduceToUnique';
import flatten from 'lodash/flatten';

import { IdCodeDescription } from '@ac/library-api';

export const createGuestPreferencesFactory = (
  roomsAssignments: AttendantRoom[]
): SheetSummaryGuestPreferences[] => {
  const allPreferences: IdCodeDescription[] = flatten(
    pickAllBySelector(roomsAssignments, roomAssignment =>
      flatten(
        (roomAssignment.currentReservations || []).map(
          reservation => reservation.preferences
        )
      )
    )
  );

  return reduceToUnique(
    allPreferences,
    (original, result) => original.code === result.code,
    preference => ({
      color: '#00ff00',
      code: preference.code || '',
      description: preference.description || '',
      count: 1
    }),
    item => ({ ...item, count: item.count + 1 })
  );
};
