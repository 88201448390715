import * as actions from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/domain/actions';
import {
  createUploadMaintenanceAttachmentSaga,
  MaintenanceAttachmentSagaApi
} from '@hkm/shared/domain/maintenanceAttachment/uploadAttachement/maintenanceUploadAttachmentSagaFactory';
import { fork } from 'redux-saga/effects';

import {
  HousekeepingRoomsApi,
  MobileAttendantApi
} from '@ac/library-api/dist/api/v0/housekeeping';

const attendantMaintenanceAttachmentApi: MaintenanceAttachmentSagaApi = {
  maintenanceAttachmentApi: MobileAttendantApi
};

const housekeepingMaintenanceAttachmentApi: MaintenanceAttachmentSagaApi = {
  maintenanceAttachmentApi: HousekeepingRoomsApi
};

export default function* maintenanceAttachmentsSagas() {
  yield fork(
    createUploadMaintenanceAttachmentSaga(
      actions.uploadAttendantAttachmentActionsSet,
      attendantMaintenanceAttachmentApi
    )
  );
  yield fork(
    createUploadMaintenanceAttachmentSaga(
      actions.uploadAttachmentActionsSet,
      housekeepingMaintenanceAttachmentApi
    )
  );
}
