import * as actions from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/domain/actions';
import { MaintenanceAttachmentsTileLoadedFile } from '@hkm/shared/domain/maintenanceAttachment/uploadAttachement/models/maintenanceUploadAttachmentData';
import { MaintenanceAttachmentsState } from '@hkm/shared/domain/maintenanceAttachment/uploadAttachement/models/maintenanceUploadAttachmentState';
import { combineActions, ReducerMap } from 'redux-actions';

import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: MaintenanceAttachmentsState = {
  version: 0,
  isPending: false,
  loadedFiles: new Map()
};

const reducerMap: ReducerMap<MaintenanceAttachmentsState, unknown> = {
  [combineActions(
    actions.uploadAttachmentActionsSet.addAttachment.trigger,
    actions.uploadAttachmentActionsSet.removeAttachment.trigger,
    actions.uploadAttachmentActionsSet.getAttachmentFile.trigger,
    actions.uploadAttachmentActionsSet.updateAttachment.trigger,
    actions.uploadAttendantAttachmentActionsSet.addAttachment.trigger,
    actions.uploadAttendantAttachmentActionsSet.removeAttachment.trigger,
    actions.uploadAttendantAttachmentActionsSet.getAttachmentFile.trigger,
    actions.uploadAttendantAttachmentActionsSet.updateAttachment.trigger
  ) as any]: state => ({
    ...state,
    isPending: true
  }),
  [combineActions(
    actions.uploadAttachmentActionsSet.addAttachment.success,
    actions.uploadAttachmentActionsSet.removeAttachment.success,
    actions.uploadAttachmentActionsSet.updateAttachment.success,
    actions.uploadAttendantAttachmentActionsSet.addAttachment.success,
    actions.uploadAttendantAttachmentActionsSet.removeAttachment.success,
    actions.uploadAttendantAttachmentActionsSet.updateAttachment.success
  ) as any]: state => ({
    ...state,
    version: state.version + 1
  }),
  [combineActions(
    actions.uploadAttachmentActionsSet.addAttachment.success,
    actions.uploadAttachmentActionsSet.removeAttachment.success,
    actions.uploadAttachmentActionsSet.getAttachmentFile.success,
    actions.uploadAttachmentActionsSet.updateAttachment.success,
    actions.uploadAttachmentActionsSet.addAttachment.failure,
    actions.uploadAttachmentActionsSet.removeAttachment.failure,
    actions.uploadAttachmentActionsSet.getAttachmentFile.failure,
    actions.uploadAttachmentActionsSet.updateAttachment.failure,
    actions.uploadAttendantAttachmentActionsSet.addAttachment.success,
    actions.uploadAttendantAttachmentActionsSet.removeAttachment.success,
    actions.uploadAttendantAttachmentActionsSet.getAttachmentFile.success,
    actions.uploadAttendantAttachmentActionsSet.updateAttachment.success,
    actions.uploadAttendantAttachmentActionsSet.addAttachment.failure,
    actions.uploadAttendantAttachmentActionsSet.removeAttachment.failure,
    actions.uploadAttendantAttachmentActionsSet.getAttachmentFile.failure,
    actions.uploadAttendantAttachmentActionsSet.updateAttachment.failure
  ) as any]: state => ({ ...state, isPending: false }),

  [combineActions(
    actions.uploadAttachmentActionsSet.getAttachmentFile.success,
    actions.uploadAttendantAttachmentActionsSet.getAttachmentFile.success
  ) as any]: (state, action: Action<MaintenanceAttachmentsTileLoadedFile>) => ({
    ...state,
    loadedFiles: new Map<string, File>(state.loadedFiles!).set(
      action.payload.attachmentId,
      action.payload.file
    )
  })
};

export default handleActions(reducerMap, initialState);
