import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/Menu/domain/actions';
import { selectMenuState } from '@hkm/components/Menu/domain/selectors';

import { AcIconButton } from '@ac/mobile-components/dist/components/icon-button';
import { Icon } from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';

const HeaderMenuButton: FC<Childless> = () => {
  const dispatch = useDispatch();
  const menuState = useSelector(selectMenuState);

  const showMenu = useCallback(() => {
    dispatch(actions.setMenuState(!menuState));
  }, [menuState]);

  return <AcIconButton icon={Icon.Menu} onClick={showMenu} />;
};

export default memo(HeaderMenuButton);
