import { mapIdCodeDescription } from '@hkm/types/reservation/mappers/mapIdCodeDescription';
import { mapReservationMembership } from '@hkm/types/reservation/mappers/mapReservatioGuestMembership';
import { mapReservationDailyOccupancy } from '@hkm/types/reservation/mappers/mapReservationDailyOccupancy';
import {
  mapAttendantReservationGuestDetails,
  mapHousekeepingReservationGuestDetails
} from '@hkm/types/reservation/mappers/mapReservationGuestDetails';
import { UnifiedReservationDailyOccupancy } from '@hkm/types/reservation/models/UnifiedReservationDailyOccupancy';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { UnifiedReservationGuestDetails } from '@hkm/types/reservation/models/UnifiedReservationGuestDetails';
import { UnifiedReservationGuestMembership } from '@hkm/types/reservation/models/UnifiedReservationGuestMembership';

import {
  AttendantAssignmentReservationDetails,
  HousekeepingRoomReservationDetailsDto,
  IdCodeDescription
} from '@ac/library-api';

export function mapAttendantReservationDetails(
  reservation: AttendantAssignmentReservationDetails
): UnifiedReservationDetails {
  return {
    id: reservation.id,
    number: reservation.number,
    status: reservation.status && mapIdCodeDescription(reservation.status),
    arrivalDate: reservation.arrivalDate,
    departureDate: reservation.departureDate,
    eta: reservation.eta,
    etd: reservation.etd,
    guest:
      reservation.guest &&
      mapAttendantReservationGuestDetails(reservation.guest),
    accompanyingGuests: reservation.accompanyingGuests.map(accompany =>
      mapAttendantReservationGuestDetails(accompany)
    ),
    occupancyDetails: reservation.dailyOccupancy
      ? [mapReservationDailyOccupancy(reservation.dailyOccupancy)]
      : [],
    preferences: reservation.preferences.map(preference =>
      mapIdCodeDescription(preference)
    ),
    memberships: reservation.memberships.map(membership =>
      mapReservationMembership(membership)
    ),
    shareId: reservation.shareId,
    isRoomNumberFixed: reservation.isRoomNumberFixed,
    totalDaysOfReservation: reservation.totalDaysOfReservation,
    currentDayOfReservation: reservation.currentDayOfReservation,
    isRoomShared: reservation.isRoomShared,
    isDayUse: reservation.isDayUse,
    isTimeGuaranteed: reservation.isTimeGuaranteed,
    guaranteedTimeOfArrival: reservation.guaranteedTimeOfArrival,
    guaranteedTimeOfDeparture: reservation.guaranteedTimeOfDeparture
  };
}

export function mapHousekeepingReservationDetails(
  reservation: HousekeepingRoomReservationDetailsDto
): UnifiedReservationDetails {
  return {
    id: reservation.id,
    number: reservation.number,
    status: reservation.status,
    arrivalDate: reservation.arrivalDate,
    departureDate: reservation.departureDate,
    eta: reservation.eta,
    etd: reservation.etd,
    guest:
      reservation.guest &&
      mapHousekeepingReservationGuestDetails(reservation.guest),
    accompanyingGuests: (
      reservation.accompanyingGuests || []
    ).map((accompany: UnifiedReservationGuestDetails) =>
      mapHousekeepingReservationGuestDetails(accompany)
    ),
    occupancyDetails: (
      reservation.occupancyDetails || []
    ).map((occupancy: UnifiedReservationDailyOccupancy) =>
      mapReservationDailyOccupancy(occupancy)
    ),
    preferences: (
      reservation.preferences || []
    ).map((preference: IdCodeDescription<string>) =>
      mapIdCodeDescription(preference)
    ),
    memberships: (
      reservation.memberships || []
    ).map((membership: UnifiedReservationGuestMembership) =>
      mapReservationMembership(membership)
    ),
    shareId: reservation.shareId,
    isRoomNumberFixed: reservation.isRoomNumberFixed,
    isRoomShared: reservation.isRoomShared,
    isDayUse: reservation.isDayUse,
    isTimeGuaranteed: reservation.isTimeGuaranteed,
    guaranteedTimeOfArrival: reservation.guaranteedTimeOfArrival,
    guaranteedTimeOfDeparture: reservation.guaranteedTimeOfDeparture
  };
}
