import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPermissionsSet } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { PermissionConjunction } from '@hkm/shared/permissions/enum/PermissionConjunction';
import { hasPermission } from '@hkm/shared/permissions/helper/permissionHelper';
import {
  PermissionArray,
  PermissionData
} from '@hkm/shared/permissions/types/permissionData';

export function usePermission(required: Permission): boolean;
export function usePermission(required: PermissionArray): boolean[];
export function usePermission(
  required: PermissionData,
  conjecture: PermissionConjunction
): boolean;
export function usePermission(
  required: PermissionData,
  conjunction: PermissionConjunction = PermissionConjunction.All
): boolean | boolean[] {
  const set = useSelector(selectPermissionsSet);

  return useMemo(() => hasPermission(set, required, conjunction), [set]);
}
