import React from 'react';
import { useTranslation } from 'react-i18next';
import flagIcons from '@hkm/components/shared/LanguageFlag/LanguageFlagIcons';

import './LanguageFlag.css';

interface Props {
  languageCode?: string;
}

const LanguageFlag: React.FC<Props> = ({ languageCode }) => {
  const { i18n } = useTranslation();
  const activeFlag = flagIcons[languageCode || i18n.language];

  return (
    <div className="language-flag-wrapper">
      <img src={activeFlag} alt={activeFlag} className="language-flag-icon" />
    </div>
  );
};

export default LanguageFlag;
