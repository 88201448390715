/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FinalizeTaskProcess from '@hkm/components/Attendant/RoomDetails/body/FinalizeTaskProcess/FinalizeTaskProcess';
import * as actions from '@hkm/components/Attendant/shared/domain/actions';
import { AttendantRoomUpdateData } from '@hkm/components/Attendant/shared/domain/interfaces/assignmentTask';
import { selectSheetGroupedRooms } from '@hkm/components/Attendant/shared/domain/selectors';
import { AttendantTaskActionType } from '@hkm/components/Attendant/shared/enum/attendantTaskActionType';
import { isTaskProgress } from '@hkm/components/Attendant/shared/utils/isTaskProgress';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { GuestServiceModalFormState } from '@hkm/components/shared/GuestService/modal/form/useFormState';
import GuestServiceModal, {
  GuestServiceModalMode
} from '@hkm/components/shared/GuestService/modal/GuestServiceModal';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';
import { useGuestServices } from '@hkm/shared/hooks/useGuestServices';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';

import {
  AttendantAssignmentTaskDetails,
  AttendantTaskProgressType,
  HousekeepingStatus
} from '@ac/library-api';
import { AcButtonPattern } from '@ac/mobile-components/dist/components/button';
import {
  AcFab,
  AcFabChild,
  AcFabContainer
} from '@ac/mobile-components/dist/components/fab';
import { Icon, Theme } from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

export interface AttendantFabNavigationProps extends Childless {
  sheetId: string;
  room: AttendantRoom;
  activeTask: AttendantAssignmentTaskDetails;
}

const AttendantFabContainer: FC<AttendantFabNavigationProps> = (
  props: AttendantFabNavigationProps
) => {
  if (props.activeTask.progress?.code === AttendantTaskProgressType.Completed) {
    return null;
  }

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [skipCleaningModal, setSkipCleaningModal] = useState<
    GuestServiceModalMode | undefined
  >(undefined);
  const [finalizeTaskWithStatus, setFinalizeTaskWithStatus] = useState<
    HousekeepingStatus
  >();

  const prefix = 'attendant-fab-navigation';
  const isRoomInQueue = props.room.queueRoomEntries.length > 0;
  const groupedRooms = useSelector(selectSheetGroupedRooms)!;
  const effectiveValues = useSelector(selectEffectiveValues)!;
  const { filteredGuestServices } = useGuestServices();

  const bypassTrackingAttendantInRoom =
    effectiveValues.bypassTrackingAttendantInRoom;

  const areRoomsInProgress = groupedRooms.get(
    AttendantTaskProgressType.InProgress
  )!.length;
  const arePausedRooms = groupedRooms.get(AttendantTaskProgressType.Paused)!
    .length;

  const activeRoom =
    (areRoomsInProgress &&
      groupedRooms.get(AttendantTaskProgressType.InProgress)?.[0]) ||
    (arePausedRooms &&
      groupedRooms.get(AttendantTaskProgressType.Paused)?.[0]) ||
    undefined;

  const showStartTaskButton =
    (!activeRoom || isRoomInQueue) &&
    isTaskProgress([AttendantTaskProgressType.Pending], props.activeTask) &&
    !bypassTrackingAttendantInRoom;

  const showResumeTaskButton =
    !areRoomsInProgress &&
    isTaskProgress(
      [AttendantTaskProgressType.Paused, AttendantTaskProgressType.Skipped],
      props.activeTask
    ) &&
    !bypassTrackingAttendantInRoom;
  const showPauseTaskButton = !arePausedRooms && !bypassTrackingAttendantInRoom;
  const showSkipTaskButton =
    (!isTaskProgress([AttendantTaskProgressType.Skipped], props.activeTask) &&
      !isTaskProgress(
        [AttendantTaskProgressType.InProgress],
        props.activeTask
      )) ||
    bypassTrackingAttendantInRoom;

  const enabledGuestServiceStatuses = filteredGuestServices([
    GuestServiceStatusType.ServiceRequested
  ]);

  const guestServiceModalDescription = t(
    !effectiveValues.bypassHKAttendantSummary &&
      activeRoom &&
      activeRoom?.id !== props.room.id
      ? 'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.SKIPPING_CLEANING_MODAL.CONTENT'
      : 'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.SKIPPING_CLEANING_MODAL.CONTENT_SHORT',
    {
      activeRoomNumber: activeRoom?.roomNumber,
      taskProgress: t(
        `GLOBAL.TASK_PROGRESS_TYPE.${activeRoom?.activeTask?.progress?.code}`
      ),
      nextRoomNumber: props.room.roomNumber
    }
  );

  // general task handler
  const getTaskPayload = (
    taskActionType: AttendantTaskActionType,
    room: AttendantRoom,
    activeTask: AttendantAssignmentTaskDetails,
    attendantRoomUpdateData?: AttendantRoomUpdateData
  ) => ({
    roomId: room.id,
    actionType: taskActionType,
    taskId: activeTask.id,
    data: attendantRoomUpdateData,
    roomNumber: room.roomNumber!
  });

  const runTask = useCallback(
    (
      taskActionType: AttendantTaskActionType,
      attendantRoomUpdateData?: AttendantRoomUpdateData
    ) =>
      dispatch(
        actions.runAttendantTask.trigger(
          getTaskPayload(
            taskActionType,
            props.room,
            props.activeTask,
            attendantRoomUpdateData
          )
        )
      ),
    [props.activeTask.id]
  );

  // task handlers
  const startTask = useCallback(() => runTask(AttendantTaskActionType.Start), [
    runTask
  ]);
  const pauseTask = useCallback(() => runTask(AttendantTaskActionType.Pause), [
    runTask
  ]);
  const resumeTask = useCallback(
    () => runTask(AttendantTaskActionType.Resume),
    [runTask]
  );
  const completeTaskVacant = useCallback(
    () => setFinalizeTaskWithStatus(HousekeepingStatus.Vacant),
    []
  );
  const completeTaskOccupied = useCallback(
    () => setFinalizeTaskWithStatus(HousekeepingStatus.Occupied),
    []
  );

  const skipTask = useCallback(
    (guestServiceModalFormState: GuestServiceModalFormState) =>
      runTask(AttendantTaskActionType.Skip, {
        untilTime: guestServiceModalFormState.serviceDeferredUntil,
        newGuestServiceStatus: guestServiceModalFormState.serviceType
      }),
    [runTask]
  );

  const cancelActiveTaskAndStartForThisRoom = useCallback(() => {
    if (!activeRoom) {
      return;
    }
    return dispatch(
      actions.runAttendantTasks.trigger({
        tasks: [
          getTaskPayload(
            AttendantTaskActionType.Cancel,
            activeRoom,
            activeRoom.activeTask!
          ),
          getTaskPayload(
            AttendantTaskActionType.Start,
            props.room,
            props.activeTask
          )
        ],
        successMessage: t(
          'ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.TASKS.SUCCESS_MESSAGE.SKIP_AND_START_ANOTHER',
          { roomNumber: activeRoom?.roomNumber }
        ) as string
      })
    );
  }, [props.room, activeRoom]);

  // finalize task handlers
  const resetProcessHandler = useCallback(
    () => setFinalizeTaskWithStatus(undefined),
    []
  );
  const finalizeProcessHandler = useCallback(
    (data: AttendantRoomUpdateData) => {
      runTask(AttendantTaskActionType.Complete, data);
      setFinalizeTaskWithStatus(undefined);
    },
    []
  );

  // skip task handlers
  const skipCleaningModalClose = useCallback(
    () => setSkipCleaningModal(undefined),
    []
  );
  const skipCleaningModalOpen = useCallback(
    () =>
      !!props.room.currentMainReservation &&
      enabledGuestServiceStatuses.length > 0
        ? setSkipCleaningModal(GuestServiceModalMode.AdHoc)
        : skipTask({}),
    [props.room.currentMainReservation, enabledGuestServiceStatuses]
  );

  const onStartTask = useCallback(() => {
    if (!activeRoom) {
      return startTask();
    }
    const isCurrentRoomInQueue = props.room.queueRoomEntries.length > 0;
    if (isCurrentRoomInQueue) {
      return cancelActiveTaskAndStartForThisRoom();
    }

    return setSkipCleaningModal(GuestServiceModalMode.AdHoc);
  }, []);

  return (
    <>
      <AcFabContainer className={prefix}>
        {showSkipTaskButton && (
          <AcFab
            theme={Theme.Dark}
            icon={Icon.Skip}
            onClick={skipCleaningModalOpen}
            testSelector={formatTestSelector(prefix, 'skip-task')}
            id={`${prefix}-skip-task`}
            pattern={AcButtonPattern.Secondary}
          />
        )}

        {showStartTaskButton && (
          <>
            <AcFab
              icon={Icon.Start}
              id={`${prefix}-start-task`}
              onClick={onStartTask}
              testSelector={formatTestSelector(prefix, 'start-task')}
            />
          </>
        )}

        {(isTaskProgress(
          [AttendantTaskProgressType.InProgress],
          props.activeTask
        ) ||
          bypassTrackingAttendantInRoom) && (
          <>
            {showPauseTaskButton && (
              <AcFab
                icon={Icon.Pause}
                theme={Theme.Dark}
                onClick={pauseTask}
                testSelector={formatTestSelector(prefix, 'pause-task')}
                id={`${prefix}-pause-task`}
                pattern={AcButtonPattern.Secondary}
              />
            )}
            <AcFab
              icon={Icon.Clean}
              expandedIcon={Icon.Cancel}
              id={`${prefix}-complete-task`}
              testSelector={formatTestSelector(prefix, 'complete-task')}
            >
              <AcFabChild
                icon={Icon.CleanVac}
                onClick={completeTaskVacant}
                testSelector={formatTestSelector(prefix, 'clean-vacant')}
                text={t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.CLEAN_VACANT')}
              />
              <AcFabChild
                icon={Icon.CleanOcc}
                onClick={completeTaskOccupied}
                testSelector={formatTestSelector(prefix, 'clean-occupied')}
                text={t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.CLEAN_OCCUPIED')}
              />
            </AcFab>
          </>
        )}

        {showResumeTaskButton && (
          <AcFab
            id={`${prefix}-resume-task`}
            onClick={resumeTask}
            icon={Icon.Resume}
            testSelector={formatTestSelector(prefix, 'resume-task')}
          />
        )}
      </AcFabContainer>

      <GuestServiceModal
        onConfirm={skipTask}
        onClose={skipCleaningModalClose}
        mode={skipCleaningModal}
        room={props.room}
        excludeGuestService={[GuestServiceStatusType.ServiceRequested]}
        description={guestServiceModalDescription}
      />

      <FinalizeTaskProcess
        room={props.room}
        initializedProcess={finalizeTaskWithStatus}
        resetProcessHandler={resetProcessHandler}
        finalizeProcessHandler={finalizeProcessHandler}
      />
    </>
  );
};

export default memo(AttendantFabContainer);
