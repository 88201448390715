import { createReservationChargesActions } from '@hkm/shared/domain/reservationCharges/reservationChargesActionsFactory';

import { TransactionCode2 } from '@ac/library-api';
import {
  createAction,
  getListAction
} from '@ac/library-utils/dist/redux-utils';

export const resetReservationRecentCharges = createAction('posting/view/reset');
export const fetchTransactionCodes = getListAction<
  undefined,
  TransactionCode2[]
>('posting/view/fetchTransactionCodes');
export const attendantReservationChargesActionsSet = createReservationChargesActions(
  'posting/view/attendant'
);
export const housekeepingReservationChargesActionsSet = createReservationChargesActions(
  'posting/view/housekeeping'
);
