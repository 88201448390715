import { MaintenanceUpdateFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/models/maintenanceFormData';

import { RoomMaintenanceStatus, UpdateMaintenances } from '@ac/library-api';

export function mapToUpdateMaintenance(
  maintenanceUpdateFormData: MaintenanceUpdateFormData
): UpdateMaintenances {
  return {
    maintenanceId: maintenanceUpdateFormData.id!,
    fromTime: maintenanceUpdateFormData.fromTime!,
    toTime: maintenanceUpdateFormData.toTime!,
    statusCode: maintenanceUpdateFormData.statusCode as RoomMaintenanceStatus,
    reasonCode: maintenanceUpdateFormData.reasonCode!,
    comment: maintenanceUpdateFormData.comment,
    returnStatusCode: maintenanceUpdateFormData.returnStatusCode!,
    allowOverbooking: false
  };
}
