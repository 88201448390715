import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AcButton,
  AcButtonPattern
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader
} from '@ac/mobile-components/dist/components/modal';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

import './MaintenanceAttachmentDeleteModal.css';

export interface AttachmentEditModalProps extends Testable {
  attachmentName: string;
  onClose: () => void;
  onRemove: () => void;
}
const MaintenanceAttachmentDeleteModal: FC<AttachmentEditModalProps> = props => {
  const { t } = useTranslation();

  function onClose(): void {
    props.onClose();
  }

  function onRemove(): void {
    props.onRemove();
    onClose();
  }

  return (
    <AcModal
      className="maintenance-attachment-delete-modal"
      isOpen={true}
      onClose={onClose}
      testSelector={props.testSelector}
    >
      <AcModalHeader
        showCloseButton={true}
        testSelector={formatTestSelector(props.testSelector, 'header')}
      >
        {t('ATTACHMENTS.REMOVE_TITLE')}
      </AcModalHeader>

      <AcModalBody className="maintenance-attachment-delete-modal-body">
        <AcText
          testSelector={formatTestSelector(
            props.testSelector,
            'warningMessage'
          )}
        >
          {t('ATTACHMENTS.REMOVE_ATTACHMENT_WARNING', {
            fileName: props.attachmentName
          })}
        </AcText>
      </AcModalBody>

      <AcModalFooter>
        <div className="maintenance-attachment-delete-modal-button">
          <AcButton
            testSelector={formatTestSelector(
              props.testSelector,
              'removeButton'
            )}
            onClick={onRemove}
            className="maintenance-attachment-delete-modal-button"
          >
            <AcButtonContent text={t('GLOBAL.REMOVE')} />
          </AcButton>
          <AcButton
            pattern={AcButtonPattern.Tertiary}
            testSelector={formatTestSelector(
              props.testSelector,
              'cancelButton'
            )}
            onClick={onClose}
            className="maintenance-attachment-delete-modal-button"
          >
            <AcButtonContent text={t('GLOBAL.CANCEL')} />
          </AcButton>
        </div>
      </AcModalFooter>
    </AcModal>
  );
};

export default memo(MaintenanceAttachmentDeleteModal);
