import { RoomState } from '@ac/library-api';
import {  MobileAttendantApi } from '@ac/library-api/dist/api/v0/housekeeping';

export async function getAttendantRoomVersionId(roomId: string): Promise<number> {
  const room: RoomState = await MobileAttendantApi.getRoomState({
    pathParams: { roomId }
  }) as RoomState;

  return +room.version;
}
