import React, { FC, HTMLAttributes, memo, ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPropertyDateFormats } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { dayjs } from '@hkm/utils/dayjs-extended';

import {
  AcFormElement,
  AcFormRow
} from '@ac/mobile-components/dist/components/form-element';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  Color,
  JustifyContent,
  TextSize
} from '@ac/mobile-components/dist/enums';
import {
  Styleable,
  Testable
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorProp } from '@ac/mobile-components/dist/utils';

export enum DatetimeVariant {
  onlyTime = 'only-time',
  onlyDate = 'only-date',
  datetime = 'datetime'
}

export interface DatetimeFormElementProps
  extends HTMLAttributes<HTMLDivElement>,
    Testable,
    Styleable {
  smallLabel?: boolean;
  smallText?: boolean;
  datetime: string;
  label?: string | ReactNode;
  timeLabel?: string | ReactNode;
  variant?: DatetimeVariant;
  color?: Color;
  flatAndJustify?: JustifyContent;
}

const defaultProps: Partial<DatetimeFormElementProps> = {
  label: '',
  variant: DatetimeVariant.datetime
};

const DatetimeFormElement: FC<DatetimeFormElementProps> = props => {
  const formats = useSelector(selectPropertyDateFormats);
  const textSize = props.smallText ? TextSize.Main2 : TextSize.Main1;
  const showTime = !(props.variant === DatetimeVariant.onlyDate);
  const showDate = !(props.variant === DatetimeVariant.onlyTime);
  const splitDatetimeLabels = !!props.timeLabel;

  const date = useMemo(
    () => (
      <AcText
        color={props.color || Color.Black}
        size={textSize}
        {...getTestSelectorProp(props.testSelector, 'dateText')}
      >
        {dayjs(props.datetime).format(formats.shortDate)}
      </AcText>
    ),
    [props.datetime, formats.shortDate, textSize, props.testSelector]
  );

  const time = useMemo(
    () => (
      <AcText
        color={props.color || Color.Gray3}
        size={textSize}
        {...getTestSelectorProp(props.testSelector, 'timeText')}
      >
        {dayjs(props.datetime).format(formats.time)}
      </AcText>
    ),
    [props.datetime, formats.time, textSize, props.testSelector]
  );

  const inner = useMemo(
    () => (
      <>
        {showDate && (
          <AcFormElement
            label={props.label!}
            equalLineHeight={true}
            smallLabel={props.smallLabel}
            className="date-time-element"
            flatAndJustify={props.flatAndJustify}
          >
            <>
              {date}&nbsp;
              {!splitDatetimeLabels && showTime && time}
            </>
          </AcFormElement>
        )}

        {splitDatetimeLabels && showTime && (
          <AcFormElement
            equalLineHeight={true}
            className="time-element"
            label={props.timeLabel!}
            smallLabel={props.smallLabel}
            flatAndJustify={props.flatAndJustify}
          >
            {time}
          </AcFormElement>
        )}
      </>
    ),
    [
      props.variant,
      props.label,
      props.timeLabel,
      props.smallLabel,
      props.datetime
    ]
  );

  return (
    <div className={props.className}>
      {splitDatetimeLabels ? <AcFormRow>{inner}</AcFormRow> : inner}
    </div>
  );
};

DatetimeFormElement.defaultProps = defaultProps;

export default memo(DatetimeFormElement);
