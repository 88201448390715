import { MaintenanceFiltersData } from '@hkm/components/Maintenance/shared/models/maintenanceFiltersData';
import { createCombinedApiFilters } from '@hkm/shared/helpers/apiFilter/apiCombinedFiltersFactory';
import { createDayRangeFilter } from '@hkm/shared/helpers/apiFilter/apiDayRangeFiltersFactory';
import {
  ApiFiltersFactoryConfig,
  createApiFilters
} from '@hkm/shared/helpers/apiFilter/apiFiltersFactory';
import { createNumberRangesFilter } from '@hkm/shared/helpers/apiFilter/apiNumberRangesFiltersFactory';

import {
  RawRoomAssignmentMaintenanceDetails,
  RoomMaintenanceState
} from '@ac/library-api';

const apiRoomNumber: string = 'roomNumber.numberNumeric';
const apiRoomCode: string = 'roomNumber.code';

const filterMap = new Map<
  keyof MaintenanceFiltersData,
  ApiFiltersFactoryConfig<RawRoomAssignmentMaintenanceDetails>
>()
  .set('id', {
    field: 'id'
  })
  .set('inoperationStates', {
    field: 'status',
    subField: 'code'
  })
  .set('floors', {
    field: 'roomFloor',
    subField: 'code'
  })
  .set('locations', {
    field: 'roomLocation',
    subField: 'code'
  })
  .set('roomTypes', {
    field: 'roomType',
    subField: 'code'
  })
  .set('returnStates', {
    field: 'returnRoomStatus',
    subField: 'code'
  })
  .set('maintenanceReasons', {
    field: 'reason',
    subField: 'code'
  })
  .set('extraMaintenanceStates', {
    field: 'state',
    subField: 'code'
  })
  .set('roomCategories', {
    field: 'roomCategory',
    subField: 'code'
  });

export function createMaintenanceApiFilter(
  filters: MaintenanceFiltersData
): string | undefined {
  const { roomRanges, validOn, startsOn, endsOn, ...codeFilters } = filters;

  codeFilters.extraMaintenanceStates = (
    codeFilters.extraMaintenanceStates || []
  ).concat(RoomMaintenanceState.Active, RoomMaintenanceState.Pending);

  return createCombinedApiFilters(
    ...createApiFilters<
      MaintenanceFiltersData,
      RawRoomAssignmentMaintenanceDetails
    >(codeFilters, filterMap),
    createNumberRangesFilter(roomRanges, apiRoomNumber, apiRoomCode),
    createDayRangeFilter<RawRoomAssignmentMaintenanceDetails>(
      startsOn,
      'fromTime'
    ),
    createDayRangeFilter<RawRoomAssignmentMaintenanceDetails>(endsOn, 'toTime'),
    createDayRangeFilter<RawRoomAssignmentMaintenanceDetails>(
      validOn,
      'toTime',
      'fromTime'
    )
  );
}
