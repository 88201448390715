import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getGuestCountFromUnifiedReservationDetails } from '@hkm/components/Housekeeping/shared/helpers/getGuestCountFromUnifiedReservationDetails';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import DoNotMoveInfo from '@hkm/components/shared/DoNotMove/DoNotMoveInfo';
import IncognitoBadge from '@hkm/components/shared/IncognitoBadge/IncognitoBadge';
import GuestDisabilitiesTemplate from '@hkm/components/shared/Templates/Guest/Disabilities/GuestDisabilitiesTemplate';
import ConnectGuestAvatarTemplate from '@hkm/components/shared/Templates/Guest/GuestAvatar/ConnectGuestAvatarTemplate';
import GuestNameTemplate from '@hkm/components/shared/Templates/Guest/GuestNameTemplate';
import GuestNumbersTemplate from '@hkm/components/shared/Templates/Guest/GuestNumbersTemplate';
import GuestPreferencesTemplate from '@hkm/components/shared/Templates/Guest/Preferences/GuestPreferencesTemplate';
import VipOrMemberTemplate from '@hkm/components/shared/Templates/Guest/VipOrMember/VipOrMemberTemplate';
import AccompanyingGuestsDetailsTemplate from '@hkm/components/shared/Templates/Reservation/AccompanyingGuests/AccompanyingGuestsDetailsTemplate';
import BookingNotesAccordion from '@hkm/components/shared/Templates/Reservation/BookingNotesAccordion/BookingNotesAccordion';
import PostChargeButton from '@hkm/components/shared/Templates/Reservation/PostChargeButton/PostChargeButton';
import {
  getVipAndMemberBadges,
  getVipBadge,
  hasReservationAccompanyingGuests,
  hasReservationDisabilities,
  hasReservationPreferences
} from '@hkm/components/shared/Templates/Reservation/utils/reservationUtils';
import { extractName } from '@hkm/shared/helpers/extractName';
import {
  NotesReservationMap,
  NotesTypeMap
} from '@hkm/shared/hooks/useGroupedReservationNotes';
import { VipOrMemberBadge } from '@hkm/shared/interfaces/vipOrMemberBadge';
import { doesReservationHaveDoNotMoveFlag } from '@hkm/shared/reservations/hasDoNotMoveFlag';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import classNames from 'classnames';

import { ReservationStatus, TitleOrders } from '@ac/library-api';
import {
  AcAccordion,
  AcAccordionContent,
  AcAccordionHeader,
  AcAccordionItem,
  AcAccordionsElementProps
} from '@ac/mobile-components/dist/components/accordion';
import { AcBox } from '@ac/mobile-components/dist/components/box';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTile } from '@ac/mobile-components/dist/components/tile';
import {
  AlignItems,
  Color,
  FlexDirection,
  Icon,
  TextSize,
  TextWeight
} from '@ac/mobile-components/dist/enums';
import { Styleable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

import './SharedReservationDetailsTemplate.css';

export interface SharedReservationDetailsProps extends Styleable {
  roomId: string;
  showGuestNames?: boolean;
  reservations: UnifiedReservationDetails[];
  groupedReservationNotes?: NotesReservationMap;
}

const defaultProps: Partial<SharedReservationDetailsProps> = {
  showGuestNames: true
};

// tslint:disable:jsx-no-lambda
const SharedReservationDetailsTemplate: FC<SharedReservationDetailsProps> = (
  props: SharedReservationDetailsProps
) => {
  const testSelectorPrefix = `shared`;
  const { t } = useTranslation();
  const classes = classNames(
    'housekeeping-details-shared-guests',
    props.className
  );

  const effectiveValues = useSelector(selectEffectiveValues)!;
  const titleOrder = effectiveValues.titleOrder
    ? effectiveValues.titleOrder
    : TitleOrders.LastNameFirstNameTitle;

  return (
    <>
      {props.reservations.map(
        (sharedReservation: UnifiedReservationDetails, index: number) => {
          const guest = sharedReservation.guest;
          const guestPersonalData = props.showGuestNames
            ? guest!.personalData
            : undefined;

          const hasPreferences: boolean = hasReservationPreferences(
            sharedReservation
          );
          const hasDisabilities: boolean = hasReservationDisabilities(guest);
          const hasAccompanyingGuests: boolean = hasReservationAccompanyingGuests(
            sharedReservation
          );
          const vipOrMemberBadges: VipOrMemberBadge[] = getVipAndMemberBadges(
            sharedReservation
          );
          const vipBadge = getVipBadge(guest!.vipCode);
          const vipBadgeColor = vipBadge ? vipBadge.color : undefined;
          const hasDoNotMoveFlag = doesReservationHaveDoNotMoveFlag(
            sharedReservation
          );

          const notesTypeMap: NotesTypeMap | undefined =
            props.groupedReservationNotes &&
            props.groupedReservationNotes.get(sharedReservation.id);

          const sharedGuestName =
            guest!.personalData && props.showGuestNames
              ? extractName(guest!.personalData.name, titleOrder)
              : t('ROOM_DETAILS.SHARED_GUEST_CUSTOM_NAME', {
                  number: index + 1
                });

          return (
            <React.Fragment key={sharedReservation.id}>
              <AcTile
                icon={Icon.Sharers}
                className={classes}
                style={props.style}
                testSelector={`${testSelectorPrefix}-${index}-details`}
                title={t('ROOM_DETAILS.SHARED_RESERVATION_GUEST')}
              >
                <AcAccordion className="accordion-shared-guests ac-spacing-bottom-md">
                  <AcAccordionItem
                    id={sharedReservation.id}
                    render={(accordionProps: AcAccordionsElementProps) => {
                      return (
                        <>
                          <AcFlex alignItems={AlignItems.center}>
                            {props.showGuestNames &&
                              effectiveValues.displayGuestAvatar && (
                                <ConnectGuestAvatarTemplate
                                  className="ac-spacing-right-sm"
                                  guest={guest!}
                                />
                              )}
                            <AcBox grow={true}>
                              {hasDoNotMoveFlag && (
                                <DoNotMoveInfo
                                  small={true}
                                  className="ac-spacing-bottom-md"
                                />
                              )}
                              <IncognitoBadge
                                className="ac-spacing-bottom-md"
                                testSelector={testSelectorPrefix}
                                details={guest?.personalData?.incognitoDetails}
                              />
                              <AcAccordionHeader
                                {...accordionProps}
                                testSelector={`${testSelectorPrefix}-accordionHeader`}
                              >
                                <AcFormElement
                                  label={t('ROOM_DETAILS.GUEST')}
                                  testSelector={formatTestSelector(
                                    testSelectorPrefix,
                                    'guest'
                                  )}
                                >
                                  <GuestNameTemplate
                                    vipBadgeColor={vipBadgeColor}
                                    guestPersonalData={guestPersonalData}
                                    hasDisabilities={hasDisabilities}
                                    customGuestName={sharedGuestName}
                                  />
                                </AcFormElement>
                              </AcAccordionHeader>
                            </AcBox>
                          </AcFlex>

                          <AcAccordionContent {...accordionProps}>
                            {hasDisabilities && (
                              <AcFlex
                                direction={FlexDirection.column}
                                className="ac-spacing-bottom-md"
                              >
                                <AcText
                                  color={Color.Gray1}
                                  size={TextSize.Main2}
                                  weight={TextWeight.Semibold}
                                >
                                  {t('ROOM_DETAILS.DISABILITIES')}
                                </AcText>
                                <GuestDisabilitiesTemplate
                                  disabilities={guest!.disabilityStatusCodes}
                                  testSelector={`${testSelectorPrefix}-${index}-specialNeeds`}
                                />
                              </AcFlex>
                            )}

                            {!!vipOrMemberBadges.length && (
                              <AcFlex
                                direction={FlexDirection.column}
                                className="ac-spacing-bottom-md"
                              >
                                <AcText
                                  color={Color.Gray1}
                                  size={TextSize.Main2}
                                  weight={TextWeight.Semibold}
                                >
                                  {t('ROOM_DETAILS.VIP_OR_MEMBER')}
                                </AcText>
                                <VipOrMemberTemplate
                                  vipOrMemberBadges={vipOrMemberBadges}
                                  testSelector={`${testSelectorPrefix}-${index}-vip`}
                                />
                              </AcFlex>
                            )}

                            {hasPreferences && (
                              <AcFlex
                                direction={FlexDirection.column}
                                className="ac-spacing-bottom-md"
                              >
                                <AcText
                                  color={Color.Gray1}
                                  size={TextSize.Main2}
                                  weight={TextWeight.Semibold}
                                >
                                  {t('ROOM_DETAILS.PREFERENCES')}
                                </AcText>
                                <GuestPreferencesTemplate
                                  preferences={sharedReservation.preferences}
                                  testSelector={`${testSelectorPrefix}-${index}-preferences`}
                                />
                              </AcFlex>
                            )}

                            <AcFlex
                              direction={FlexDirection.column}
                              className="ac-spacing-bottom-md"
                            >
                              <AcText
                                color={Color.Gray1}
                                size={TextSize.Main2}
                                weight={TextWeight.Semibold}
                                testSelector={`${testSelectorPrefix}-${index}-guestCount`}
                              >
                                {t('ROOM_DETAILS.GUEST_COUNT')}
                              </AcText>
                              <AcText color={Color.Black} size={TextSize.Main1}>
                                <GuestNumbersTemplate
                                  count={getGuestCountFromUnifiedReservationDetails(
                                    sharedReservation
                                  )}
                                />
                              </AcText>
                            </AcFlex>
                          </AcAccordionContent>
                        </>
                      );
                    }}
                  />
                </AcAccordion>

                <AcFlex
                  grow={true}
                  style={{ flexBasis: '100%' }}
                  direction={FlexDirection.column}
                >
                  <AcText
                    color={Color.Gray1}
                    size={TextSize.Main2}
                    weight={TextWeight.Semibold}
                  >
                    {t('GLOBAL.RESERVATION_STATUS.TITLE.LONG')}
                  </AcText>
                  <AcText color={Color.Black} size={TextSize.Main1}>
                    {sharedReservation.status!.description}
                  </AcText>
                </AcFlex>

                {hasAccompanyingGuests && (
                  <AccompanyingGuestsDetailsTemplate
                    showGuestNames={props.showGuestNames}
                    accompanyingGuests={sharedReservation.accompanyingGuests}
                  />
                )}

                <PostChargeButton
                  roomId={props.roomId}
                  reservationId={sharedReservation.id}
                  reservationStatus={
                    sharedReservation.status?.code as ReservationStatus
                  }
                />
              </AcTile>

              {!!notesTypeMap?.size && (
                <AcTile
                  title={t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.SHARE_NOTES', {
                    name: sharedGuestName
                  })}
                  icon={Icon.Notes}
                  className="ac-spacing-top-sm"
                >
                  {Array.from(notesTypeMap.keys()).map(
                    (noteType: string, key: number) => {
                      const groupedBookingNotesType = notesTypeMap.get(
                        noteType
                      )!;

                      return (
                        <BookingNotesAccordion
                          key={key}
                          title={groupedBookingNotesType.description}
                          bookingNotes={groupedBookingNotesType.bookingNotes}
                        />
                      );
                    }
                  )}
                </AcTile>
              )}
            </React.Fragment>
          );
        }
      )}
    </>
  );
};

SharedReservationDetailsTemplate.defaultProps = defaultProps;

export default memo(SharedReservationDetailsTemplate);
