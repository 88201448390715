import { Area } from '@hkm/components/TaskManagement/domain/interfaces/area';

import { TaskObjectType } from '@ac/library-api';

export const AreaRelations: Area[] = [
  {
    objectType: TaskObjectType.Reservation,
    displayName: TaskObjectType.Reservation
  },
  {
    objectType: TaskObjectType.Room,
    displayName: TaskObjectType.Room
  },
  {
    objectType: TaskObjectType.Account,
    displayName: TaskObjectType.Account
  },
  {
    objectType: TaskObjectType.Group,
    displayName: TaskObjectType.Group
  },
  {
    objectType: TaskObjectType.ARAccount,
    displayName: 'AR Account'
  },
  {
    objectType: TaskObjectType.Profile,
    displayName: TaskObjectType.Profile
  }
];
