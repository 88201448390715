import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Link } from 'react-router-dom';
import { initI18n } from '@hkm/i18n';

import { BaseApi, initLoggerWithDefaultMiddlewares } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations/';
import { SessionDataHost } from '@ac/library-utils/dist/services';
import { initializeAppLikeBehaviour } from '@ac/mobile-components/dist/app-like-behaviour';
import { AcMobileProvider } from '@ac/mobile-components/dist/config';
import { AcMobileConfig } from '@ac/mobile-components/dist/config/AcMobileContext';

import AppContainer from './components/App/AppContainer';
import initializeAuthService from './services/authService';
import configureStore from './store/configureStore';
import rootSaga from './store/sagas';
import { trySavePropertyIdFromURL } from './utils/region/trySavePropertyIdFromURL';
import { setupUILanguage } from './utils/setupUILanguage';
import registerServiceWorker from './registerServiceWorker';

import 'pure-swipe';
import '@ac/mobile-components/dist/styles/index.css';
import './index.css';

const acConfigHousekeepingUrl =
  acConfig.frontendUrls.housekeeping &&
  `${acConfig.frontendUrls.housekeeping}/`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
(process.env.REACT_APP_DEVELOPMENT_IP_ADDRESS &&
  `${acConfig.frontendUrls.localhost}/`) ||
  process.env.REACT_APP_PUBLIC_URL ||
  acConfigHousekeepingUrl ||
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  __webpack_public_path__;

SessionDataHost.setupSessionData(() => {
  trySavePropertyIdFromURL();
});

initializeAuthService();
initializeAppLikeBehaviour();

export const store = configureStore();
const rootElement = document.getElementById('root');

const mobileComponentsConfig: AcMobileConfig = {
  rootElement: rootElement!,
  linkComponent: Link
};

store.runSaga(rootSaga);
BaseApi.host = acConfig.apiUrl;

initI18n().then(() => {
  setupUILanguage();
});

initLoggerWithDefaultMiddlewares('housekeepingMobile');

const root = createRoot(rootElement!);

root.render(
  <Provider store={store}>
    <AcMobileProvider config={mobileComponentsConfig}>
      <AppContainer />
    </AcMobileProvider>
  </Provider>
);
registerServiceWorker();
