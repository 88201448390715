import { Filters } from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';
import { TaskArea } from '@hkm/components/TaskManagement/domain/enums/TaskArea';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import moment from 'moment-timezone';

export enum FilterFields {
  Areas = 'areas',
  Assignee = 'assignees',
  Departments = 'departments',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  TimeFrom = 'timeFrom',
  TimeTo = 'timeTo'
}

export const affectedFieldsByQuickFilterMap = {
  [TaskQuickFilter.RESERVATION_TASKS]: [FilterFields.Areas],
  [TaskQuickFilter.ROOM_TASKS]: [FilterFields.Areas],
  [TaskQuickFilter.MY_TASKS]: [FilterFields.Assignee],
  [TaskQuickFilter.MY_DEPARTMENT_TASKS]: [FilterFields.Departments],
  [TaskQuickFilter.DUE_TODAY]: [
    FilterFields.DateFrom,
    FilterFields.DateTo,
    FilterFields.TimeFrom,
    FilterFields.TimeTo
  ]
};

const getValueWithFieldsToSet = (
  fieldsToSet: string[],
  currentUser: MappedConfigurationUser,
  quickFilter?: TaskQuickFilter
) => {
  const currentDate = moment();
  const fieldNameToValueMap = {
    [FilterFields.Areas]: {
      [TaskQuickFilter.RESERVATION_TASKS]: [TaskArea.Reservation],
      [TaskQuickFilter.ROOM_TASKS]: [TaskArea.Room]
    },
    [FilterFields.Assignee]: {
      [TaskQuickFilter.MY_TASKS]: currentUser?.id ? [currentUser.id] : undefined
    },
    [FilterFields.Departments]: {
      [TaskQuickFilter.MY_DEPARTMENT_TASKS]: currentUser?.department?.id
        ? [currentUser?.department?.id]
        : undefined
    },
    [FilterFields.DateFrom]: {
      [TaskQuickFilter.DUE_TODAY]: currentDate.format(
        APP_CONSTANTS.API_DATE_FORMAT
      )
    },
    [FilterFields.DateTo]: {
      [TaskQuickFilter.DUE_TODAY]: currentDate.format(
        APP_CONSTANTS.API_DATE_FORMAT
      )
    },
    [FilterFields.TimeFrom]: {
      [TaskQuickFilter.DUE_TODAY]: currentDate
        .hours(0)
        .minutes(0)
        .format(APP_CONSTANTS.DEFAULT_OUTPUT_DATE_FORMAT)
    },
    [FilterFields.TimeTo]: {
      [TaskQuickFilter.DUE_TODAY]: currentDate
        .hours(23)
        .minutes(59)
        .format(APP_CONSTANTS.DEFAULT_OUTPUT_DATE_FORMAT)
    }
  };

  return fieldsToSet.reduce((acc, next) => {
    acc[next] = fieldNameToValueMap[next][quickFilter];

    return acc;
  }, {});
};

export const getQuickFilter = (
  currentUser: MappedConfigurationUser,
  previousQuickFilter?: TaskQuickFilter,
  quickFilter?: TaskQuickFilter
): Partial<Filters> => {
  const fieldsToSet: string[] = quickFilter
    ? affectedFieldsByQuickFilterMap[quickFilter]
    : [];
  const valueToSet = getValueWithFieldsToSet(
    fieldsToSet,
    currentUser,
    quickFilter
  );
  const fieldsToClear = previousQuickFilter
    ? affectedFieldsByQuickFilterMap[previousQuickFilter]
    : [];
  const valueToClear = fieldsToClear.reduce((acc, next) => {
    acc[next] = undefined;

    return acc;
  }, {});

  return { ...valueToClear, ...valueToSet };
};
