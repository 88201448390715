import { parseNumberRanges } from '@hkm/shared/numberRange/numberRangesParser';
import { NumberRangeType } from '@hkm/shared/numberRange/numberRangeType';

import { buildFIQLFilter, FIQLOperators } from '@ac/library-api';

const numberRangeOperators = new Map<
  NumberRangeType,
  FIQLOperators | [FIQLOperators, FIQLOperators]
>()
  // equal doesn't work on backend with number values, so we use >=x && <=x for equality workaround
  .set(NumberRangeType.Equal, [
    FIQLOperators.greaterThenOrEqual,
    FIQLOperators.lessThenOrEqual
  ])
  .set(NumberRangeType.Lesser, FIQLOperators.lessThen)
  .set(NumberRangeType.Greater, FIQLOperators.greaterThen)
  .set(NumberRangeType.LesserOrEqual, FIQLOperators.lessThenOrEqual)
  .set(NumberRangeType.GreaterOrEqual, FIQLOperators.greaterThenOrEqual)
  .set(NumberRangeType.InRange, [
    FIQLOperators.greaterThenOrEqual,
    FIQLOperators.lessThenOrEqual
  ]);

export function createNumberRangesFilter(
  roomRanges: string | undefined,
  numericProperty: string,
  textProperty: string
): string | undefined {
  const ranges = parseNumberRanges(roomRanges);
  if (!ranges || ranges.length === 0) {
    return undefined;
  }

  const filters = ranges.map(range => {
    if (typeof range === 'string') {
      return buildFIQLFilter(textProperty, FIQLOperators.equal, range);
    }

    const valueA = range.a;
    const valueB = range.b === undefined ? range.a : range.b;
    const operators = numberRangeOperators.get(range.type)!;
    const operatorA = Array.isArray(operators) ? operators[0] : operators;
    const operatorB = Array.isArray(operators) ? operators[1] : null;
    const filterPartA = buildFIQLFilter(numericProperty, operatorA, valueA);
    const filterPartB = operatorB
      ? `${FIQLOperators.and}${buildFIQLFilter(
          numericProperty,
          operatorB,
          valueB
        )}`
      : '';

    return `${filterPartA}${filterPartB}`;
  });

  return `(${filters!.join(FIQLOperators.or)})`;
}
