import { ServiceTypeDayUseIncludedValue, SheetSummaryHousekeepingTasks } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryHouskeepingTasks';
import { EffectiveValues } from '@hkm/shared/effectiveValues/effectiveValues';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';

type SummaryObject = Omit<SheetSummaryHousekeepingTasks, 'serviceTypes'> & { serviceTypes: { [key: string]: ServiceTypeDayUseIncludedValue} };

export const createHousekeepingTasksSummaryFactory = (roomsAssignments: AttendantRoom[], effectiveValues: EffectiveValues): SheetSummaryHousekeepingTasks => {

  const summaryObject = roomsAssignments.reduce((sheetSummaryTasks: SummaryObject, room: AttendantRoom) => {

    sheetSummaryTasks.total += 1;

    if (room.greenService && effectiveValues.greenService) {
      sheetSummaryTasks.greenService.value += 1;
      sheetSummaryTasks.greenService.dayUseValue += Number(hasDayUse(room));
    }

    if (room.serviceType?.code) {
      const serviceType = sheetSummaryTasks.serviceTypes[room.serviceType.code];

      sheetSummaryTasks.serviceTypes[room.serviceType.code] = {
        code: room.serviceType.code,
        description: room.serviceType.description || '',
        value: (serviceType?.value || 0) + 1,
        dayUseValue: (serviceType?.dayUseValue || 0) + Number(hasDayUse(room))
      };
    }

    return sheetSummaryTasks;
  }, {
    total: 0,
    serviceTypes: {},
    greenService: {
      dayUseValue: 0,
      value: 0
    }
  });

  return { ...summaryObject, serviceTypes: Object.values(summaryObject.serviceTypes) };
};

function hasDayUse(room: AttendantRoom): boolean {
  return !!room.currentMainReservation?.isDayUse || !!room.nextMainReservation?.isDayUse;
}
