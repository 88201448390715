import { getRoomsMap } from '@hkm/components/App/domain/selectors';
import { SourceModuleType } from '@hkm/shared/enum/SourceModuleType';
import { all, call, select } from 'redux-saga/effects';

import {
  buildFIQLFilter,
  FIQLOperators,
  HousekeepingRoomDetailsDto,
  LibraryApiResponse,
  PageResponse,
  RawPageResponse,
  RawRoomAssignmentMaintenanceDetails,
  Room,
  RoomAssignmentMaintenanceDetails,
  RoomState
} from '@ac/library-api';
import {
  HousekeepingRoomsApi,
  HousekeepingViewsApi
} from '@ac/library-api/dist/api/v0/housekeeping';
import { repeatableCall } from '@ac/library-utils/dist/utils';

type ItemsVersionMap = Map<string, number>;

export function* isDashboardUpdated(
  roomNumbers: string[],
  dashboardType: SourceModuleType
) {
  const roomsMap: Map<string, Room> = yield select(getRoomsMap);
  const roomIds = roomNumbers.map(n => roomsMap.get(n)!.id);

  // getRoomState always gives the fresh version number
  const roomStates: RoomState[] = yield all(
    roomIds.map(roomId =>
      HousekeepingRoomsApi.getRoomState({
        pathParams: { roomId },
        customConfig: { skipCache: true }
      })
    )
  );

  const dashboardItemsVersions = roomStates.reduce((prev, next) => {
    prev.set(next.id, parseInt(next.version!, 10));

    return prev;
  }, new Map<string, number>());

  // just recall appropriate dashboard api until will get relevant version
  switch (dashboardType) {
    case SourceModuleType.Maintenance:
      return (yield call(
        compareWithMaintenanceDashboard,
        roomNumbers,
        dashboardItemsVersions
      )) as Generator;
    case SourceModuleType.Housekeeping:
      return (yield call(
        compareWithHousekeepingDashboard,
        roomNumbers,
        dashboardItemsVersions
      )) as Generator;
    default:
      return;
  }
}

export function* compareWithMaintenanceDashboard(
  roomNumbers: string[],
  dashboardItemsVersions: ItemsVersionMap
) {
  let dashboardRefreshed = false;

  const checkResult = (
    r: PageResponse<
      RawRoomAssignmentMaintenanceDetails,
      RoomAssignmentMaintenanceDetails
    >
  ) => {
    dashboardRefreshed = r.results.every(
      item =>
        item.housekeepingRoomVersion ===
        dashboardItemsVersions.get(item.room!.id)
    );

    return dashboardRefreshed;
  };

  yield repeatableCall(
    () =>
      HousekeepingViewsApi.getMaintenanceDashboard({
        queryParams: {
          filter: buildFIQLFilter(
            'roomNumber',
            FIQLOperators.equal,
            roomNumbers
          )
        },
        customConfig: { skipCache: true }
      }),
    checkResult
  );

  return dashboardRefreshed;
}

export function* compareWithHousekeepingDashboard(
  roomNumbers: string[],
  dashboardItemsVersions: ItemsVersionMap
) {
  let dashboardRefreshed = false;

  const checkResult = (
    r: LibraryApiResponse<RawPageResponse<HousekeepingRoomDetailsDto>>
  ) => {
    dashboardRefreshed = r.data?.results.every(
      item =>
        item.housekeepingRoomVersion === dashboardItemsVersions.get(item.id)
    );

    return dashboardRefreshed;
  };

  yield repeatableCall(
    () =>
      HousekeepingViewsApi.getHousekeepingDashboard({
        queryParams: {
          filter: buildFIQLFilter(
            'roomNumber',
            FIQLOperators.equal,
            roomNumbers
          )
        },
        customConfig: { skipCache: true }
      }),
    checkResult
  );

  return dashboardRefreshed;
}
