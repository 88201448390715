import * as actions from '@hkm/components/Menu/domain/actions';
import {
  MenuBehaviour,
  MenuStore
} from '@hkm/components/Menu/domain/interfaces';
import { LanguageDefinition } from '@hkm/components/Menu/LanguageSelector/domain/interfaces/languageDefinition';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import { ReducerMap } from 'redux-actions';

import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: MenuStore = {
  isMenuOpen: false,
  behaviour: MenuBehaviour.Enable,
  selectedLanguage: { languageCode: APP_CONSTANTS.FALLBACK_LANGUAGE }
};

const reducerMap: ReducerMap<MenuStore, unknown> = {
  [actions.setMenuState]: (state, action: Action<boolean>) => ({
    ...state,
    isMenuOpen: action.payload
  }),
  [actions.setMenuBehaviour]: (state, action: Action<MenuBehaviour>) => ({
    ...state,
    behaviour: action.payload
  }),
  [actions.languageSelect.trigger]: (
    state,
    action: Action<LanguageDefinition>
  ) => ({
    ...state,
    selectedLanguage: action.payload
  })
};

export default handleActions(reducerMap, initialState);
