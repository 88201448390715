import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectHousekeepingDashboardIsLoading } from '@hkm/components/Housekeeping/Dashboard/domain/selectors';
import { HousekeepingManagePermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingManagePermissionsConfig';
import { HousekeepingStatisticsPermissionsConfig } from '@hkm/components/Housekeeping/Statistics/config/housekeepingStatisticsPermissionsConfig';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';

import {
  AcFab,
  AcFabChild,
  AcFabContainer
} from '@ac/mobile-components/dist/components/fab';
import { Icon } from '@ac/mobile-components/dist/enums';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

const HousekeepingDashboardFabContainer: FC = () => {
  const hasStatisticsAccess = usePermission(
    ...HousekeepingStatisticsPermissionsConfig
  );
  const hasBatchAccess = usePermission(...HousekeepingManagePermissionsConfig);
  const { t } = useTranslation();
  const isLoading = useSelector(selectHousekeepingDashboardIsLoading);
  const testSelector = 'housekeepingDashboardFabContainer-menuFab';

  return (
    <AcFabContainer>
      <AcFab
        icon={Icon.ActionMenu}
        expandedIcon={Icon.Cancel}
        id="menu"
        testSelector={testSelector}
      >
        <AcFabChild
          icon={Icon.Filter}
          text={t('ADVANCED_FILTERS.TITLE')}
          testSelector={formatTestSelector(testSelector, 'advancedFilters')}
          link={APP_ROUTES.HOUSEKEEPING.FILTERS}
          disabled={isLoading}
        />
        {hasBatchAccess && (
          <AcFabChild
            icon={Icon.BatchChange}
            text={t('HOUSEKEEPING_BATCH.STATUS_CHANGE.TITLE')}
            testSelector={formatTestSelector(testSelector, 'batchChange')}
            link={APP_ROUTES.HOUSEKEEPING.BATCH}
            disabled={isLoading}
          />
        )}
        {hasStatisticsAccess && (
          <AcFabChild
            icon={Icon.Projection}
            text={t('HOUSEKEEPING_ROOM_STATISTICS.TITLE')}
            testSelector={formatTestSelector(testSelector, 'statistics')}
            link={APP_ROUTES.HOUSEKEEPING.STATISTICS}
          />
        )}
      </AcFab>
    </AcFabContainer>
  );
};

export default memo(HousekeepingDashboardFabContainer);
