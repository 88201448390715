import { findRegionData } from '@hkm/utils/region/findRegion';
import { getCurrentRegionCode } from '@hkm/utils/region/getCurrentRegionCode';
import { getUrlForNewRegion } from '@hkm/utils/region/newRegionUrl';

import { UnitAssignmentListItem } from '@ac/library-api';
import { getGlobalRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const redirectToProperRegionIfNecessary = (
  property: UnitAssignmentListItem | undefined
) => {
  const region = findRegionData(property?.regionCode ?? getGlobalRegionCode());
  const currentRegionCode = getCurrentRegionCode();

  if (currentRegionCode.toLowerCase() === region?.code.toLowerCase()) {
    return;
  }

  if (
    region &&
    currentRegionCode &&
    region.code.toLowerCase() !== currentRegionCode.toLowerCase() &&
    property?.unitId
  ) {
    window.location.replace(
      getUrlForNewRegion(
        region.code ?? '',
        region.domain ?? '',
        property.unitId
      )
    );
  }
};
