import {
  AttendantAssignmentDataState,
  AttendantSheetState
} from '@hkm/components/Attendant/shared/domain/interfaces/assignmentData';
import { Store } from '@hkm/store/interfaces/store';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { createSelector } from 'reselect';

import { AttendantTaskProgressType } from '@ac/library-api';

export const selectAttendantAssignmentDataState = (
  state: Store
): AttendantAssignmentDataState => state.attendantAssignmentData;

export const selectAssignedSheetState = (state: Store): AttendantSheetState =>
  state.attendantAssignmentData.assignedSheet;

export const selectSheetRooms = createSelector(
  selectAssignedSheetState,
  (attendantSheetState: AttendantSheetState) => {
    return attendantSheetState.data
      ? attendantSheetState.data.roomAssignments
      : [];
  }
);

export const selectSheetGroupedRooms = createSelector(
  selectAssignedSheetState,
  (attendantSheetState: AttendantSheetState) => {
    return attendantSheetState.data
      ? attendantSheetState.data.groupedRoomAssignments
      : new Map<AttendantTaskProgressType, AttendantRoom[]>();
  }
);

export const selectIsLoadingOrEditingSheets = createSelector(
  selectAttendantAssignmentDataState,
  state => {
    return !!(
      !state.assignedSheet.didInitialize ||
      state.assignedSheet.isChanging ||
      state.assignedSheet.isFetching ||
      state.assignedSheet.isWaitingForRefresh ||
      state.assignedSheet.isTaskChanging
    );
  }
);

export const selectAttendantSheetVersion = createSelector(
  selectAssignedSheetState,
  (attendantSheetState: AttendantSheetState) => {
    return attendantSheetState.data ? attendantSheetState.data.version : 0;
  }
);

export const selectAttendantGuestNamesShow = createSelector(
  selectAssignedSheetState,
  (attendantSheetState: AttendantSheetState) => {
    return attendantSheetState.data
      ? attendantSheetState.data.showGuestNames
      : false;
  }
);

export const selectAttendantStaleRoomsIds = createSelector(
  selectAttendantAssignmentDataState,
  state => state.staleRoomsIds
);
export const selectStaleSheetVersionId = createSelector(
  selectAttendantAssignmentDataState,
  state => state.staleSheetVersionId
);

export const selectViewingAttendantRoom = createSelector(
  selectAttendantAssignmentDataState,
  state => state.viewingAttendantRoom
);

export const selectDidInitialize = createSelector(
  selectAssignedSheetState,
  state => state.didInitialize
);

export const selectPendingRoomQueue = createSelector(selectSheetRooms, state =>
  state.filter(room => room.isInPendingRoomQueue)
);

export const selectServiceRequested = createSelector(selectSheetRooms, state =>
  state.filter(room => room.serviceRequested)
);
