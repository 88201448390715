import { HousekeepingFiltersData } from '@hkm/components/Housekeeping/shared/models/housekeepingFiltersData';

export const HousekeepingAdvancedFiltersOrder: Array<keyof HousekeepingFiltersData> = [
  'query',
  'roomRanges',
  'roomCategories',
  'roomTypes',
  'floors',
  'sections',
  'locations',
  'housekeepingStates',
  'frontDeskStates',
  'guestServiceStatuses',
  'roomConditions',
  'inoperationStates',
  'greenService'
];
