import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import APP_ROUTES from '@hkm/constants/routing.constants';

export const useRedirectToAssignment = (roomId: string) => {
  const effectiveValues = useSelector(selectEffectiveValues)!;
  const navigate = useNavigate();

  const callback = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      if (effectiveValues.bypassHKAttendantSummary) {
        navigate(
          generatePath(APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_DETAILS, {
            roomId
          })
        );
      } else {
        navigate(generatePath(APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_LIST));
      }
    },
    [effectiveValues, roomId]
  );

  return callback;
};
