import {
  TasksCompanyProfile,
  TasksIndividualProfile,
  TasksTravelAgentProfile
} from '@hkm/components/TaskManagement/models/profiles';
import { getProfileName } from '@hkm/components/TaskManagement/utils/getProfileName';

import {
  buildFIQLFilter,
  CodeValue,
  CompanyDashboardDetailsDto,
  CompanyDashboardDto,
  FIQLOperators,
  IndividualDashboardDto,
  IndividualDto,
  IndividualPreferenceDto,
  LibraryApiResponse,
  Nullable,
  PageResponseDto,
  ProfileRole,
  ProfilesDashboardAddressDto,
  ProfilesDashboardCommunicationChannelDto,
  TitleOrders,
  TravelAgentDashboardDetailsDto,
  TravelAgentDashboardDto
} from '@ac/library-api';
import {
  CompanyApi,
  IndividualApi,
  TravelAgentsApi
} from '@ac/library-api/dist/api/v0/profiles';

export const getIndividualProfile = async (
  nameSetting: TitleOrders,
  id: string
): Promise<TasksIndividualProfile[]> => {
  const profile = (await IndividualApi.getById({
    pathParams: { profileId: id }
  })) as LibraryApiResponse<IndividualDto>;
  const profiles = (await IndividualApi.getDashboard({
    queryParams: { filter: buildFIQLFilter('id', FIQLOperators.equal, id) }
  })) as LibraryApiResponse<PageResponseDto<IndividualDashboardDto>>;

  return [
    mapIndividualProfile(
      profiles.data.results?.[0],
      nameSetting,
      profile.data.preferences
    )
  ];
};

export const getCompanyProfile = async (
  nameSetting: TitleOrders,
  id: string
): Promise<TasksCompanyProfile[]> => {
  const profiles = (await CompanyApi.getDashboard({
    queryParams: { filter: buildFIQLFilter('id', FIQLOperators.equal, id) }
  })) as LibraryApiResponse<PageResponseDto<CompanyDashboardDto>>;

  return [mapCompanyProfile(profiles.data.results?.[0], nameSetting)];
};

export const getTravelAgentProfile = async (
  nameSetting: TitleOrders,
  id: string
): Promise<TasksTravelAgentProfile[]> => {
  const profiles = (await TravelAgentsApi.getDashboard({
    queryParams: { filter: buildFIQLFilter('id', FIQLOperators.equal, id) }
  })) as LibraryApiResponse<PageResponseDto<TravelAgentDashboardDto>>;

  return [mapTravelAgentProfile(profiles.data.results?.[0], nameSetting)];
};

export const mapIndividualProfile = (
  profile: IndividualDashboardDto,
  nameSetting: TitleOrders,
  preferences?: Nullable<IndividualPreferenceDto[]>
): TasksIndividualProfile => {
  const {
    id,
    details,
    typeCode,
    addresses,
    memberships,
    communicationChannels
  } = profile;

  const normalizedProfile: TasksIndividualProfile = {
    profileRole: ProfileRole.IND,
    id,
    details,
    typeCode,
    addresses,
    memberships,
    communicationChannels,
    preferences: preferences?.map(preference => ({
      code: preference.attributeCode,
      id: preference.id
    })),
    displayName: ''
  };

  return {
    ...normalizedProfile,
    displayName: getDisplayNameForProfile(normalizedProfile, nameSetting)
  };
};

export const mapCompanyProfile = (
  {
    id,
    details,
    typeCode,
    addresses,
    communicationChannels
  }: {
    id: string;
    typeCode: CodeValue;
    details: CompanyDashboardDetailsDto;
    addresses?: Nullable<ProfilesDashboardAddressDto[]>;
    communicationChannels?: Nullable<
      ProfilesDashboardCommunicationChannelDto[]
    >;
  },
  nameSetting: TitleOrders
): TasksCompanyProfile => {
  const normalizedProfile: TasksCompanyProfile = {
    profileRole: ProfileRole.CMP,
    id,
    details,
    typeCode,
    addresses,
    communicationChannels,
    displayName: ''
  };

  return {
    ...normalizedProfile,
    displayName: getDisplayNameForProfile(normalizedProfile, nameSetting)
  };
};

export const mapTravelAgentProfile = (
  {
    id,
    details,
    typeCode,
    addresses,
    communicationChannels
  }: {
    id: string;
    typeCode: CodeValue;
    details: TravelAgentDashboardDetailsDto;
    addresses?: Nullable<ProfilesDashboardAddressDto[]>;
    communicationChannels?: Nullable<
      ProfilesDashboardCommunicationChannelDto[]
    >;
  },
  nameSetting: TitleOrders
): TasksTravelAgentProfile => {
  const normalizedProfile: TasksTravelAgentProfile = {
    profileRole: ProfileRole.TA,
    id,
    details,
    typeCode,
    addresses,
    communicationChannels,
    displayName: ''
  };

  return {
    ...normalizedProfile,
    displayName: getDisplayNameForProfile(normalizedProfile, nameSetting)
  };
};

export function getDisplayNameForProfile(
  profile: any,
  nameSetting: TitleOrders
): string {
  const isIndividual = profile.profileRole === ProfileRole.IND;
  const isCompany = profile.profileRole === ProfileRole.CMP;

  if (isIndividual) {
    return getProfileName({
      firstName: profile.details.firstName,
      middleName: profile.details.middleName,
      lastName: profile.details.lastName,
      title: profile.details.titleCode?.description,
      suffix: profile.details.suffix?.description,
      nameSetting
    });
  } else if (isCompany) {
    return profile.details.fullName;
  }

  return profile.details.fullName;
}
