import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';
import { mapMaintenanceRoomDetails } from '@hkm/types/room/mappers/mapRoomDetails';

import {
  RoomAssignmentMaintenanceDetails,
  RoomMaintenanceState
} from '@ac/library-api';

export function createMaintenanceRoom(
  details: RoomAssignmentMaintenanceDetails
): MaintenanceRoom {
  const state = details.state!.code;
  const hasResolvedState =
    state !== RoomMaintenanceState.Active &&
    state !== RoomMaintenanceState.Pending;
  const unifiedRoomDetails = mapMaintenanceRoomDetails(details);

  return {
    ...details,
    hasResolvedState,
    unifiedRoomDetails
  };
}
