import * as actions from '@hkm/components/Housekeeping/Statistics/domain/actions';
import {
  HousekeepingStatisticsData,
  HousekeepingStatisticsState
} from '@hkm/components/Housekeeping/Statistics/domain/interfaces';
import { ReducerMap } from 'redux-actions';

import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: HousekeepingStatisticsState = {
  isLoading: false,
  hasError: false
};

const reducerMap: ReducerMap<HousekeepingStatisticsState, unknown> = {
  [actions.fetch.trigger]: state => ({ ...state, isLoading: true }),
  [actions.fetch.failure]: state => ({
    ...state,
    isLoading: false,
    hasError: true
  }),
  [actions.fetch.success]: state => ({
    ...state,
    isLoading: false,
    hasError: true
  }),
  [actions.fetch.success]: (
    state,
    action: Action<HousekeepingStatisticsData>
  ) => ({
    ...state,
    isLoading: false,
    hasError: false,
    data: action.payload
  }),
  [actions.flush]: () => ({
    isLoading: false,
    hasError: false,
    data: undefined
  })
};

export default handleActions(reducerMap, initialState);
