import React, {
  FC,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import {
  selectHousekeepingDashboardFilters,
  selectHousekeepingDashboardPagination,
  selectHousekeepingMode,
  selectHousekeepingSelectedRooms
} from '@hkm/components/Housekeeping/Dashboard/domain/selectors';
import HousekeepingDashboardFabContainer from '@hkm/components/Housekeeping/Dashboard/Fab/HousekeepingDashboardFabContainer';
import HousekeepingDashboardFooter from '@hkm/components/Housekeeping/Dashboard/Footer/HousekeepingDashboardFooter';
import HousekeepingDashboardRoom from '@hkm/components/Housekeeping/Dashboard/RoomItem/HousekeepingDashboardRoom';
import HousekeepingDashboardSubHeader from '@hkm/components/Housekeeping/Dashboard/SubHeader/HousekeepingDashboardSubHeader';
import * as detailsActions from '@hkm/components/Housekeeping/Details/domain/actions';
import { HousekeepingManagePermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingManagePermissionsConfig';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import PaginatedTileList from '@hkm/components/shared/PaginatedList/PaginatedTileList';
import { HousekeepingNotificationEnvelopeContent } from '@hkm/services/signalRClient/envelopeContent/HousekeepingNotificationEnvelopeContent';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { DashboardMode } from '@hkm/shared/enum/dashboardMode';
import { useNotification } from '@hkm/shared/hooks/useNotification';
import { useRoomQueueChangedNotification } from '@hkm/shared/hooks/useRoomQueueChangedNotification';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';

import { NotificationEnvelope } from '@ac/library-api/dist/types/entities/signalr/notificationEnvelope';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { AcPress } from '@ac/mobile-components/dist/components/press';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

import './HousekeepingDashboardContainer.css';

const HousekeepingDashboardContainer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dashboardMode = useSelector(selectHousekeepingMode);
  const selectedRooms = useSelector(selectHousekeepingSelectedRooms);
  const filters = useSelector(selectHousekeepingDashboardFilters);

  const pagination = useSelector(selectHousekeepingDashboardPagination);
  const bodyScrollRef = useRef<HTMLDivElement>(null);
  const hasPage: boolean = !!pagination.page;
  const rooms = !hasPage ? [] : pagination.page!.results;
  const hasFilters = !!Object.values(filters).filter(Boolean).length;

  const hasBatchAccess = usePermission(...HousekeepingManagePermissionsConfig);

  const notificationCallback = useCallback(
    (data: NotificationEnvelope<HousekeepingNotificationEnvelopeContent>) => {
      dispatch(actions.patchChangedRoom(data.notification?.roomId));
    },
    []
  );

  useNotification(
    SignalREventType.HousekeepingRoomChanged,
    notificationCallback
  );
  useRoomQueueChangedNotification();

  const onFiltersReset = useCallback(() => {
    dispatch(actions.pagination.setFilters({}));
  }, []);

  useLayoutEffect(() => {
    if (pagination.pageId) {
      // Scroll table to top if we fetched a new page
      bodyScrollRef.current!.scrollTo(0, 0);
    }
  }, [bodyScrollRef.current, pagination.pageId]);

  useEffect(() => {
    dispatch(detailsActions.reset());
    dispatch(actions.pagination.enableAutoRefresh());

    return () => {
      dispatch(actions.setHousekeepingMode(DashboardMode.Default));
      dispatch(actions.pagination.disableAutoRefresh());
    };
  }, []);

  useEffect(() => {
    dispatch(actions.clearHousekeepingSelection());
  }, [pagination.page]);

  const onPageRequest = useCallback(
    (pageNumber: number) => {
      dispatch(actions.pagination.fetchPage(pageNumber));
    },
    [pagination.page]
  );

  const roomItems: ReactNode[] = useMemo(
    () =>
      (pagination.page?.results || []).map(room => {
        const testSelector = formatTestSelector(
          'housekeepingDashboard-room',
          room.roomNumber?.code
        );
        const isSelected = !!selectedRooms.find(
          roomToCheck => roomToCheck.id === room.id
        );

        return (
          <HousekeepingDashboardRoom
            room={room}
            testSelector={testSelector}
            key={room.id}
            selected={isSelected}
          />
        );
      }),
    [pagination.page?.results, selectedRooms]
  );

  const switchToSelectedMode = useCallback(() => {
    if (hasBatchAccess) {
      dispatch(actions.setHousekeepingMode(DashboardMode.Selection));
    }
  }, [hasBatchAccess]);

  const subheader = useMemo(
    () => <HousekeepingDashboardSubHeader rooms={rooms} />,
    [rooms]
  );

  const fabs = useMemo(() => <HousekeepingDashboardFabContainer />, []);

  return (
    <>
      <ConnectedHeader showHelpButton={true}>
        {t('HOUSEKEEPING_DASHBOARD.TITLE')}
      </ConnectedHeader>
      <AcBody
        showSpinner={pagination.isLoading}
        scrollRef={bodyScrollRef}
        className={`housekeeping-dashboard-body dashboard-mode-${dashboardMode}`}
        subheader={subheader}
        fabs={dashboardMode === DashboardMode.Default ? fabs : undefined}
      >
        <AcPress
          time={1000}
          onLongPress={switchToSelectedMode}
          className="housekeeping-dashboard-press"
        >
          <PaginatedTileList
            className="housekeeping-dashboard-list"
            noContentTitle={t('ROOM_LIST.NO_CONTENT_TITLE')}
            isLoading={pagination.isLoading}
            hasFilters={hasFilters}
            onFiltersReset={onFiltersReset}
            onPageRequest={onPageRequest}
            paging={pagination.page?.paging}
          >
            {roomItems}
          </PaginatedTileList>
        </AcPress>
      </AcBody>
      <HousekeepingDashboardFooter rooms={rooms} />
    </>
  );
};

export default memo(HousekeepingDashboardContainer);
