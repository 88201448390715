import { HousekeepingFiltersData } from '@hkm/components/Housekeeping/shared/models/housekeepingFiltersData';
import { createCombinedApiFilters } from '@hkm/shared/helpers/apiFilter/apiCombinedFiltersFactory';
import {
  ApiFiltersFactoryConfig,
  createApiFilters
} from '@hkm/shared/helpers/apiFilter/apiFiltersFactory';
import { createNumberRangesFilter } from '@hkm/shared/helpers/apiFilter/apiNumberRangesFiltersFactory';

import {
  buildFIQLFilter,
  combineFilters,
  FIQLOperators,
  HousekeepingRoomDetailsDto
} from '@ac/library-api';

const apiRoomNumber: string = 'roomNumber.numberNumeric';
const apiRoomCode: string = 'roomNumber.code';

const filterMap = new Map<
  keyof HousekeepingFiltersData,
  ApiFiltersFactoryConfig<HousekeepingRoomDetailsDto>
>()
  .set('inoperationStates', {
    field: 'maintenanceStatus',
    subField: 'code'
  })
  .set('floors', {
    field: 'floor',
    subField: 'code'
  })
  .set('roomStates', {
    field: 'roomStatus',
    subField: 'code'
  })
  .set('frontDeskStates', {
    field: 'frontdeskStatus',
    subField: 'code'
  })
  .set('sections', {
    field: 'housekeepingSections',
    subField: 'code',
    customOperator: FIQLOperators.contains
  })
  .set('housekeepingStates', {
    field: 'housekeepingStatus',
    subField: 'code'
  })
  .set('locations', {
    field: 'roomLocation',
    subField: 'code'
  })
  .set('roomTypes', {
    field: 'roomType',
    subField: 'code'
  })
  .set('roomCategories', {
    field: 'roomCategory',
    subField: 'code'
  })
  .set('roomConditions', {
    field: 'roomConditions',
    subField: 'code'
  })
  .set('greenService', {
    field: 'greenService'
  });

export function createHousekeepingApiFilter(
  filters: HousekeepingFiltersData
): string | undefined {
  const { roomRanges, guestServiceStatuses, ...codeFilters } = filters;

  const guestServiceFilters = (guestServiceStatuses || []).map(value =>
    buildFIQLFilter(value, FIQLOperators.equal, true)
  );

  return createCombinedApiFilters(
    ...createApiFilters<HousekeepingFiltersData, HousekeepingRoomDetailsDto>(
      codeFilters,
      filterMap
    ),
    createNumberRangesFilter(roomRanges, apiRoomNumber, apiRoomCode),
    combineFilters(guestServiceFilters, FIQLOperators.or)
  );
}
