import {
  MultiselectFilters,
  MultiselectNameFields,
  MultiselectValueFields
} from '@hkm/components/TaskManagement/Dashboard/domain/enums';
import { Filters } from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import { Area } from '@hkm/components/TaskManagement/domain/interfaces/area';
import { TasksRelationsState } from '@hkm/components/TaskManagement/relations/domain/interfaces';
import i18n from 'i18next';

import { Department, TaskPriority, TaskStatus } from '@ac/library-api';
import { AcSelectValue } from '@ac/mobile-components/dist/components/select';

interface MultiselectOption {
  name: MultiselectFilters;
  optionValueField: MultiselectValueFields;
  optionNameField: MultiselectNameFields;
  label: string;
  relatedQuickFilters?: TaskQuickFilter[];
}
export interface MultiselectFiltersConfigItem {
  name: MultiselectFilters;
  value: string[];
  label: string;
  options: AcSelectValue[];
  optionValueField: string;
  optionNameField: string;
  relatedQuickFilters?: TaskQuickFilter[];
  isSearchField?: boolean;
  updateValue: (value: string[]) => void;
}

export type MultiselectFiltersConfig = MultiselectFiltersConfigItem[];

export type DashboardRelationField =
  | MappedConfigurationUser
  | Department
  | TaskPriority
  | TaskStatus
  | Area;

export type MultiselectOptionRelations = DashboardRelationField[];

export interface MultiselectFiltersOptions {
  [key: string]: {
    name: MultiselectFilters;
    optionValueField: MultiselectValueFields;
    optionNameField: MultiselectNameFields;
    label: string;
    isSearchField?: boolean;
    relatedQuickFilters?: TaskQuickFilter[];
  };
}

export const getMultiselectFiltersOptions = (): MultiselectFiltersOptions => {
  return {
    [MultiselectFilters.Status]: {
      name: MultiselectFilters.Status,
      optionValueField: MultiselectValueFields.Id,
      optionNameField: MultiselectNameFields.DisplayName,
      label: i18n.t('TASK_MANAGEMENT.DASHBOARD.FILTERS.STATUS')
    },
    [MultiselectFilters.Priority]: {
      name: MultiselectFilters.Priority,
      optionValueField: MultiselectValueFields.Id,
      optionNameField: MultiselectNameFields.DisplayName,
      label: i18n.t('TASK_MANAGEMENT.DASHBOARD.FILTERS.PRIORITY')
    },
    [MultiselectFilters.Department]: {
      name: MultiselectFilters.Department,
      optionValueField: MultiselectValueFields.Id,
      optionNameField: MultiselectNameFields.DisplayName,
      label: i18n.t('TASK_MANAGEMENT.DASHBOARD.FILTERS.DEPARTMENT'),
      relatedQuickFilters: [TaskQuickFilter.MY_DEPARTMENT_TASKS]
    },
    [MultiselectFilters.Area]: {
      name: MultiselectFilters.Area,
      optionValueField: MultiselectValueFields.ObjectType,
      optionNameField: MultiselectNameFields.DisplayName,
      label: i18n.t('TASK_MANAGEMENT.DASHBOARD.FILTERS.AREA'),
      relatedQuickFilters: [
        TaskQuickFilter.ROOM_TASKS,
        TaskQuickFilter.RESERVATION_TASKS
      ]
    },
    [MultiselectFilters.Assignee]: {
      isSearchField: true,
      name: MultiselectFilters.Assignee,
      optionValueField: MultiselectValueFields.Id,
      optionNameField: MultiselectNameFields.FullName,
      label: i18n.t('TASK_MANAGEMENT.DASHBOARD.FILTERS.ASSIGNEE'),
      relatedQuickFilters: [TaskQuickFilter.MY_TASKS]
    }
  };
};

export const getMultiselectFiltersConfig = (
  updateFiltersState: (valueToSet: Partial<Filters>) => void,
  selectedFilters: Filters,
  relations?: TasksRelationsState
): MultiselectFiltersConfig => {
  const config = getMultiselectFiltersOptions();
  return Object.keys(config).map(fieldKey => {
    const multiselectField = config[fieldKey];

    return {
      name: multiselectField.name,
      value: selectedFilters[fieldKey],
      label: multiselectField.label,
      updateValue: (value: string[]) =>
        updateFiltersState({ [fieldKey]: value.length ? value : undefined }),
      options: getFieldOptions(multiselectField, relations),
      optionValueField: multiselectField.optionValueField,
      optionNameField: multiselectField.optionNameField,
      relatedQuickFilters: multiselectField.relatedQuickFilters,
      isSearchField: multiselectField.isSearchField
    };
  });
};

const getFieldOptions = (
  field: MultiselectOption,
  relations?: TasksRelationsState
): AcSelectValue[] | [] => {
  const fieldInRelations = relations?.[
    field.name
  ] as MultiselectOptionRelations;

  return fieldInRelations?.length
    ? fieldInRelations!.map(fieldOption => ({
        value: fieldOption[field.optionValueField],
        itemLabel: fieldOption[field.optionNameField]
      }))
    : [];
};
