import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HighlightText from '@hkm/components/shared/Utilities/HightlightText/HighlightText';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import { debounce } from 'lodash';

import {
  AcButton,
  AcButtonPattern
} from '@ac/mobile-components/dist/components/button';
import { AcDivider } from '@ac/mobile-components/dist/components/divider';
import { AcFieldText } from '@ac/mobile-components/dist/components/field';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcRadioButton } from '@ac/mobile-components/dist/components/radio-button';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FlexDirection,
  Icon,
  TextSize
} from '@ac/mobile-components/dist/enums';

import './SearchField.css';

interface Option {
  id: string;
  text: string;
}

interface ComponentProps {
  optionsArray: Option[];
  label: string;
  value?: Option;
  className?: string;
  disabled?: boolean;
  onSearch: (value?: string) => void;
  onOptionSelect: (value?: string) => void;
  onClearSelection: () => void;
}

const SearchField = (props: ComponentProps) => {
  const { t } = useTranslation();
  const {
    label,
    className,
    value,
    disabled,
    onClearSelection,
    onSearch,
    optionsArray,
    onOptionSelect
  } = props;
  const [searchValue, setSearchValue] = useState<string | undefined>(
    value?.text
  );

  const callSearch = useCallback(
    debounce((value?: string) => {
      onSearch(value);
    }, APP_CONSTANTS.DEBOUNCE_TIMEOUT),
    []
  );

  const onSearchChange = useCallback(
    (value?: string) => {
      setSearchValue(value);
      value?.length && value?.length > 1 && callSearch(value);
      if (!value) {
        onOptionSelect(undefined);
        callSearch(undefined);
      }
    },
    [callSearch, onOptionSelect]
  );

  const clearSelectedValue = useCallback(() => {
    onClearSelection();
    setSearchValue(undefined);
    onOptionSelect(undefined);
  }, [onClearSelection, onOptionSelect]);

  const searchInfoText = useMemo(() => {
    if (!searchValue || searchValue.length < 2) {
      return t('COMPONENTS.SEARCH_FIELD.ENTER_CHARACTERS');
    }
    if (searchValue && !optionsArray.length) {
      return t('GLOBAL.NO_RESULTS_FOUND');
    }
    return '';
  }, [optionsArray.length, searchValue, t]);

  return (
    <AcFlex
      className={`full-width search-field position-relative ${className || ''}`}
      direction={FlexDirection.column}
      grow
    >
      <AcFieldText
        value={searchValue}
        className={value ? 'selected-option' : ''}
        label={label}
        disabled={disabled}
        icon={Icon.Search}
        onChange={onSearchChange}
      />
      {searchInfoText && (
        <AcText size={TextSize.Main3}>{searchInfoText}</AcText>
      )}
      {value && (
        <AcButton
          className="clear-button"
          pattern={AcButtonPattern.Tertiary}
          disabled={disabled}
          onClick={clearSelectedValue}
        >
          <AcIcon icon={Icon.Cancel} />
        </AcButton>
      )}

      {((searchValue && searchValue.length > 1) || value?.id) &&
        optionsArray.map(option => {
          const setSelectedValue = () => {
            onOptionSelect(option.id);
          };

          return (
            <AcFlex
              key={option.id}
              className="full-width"
              direction={FlexDirection.column}
              onClick={setSelectedValue}
            >
              <AcFlex alignItems={AlignItems.center}>
                <AcDivider />
                <AcRadioButton
                  disabled={disabled}
                  checked={option.id === value?.id}
                />
                <HighlightText
                  text={option.text}
                  searchValue={searchValue || option.text}
                />
              </AcFlex>
            </AcFlex>
          );
        })}
    </AcFlex>
  );
};

export default SearchField;
