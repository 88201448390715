import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';

export enum UsersSelectorActionType {
  LoadUsers = 'LoadUsers'
}

interface LoadUsersPayload {
  usersList: MappedConfigurationUser[];
}

type UserSelectorLoadUsersAction = {
  type: UsersSelectorActionType.LoadUsers;
  payload: LoadUsersPayload;
};

export const usersSelectorLoadUsers = (
  payload: LoadUsersPayload
): UserSelectorLoadUsersAction => ({
  type: UsersSelectorActionType.LoadUsers,
  payload
});

export type UsersSelectorAction = UserSelectorLoadUsersAction;
