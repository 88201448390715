import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  TasksCompanyProfile,
  TasksIndividualProfile,
  TasksTravelAgentProfile
} from '@hkm/components/TaskManagement/models/profiles';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import { getProfileCommunicationChannel } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Profile/helpers';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Accordion/Accordion';

import { CommunicationMode, ProfileRole } from '@ac/library-api';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';

interface ComponentProps {
  additonalInfo?: React.ReactNode;
}

const CommunicationChannels: FC<ComponentProps> = (props: ComponentProps) => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const profile = linkedObject!.object as
    | TasksIndividualProfile
    | TasksCompanyProfile
    | TasksTravelAgentProfile;

  const profileNumber = useMemo(
    () =>
      getProfileCommunicationChannel(
        CommunicationMode.Phone,
        profile.communicationChannels
      ),
    [profile.communicationChannels]
  );

  const profileEmail = useMemo(
    () =>
      getProfileCommunicationChannel(
        CommunicationMode.Email,
        profile.communicationChannels
      ),
    [profile.communicationChannels]
  );

  const profileWebsite = useMemo(
    () =>
      profile.profileRole !== ProfileRole.IND
        ? getProfileCommunicationChannel(
            CommunicationMode.Website,
            profile.communicationChannels,
            false
          )
        : undefined,
    [profile.communicationChannels, profile.profileRole]
  );

  return profile.profileRole !== ProfileRole.IND ? (
    <Accordion
      header={
        <AcFormElement
          className="ac-spacing-top-sm ac-spacing-bottom-sm"
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.PHONE')}
          testSelector="task-profile-number"
        >
          {profileNumber ? profileNumber : '-'}
        </AcFormElement>
      }
      id={profile.id}
    >
      <AcFormElement
        className="ac-spacing-bottom-sm"
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.EMAIL')}
        testSelector="task-profile-email"
      >
        {profileEmail ? profileEmail : '-'}
      </AcFormElement>
      <AcFormElement
        className="ac-spacing-bottom-sm"
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.WEBSITE')}
        testSelector="task-profile-website"
      >
        {profileWebsite ? profileWebsite : '-'}
      </AcFormElement>
      {props.additonalInfo}
    </Accordion>
  ) : (
    <>
      <AcFormElement
        className="ac-spacing-bottom-sm"
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.PHONE')}
        testSelector="task-profile-number"
      >
        {profileNumber ?? '-'}
      </AcFormElement>

      <AcFormElement
        className="ac-spacing-bottom-sm"
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.EMAIL')}
        testSelector="task-profile-email"
      >
        {profileEmail ?? '-'}
      </AcFormElement>
      {props.additonalInfo}
    </>
  );
};

export default CommunicationChannels;
