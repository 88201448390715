import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectedFooter from '@hkm/components/shared/LayoutComponents/ConnectedFooter';
import { MaintenanceReleaseFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceReleaseForm/models/maintenanceReleaseData';

import {
  AcButton,
  AcButtonPattern
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFooterWideButtons } from '@ac/mobile-components/dist/components/layout';
import { Icon } from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { FormRenderProps } from '@ac/react-infrastructure';

export interface MaintenanceReleaseFooterProps extends Childless {
  formProps: FormRenderProps<MaintenanceReleaseFormData>;
  onCancel(): void;
}

const MaintenanceReleaseFooter: FC<MaintenanceReleaseFooterProps> = (
  props: MaintenanceReleaseFooterProps
) => {
  const prefix = 'maintenance-release-footer';
  const values = props.formProps.values;
  const { t } = useTranslation();

  return (
    <ConnectedFooter className={prefix} noPadding={true}>
      <AcFooterWideButtons>
        <AcButton
          onClick={props.formProps.handleSubmit}
          disabled={props.formProps.invalid}
          testSelector={`${prefix}-confirm-button`}
        >
          <AcButtonContent
            text={t(`MAINTENANCE_RELEASE.${values.state}.CONFIRM`)}
          />
        </AcButton>

        <AcButton
          onClick={props.onCancel}
          pattern={AcButtonPattern.Tertiary}
          testSelector={`${prefix}-cancel-button`}
        >
          <AcButtonContent
            icon={Icon.Cancel}
            text={t(`MAINTENANCE_RELEASE.${values.state}.CANCEL`)}
          />
        </AcButton>
      </AcFooterWideButtons>
    </ConnectedFooter>
  );
};

export default memo(MaintenanceReleaseFooter);
