import { MaintenanceCreateState } from '@hkm/shared/domain/maintenanceCreate/models/maintenanceCreateState';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { combineActions, ReducerMap } from 'redux-actions';

import { ConflictDetails } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

import * as actions from './actions';

const initialState: MaintenanceCreateState = {
  reservations: [],
  isPending: false,
  version: 0
};

const reducerMap: ReducerMap<MaintenanceCreateState, unknown> = {
  [combineActions(
    actions.setMaintenanceActionsSet.validateMaintenance.trigger,
    actions.setAttendantMaintenanceActionsSet.validateMaintenance.trigger
  ) as any]: state => ({
    ...state,
    isLoadingConflicts: true,
    validationConflicts: undefined
  }),
  [combineActions(
    actions.setMaintenanceActionsSet.validateMaintenance.failure,
    actions.setAttendantMaintenanceActionsSet.validateMaintenance.failure,
    actions.clearConflicts
  ) as any]: state => ({
    ...state,
    isLoadingConflicts: false,
    validationConflicts: undefined
  }),
  [combineActions(
    actions.setMaintenanceActionsSet.validateMaintenance.success,
    actions.setAttendantMaintenanceActionsSet.validateMaintenance.success
  ) as any]: (state, action: Action<ConflictDetails[]>) => ({
    ...state,
    isLoadingConflicts: false,
    validationConflicts: action.payload
  }),
  [combineActions(
    actions.setMaintenanceActionsSet.createMaintenance.trigger,
    actions.setAttendantMaintenanceActionsSet.createMaintenance.trigger
  ) as any]: state => ({
    ...state,
    isPending: true
  }),
  [combineActions(
    actions.setMaintenanceActionsSet.createMaintenance.success,
    actions.setAttendantMaintenanceActionsSet.createMaintenance.success
  ) as any]: state => ({
    ...state,
    version: state.version + 1
  }),
  [combineActions(
    actions.setMaintenanceActionsSet.fetchReservations.success,
    actions.setAttendantMaintenanceActionsSet.fetchReservations.success
  ) as any]: (state, action: Action<UnifiedReservationDetails[]>) => ({
    ...state,
    reservations: action.payload
  }),
  [combineActions(
    actions.setMaintenanceActionsSet.createMaintenance.failure,
    actions.setMaintenanceActionsSet.createMaintenance.success,
    actions.setAttendantMaintenanceActionsSet.createMaintenance.failure,
    actions.setAttendantMaintenanceActionsSet.createMaintenance.success
  ) as any]: state => ({ ...state, isPending: false })
};

export default handleActions(reducerMap, initialState);
