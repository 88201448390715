import React, { FC } from 'react';

import './AreaGrid.css';

interface ComponentProps {
  children: React.ReactNode;
}

const AreaGrid: FC<ComponentProps> = (props: ComponentProps) => {
  return (
    <div className="area-grid">
      {props.children}
    </div>
  );
};

export default AreaGrid;
