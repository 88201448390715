import React from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from '@hkm/shared/helpers/noop';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  Color,
  Icon,
  IconSize,
  JustifyContent,
  TextSize
} from '@ac/mobile-components/dist/enums';

import './AlertBarContent.sass';

interface Props {
  queueRoomsCount: number;
  showLink?: boolean;
  onShow?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const QueueRoomsAlertBar: React.FC<Props> = ({
  queueRoomsCount,
  showLink,
  onShow = noop
}) => {
  const { t } = useTranslation();

  return (
    <AcFlex
      justifyContent={JustifyContent.spaceBetween}
      className="alert-bar-content"
    >
      <AcText size={TextSize.Main2}>
        <AcIcon
          className="ac-spacing-right-md"
          icon={Icon.Queue}
          color={Color.Warning}
          size={IconSize.Medium}
        />
        {t('ATTENDANT_ASSIGNMENTS.SHARED.ALERT_BAR.QUEUE_ROOMS', {
          count: queueRoomsCount
        })}
      </AcText>
      {showLink && (
        <a href="#" onClick={onShow} className="link">
          <AcText size={TextSize.Main2}>{t('GLOBAL.SHOW')}</AcText>
        </a>
      )}
    </AcFlex>
  );
};
