import React, { FC, memo } from 'react';
import DayUseBadge from '@hkm/components/shared/DayUseBadge/DayUseBadge';
import EtaEtdGuaranteedBadge from '@hkm/components/shared/EtaEtdGuaranteedBadge/EtaEtdGuaranteedBadge';
import { getReservationBadgesCount } from '@hkm/components/shared/ReservationBadges/utils';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import classNames from 'classnames';

import {
  Childless,
  Styleable,
  Testable
} from '@ac/mobile-components/dist/interfaces/componentProps';

interface ReservationBadges extends Styleable, Childless, Testable, Styleable {
  reservation: UnifiedReservationDetails;
}

const ReservationBadges: FC<ReservationBadges> = (props: ReservationBadges) => {
  const badgesCount = getReservationBadgesCount(props.reservation);
  const multipleBadges = badgesCount > 1;

  return (
    <>
      {props.reservation.isDayUse && (
        <DayUseBadge className="ac-spacing-bottom-xs" />
      )}
      {props.reservation.isTimeGuaranteed && (
        <EtaEtdGuaranteedBadge
          className={classNames(
            'ac-spacing-bottom-xs',
            multipleBadges && 'ac-spacing-left-xs'
          )}
        />
      )}
    </>
  );
};

export default memo(ReservationBadges);
