import { SortOptionsCode } from '@hkm/components/TaskManagement/TaskPreview/Comments/domain/enums';
import i18n from 'i18next';

interface SortOption {
  value: SortOptionsCode;
  itemLabel: string;
}

export const SortOptions: SortOption[] = [
  {
    value: SortOptionsCode.newest,
    itemLabel: i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.COMMENTS.NEWEST_FIRST')
  },
  {
    value: SortOptionsCode.oldest,
    itemLabel: i18n.t('TASK_MANAGEMENT.TASK_PREVIEW.COMMENTS.OLDEST_FIRST')
  }
];
