import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { RoomMaintenanceStatus } from '@ac/library-api';
import {
  AcBadge,
  BadgeSize
} from '@ac/mobile-components/dist/components/badge';
import { Color, TextSize } from '@ac/mobile-components/dist/enums';
import {
  Childless,
  Styleable,
  Testable
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorProp } from '@ac/mobile-components/dist/utils';

interface MaintenanceStatusBadgeProps extends Styleable, Childless, Testable {
  status: RoomMaintenanceStatus;
  showLabel?: boolean;
  fullTextInTile?: boolean;
  badgeSize?: BadgeSize;
  badgeTextSize?: TextSize;
}

const colorMap = new Map<RoomMaintenanceStatus, Color>()
  .set(RoomMaintenanceStatus.OutOfOrder, Color.Black)
  .set(RoomMaintenanceStatus.OutOfService, Color.OutOfService);

const MaintenanceStatusBadge: FC<MaintenanceStatusBadgeProps> = props => {
  const { t } = useTranslation();
  const color: Color = colorMap.get(props.status) || Color.Gray5;
  const labelText = props.showLabel
    ? t(`GLOBAL.MAINTENANCE_STATUS.LONG.${props.status}`)
    : undefined;
  const badgeText = t(
    `GLOBAL.MAINTENANCE_STATUS.${props.fullTextInTile ? 'LONG' : 'SHORT'}.${
      props.status
    }`
  );

  const className = classNames('maintenance-status-badge', props.className);

  return (
    <AcBadge
      {...getTestSelectorProp(props.testSelector, 'maintenanceStatusBadge')}
      style={props.style}
      className={className}
      badgeText={badgeText}
      label={labelText}
      backgroundColor={color}
      size={props.badgeSize ?? BadgeSize.md}
      badgeTextSize={props.badgeTextSize ?? TextSize.Main2}
    />
  );
};

export default MaintenanceStatusBadge;
