import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoomStatesMap } from '@hkm/components/App/domain/selectors';
import { HousekeepingStatisticsData } from '@hkm/components/Housekeeping/Statistics/domain/interfaces';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import StatisticsSection from '@hkm/components/shared/StatisticsSection/StatisticsSection';
import {
  StatisticSectionDataItem,
  StatisticsSectionData
} from '@hkm/components/shared/StatisticsSection/StatisticsSectionData';
import {
  DictionaryLabelLength,
  extractDictionaryEntryLabel
} from '@hkm/shared/dictionaries/dictionaryItemsLabelExtractor';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';

import {
  DiscrepancyType,
  HousekeepingRoomSummaryDto,
  RoomStatus
} from '@ac/library-api';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';

interface HousekeepingStatisticsBodyProps extends Childless {
  data: HousekeepingStatisticsData;
}

const HousekeepingStatisticsBody: FC<HousekeepingStatisticsBodyProps> = (
  props: HousekeepingStatisticsBodyProps
) => {
  const { t } = useTranslation();
  const roomStates = useSelector(getRoomStatesMap);
  const effectiveValues = useSelector(selectEffectiveValues)!;

  const roomSummary = (props.data.roomSummary ||
    {}) as HousekeepingRoomSummaryDto;
  const reservationSummary = props.data.reservationSummary;
  const departures = props.data.departuresSummary;
  const occupancy = props.data.occupancySummary;
  const arrivals = props.data.arrivalsSummary;

  const enabledGuestServiceItems = useMemo(() => {
    const servicesValues: StatisticSectionDataItem[] = [];

    if (effectiveValues.guestServiceStatuses) {
      if (effectiveValues.guestServiceStatusDoNotDisturbed) {
        servicesValues.push({
          label: t(
            `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.DoNotDisturb}`
          ),
          value: roomSummary.doNotDisturbCount
        });
      }
      if (effectiveValues.guestServiceStatusServiceRefused) {
        servicesValues.push({
          label: t(
            `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.ServiceRefused}`
          ),
          value: roomSummary.serviceRefusedCount
        });
      }
      if (effectiveValues.guestServiceStatusServiceRequested) {
        servicesValues.push({
          label: t(
            `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.ServiceRequested}`
          ),
          value: roomSummary.serviceRequestedCount
        });
      }
      if (effectiveValues.guestServiceStatusServiceDeferred) {
        servicesValues.push({
          label: t(
            `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.ServiceDeferred}`
          ),
          value: roomSummary.serviceDeferredCount
        });
      }
    }
    if (effectiveValues.greenService) {
      servicesValues.push({
        label: t(`GLOBAL.GREEN_SERVICE.TITLE`),
        value: roomSummary.greenServiceCount
      });
    }
    if (effectiveValues.enabledQueueRooms) {
      servicesValues.push({
        label: t(`GLOBAL.QUEUE_ROOMS.PENDING_ROOMS`),
        value: roomSummary.pendingInQueueCount
      });
      servicesValues.push({
        label: t(`GLOBAL.QUEUE_ROOMS.READY_ROOMS`),
        value: roomSummary.readyInQueueCount
      });
    }

    return servicesValues;
  }, [
    effectiveValues,
    roomSummary.doNotDisturbCount,
    roomSummary.serviceRefusedCount,
    roomSummary.serviceRequestedCount,
    roomSummary.serviceDeferredCount,
    roomSummary.greenServiceCount,
    roomSummary.pendingInQueueCount,
    roomSummary.readyInQueueCount
  ]);

  const discrepanciesItems = useMemo(() => {
    const discrepanciesValues: StatisticSectionDataItem[] = [
      {
        label: t(`GLOBAL.DISCREPANCY.${DiscrepancyType.Sleep}`),
        value: roomSummary.sleepDiscrepancyCount
      },
      {
        label: t(`GLOBAL.DISCREPANCY.${DiscrepancyType.Skip}`),
        value: roomSummary.skipDiscrepancyCount
      }
    ];

    if (effectiveValues.occupancyDiscrepancy) {
      discrepanciesValues.push({
        label: t(
          `HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.DISCREPANCIES.OCCUPANCY_DISCREPANCY`
        ),
        value: roomSummary.occupancyDiscrepancyCount
      });
    }

    return discrepanciesValues;
  }, [
    effectiveValues,
    roomSummary.sleepDiscrepancyCount,
    roomSummary.skipDiscrepancyCount,
    roomSummary.occupancyDiscrepancyCount,
    effectiveValues.occupancyDiscrepancy
  ]);

  function getRoomStatusTranslation(status: RoomStatus) {
    return extractDictionaryEntryLabel(
      roomStates.get(status),
      DictionaryLabelLength.Long
    );
  }

  const housekeeping: StatisticsSectionData | undefined = useMemo(
    () =>
      props.data.roomSummary
        ? {
            id: 'hk',
            title: t('HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSEKEEPING.TITLE'),
            subtitle: t(
              'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSEKEEPING.SUBTITLE'
            ),
            mainValue: props.data.roomSummary!.totalCount,
            items: [
              {
                label: getRoomStatusTranslation(RoomStatus.CL),
                value: roomSummary.cleanCount
              },
              {
                label: getRoomStatusTranslation(RoomStatus.DI),
                value: roomSummary.dirtyCount
              },
              {
                label: getRoomStatusTranslation(RoomStatus.PU),
                value: roomSummary.pickupCount
              },
              {
                label: getRoomStatusTranslation(RoomStatus.IN),
                value: roomSummary.inspectedCount
              }
            ]
          }
        : undefined,
    [
      roomSummary.inspectedCount,
      roomSummary.pickupCount,
      roomSummary.dirtyCount,
      roomSummary.cleanCount
    ]
  );

  const { enabledDayUse, enabledHourlyInventoryManagement } = useSelector(
    selectEffectiveValues
  )!;

  const houseStatus: StatisticsSectionData = useMemo(() => {
    const items: StatisticSectionDataItem[] = [
      {
        label: t(`HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.STAYOVERS`),
        value: roomSummary.stayoverCount
      },
      {
        label: t(
          `HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.TOTAL_ARRIVALS`
        ),
        value: roomSummary.arrivalCount
      },
      {
        label: t(
          `HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.ALLOCATED_ROOMS`
        ),
        value: roomSummary.allocatedCount
      },
      {
        label: t(
          `HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.UNALLOCATED_ROOMS`
        ),
        value: roomSummary.unallocatedCount
      }
    ];

    if (enabledDayUse) {
      items.push(
        {
          label: t(
            `HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.ALLOCATED_DAY_USE_COUNT`
          ),
          value: reservationSummary.allocatedDayUseCount
        },
        {
          label: t(
            `HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.TODAY_DAY_USE_COUNT`
          ),
          value: reservationSummary.todayDayUseCount
        }
      );
    }

    if (enabledHourlyInventoryManagement) {
      items.push(
        {
          label: t(
            `HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.GUARANTEED_EARLY_ARRIVAL`
          ),
          value: roomSummary.guaranteedTimeArrivalCount
        },
        {
          label: t(
            `HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.GUARANTEED_LATE_DEPARTURE`
          ),
          value: roomSummary.guaranteedTimeDepartureCount
        }
      );
    }

    return {
      id: 'hs',
      title: t('HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.TITLE'),
      subtitle: t('HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.SUBTITLE'),
      mainValue: roomSummary.departureCount,
      items
    };
  }, [
    roomSummary,
    reservationSummary,
    departures.totalCount,
    departures.expectedCount,
    arrivals.expectedCount,
    occupancy.adultGuestCount,
    occupancy.childGuestCount,
    occupancy.vipGuestCount,
    enabledDayUse,
    enabledHourlyInventoryManagement
  ]);

  const guestSpecialRequest: StatisticsSectionData | null = useMemo(
    () =>
      Object.keys(enabledGuestServiceItems).length > 0
        ? {
            id: 'gsr',
            title: t(
              'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.GUEST_SPECIAL_REQUEST.TITLE'
            ),
            subtitle: t(
              'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.GUEST_SPECIAL_REQUEST.SUBTITLE'
            ),
            mainValue: enabledGuestServiceItems.reduce(
              (sum: number, item: StatisticSectionDataItem) =>
                (item.value as number) + sum,
              0
            ) as number,
            items: enabledGuestServiceItems
          }
        : null,
    [
      roomSummary.doNotDisturbCount,
      roomSummary.serviceRefusedCount,
      roomSummary.serviceRequestedCount,
      roomSummary.serviceDeferredCount,
      roomSummary.greenServiceCount,
      roomSummary.pendingInQueueCount,
      roomSummary.readyInQueueCount
    ]
  );

  const discrepancies: StatisticsSectionData | undefined = useMemo(
    () =>
      props.data.roomSummary
        ? {
            id: 'dis',
            title: t(
              'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.DISCREPANCIES.TITLE'
            ),
            subtitle: t(
              'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.DISCREPANCIES.SUBTITLE'
            ),
            mainValue: discrepanciesItems.reduce(
              (sum: number, item: StatisticSectionDataItem) =>
                (item.value as number) + sum,
              0
            ) as number,
            items: discrepanciesItems
          }
        : undefined,
    [
      roomSummary.skipDiscrepancyCount,
      roomSummary.sleepDiscrepancyCount,
      roomSummary.occupancyDiscrepancyCount
    ]
  );

  const displayWidgets = [
    housekeeping,
    houseStatus,
    guestSpecialRequest,
    discrepancies
  ].filter(Boolean) as StatisticsSectionData[];

  return useMemo(
    () => (
      <AcBody>
        {displayWidgets.map(item => (
          <StatisticsSection data={item} key={item.id} />
        ))}
      </AcBody>
    ),
    [...displayWidgets]
  );
};

export default memo(HousekeepingStatisticsBody);
