import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DictionaryEntry } from '@hkm/components/App/domain/interfaces/DictionariesStore';
import {
  getFloorsMap,
  getFrontDeskStatesMap,
  getHousekeepingStatesMap,
  getRoomCategoriesMap,
  getRoomConditionsMap,
  getRoomLocationsMap,
  getRoomTypesMap,
  getSectionsMap
} from '@hkm/components/App/domain/selectors';
import { HousekeepingAdvancedFiltersConfigs } from '@hkm/components/Housekeeping/shared/filters/housekeepingAdvancedFiltersConfigs';
import { HousekeepingAdvancedFiltersOrder } from '@hkm/components/Housekeeping/shared/filters/housekeepingAdvancedFiltersOrder';
import { HousekeepingFiltersData } from '@hkm/components/Housekeeping/shared/models/housekeepingFiltersData';
import { disabledMenuSwipeClass } from '@hkm/components/Menu/MenuContainer';
import Chips from '@hkm/components/shared/Chips/Chips';
import { ChipsItem } from '@hkm/components/shared/Chips/ChipsItem';
import { useGuestServices } from '@hkm/shared/hooks/useGuestServices';
import { arrayToMapByKey } from '@hkm/utils/arrayToMapByKey';
import classNames from 'classnames';

import {
  Changeable,
  Childless,
  Styleable
} from '@ac/mobile-components/dist/interfaces/componentProps';

interface HousekeepingFiltersChipsProps
  extends Styleable,
    Childless,
    Changeable<HousekeepingFiltersData> {
  filters: HousekeepingFiltersData;
  deleteKeys?: boolean;
}

const HousekeepingFiltersChips: FC<HousekeepingFiltersChipsProps> = (
  props: HousekeepingFiltersChipsProps
) => {
  const { t } = useTranslation();
  const roomTypes = useSelector(getRoomTypesMap)!;
  const roomCategories = useSelector(getRoomCategoriesMap)!;
  const floors = useSelector(getFloorsMap)!;
  const sections = useSelector(getSectionsMap)!;
  const locations = useSelector(getRoomLocationsMap)!;
  const frontDeskStates = useSelector(getFrontDeskStatesMap)!;
  const housekeepingStates = useSelector(getHousekeepingStatesMap)!;
  const roomConditions = useSelector(getRoomConditionsMap)!;
  const guestServiceStatuses = useGuestServices();
  const guestServiceStatusesMap = arrayToMapByKey(
    guestServiceStatuses.activeGuestServices,
    'code'
  );

  const valueToItemMap = useMemo(
    () =>
      new Map<
        keyof HousekeepingFiltersData,
        (value: string) => DictionaryEntry
      >()
        .set('roomTypes', value => roomTypes.get(value)!)
        .set('roomCategories', value => roomCategories.get(value)!)
        .set('floors', value => floors.get(value)!)
        .set('sections', value => sections.get(value)!)
        .set('locations', value => locations.get(value)!)
        .set('frontDeskStates', value => frontDeskStates.get(value)!)
        .set(
          'guestServiceStatuses',
          value => guestServiceStatusesMap.get(value)!
        )
        .set('housekeepingStates', value => housekeepingStates.get(value)!)
        .set('roomConditions', value => roomConditions.get(value)!),
    [
      roomTypes,
      roomCategories,
      floors,
      sections,
      locations,
      frontDeskStates,
      housekeepingStates,
      roomConditions
    ]
  );

  const onRemove = useCallback(
    (field: keyof HousekeepingFiltersData) => callOnChangeWithout([field]),
    [props.filters]
  );

  const onClear = useCallback(
    () => callOnChangeWithout(HousekeepingAdvancedFiltersOrder),
    [props.filters]
  );

  if (!hasAnyAdvancedHousekeepingFilters(props.filters)) {
    return null;
  }

  const chips: Array<ChipsItem<
    keyof HousekeepingFiltersData
  >> = HousekeepingAdvancedFiltersOrder.map(field => {
    const config = HousekeepingAdvancedFiltersConfigs.get(field)!;
    const itemProvider = valueToItemMap.get(field) || ((value: any) => value);
    const fieldValue = props.filters[field];
    const values = fieldValue
      ? Array.isArray(fieldValue)
        ? fieldValue
        : [fieldValue]
      : [];
    const formattedItems = values.map(value => {
      const item = itemProvider(value);

      return item
        ? config.shortFormatter(item)
        : t('GLOBAL.NOT_FOUND_FALLBACK');
    });

    return formattedItems.length === 0
      ? null
      : {
          label: `${t(config.labelKey)}: ${formattedItems.join(', ')}`,
          value: field,
          id: field
        };
  }).filter(Boolean) as Array<ChipsItem<keyof HousekeepingFiltersData>>;

  const className: string = classNames(
    'housekeeping-filters-chips',
    disabledMenuSwipeClass,
    props.className
  );

  function hasAnyAdvancedHousekeepingFilters(
    filter: HousekeepingFiltersData
  ): boolean {
    return HousekeepingAdvancedFiltersOrder.some(field => !!filter[field]);
  }

  function callOnChangeWithout(fields: Array<keyof HousekeepingFiltersData>) {
    if (props.onChange) {
      const newFilters = { ...props.filters };
      if (props.deleteKeys) {
        fields.forEach(field => delete newFilters[field]);
      } else {
        fields.forEach(field => (newFilters[field] = undefined));
      }
      props.onChange(newFilters);
    }
  }

  return (
    <Chips
      className={className}
      style={props.style}
      items={chips}
      onClear={onClear}
      onRemove={onRemove}
    />
  );
};

export default memo(HousekeepingFiltersChips);
