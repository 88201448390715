import { useCallback, useMemo, useState } from 'react';
import { SortOptionsCode } from '@hkm/components/TaskManagement/TaskPreview/Comments/domain/enums';
import { TaskPreviewDetails } from '@hkm/components/TaskManagement/TaskPreview/domain/interfaces';
import { dayjs } from '@hkm/utils/dayjs-extended';

import { LoginService } from '@ac/library-utils/dist/services';

const useCommentsState = (task: TaskPreviewDetails) => {
  const [showOnlyMyComments, setShowOnlyMyComments] = useState(false);
  const [sortBy, setSortBy] = useState(SortOptionsCode.newest);

  const comments = useMemo(() => {
    const onlyComments = (task?.history?.entries || []).filter(
      ({ comment }) => comment
    );

    const sortedComments = onlyComments.sort((a, b) =>
      sortBy === SortOptionsCode.oldest
        ? dayjs(a.creationTime).isAfter(b.creationTime)
          ? 1
          : -1
        : !dayjs(a.creationTime).isAfter(b.creationTime)
        ? 1
        : -1
    );

    const currentUserId = LoginService.authData()?.userId;

    return showOnlyMyComments && currentUserId
      ? sortedComments.filter(({ userId }) => userId === currentUserId)
      : sortedComments;
  }, [task, sortBy, showOnlyMyComments]);

  const toggleShowOnlyMyComments = useCallback(
    () => setShowOnlyMyComments(state => !state),
    []
  );

  const onSortChange = useCallback((option: SortOptionsCode) => {
    setSortBy(option);
  }, []);

  return {
    showOnlyMyComments,
    sortBy,
    toggleShowOnlyMyComments,
    onSortChange,
    comments
  };
};

export default useCommentsState;
