import { LanguageDefinition } from '@hkm/components/Menu/LanguageSelector/domain/interfaces/languageDefinition';

export interface MenuStore {
  isMenuOpen: boolean;
  behaviour: MenuBehaviour;
  selectedLanguage: LanguageDefinition;
}

export enum MenuBehaviour {
  Enable = 'Enable',
  Disable = 'Disable',
  Force = 'Force'
}
