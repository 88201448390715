import { SheetSummaryInstructions } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryInstructions';

import { AttendantAssignmentSheetDetails } from '@ac/library-api';

export const createInstructionsFactory = (
  sheet: AttendantAssignmentSheetDetails
): SheetSummaryInstructions => {
  return {
    hasInstructions: sheet.hasInstructions,
    instructions: sheet.instructions || '',
    floorCount: sheet.floorCount
  };
};
