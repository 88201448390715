import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getSelectedLanguage,
  getSupportedUiLanguages
} from '@hkm/components/App/domain/selectors';
import { languageSelect } from '@hkm/components/Menu/domain/actions';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';

import {
  AcSelect,
  AcSelectPattern,
  AcSelectValue
} from '@ac/mobile-components/dist/components/select';
import { LoadingState } from '@ac/mobile-components/dist/enums';

const LanguageSelector: React.FC = () => {
  const { t, ready } = useTranslation();
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(getSelectedLanguage);
  const effectiveValues = useSelector(selectEffectiveValues);
  const supportedUiLanguages = useSelector(getSupportedUiLanguages);
  const { pathname } = useLocation();

  const [wasDefaultLanguageSet, setWasDefaultLanguageSet] = useState(false);

  const availableLanguages: AcSelectValue[] = useMemo(() => {
    if (!effectiveValues || !supportedUiLanguages) {
      return [];
    }
    const { supportedHousekeepingMobileLanguages } = effectiveValues;
    const availableSupportedLanguages = (
      supportedUiLanguages || []
    ).filter(({ code }) =>
      supportedHousekeepingMobileLanguages.supportedLanguages?.includes(code!)
    );

    return availableSupportedLanguages.map(el => {
      return {
        value: el.code!,
        itemLabel: el.nativeLanguageName!,
        inputLabel: el.nativeLanguageName!
      };
    });
  }, [effectiveValues, supportedUiLanguages]);

  useEffect(() => {
    if (!effectiveValues || !supportedUiLanguages || wasDefaultLanguageSet) {
      return;
    }
    const { supportedHousekeepingMobileLanguages } = effectiveValues;

    if (
      selectedLanguage?.languageCode !==
      supportedHousekeepingMobileLanguages.languageCode
    ) {
      dispatch(
        languageSelect.trigger({
          languageCode: supportedHousekeepingMobileLanguages.languageCode!,
          pathname
        })
      );
      setWasDefaultLanguageSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectiveValues, supportedUiLanguages]);

  const handleLanguageChange = useCallback(
    (languageCode: string) => {
      dispatch(languageSelect.trigger({ languageCode, pathname }));
    },
    [dispatch, pathname]
  );

  return (
    <AcSelect
      selectedItem={selectedLanguage.languageCode}
      itemsList={availableLanguages}
      label={t('MENU.LANGUAGE')}
      showSearch={false}
      showInputs={true}
      clearable={false}
      readonly={!ready}
      pattern={AcSelectPattern.Borderless}
      testSelector="language"
      loading={ready ? LoadingState.Default : LoadingState.Loading}
      onChange={handleLanguageChange}
    />
  );
};

export default LanguageSelector;
