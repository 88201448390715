import * as appActions from '@hkm/components/App/domain/actions';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { getConfigurationUsers } from '@hkm/components/TaskManagement/apis/configuration';
import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';
import LinkedObjectManager from '@hkm/components/TaskManagement/managers/LinkedObjectManager';
import { SelectedObjectModel } from '@hkm/components/TaskManagement/shared/interfaces/selectedObjectModel';
import * as actions from '@hkm/components/TaskManagement/TaskPreview/domain/actions';
import { UpdateTaskLinkedObjectParams } from '@hkm/components/TaskManagement/TaskPreview/domain/interfaces';
import { serializeTaskForRemoveObject } from '@hkm/components/TaskManagement/TaskPreview/domain/mappers';
import { EffectiveValues } from '@hkm/shared/effectiveValues/effectiveValues';
import { put, select, takeLatest } from 'redux-saga/effects';

import {
  BodyTask,
  buildFIQLFilter,
  ConfigBuilder,
  FIQLOperators,
  LinkedObjectInfo,
  RawLinkedObject,
  RawTask
} from '@ac/library-api';
import { TasksApi } from '@ac/library-api/dist/api/v0/taskManagement';
import { Action } from '@ac/library-utils/dist/declarations';

function* fetchTask(action: Action<string>) {
  try {
    const result: RawTask = yield TasksApi.getById({
      pathParams: { id: action.payload }
    });

    if (result.linkedObject) {
      yield put(actions.loadLinkedObject.trigger(result.linkedObject));
    } else {
      yield put(actions.clearLinkedObject());
    }

    yield put(actions.loadTaskRelatedUsers.trigger(result));
    yield put(actions.loadTask.success(result));
  } catch (error) {
    yield put(appActions.displayExtractedError(error));
    yield put(actions.loadTask.failure(error));
  }
}

function* fetchLinkedObject(
  action: Action<RawLinkedObject | LinkedObjectInfo>
) {
  try {
    const effectiveValues: EffectiveValues = yield select(
      selectEffectiveValues
    )!;
    const manager = new LinkedObjectManager(effectiveValues.titleOrder);
    const linkedObject: SelectedObjectModel = yield manager.getSelectedObject(
      action.payload
    );

    yield put(actions.loadLinkedObject.success(linkedObject));
  } catch (error) {
    yield put(appActions.displayExtractedError(error));
    yield put(actions.loadLinkedObject.failure(error));
  }
}

function* fetchRelatedUsers(action: Action<RawTask>) {
  try {
    const { assignedUserId, reporterUserId } = action.payload;
    const userIds = [assignedUserId, reporterUserId].filter(
      Boolean
    ) as string[];
    const uniqueUserIds = [...new Set(userIds)];
    const users: MappedConfigurationUser[] = yield getConfigurationUsers({
      filter: buildFIQLFilter('id', FIQLOperators.in, uniqueUserIds)
    });

    yield put(actions.loadTaskRelatedUsers.success(users));
  } catch (error) {
    yield put(appActions.displayExtractedError(error));
  }
}

function* updateTaskLinkedObject(action: Action<UpdateTaskLinkedObjectParams>) {
  try {
    const task = action.payload.task;
    const serializedTask = serializeTaskForRemoveObject(
      task,
      action.payload.linkedObject
    );
    const config = new ConfigBuilder()
      .setHeaders({
        'IF-Match': task.version
      })
      .getConfig();

    yield TasksApi.putById({
      data: serializedTask,
      customConfig: config,
      pathParams: { id: task.id }
    }) as Promise<BodyTask>;

    yield put(actions.updateTaskLinkedObject.success());
    yield put(actions.loadTask.trigger(task.id));
  } catch (error) {
    yield put(appActions.displayExtractedError(error));
    yield put(actions.updateTaskLinkedObject.failure(error));
  }
}

export default function* taskDetailsSagas() {
  yield takeLatest(actions.loadTask.trigger, fetchTask);
  yield takeLatest(actions.loadLinkedObject.trigger, fetchLinkedObject);
  yield takeLatest(actions.loadTaskRelatedUsers.trigger, fetchRelatedUsers);
  yield takeLatest(
    actions.updateTaskLinkedObject.trigger,
    updateTaskLinkedObject
  );
}
