import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectBusinessDate } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import * as createMaintenanceActions from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/actions';
import { selectMaintenanceCreateState } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/selectors';
import MaintenanceForm from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/MaintenanceCreateForm';
import { MaintenanceFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/model/maintenanceFormData';
import APP_ROUTES from '@hkm/constants/routing.constants';

import { SetMaintenances } from '@ac/library-api';
import { AcSpinnerCover } from '@ac/mobile-components/dist/components/spinner';
import { useComponentDidUpdateEffect } from '@ac/mobile-components/dist/hooks';

const CreateMaintenance: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const businessDate = useSelector(selectBusinessDate);
  const maintenanceCreateState = useSelector(selectMaintenanceCreateState);

  const showSpinner = maintenanceCreateState.isPending;
  const [initialValues] = useState<MaintenanceFormData>({
    businessDate,
    fromTime: businessDate,
    toTime: businessDate
  });

  const onSubmit = useCallback((data: SetMaintenances) => {
    dispatch(
      createMaintenanceActions.setMaintenanceActionsSet.createMaintenance.trigger(
        {
          data
        }
      )
    );
  }, []);

  const onValidateMaintenance = useCallback((data: SetMaintenances) => {
    dispatch(
      createMaintenanceActions.setMaintenanceActionsSet.validateMaintenance.trigger(
        {
          data
        }
      )
    );
  }, []);

  useComponentDidUpdateEffect(() => {
    backward();
  }, [maintenanceCreateState.version]);

  function backward(): void {
    navigate(APP_ROUTES.MAINTENANCE.DASHBOARD);
  }

  return (
    <>
      <MaintenanceForm
        backward={backward}
        onSubmit={onSubmit}
        initialValues={initialValues}
        onValidateMaintenance={onValidateMaintenance}
        title={t('MAINTENANCE_CREATE.TITLE.ADD')}
      />
      <AcSpinnerCover
        fixed={true}
        testSelector="create-maintenance-spinner"
        disabled={!showSpinner}
      />
    </>
  );
};

export default memo(CreateMaintenance);
