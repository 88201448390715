import { OOOStatisticsStateData } from '@hkm/components/Maintenance/Statistics/domain/interfaces';

import { createAction, getOneAction } from '@ac/library-utils/dist/redux-utils';

export const fetch = getOneAction<undefined, OOOStatisticsStateData>(
  'OOOStatistics'
);
export const flush = createAction('OOOStatistics/flush');
export const enableRefresh = createAction('OOOStatistics/enableRefresh');
export const disableRefresh = createAction('OOOStatistics/disableRefresh');
