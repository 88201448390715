import * as appActions from '@hkm/components/App/domain/actions';
import { HousekeepingPagination } from '@hkm/components/Housekeeping/Dashboard/domain/interfaces';
import { selectBusinessDate } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import {
  selectPostingDashboardFilters,
  selectPostingDashboardPagination,
  selectPostingDashboardSort
} from '@hkm/components/Posting/Dashboard/domain/selectors';
import { getPostingReservedKind } from '@hkm/components/Posting/Dashboard/domain/utils/getPostingReservedKind';
import { PostingSort } from '@hkm/components/Posting/Dashboard/enum/postingSort';
import { createPostingApiFilter } from '@hkm/components/Posting/Dashboard/filters/postingApiFilterFactory';
import { PostingFiltersData } from '@hkm/components/Posting/Dashboard/filters/postingFiltersData';
import { activeReservationStates } from '@hkm/shared/reservations/activeReservationStatuses';
import { PAGINATION_PAGE_SIZE } from '@hkm/store/pagination/paginationConfig';
import { createPaginationSaga } from '@hkm/store/pagination/paginationSagaFactory';
import { createHousekeepingRoom } from '@hkm/types/housekeeping/factories/createHousekeepingRoom';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import { Task } from '@redux-saga/types';
import {
  cancel,
  fork,
  put,
  select,
  take,
  takeLatest
} from 'redux-saga/effects';

import {
  HousekeepingRoomDetailsDto,
  LibraryApiResponse,
  PageResponse,
  RawPageResponse
} from '@ac/library-api';
import { HousekeepingViewsApi } from '@ac/library-api/dist/api/v0/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';

import * as actions from './actions';

const DEFAULT_SORT: PostingSort = PostingSort.RoomNumber;
function* fetchPostingDashboardPage(action: Action<number>) {
  try {
    const filters: PostingFiltersData = yield select(
      selectPostingDashboardFilters
    );
    const sort: PostingSort = yield select(selectPostingDashboardSort);
    const sorts: string =
      sort === DEFAULT_SORT ? sort : `${sort},${DEFAULT_SORT}`;
    const businessDate: string = yield select(selectBusinessDate);

    const page: LibraryApiResponse<PageResponse<
      HousekeepingRoomDetailsDto,
      HousekeepingRoom
    >> = yield HousekeepingViewsApi.getHousekeepingDashboard({
      queryParams: {
        sort: sorts,
        filter: createPostingApiFilter({
          ...filters,
          reservationStatus: Array.from(activeReservationStates)
        }),
        pageSize: PAGINATION_PAGE_SIZE,
        pageNumber: action.payload
      }
    }) as Promise<
      LibraryApiResponse<RawPageResponse<HousekeepingRoomDetailsDto>>
    >;

    const postingRoomsPage: PageResponse<
      HousekeepingRoomDetailsDto,
      HousekeepingRoom
    > = {
      paging: page.data.paging,
      results: page.data.results.map(result =>
        createHousekeepingRoom(result, businessDate, getPostingReservedKind)
      )
    };

    yield put(actions.pagination.fetchedPage(postingRoomsPage));
  } catch {
    yield put(actions.pagination.error());
  }
}

function* patchChangedRoom(action: Action<string>) {
  try {
    const roomId: string = action.payload;
    const state: HousekeepingPagination = yield select(
      selectPostingDashboardPagination
    );
    const hasRoom = !!state.page?.results.some(
      roomToCheck => roomToCheck.id === roomId
    );

    if (!hasRoom) {
      return;
    }

    const businessDate: string = yield select(selectBusinessDate);

    const room: LibraryApiResponse<HousekeepingRoomDetailsDto> = yield HousekeepingViewsApi.getHousekeepingRoomDetails(
      {
        pathParams: { roomId }
      }
    );

    const housekeepingRoom = createHousekeepingRoom(
      room.data,
      businessDate,
      getPostingReservedKind
    );

    yield put(actions.replaceChangedRoom(housekeepingRoom));
  } catch (e) {
    // tslint:disable-next-line:no-empty
    console.error(e);
  }
}

function* detailsSagas() {
  yield fork(
    createPaginationSaga(actions.pagination, selectPostingDashboardPagination)
  );
  yield takeLatest(actions.pagination.fetchPage, fetchPostingDashboardPage);
  yield takeLatest(actions.patchChangedRoom, patchChangedRoom);
}

export default function* housekeepingDashboardSagas() {
  while (true) {
    const task: Task = yield fork(detailsSagas);
    yield take(appActions.clearAllViews);
    yield cancel(task);
  }
}
