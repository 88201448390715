import { willRegionChangeForProperty } from '@hkm/utils/region/willRegionChangeForProperty';

import { UnitAssignmentListItem } from '@ac/library-api';
import { SessionService } from '@ac/library-utils/dist/services';

export const setProperty = (property?: UnitAssignmentListItem): void => {
  if (!property || !property.unitId) {
    return;
  }

  // We leave the current session data intact, because
  // New region = new domain, new domain = fresh localStorage/sessionStorage data
  if (willRegionChangeForProperty(property)) {
    return;
  }

  SessionService.setPropertyId(property.unitId);
};
