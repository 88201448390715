import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { HousekeepingCleaningScheduleFeatureToggles } from '@hkm/components/Housekeeping/shared/config/housekeepingFeatureToggles';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';
import { useHasRequiredFeatureToggles } from '@hkm/shared/hooks/useHasRequiredFeatureToggles';

export function useGuestServiceMap(): Map<GuestServiceStatusType, boolean> {
  const effectiveValues = useSelector(selectEffectiveValues)!;
  const isServicePreferredEnabled =
    useHasRequiredFeatureToggles(HousekeepingCleaningScheduleFeatureToggles) &&
    effectiveValues.guestServiceStatusServicePreferred;

  return useMemo(
    () =>
      new Map<GuestServiceStatusType, boolean>([
        [
          GuestServiceStatusType.ServiceRequested,
          effectiveValues.guestServiceStatusServiceRequested
        ],
        [
          GuestServiceStatusType.ServiceDeferred,
          effectiveValues.guestServiceStatusServiceDeferred
        ],
        [
          GuestServiceStatusType.DoNotDisturb,
          effectiveValues.guestServiceStatusDoNotDisturbed
        ],
        [GuestServiceStatusType.ServicePreferred, isServicePreferredEnabled],
        [
          GuestServiceStatusType.ServiceRefused,
          effectiveValues.guestServiceStatusServiceRefused
        ]
      ]),
    [effectiveValues]
  );
}
