import React, { FC, memo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AttendantCreateMaintenance from '@hkm/components/Attendant/CreateMaintenance/AttendantCreateMaintenance';
import AttendantRoomDetails from '@hkm/components/Attendant/RoomDetails/AttendantRoomDetails';
import AttendantRoomList from '@hkm/components/Attendant/RoomList/AttendantRoomList';
import { AttendantAssignmentsDictionaries } from '@hkm/components/Attendant/shared/config/attendantDictionaries';
import { AttendantEffectiveValues } from '@hkm/components/Attendant/shared/config/attendantEffeectiveValues';
import { AttendantViewPermissionsConfig } from '@hkm/components/Attendant/shared/config/attendantViewPermissionsConfig';
import AttendantAssignmentSheetSummary from '@hkm/components/Attendant/SheetSummary/AttendantSheetSummary';
import HousekeepingFilters from '@hkm/components/Housekeeping/AdvancedFilters/HousekeepingAdvancedFilters';
import BatchContainer from '@hkm/components/Housekeeping/Batch/BatchContainer';
import HousekeepingCreateMaintenance from '@hkm/components/Housekeeping/CreateMaintenance/HousekeepingCreateMaintenance';
import HousekeepingDashboardContainer from '@hkm/components/Housekeeping/Dashboard/HousekeepingDashboardContainer';
import { HousekeepingDetailsDictionaries } from '@hkm/components/Housekeeping/Details/config/housekeepingDetailsDictionaries';
import HousekeepingDetailsContainer from '@hkm/components/Housekeeping/Details/HousekeepingDetailsContainer';
import HelpContainer from '@hkm/components/Housekeeping/Help/HelpContainer';
import { HousekeepingDashboardDictionaries } from '@hkm/components/Housekeeping/shared/config/housekeepingDashboardDictionaries';
import { HousekeepingEffectiveValues } from '@hkm/components/Housekeeping/shared/config/housekeepingEffectiveValues';
import { HousekeepingManagePermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingManagePermissionsConfig';
import { HousekeepingViewPermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingViewPermissionsConfig';
import { HousekeepingAdvancedFiltersDictionaries } from '@hkm/components/Housekeeping/shared/filters/housekeepingAdvancedFiltersDictionaries';
import { HousekeepingStatisticsDictionaries } from '@hkm/components/Housekeeping/Statistics/config/housekeepingStatisticsDictionaries';
import { HousekeepingStatisticsPermissionsConfig } from '@hkm/components/Housekeeping/Statistics/config/housekeepingStatisticsPermissionsConfig';
import HousekeepingStatistics from '@hkm/components/Housekeeping/Statistics/HousekeepingStatistics';
import LoginContainer from '@hkm/components/Login/LoginContainer';
import MaintenanceAdvancedFilters from '@hkm/components/Maintenance/AdvancedFilters/MaintenanceAdvancedFilters';
import { MaintenanceManagePermissionsConfig } from '@hkm/components/Maintenance/config/maintenanceManagePermissionsConfig';
import { MaintenanceViewPermissionsConfig } from '@hkm/components/Maintenance/config/maintenanceViewPermissionsConfig';
import CopyMaintenance from '@hkm/components/Maintenance/Create/CopyMaintenance';
import CreateMaintenance from '@hkm/components/Maintenance/Create/CreateMaintenance';
import MaintenanceDashboard from '@hkm/components/Maintenance/Dashboard/MaintenanceDashboard';
import MaintenanceDetailsContainer from '@hkm/components/Maintenance/Details/MaintenanceDetailsContainer';
import ReleaseMaintenance from '@hkm/components/Maintenance/Release/ReleaseMaintenance';
import { MaintenanceDashboardDictionaries } from '@hkm/components/Maintenance/shared/config/maintenanceDashboardDictionaries';
import { MaintenanceEffectiveValues } from '@hkm/components/Maintenance/shared/config/maintenanceEffectiveValues';
import { MaintenanceAdvancedFiltersDictionaries } from '@hkm/components/Maintenance/shared/filters/maintenanceAdvancedFiltersDictionaries';
import MaintenanceStatistics from '@hkm/components/Maintenance/Statistics/MaintenanceStatistics';
import { MenuBehaviour } from '@hkm/components/Menu/domain/interfaces';
import HousekeepingPostingDashboard from '@hkm/components/Posting/Dashboard/HousekeepingPostingDashboard';
import AttendantPostingReservations from '@hkm/components/Posting/PostingReservation/AttendantPostingReservations';
import HousekeepingPostingReservations from '@hkm/components/Posting/PostingReservation/HousekeepingPostingReservations';
import AttendantPostingView from '@hkm/components/Posting/PostingView/AttendantPostingView';
import HousekeepingPostingView from '@hkm/components/Posting/PostingView/HousekeepingPostingView';
import { PostingDictionaries } from '@hkm/components/Posting/shared/config/postingDictionaries';
import {
  AttendantPostingEffectiveValues,
  HousekeepingPostingEffectiveValues
} from '@hkm/components/Posting/shared/config/postingEffeectiveValues';
import {
  AttendantPostingPermissionsConfig,
  HousekeepingPostingPermissionsConfig
} from '@hkm/components/Posting/shared/config/postingPermissionsConfig';
import { AppRouteContent } from '@hkm/components/shared/Routes/AppRouteContent/AppRouteContent';
import RouteComposeWrapper from '@hkm/components/shared/Routes/RouteComposeWrapper/RouteComposeWrapper';
import { TaskManagementRoutes } from '@hkm/components/TaskManagement/Routes/Routes';
import APP_ROUTES from '@hkm/constants/routing.constants';
import EmptyComponent from '@hkm/shared/empty/EmptyComponent';
import { SourceModuleType } from '@hkm/shared/enum/SourceModuleType';
import { requireAttendantSheet } from '@hkm/utils/routeGuards/requireAttendantSheet';
import { requireAuth } from '@hkm/utils/routeGuards/requireAuth';
import { requireDictionaries } from '@hkm/utils/routeGuards/requireDictionaries';
import { requireEffectiveValues } from '@hkm/utils/routeGuards/requireEffectiveValues';
import { requirePermission } from '@hkm/utils/routeGuards/requirePermission';
import { compose } from 'redux';

import './RouterAnimations.css';

const AppRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={APP_ROUTES.HOUSEKEEPING.DASHBOARD}
          element={
            <AppRouteContent sourceModuleType={SourceModuleType.Housekeeping}>
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(HousekeepingEffectiveValues),
                  requirePermission(...HousekeepingViewPermissionsConfig),
                  requireDictionaries(HousekeepingDashboardDictionaries)
                )(HousekeepingDashboardContainer)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.HOUSEKEEPING.BATCH}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Housekeeping}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(HousekeepingEffectiveValues),
                  requirePermission(...HousekeepingManagePermissionsConfig),
                  requireDictionaries(HousekeepingDetailsDictionaries)
                )(BatchContainer)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.HOUSEKEEPING.DETAILS}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Housekeeping}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(HousekeepingEffectiveValues),
                  requirePermission(...HousekeepingViewPermissionsConfig),
                  requireDictionaries(HousekeepingDetailsDictionaries)
                )(HousekeepingDetailsContainer)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.HOUSEKEEPING.FILTERS}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Housekeeping}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(HousekeepingEffectiveValues),
                  requirePermission(...HousekeepingViewPermissionsConfig),
                  requireDictionaries(HousekeepingAdvancedFiltersDictionaries)
                )(HousekeepingFilters)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.HOUSEKEEPING.STATISTICS}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Housekeeping}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(HousekeepingEffectiveValues),
                  requirePermission(...HousekeepingStatisticsPermissionsConfig),
                  requireDictionaries(HousekeepingStatisticsDictionaries)
                )(HousekeepingStatistics)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.HOUSEKEEPING.ADD_MAINTENANCE}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Housekeeping}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(HousekeepingEffectiveValues),
                  requirePermission(...MaintenanceManagePermissionsConfig),
                  requireDictionaries(MaintenanceDashboardDictionaries)
                )(HousekeepingCreateMaintenance)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.MAINTENANCE.DASHBOARD}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Enable}
              sourceModuleType={SourceModuleType.Maintenance}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(MaintenanceEffectiveValues),
                  requirePermission(...MaintenanceViewPermissionsConfig),
                  requireDictionaries(MaintenanceDashboardDictionaries)
                )(MaintenanceDashboard)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.MAINTENANCE.ADD}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Maintenance}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(MaintenanceEffectiveValues),
                  requirePermission(...MaintenanceManagePermissionsConfig),
                  requireDictionaries(MaintenanceDashboardDictionaries)
                )(CreateMaintenance)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.MAINTENANCE.COPY}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Maintenance}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(MaintenanceEffectiveValues),
                  requirePermission(...MaintenanceManagePermissionsConfig),
                  requireDictionaries(MaintenanceDashboardDictionaries)
                )(CopyMaintenance)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.MAINTENANCE.FILTERS}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Maintenance}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(MaintenanceEffectiveValues),
                  requirePermission(...MaintenanceViewPermissionsConfig),
                  requireDictionaries(MaintenanceAdvancedFiltersDictionaries)
                )(MaintenanceAdvancedFilters)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.MAINTENANCE.DETAILS}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Maintenance}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(MaintenanceEffectiveValues),
                  requirePermission(...MaintenanceViewPermissionsConfig),
                  requireDictionaries(HousekeepingDetailsDictionaries)
                )(MaintenanceDetailsContainer)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.MAINTENANCE.RELEASE}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Maintenance}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(MaintenanceEffectiveValues),
                  requirePermission(...MaintenanceManagePermissionsConfig),
                  requireDictionaries(MaintenanceDashboardDictionaries)
                )(ReleaseMaintenance)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.MAINTENANCE.STATISTICS}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Maintenance}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(MaintenanceEffectiveValues),
                  requirePermission(...MaintenanceViewPermissionsConfig),
                  requireDictionaries(HousekeepingDetailsDictionaries)
                )(MaintenanceStatistics)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY}
          element={
            <AppRouteContent sourceModuleType={SourceModuleType.Attendant}>
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(AttendantEffectiveValues),
                  requirePermission(...AttendantViewPermissionsConfig),
                  requireDictionaries(AttendantAssignmentsDictionaries),
                  requireAttendantSheet
                )(AttendantAssignmentSheetSummary)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_LIST}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Attendant}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(AttendantEffectiveValues),
                  requirePermission(...AttendantViewPermissionsConfig),
                  requireDictionaries(AttendantAssignmentsDictionaries),
                  requireAttendantSheet
                )(AttendantRoomList)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_DETAILS}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Attendant}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(AttendantEffectiveValues),
                  requirePermission(...AttendantViewPermissionsConfig),
                  requireDictionaries(AttendantAssignmentsDictionaries),
                  requireAttendantSheet
                )(AttendantRoomDetails)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.ATTENDANT_ASSIGNMENTS.ADD_MAINTENANCE}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Attendant}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(AttendantEffectiveValues),
                  requirePermission(...AttendantViewPermissionsConfig),
                  requireDictionaries(MaintenanceDashboardDictionaries),
                  requireAttendantSheet
                )(AttendantCreateMaintenance)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.DASHBOARD}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Enable}
              sourceModuleType={SourceModuleType.HousekeepingPosting}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(HousekeepingPostingEffectiveValues),
                  requirePermission(...HousekeepingPostingPermissionsConfig),
                  requireDictionaries(PostingDictionaries)
                )(HousekeepingPostingDashboard)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.RESERVATIONS}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.HousekeepingPosting}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(HousekeepingPostingEffectiveValues),
                  requirePermission(...HousekeepingPostingPermissionsConfig),
                  requireDictionaries(PostingDictionaries)
                )(HousekeepingPostingReservations)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.POSTING_VIEW}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.HousekeepingPosting}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(HousekeepingPostingEffectiveValues),
                  requirePermission(...HousekeepingPostingPermissionsConfig),
                  requireDictionaries(PostingDictionaries)
                )(HousekeepingPostingView)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.MOBILE_POSTING.ATTENDANT.RESERVATIONS}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.AttendantPosting}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(AttendantPostingEffectiveValues),
                  requirePermission(...AttendantPostingPermissionsConfig),
                  requireDictionaries(PostingDictionaries),
                  requireAttendantSheet
                )(AttendantPostingReservations)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.MOBILE_POSTING.ATTENDANT.POSTING_VIEW}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.AttendantPosting}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireEffectiveValues(AttendantPostingEffectiveValues),
                  requirePermission(...AttendantPostingPermissionsConfig),
                  requireDictionaries(PostingDictionaries),
                  requireAttendantSheet
                )(AttendantPostingView)}
              />
            </AppRouteContent>
          }
        />

        {TaskManagementRoutes}

        <Route
          path={APP_ROUTES.HELP}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Other}
            >
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireDictionaries(HousekeepingDetailsDictionaries)
                )(HelpContainer)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={'/'}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Force}
              sourceModuleType={SourceModuleType.Other}
            >
              {/* // NOTE: Empty set is required to load uiLanguages on home page */}
              <RouteComposeWrapper
                component={compose(
                  requireAuth,
                  requireDictionaries(new Set())
                )(EmptyComponent)}
              />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.LOGIN}
          element={
            <AppRouteContent
              menuBehaviour={MenuBehaviour.Disable}
              sourceModuleType={SourceModuleType.Other}
            >
              <RouteComposeWrapper component={LoginContainer} />
            </AppRouteContent>
          }
        />

        <Route
          path={APP_ROUTES.HOME}
          element={<Navigate to={APP_ROUTES.HOME} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default memo(AppRoutes);
