import { PropsWithChildren, useContext, useLayoutEffect } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as menuActions from '@hkm/components/Menu/domain/actions';
import { MenuBehaviour } from '@hkm/components/Menu/domain/interfaces';
import MenuContainer from '@hkm/components/Menu/MenuContainer';
import { SourceModuleType } from '@hkm/shared/enum/SourceModuleType';

import { AcLayout } from '@ac/mobile-components/dist/components/layout';
import AcMobileContext from '@ac/mobile-components/dist/config';

interface ComponentProps {
  sourceModuleType: SourceModuleType;
  menuBehaviour?: MenuBehaviour;
}

export const AppRouteContent = (
  props: PropsWithChildren<ComponentProps>
): JSX.Element => {
  const dispatch = useDispatch();
  const acMobileContext = useContext(AcMobileContext)!;
  const { sourceModuleType, menuBehaviour } = props;

  useLayoutEffect(() => {
    dispatch(menuActions.setMenuBehaviour(menuBehaviour!));
  }, [menuBehaviour]);

  useLayoutEffect(() => {
    acMobileContext.setCurrentModuleName(sourceModuleType);
  }, [sourceModuleType]);

  return (
    <AcLayout>
      <MenuContainer />
      {props.children}
    </AcLayout>
  );
};
