import { getCustomConfig } from '@hkm/utils/getCustomConfig';

import {
  ApiOperation,
  HousekeepingOperationExecutionDetails,
  LongRunningProcess,
  OperationStatus,
  RawHousekeepingOperationExecutionDetails,
  RoomIdPathParam,
  SetAttendantMaintenance
} from '@ac/library-api';
import { MobileAttendantApi } from '@ac/library-api/dist/api/v0/housekeeping';

export class SetMaintenanceAttendantProcess extends LongRunningProcess<
  RoomIdPathParam,
  SetAttendantMaintenance
> {
  private static _statusResponseHandler(
    response: ApiOperation<
      RawHousekeepingOperationExecutionDetails,
      HousekeepingOperationExecutionDetails
    >
  ) {
    return (
      !!response.status &&
      response.status.operationStatus === OperationStatus.Completed
    );
  }

  constructor(
    payload: SetAttendantMaintenance,
    roomId: string,
    version: number
  ) {
    super({
      action: MobileAttendantApi.postSetMaintenances,
      pathParams: { roomId },
      payload,
      customHeaders: getCustomConfig({
        version
      }).headers,
      statusResponseHandler: (
        response: ApiOperation<
          RawHousekeepingOperationExecutionDetails,
          HousekeepingOperationExecutionDetails
        >
      ) => SetMaintenanceAttendantProcess._statusResponseHandler(response)
    });
  }
}
