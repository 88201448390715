import { AttendantTaskOrder } from '@hkm/components/Attendant/shared/config/attendantTaskOrder';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';

import { AttendantTaskProgressType } from '@ac/library-api';

export function getGroupRoomsByStatus(
  rooms: AttendantRoom[]
): Map<AttendantTaskProgressType, AttendantRoom[]> {
  const groupRooms = new Map<AttendantTaskProgressType, AttendantRoom[]>();

  // create map in correct order
  AttendantTaskOrder.map(task => groupRooms.set(task, []));

  // we skip rooms without tasks
  rooms.forEach(room => {
    if (
      room.activeTask &&
      groupRooms.has(
        room.activeTask.progress?.code as AttendantTaskProgressType
      )
    ) {
      groupRooms
        .get(room.activeTask.progress?.code as AttendantTaskProgressType)!
        .push(room);
    }
  });

  return groupRooms;
}
