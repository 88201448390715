import { AttendantEffectiveValues } from '@hkm/components/Attendant/shared/config/attendantEffeectiveValues';
import { EffectiveValues } from '@hkm/shared/effectiveValues/effectiveValues';

export const HousekeepingPostingEffectiveValues: Map<keyof EffectiveValues, any> = new Map<keyof EffectiveValues, any>()
  .set('enabledHousekeepingMobilePosting', true)
  .set('mobilePostingItems', []);

export const AttendantPostingEffectiveValues: Map<keyof EffectiveValues, any> = new Map<keyof EffectiveValues, any>(
  [...HousekeepingPostingEffectiveValues, ...AttendantEffectiveValues]
);
