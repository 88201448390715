import * as appActions from '@hkm/components/App/domain/actions';
import * as batchActions from '@hkm/components/Housekeeping/Batch/domain/actions';
import { ChangeRoomStatusResult } from '@hkm/components/Housekeeping/Batch/domain/interfaces';
import { createHousekeepingApiFilter } from '@hkm/components/Housekeeping/shared/filters/housekeepingApiFilterFactory';
import { HousekeepingBatchStatusChangeData } from '@hkm/components/Housekeeping/shared/models/housekeepingBatchStatusChangeData';
import { hasFailedAction } from '@hkm/shared/helpers/actionResult';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import i18n from 'i18next';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  getDtoForAllPages,
  HousekeepingRoomDetailsDto,
  LibraryApiResponse,
  PageResponse,
  RawPageResponse,
  RoomStatus
} from '@ac/library-api';
import {
  HousekeepingRoomsApi,
  HousekeepingViewsApi
} from '@ac/library-api/dist/api/v0/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';

export function* changeBatchRoomsStatus(
  action: Action<HousekeepingBatchStatusChangeData>
) {
  try {
    const filter = createHousekeepingApiFilter({
      roomRanges: action.payload.roomRanges
    });

    const rooms: PageResponse<
      HousekeepingRoomDetailsDto,
      HousekeepingRoomDetailsDto
    > = yield getDtoForAllPages(
      ({ pageNumber, pageSize }) =>
        HousekeepingViewsApi.getHousekeepingDashboard({
          queryParams: {
            filter,
            pageNumber,
            pageSize
          }
        }) as Promise<
          LibraryApiResponse<RawPageResponse<HousekeepingRoomDetailsDto>>
        >
    );

    // remove those rooms that already have this status
    const filteredRooms = rooms.results.filter(
      (item: HousekeepingRoomDetailsDto) =>
        item.roomStatus!.code !== action.payload.roomStates
    );

    const result: ChangeRoomStatusResult[] = yield all(
      filteredRooms.map(item => {
        return call(changeStatusAction, item, action.payload.roomStates!);
      })
    );

    yield put(batchActions.changeBatchRoomsStatusResult(result));

    // we check also rooms.results because there is possibility that it was removed by filter
    const resultIsEmpty = result.length === 0 && rooms.results.length === 0;

    if (!resultIsEmpty && !hasFailedAction(result)) {
      yield put(
        appActions.displaySuccess(
          i18n.t('HOUSEKEEPING_BATCH.ALL_ROOMS_CHANGED_SUCCESS')
        )
      );
    }

    if (resultIsEmpty) {
      yield put(
        appActions.displayWarning(i18n.t('HOUSEKEEPING_BATCH.ROOMS_NOT_FOUND'))
      );
    }

    yield put(batchActions.changeBatchRoomsStatus.success());
  } catch (e) {
    yield call(displayError);
    yield put(batchActions.changeBatchRoomsStatus.failure(e));
  }
}

export function* changeSingleRoomStatus(
  action: Action<ChangeRoomStatusResult>
) {
  try {
    const result: ChangeRoomStatusResult = yield call(
      changeStatusAction,
      action.payload.item,
      action.payload.statusValue!
    );

    if (!result.error) {
      yield put(batchActions.changeSingleRoomStatus.success(result));
      yield put(
        appActions.displaySuccess(
          i18n.t('HOUSEKEEPING_BATCH.SINGLE_ROOM_CHANGE_SUCCESS', {
            roomNumber: result.item.roomNumber!.code!
          })
        )
      );
    } else {
      yield put(
        appActions.displayError(
          i18n.t('HOUSEKEEPING_BATCH.SINGLE_ROOM_CHANGE_FAILED', {
            roomNumber: result.item.roomNumber!.code!
          })
        )
      );
    }
  } catch (e) {
    yield call(displayError);
  }
}

export function* changeStatusAction(
  room: HousekeepingRoomDetailsDto,
  status: RoomStatus
) {
  const changeRoomResult: ChangeRoomStatusResult = {
    item: room
  };

  try {
    yield HousekeepingRoomsApi.postChangeRoomStatus({
      pathParams: { roomNumber: room.roomNumber!.code! },
      data: {
        statusCode: status
      },
      customConfig: getCustomConfig({
        version: room.housekeepingRoomVersion!
      })
    });
  } catch (e) {
    changeRoomResult.error = e;
  }

  return changeRoomResult;
}

export function* displayError() {
  yield put(
    appActions.displayError(i18n.t('HOUSEKEEPING_BATCH.CHANGE_FAILED'))
  );
}

export default function* housekeepingBatchStatusSagas() {
  yield takeLatest(
    batchActions.changeBatchRoomsStatus.trigger,
    changeBatchRoomsStatus
  );
  yield takeLatest(
    batchActions.changeSingleRoomStatus.trigger,
    changeSingleRoomStatus
  );
}
