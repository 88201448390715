import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OccupancyDiscrepancyModalBody, {
  OccupancyDiscrepancyModalBodyChange
} from '@hkm/components/shared/OccupancyDiscrepancy/Modal/Body/OccupancyDiscrepancyModalBody';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';
import { RoomOccupancy } from '@hkm/shared/roomOccupancy/roomOccupancy';

import {
  AcButton,
  AcButtonPattern
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AcModal,
  AcModalFooter,
  AcModalHeader
} from '@ac/mobile-components/dist/components/modal';
import {
  AlignItems,
  FlexDirection,
  Icon,
  JustifyContent
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';

export interface HousekeepingOccupancyModalProps extends Testable {
  occupancy: RoomOccupancy;
  open: boolean;
  onClose: () => void;
  onSave: (guests: GuestCount) => void;
}
const OccupancyDiscrepancyModal: FC<HousekeepingOccupancyModalProps> = props => {
  const { t } = useTranslation();
  const [state, setState] = useState<OccupancyDiscrepancyModalBodyChange>();

  const onSave = useCallback(() => {
    props.onSave({
      adultCount: state!.adultCount,
      childCount: state!.childCount
    });
    props.onClose();
  }, [props.onSave, props.onClose, state]);

  return (
    <AcModal isOpen={props.open} onClose={props.onClose}>
      <AcModalHeader showCloseButton={true}>
        {t('ROOM_DETAILS.HOUSEKEEPING_OCCUPANCY.LABEL')}
      </AcModalHeader>

      <OccupancyDiscrepancyModalBody
        occupancy={props.occupancy}
        onChange={setState}
      />

      <AcModalFooter>
        <AcFlex
          direction={FlexDirection.column}
          grow={true}
          justifyContent={JustifyContent.center}
          alignItems={AlignItems.center}
        >
          <AcButton onClick={onSave} fullwidth={true} disabled={!state?.valid}>
            <AcButtonContent text={t('GLOBAL.SAVE')} />
          </AcButton>
          <AcButton pattern={AcButtonPattern.Tertiary} onClick={props.onClose}>
            <AcButtonContent icon={Icon.Cancel} text={t('GLOBAL.CANCEL')} />
          </AcButton>
        </AcFlex>
      </AcModalFooter>
    </AcModal>
  );
};

export default memo(OccupancyDiscrepancyModal);
