import { DiscrepancyType } from '@ac/library-api';
import { Color } from '@ac/mobile-components/dist/enums';

const colorMap = new Map<DiscrepancyType, Color>()
  .set(DiscrepancyType.Skip, Color.DiscrepancySkip)
  .set(DiscrepancyType.Sleep, Color.DiscrepancySleep)
  .set(DiscrepancyType.Occupancy, Color.DiscrepancyOccupancy);

export function getDiscrepancyColor(status: DiscrepancyType): Color {
  return colorMap.get(status) || Color.Gray3;
}
