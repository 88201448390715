import { GroupedReservedKinds } from '@hkm/shared/reservedKind/groupedReservedKinds';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

export function doesRoomHaveDoNotMoveFlag(room?: GroupedReservedKinds): boolean {
  return !!room?.currentReservations?.some(doesReservationHaveDoNotMoveFlag);
}

export function doesReservationHaveDoNotMoveFlag(reservation?: UnifiedReservationDetails): boolean {
  return !!reservation?.isRoomNumberFixed;
}
