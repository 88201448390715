import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CountWithDescription from '@hkm/components/Attendant/SheetSummary/body/CountWithDesciption/CountWithDescription';
import SheetsSummaryAccordion from '@hkm/components/Attendant/SheetSummary/body/SheetSummaryAccordion/SheetsSummaryAccordion';
import { SheetSummaryLinenChange } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryLinenChange';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  FlexDirection,
  Icon,
  TextSize,
  TextWeight
} from '@ac/mobile-components/dist/enums';

export interface LinenChangeProps {
  linenChange: SheetSummaryLinenChange;
}

export const LinenChange: React.FC<LinenChangeProps> = (
  props: LinenChangeProps
) => {
  const { t } = useTranslation();
  const { total, roomTypesLinenChange } = props.linenChange;
  const renderSubtitle = useMemo(
    () => (
      <AcText
        size={TextSize.H1}
        weight={TextWeight.Semibold}
        testSelector="linen-change-total"
      >
        {total}
      </AcText>
    ),
    [total]
  );

  const renderContent = useMemo(
    () => (
      <AcFlex direction={FlexDirection.column}>
        {roomTypesLinenChange.map((roomTypeInfo, index) => (
          <CountWithDescription
            key={index}
            count={roomTypeInfo.count}
            description={roomTypeInfo.codeName}
          />
        ))}
      </AcFlex>
    ),
    [roomTypesLinenChange]
  );

  return (
    <SheetsSummaryAccordion
      icon={Icon.LinenChange}
      title={t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.LINEN_CHANGE.TITLE')}
      renderSubtitle={renderSubtitle}
      renderContent={renderContent}
      expandDisabled={total === 0}
    />
  );
};

export default memo(LinenChange);
