import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHousekeepingStates,
  getRoomConditions,
  getRoomStatesMap
} from '@hkm/components/App/domain/selectors';
import * as detailsActions from '@hkm/components/Housekeeping/Details/domain/actions';
import { HousekeepingManagePermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingManagePermissionsConfig';
import { MaintenanceManagePermissionsConfig } from '@hkm/components/Maintenance/config/maintenanceManagePermissionsConfig';
import {
  selectEffectiveValues,
  selectPropertyDateFormats
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import GuestServiceField from '@hkm/components/shared/GuestService/GuestServiceField';
import { GuestServiceModalFormState } from '@hkm/components/shared/GuestService/modal/form/useFormState';
import NextUseTile from '@hkm/components/shared/NextUse/NextUseTile';
import OccupancyDiscrepancyButton from '@hkm/components/shared/OccupancyDiscrepancy/OccupancyDiscrepancyButton';
import ReservationsMovementsInfo from '@hkm/components/shared/ReservationMovements/ReservationsMovementsInfo';
import RoomStatusBadge from '@hkm/components/shared/RoomStatusBadge/RoomStatusBadge';
import * as maintenanceAttachmentsActions from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/domain/actions';
import { AttachmentMangeAccessLevel } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/enum/attachmentMangeAccessLevel';
import { MaintenanceAttachmentData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/maintenanceAttachmentData';
import MaintenanceAttachmentsTile from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/MaintenanceAttachmentsTile';
import MaintenanceDetailsTile from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/MaintenanceDetailsTile';
import * as updateMaintenanceActions from '@hkm/components/shared/Templates/Maintenance/shared/domain/actions';
import GuestReservationDetailsTemplate from '@hkm/components/shared/Templates/Reservation/GuestReservationDetails/GuestReservationDetailsTemplate';
import SharedReservationDetailsTemplate from '@hkm/components/shared/Templates/Reservation/SharedReservationDetails/SharedReservationDetailsTemplate';
import { hasReservationSharedGuest } from '@hkm/components/shared/Templates/Reservation/utils/reservationUtils';
import {
  DictionaryLabelLength,
  extractDictionaryEntryLabel
} from '@hkm/shared/dictionaries/dictionaryItemsLabelExtractor';
import { isGreenServiceObtainableByRoom } from '@hkm/shared/domain/greenServiceToggle/utils';
import {
  AddMaintenanceAttachmentData,
  RemoveMaintenanceAttachmentData,
  UpdateMaintenanceAttachmentData
} from '@hkm/shared/domain/maintenanceAttachment/uploadAttachement/models/maintenanceUploadAttachmentData';
import { getDiscrepancyColor } from '@hkm/shared/helpers/getDiscrapencyColor';
import { useEnabledRoomStates } from '@hkm/shared/hooks/useEnabledRoomStates';
import { useGroupedReservationNotes } from '@hkm/shared/hooks/useGroupedReservationNotes';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { useReservationMovement } from '@hkm/shared/reservations/reservationMovementHooks';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import { createTimeWithTimezone } from '@hkm/utils/dateHelper';
import { getLocalizedString } from '@hkm/utils/getLocalizedString';

import {
  DictionaryEntity,
  DiscrepancyType,
  HousekeepingStatus,
  RawGenericEntity,
  ReservationStatus,
  RoomMaintenancesAttachmentPathParam,
  RoomStatus,
  UpdateMaintenances
} from '@ac/library-api';
import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { AcBox } from '@ac/mobile-components/dist/components/box';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AcFormElement,
  AcFormGroup
} from '@ac/mobile-components/dist/components/form-element';
import { AcMultiSelect } from '@ac/mobile-components/dist/components/multiselect';
import { AcSelect } from '@ac/mobile-components/dist/components/select';
import { AcSelectValue } from '@ac/mobile-components/dist/components/select/interfaces/AcSelectValue';
import { AcSwitch } from '@ac/mobile-components/dist/components/switch';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AcTile,
  AcTileGroup
} from '@ac/mobile-components/dist/components/tile';
import {
  AlignItems,
  Color,
  Icon,
  JustifyContent,
  TextSize,
  TextWeight
} from '@ac/mobile-components/dist/enums';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

import './HousekeepingDetailsBody.css';

export interface HousekeepingDetailsBodyProps {
  room: HousekeepingRoom;
  maintenanceAttachments: MaintenanceAttachmentData[];
}

/* tslint:disable:jsx-no-lambda */
const HousekeepingDetailsBody: FC<HousekeepingDetailsBodyProps> = (
  props: HousekeepingDetailsBodyProps
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const testSelectorPrefix = 'housekeeping-details';

  const reservation = props.room.currentMainReservation!;
  const hasReservation = !!reservation;
  const hasReservationShared: boolean = hasReservationSharedGuest(props.room);
  const hasMaintenanceManagePermission = usePermission(
    ...MaintenanceManagePermissionsConfig
  );
  const maintenanceAttachmentPermission: AttachmentMangeAccessLevel = hasMaintenanceManagePermission
    ? AttachmentMangeAccessLevel.All
    : AttachmentMangeAccessLevel.None;

  const { moveIn, moveOut } = useReservationMovement(
    props.room,
    r => r.status!.code as ReservationStatus
  );
  const hasReservationMovement = !!moveIn || !!moveOut;

  const hasMaintenance = props.room.currentMaintenance;
  const effectiveValues = useSelector(selectEffectiveValues)!;
  const formats = useSelector(selectPropertyDateFormats);

  const isGreenServiceObtainable = isGreenServiceObtainableByRoom(
    props.room,
    r => r.status!.code as ReservationStatus
  );
  const isGreenServiceEnabled = effectiveValues.greenService;
  const isOccupancyDiscrepancyEnabled = effectiveValues.occupancyDiscrepancy;

  const isReadonly = !usePermission(...HousekeepingManagePermissionsConfig);

  const isRoomConditionsFunctionalityEnabled =
    effectiveValues.enableRoomConditions;
  const roomConditions = (useSelector(getRoomConditions) || [])
    .filter(({ isActive }) => isActive)
    .map((state: RawGenericEntity) => ({
      value: state.id!,
      itemLabel: `${state.code} - ${getLocalizedString(state.description)}`
    }));
  const selectedRoomConditions = (props.room.roomConditions || []).map(
    ({ id }) => id!
  );

  const roomStatesMap = useSelector(getRoomStatesMap);
  const roomStates: AcSelectValue[] = useEnabledRoomStates().map(
    (state: DictionaryEntity) => ({
      value: state.code!,
      itemLabel: extractDictionaryEntryLabel(state, DictionaryLabelLength.Long)
    })
  );

  const selectedRoomState: string = props.room.roomStatus!.code!;
  const selectedRoomStateObject = roomStatesMap.get(selectedRoomState);

  const housekeepingStates: AcSelectValue[] = useSelector(
    getHousekeepingStates
  )!.map((state: DictionaryEntity) => ({
    value: state.code!,
    itemLabel: state.name!
  }));

  const selectedHousekeepingStatus: string = props.room.housekeepingStatus!
    .code!;

  const onGuestServiceChangeChange = useCallback(
    (guestServiceModalFormState: GuestServiceModalFormState) => {
      dispatch(
        detailsActions.guestService.changeGuestServiceStatus.trigger({
          untilTime: guestServiceModalFormState.serviceDeferredUntil,
          roomId: props.room.id,
          currentStatus: guestServiceModalFormState.initialServiceType,
          instruction: guestServiceModalFormState.instruction,
          afterTime: guestServiceModalFormState.servicePreferredAfterTime,
          newStatus: guestServiceModalFormState.serviceType,
          versionId: props.room.housekeepingRoomVersion!
        })
      );
    },
    [dispatch, props.room.housekeepingRoomVersion, props.room.id]
  );

  const onRoomStatusChange = useCallback(
    (newStatus: RoomStatus) =>
      dispatch(
        detailsActions.roomStatus.changeRoomStatus.trigger({
          roomNumber: props.room.roomNumber!.code!,
          roomStatus: newStatus,
          housekeepingRoomVersion: props.room.housekeepingRoomVersion!,
          roomId: props.room.id
        })
      ),
    [props.room]
  );

  const onRoomConditionsChange = useCallback(
    (newSelectedConditions: string[]) => {
      const conditionsToRemove = selectedRoomConditions.filter(
        id => !newSelectedConditions.includes(id)
      );
      const conditionsToAdd = newSelectedConditions.filter(
        id => !selectedRoomConditions.includes(id)
      );

      dispatch(
        detailsActions.roomConditions.changeRoomConditions.trigger({
          roomId: props.room.id,
          roomConditions: {
            add: conditionsToAdd,
            remove: conditionsToRemove
          },
          housekeepingRoomVersion: props.room.housekeepingRoomVersion || 0
        })
      );
    },
    [props.room.housekeepingRoomVersion, props.room.id]
  );

  const onHousekeepingStatusChange = useCallback(
    (newStatus: HousekeepingStatus) =>
      dispatch(
        detailsActions.housekeepingStatus.changeHousekeepingStatus.trigger({
          roomNumber: props.room.roomNumber!.code!,
          housekeepingStatus: newStatus,
          housekeepingRoomVersion: props.room.housekeepingRoomVersion!,
          roomId: props.room.id
        })
      ),
    [props.room]
  );

  const toggleGreenService = useCallback(
    () =>
      dispatch(
        detailsActions.greenService.toggleGreenService.trigger({
          roomId: props.room.id,
          hasGreenService: props.room.greenService,
          roomVersion: props.room.housekeepingRoomVersion!
        })
      ),
    [props.room]
  );

  const onMaintenanceUpdate = useCallback(
    (updateMaintenances: UpdateMaintenances) => {
      dispatch(
        updateMaintenanceActions.updateMaintenanceActionsSet.updateMaintenance.trigger(
          {
            data: updateMaintenances,
            roomId: props.room.unifiedRoomDetails.id,
            roomNumber: props.room.unifiedRoomDetails.roomNumber!,
            housekeepingRoomVersion: props.room.unifiedRoomDetails
              .housekeepingRoomVersion!
          }
        )
      );
    },
    [props.room]
  );

  const onRoomOccupancySave = useCallback(
    (count: GuestCount) =>
      dispatch(detailsActions.roomOccupancy.setRoomOccupancy.trigger(count)),
    []
  );

  const onRoomOccupancyDelete = useCallback(
    () => dispatch(detailsActions.roomOccupancy.removeRoomOccupancy.trigger()),
    []
  );

  const onMaintenanceAttachmentAdd = useCallback(
    (data: AddMaintenanceAttachmentData) =>
      dispatch(
        maintenanceAttachmentsActions.uploadAttachmentActionsSet.addAttachment.trigger(
          data
        )
      ),
    []
  );

  const onMaintenanceAttachmentRemove = useCallback(
    (data: RemoveMaintenanceAttachmentData) =>
      dispatch(
        maintenanceAttachmentsActions.uploadAttachmentActionsSet.removeAttachment.trigger(
          data
        )
      ),
    []
  );

  const onMaintenanceAttachmentUpdate = useCallback(
    (data: UpdateMaintenanceAttachmentData) =>
      dispatch(
        maintenanceAttachmentsActions.uploadAttachmentActionsSet.updateAttachment.trigger(
          data
        )
      ),
    []
  );

  const onMaintenanceAttachmentRequest = useCallback(
    (data: RoomMaintenancesAttachmentPathParam) =>
      dispatch(
        maintenanceAttachmentsActions.uploadAttachmentActionsSet.getAttachmentFile.trigger(
          data
        )
      ),
    []
  );

  const groupedReservationNotes = useGroupedReservationNotes(
    props.room.currentReservationsIds || []
  );

  const desiredTime = useMemo(
    () => props.room.queueRoomEntries?.[0]?.desiredTime,
    [props.room.queueRoomEntries]
  );
  const formattedDesiredTime = useMemo(
    () =>
      desiredTime && createTimeWithTimezone(desiredTime).format(formats.time),
    [desiredTime, formats.time]
  );
  const isDesiredTimeEnabled = useMemo(() => effectiveValues.enabledReadyBy, [
    effectiveValues.enabledReadyBy
  ]);

  return (
    <div className="housekeeping-details-body">
      <AcTileGroup>
        <AcTile title={t('ROOM_DETAILS.ROOM_INFORMATION')} icon={Icon.Room}>
          <AcFormGroup>
            {hasReservationMovement && (
              <ReservationsMovementsInfo small={true} />
            )}
            <AcFlex>
              <AcFormElement
                label={t('GLOBAL.ROOM_NUMBER.LONG')}
                testSelector={formatTestSelector(
                  testSelectorPrefix,
                  'roomNumber'
                )}
              >
                {props.room.roomNumber!.code}
              </AcFormElement>

              {isDesiredTimeEnabled && desiredTime && (
                <AcFormElement
                  className="ready-by-time-room-details ac-spacing-left-lg"
                  label={t('ATTENDANT_ASSIGNMENTS.DESIRED_TIME')}
                >
                  {formattedDesiredTime}
                </AcFormElement>
              )}
            </AcFlex>

            <AcFormElement
              label={t('GLOBAL.ROOM_TYPE.LONG')}
              testSelector={formatTestSelector(testSelectorPrefix, 'roomType')}
            >
              {t('ROOM_DETAILS.ROOM_TYPE_FORMAT', {
                code: props.room.roomType!.code,
                name: props.room.roomType!.description
              })}
            </AcFormElement>
            <AcFormElement
              label={t('GLOBAL.FRONT_DESK_STATUS.LONG')}
              testSelector={formatTestSelector(testSelectorPrefix, 'frontdesk')}
            >
              {props.room.frontdeskStatus!.description}
            </AcFormElement>

            {isOccupancyDiscrepancyEnabled && (
              <OccupancyDiscrepancyButton
                testSelector={testSelectorPrefix}
                occupancy={props.room.roomOccupancy}
                onSave={onRoomOccupancySave}
                onDelete={onRoomOccupancyDelete}
              />
            )}
          </AcFormGroup>
        </AcTile>

        {hasReservation && !hasReservationShared && (
          <GuestReservationDetailsTemplate
            roomId={props.room.id}
            reservation={reservation}
            groupedReservationNotes={groupedReservationNotes}
          />
        )}

        {hasReservationShared && (
          <SharedReservationDetailsTemplate
            roomId={props.room.id}
            groupedReservationNotes={groupedReservationNotes}
            reservations={props.room.currentReservations || []}
          />
        )}

        {hasMaintenance && (
          <>
            <MaintenanceDetailsTile
              onSubmit={onMaintenanceUpdate}
              readonly={!hasMaintenanceManagePermission}
              unifiedRoomDetails={props.room.unifiedRoomDetails}
              maintenanceDetails={props.room.currentMaintenance!}
            />
            <MaintenanceAttachmentsTile
              manageAccessLevel={maintenanceAttachmentPermission}
              attachments={props.maintenanceAttachments}
              roomId={props.room.id}
              testSelector="maintenanceAttachmentsTile"
              roomVersionId={props.room.housekeepingRoomVersion!}
              maintenanceId={props.room.currentMaintenance!.id}
              onAdd={onMaintenanceAttachmentAdd}
              onUpdate={onMaintenanceAttachmentUpdate}
              onFileRequest={onMaintenanceAttachmentRequest}
              onRemove={onMaintenanceAttachmentRemove}
            />
          </>
        )}

        <AcTile title={t('ROOM_DETAILS.HOUSEKEEPING')} icon={Icon.Housekeeping}>
          <AcFormGroup>
            <AcFlex alignItems={AlignItems.flexEnd}>
              <AcBox style={{ flexBasis: '100%' }}>
                {isReadonly ? (
                  <AcFormElement
                    label={t('GLOBAL.HOUSEKEEPING_STATUS.MEDIUM')}
                    testSelector={`${testSelectorPrefix}-hk-status`}
                  >
                    {props.room.housekeepingStatus!.description}
                  </AcFormElement>
                ) : (
                  <AcSelect
                    required={true}
                    showInputs={true}
                    label={t('GLOBAL.HOUSEKEEPING_STATUS.MEDIUM')}
                    onChange={onHousekeepingStatusChange}
                    itemsList={housekeepingStates}
                    selectedItem={selectedHousekeepingStatus}
                    testSelector={`${testSelectorPrefix}-hk-status`}
                  />
                )}
              </AcBox>
              {props.room.discrepancy &&
                props.room.discrepancy.code !== DiscrepancyType.Occupancy && (
                  <AcBadge
                    className="housekeeping-details-body-discrepancy-badge"
                    tileUppercase={true}
                    badgeText={t(
                      `GLOBAL.DISCREPANCY.${props.room.discrepancy.code}`
                    )}
                    backgroundColor={getDiscrepancyColor(
                      props.room.discrepancy.code as DiscrepancyType
                    )}
                  />
                )}
            </AcFlex>

            {isReadonly ? (
              <AcFormElement
                label={t('GLOBAL.ROOM_STATUS.LONG')}
                testSelector={`${testSelectorPrefix}-room-status`}
              >
                {extractDictionaryEntryLabel(
                  selectedRoomStateObject,
                  DictionaryLabelLength.Long
                )}
              </AcFormElement>
            ) : (
              <AcSelect
                showInputs={true}
                label={t('GLOBAL.ROOM_STATUS.LONG')}
                onChange={onRoomStatusChange}
                itemsList={roomStates}
                selectedItem={selectedRoomState}
                testSelector={`${testSelectorPrefix}-room-status`}
                unlistedValueLabel={extractDictionaryEntryLabel(
                  selectedRoomStateObject,
                  DictionaryLabelLength.Long
                )}
                itemTemplate={item => (
                  <RoomStatusBadge
                    status={item.value as RoomStatus}
                    showLabel={true}
                  />
                )}
                required={true}
              />
            )}

            <GuestServiceField
              isReadonly={isReadonly}
              testSelector={testSelectorPrefix}
              onChange={onGuestServiceChangeChange}
              room={props.room}
            />

            {isRoomConditionsFunctionalityEnabled ? (
              <AcMultiSelect
                placeholder={t('GLOBAL.SELECT')}
                label={t('ROOM_DETAILS.ROOM_CONDITIONS')}
                itemsList={roomConditions}
                selectedItem={selectedRoomConditions}
                testSelector={`${testSelectorPrefix}-room-conditions`}
                showInputs
                showSearch
                onChange={onRoomConditionsChange}
              />
            ) : (
              <></>
            )}

            {isGreenServiceObtainable && isGreenServiceEnabled && (
              <AcFlex
                alignItems={AlignItems.center}
                justifyContent={JustifyContent.spaceBetween}
              >
                <AcText
                  color={Color.Gray1}
                  size={TextSize.Main2}
                  weight={TextWeight.Semibold}
                >
                  {t('GLOBAL.GREEN_SERVICE.TITLE')}
                </AcText>
                <AcSwitch
                  onChange={toggleGreenService}
                  disabled={isReadonly}
                  state={props.room.greenService}
                  selected={props.room.greenService}
                  testSelector={`${testSelectorPrefix}-green-service-switch`}
                />
              </AcFlex>
            )}
          </AcFormGroup>
        </AcTile>

        <NextUseTile
          groupedReservedKinds={props.room}
          unifiedRoomDetails={props.room.unifiedRoomDetails}
        />
      </AcTileGroup>
    </div>
  );
};

export default memo(HousekeepingDetailsBody);
