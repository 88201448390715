import { ImageUrlMap } from '@hkm/components/App/imageQueue/domain/interfaces';

export function refreshImageUrlMap(imageQueue: string[], cachedImageUrlMap: ImageUrlMap, newImageUrlMap: ImageUrlMap): ImageUrlMap {
  const mergedImageUrlMap = { ...cachedImageUrlMap, ...newImageUrlMap };

  return Object.entries(mergedImageUrlMap).reduce((imageUrlMap: ImageUrlMap, [key, value]) => {
    if (imageQueue.includes(key)) {
      imageUrlMap[key] = value;
    } else if (value) {
      URL.revokeObjectURL(value!);
    }

    return imageUrlMap;
  }, {} as ImageUrlMap);
}
