import { Filters } from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import { affectedFieldsByQuickFilterMap, FilterFields } from '@hkm/components/TaskManagement/utils/quickFilter';

export const getFiltersDefaultStateWithQuickFilter = (quickFilter: TaskQuickFilter, filtersState: Filters): Filters => {
  if (quickFilter === TaskQuickFilter.DUE_TODAY) {
    return {
      [FilterFields.DateFrom]: filtersState.dateFrom,
      [FilterFields.DateTo]: filtersState.dateTo,
      [FilterFields.TimeFrom]: filtersState.timeFrom,
      [FilterFields.TimeTo]: filtersState.timeTo
    };
  }

  if (quickFilter === TaskQuickFilter.MY_DEPARTMENT_TASKS) {
    return {
      [FilterFields.Departments]: filtersState.departments
    };
  }

  if (quickFilter === TaskQuickFilter.MY_TASKS) {
    return {
      [FilterFields.Assignee]: filtersState.assignees
    };
  }

  return { [FilterFields.Areas]: filtersState.areas };
};

export const getFieldsExcludedByQuickFilter = (quickFilter: TaskQuickFilter) => affectedFieldsByQuickFilterMap[quickFilter];
