import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate } from 'react-router';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { HousekeepingPostingPermissionsConfig } from '@hkm/components/Posting/shared/config/postingPermissionsConfig';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { SourceModuleType } from '@hkm/shared/enum/SourceModuleType';
import { PostChargeLocationState } from '@hkm/shared/interfaces/locationStates';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { activeReservationStates } from '@hkm/shared/reservations/activeReservationStatuses';

import { ReservationStatus } from '@ac/library-api';
import {
  AcButton,
  AcButtonPattern
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import AcMobileContext from '@ac/mobile-components/dist/config';
import { Color, Icon, JustifyContent } from '@ac/mobile-components/dist/enums';

interface PostChargeButtonProps {
  roomId: string;
  reservationId: string;
  reservationStatus: ReservationStatus;
}

const PostChargeButton: FC<PostChargeButtonProps> = ({
  roomId,
  reservationId,
  reservationStatus
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const appContext = useContext(AcMobileContext)!;
  const isMobilePostingOn = useSelector(selectEffectiveValues)!
    .enabledHousekeepingMobilePosting;
  const hasPermission = usePermission(...HousekeepingPostingPermissionsConfig);
  const isPostingReservation = useMemo(
    () => activeReservationStates.has(reservationStatus),
    [reservationStatus]
  );
  const showPostChargeButton =
    isMobilePostingOn && hasPermission && isPostingReservation;

  const postChargeHandle = useCallback(() => {
    const modulePath =
      appContext.currentModuleName === SourceModuleType.Attendant
        ? APP_ROUTES.MOBILE_POSTING.ATTENDANT.POSTING_VIEW
        : APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.POSTING_VIEW;

    navigate(
      generatePath(modulePath, {
        roomId,
        reservationId
      }),
      {
        state: {
          returnPath: location.pathname
        } as PostChargeLocationState
      }
    );
  }, [roomId, reservationId, appContext.currentModuleName]);

  return (
    <>
      {showPostChargeButton && (
        <AcFlex
          justifyContent={JustifyContent.center}
          className="ac-spacing-top-s"
        >
          <AcButton
            fullwidth={true}
            pattern={AcButtonPattern.Tertiary}
            onClick={postChargeHandle}
          >
            <AcButtonContent
              icon={Icon.PostingCharge}
              text={t('POSTING.RESERVATION_LIST.POSTING_CHARGES')}
              iconColor={Color.PrimaryDark}
            />
          </AcButton>
        </AcFlex>
      )}
    </>
  );
};

export default memo(PostChargeButton);
