import { MaintenanceFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/model/maintenanceFormData';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';
import { dayjs } from '@hkm/utils/dayjs-extended';

import { IdCodeDescription, RoomStatus } from '@ac/library-api';

export function getInitialData(
  maintenanceRoom: Partial<MaintenanceRoom>,
  businessDate: string
): MaintenanceFormData {
  return {
    roomNumbers: maintenanceRoom.room ? maintenanceRoom.room!.code : '',
    comment: maintenanceRoom.comment ? maintenanceRoom.comment : '',
    fromTime: maintenanceRoom.fromTime
      ? getOlderDate(maintenanceRoom.fromTime, businessDate)
      : '',
    toTime: maintenanceRoom.toTime
      ? getOlderDate(maintenanceRoom.toTime, businessDate)
      : '',
    reasonCode: maintenanceRoom.reason ? maintenanceRoom.reason!.code : '',
    returnStatusCode: maintenanceRoom.returnRoomStatus
      ? getStatusCode(maintenanceRoom.returnRoomStatus)
      : undefined,
    statusCode: maintenanceRoom.status ? maintenanceRoom.status!.code : '',
    businessDate
  };
}

function getStatusCode(returnRoomStatus: IdCodeDescription): RoomStatus {
  return [RoomStatus.IN, RoomStatus.PU].includes(
    returnRoomStatus!.code! as RoomStatus
  )
    ? RoomStatus.DI
    : (returnRoomStatus!.code! as RoomStatus);
}

function getOlderDate(date1: string, date2: string) {
  return dayjs(date1).isBefore(date2) ? date2 : date1;
}
