import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import MaintenanceFormValidationConflict from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/Validation/MaintenanceFormValidationConflict';
import { MaintenanceFormValidationConflictData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/Validation/maintenanceFormValidationConflictData';

import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import {
  AcModal,
  AcModalBody,
  AcModalHeader,
  AcModalPattern
} from '@ac/mobile-components/dist/components/modal';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  Color,
  Icon,
  IconSize,
  TextSize
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

import './MaintenanceFormValidationModal.css';

interface Props extends Testable {
  conflicts: MaintenanceFormValidationConflictData[];
  isOpen: boolean;
  onClose: () => void;
}

const MaintenanceFormValidationModal: FC<Props> = props => {
  const { t } = useTranslation();

  return (
    <AcModal
      className="maintenance-form-validation-modal"
      isOpen={props.isOpen}
      onClose={props.onClose}
      pattern={AcModalPattern.Drawer}
      testSelector={props.testSelector}
    >
      <AcModalHeader showCloseButton={true}>
        {t('MAINTENANCE_CREATE.VALIDATION.MODAL.TITLE')}
      </AcModalHeader>

      <AcModalBody className="maintenance-form-validation-modal-body">
        <div className="maintenance-form-validation-modal-counter">
          <AcIcon
            icon={Icon.Todo}
            color={Color.Warning}
            className="ac-spacing-right-sm"
            size={IconSize.Medium}
          />
          <AcText size={TextSize.Main2} equalLineHeight={true}>
            {t('MAINTENANCE_CREATE.VALIDATION.MODAL.CONFLICTS', {
              count: props.conflicts.length
            })}
          </AcText>
        </div>

        {props.conflicts.map((conflict, index) => (
          <MaintenanceFormValidationConflict
            testSelector={formatTestSelector(
              props.testSelector,
              'conflict',
              index.toString()
            )}
            key={index}
            conflict={conflict}
            className="maintenance-form-validation-modal-conflict"
          />
        ))}
      </AcModalBody>
    </AcModal>
  );
};

export default memo(MaintenanceFormValidationModal);
