import { MaintenanceSort } from '@hkm/components/Maintenance/shared/enum/maintenanceSort';
import { MaintenanceFiltersData } from '@hkm/components/Maintenance/shared/models/maintenanceFiltersData';
import { createPaginationActions } from '@hkm/store/pagination/paginationActionsFactory';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';

import { RawRoomAssignmentMaintenanceDetails } from '@ac/library-api';
import { createAction } from '@ac/library-utils/dist/redux-utils';

export const pagination = createPaginationActions<
  RawRoomAssignmentMaintenanceDetails,
  MaintenanceRoom,
  MaintenanceSort,
  MaintenanceFiltersData
>('maintenance/dashboard');
export const patchChangedMaintenance = createAction<string>(
  'maintenance/dashboard/patchChangedMaintenance'
);
export const replaceChangedMaintenance = createAction<MaintenanceRoom>(
  'maintenance/dashboard/replaceChangedMaintenance'
);
