import { CustomDictionaryConfig } from '@hkm/shared/dictionaries/dictionariesConfig';
import { Dictionary } from '@hkm/shared/dictionaries/dictionary';

import { buildFIQLFilter, FIQLOperators } from '@ac/library-api';
import { RoomTypeApi } from '@ac/library-api/dist/api/v0/configuration/handcraftedEntities';

export const HousekeepingAdvancedFiltersDictionaries: Set<
  Dictionary | CustomDictionaryConfig
> = new Set<Dictionary | CustomDictionaryConfig>()
  .add(Dictionary.HousekeepingStatus)
  .add(Dictionary.HousekeepingSection)
  .add(Dictionary.RoomLocation)
  .add(Dictionary.Floor)
  .add(Dictionary.RoomCategory)
  .add(Dictionary.RoomCondition)
  .add({
    api: RoomTypeApi,
    filter: buildFIQLFilter('isActive', FIQLOperators.equal, true),
    dictionaryName: Dictionary.RoomType
  })
  .add(Dictionary.FrontDeskStatus);
