import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useExtractGuestFullName } from '@hkm/shared/hooks/useExtractGuestFullName';

import { HousekeepingRoomReservationGuestPersonalDataDetailsDto } from '@ac/library-api';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  Color,
  FlexDirection,
  Icon,
  TextSize
} from '@ac/mobile-components/dist/enums';

interface GuestNameTemplateProps {
  guestPersonalData?: HousekeepingRoomReservationGuestPersonalDataDetailsDto;
  hasDisabilities?: boolean;
  vipBadgeColor?: string;
  customGuestName?: string;
}

const defaultProps: GuestNameTemplateProps = {
  customGuestName: ''
};

const GuestNameTemplate: FC<GuestNameTemplateProps> = (
  props: GuestNameTemplateProps
) => {
  const { t } = useTranslation();

  const hasName = !!props.guestPersonalData;
  const hasAltName = hasName && !!props.guestPersonalData!.alternateName;
  const formattedName = hasName
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useExtractGuestFullName(props.guestPersonalData!.name)
    : props.customGuestName;
  const formattedAltName =
    hasAltName &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useExtractGuestFullName(props.guestPersonalData!.alternateName!);

  return (
    <AcFlex direction={FlexDirection.column}>
      <AcText color={Color.Black} size={TextSize.Main1}>
        {formattedName}
        {props.hasDisabilities && (
          <AcIndicator
            className="ac-spacing-left-sm"
            icon={Icon.Disability}
            backgroundColor={Color.Alert}
          />
        )}
        {!!props.vipBadgeColor && (
          <AcIndicator
            className="ac-spacing-left-xs"
            backgroundColor={props.vipBadgeColor}
            icon={Icon.Vip}
          />
        )}
      </AcText>
      {hasAltName && (
        <AcText color={Color.Black} size={TextSize.Main1}>
          {t('GLOBAL.IN_BRACKETS', { text: formattedAltName })}
        </AcText>
      )}
    </AcFlex>
  );
};

GuestNameTemplate.defaultProps = defaultProps;

export default memo(GuestNameTemplate);
