import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DatetimeFormElement, {
  DatetimeVariant
} from '@hkm/components/shared/DatetimeFormElement/DatetimeFormElement';
import DayUseBadge from '@hkm/components/shared/DayUseBadge/DayUseBadge';
import ReservationsMovementsLabel from '@hkm/components/shared/ReservationMovements/ReservationMovementsLabel';
import ReservationsMovementsInfo from '@hkm/components/shared/ReservationMovements/ReservationsMovementsInfo';
import { useReservationMovement } from '@hkm/shared/reservations/reservationMovementHooks';
import { GroupedReservedKinds } from '@hkm/shared/reservedKind/groupedReservedKinds';
import classNames from 'classnames';

import { ReservationStatus } from '@ac/library-api';
import { AcBox } from '@ac/mobile-components/dist/components/box';
import { AcFormRow } from '@ac/mobile-components/dist/components/form-element';
import { JustifyContent } from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';

import './ReservationMovementDashboardItem.css';

interface ReservationMovementDashboardItemProps extends Testable {
  room: GroupedReservedKinds;
  className?: string;
}

export const ReservationMovementDashboardItem = ({
  room,
  testSelector,
  className: classNameProp
}: ReservationMovementDashboardItemProps): JSX.Element | null => {
  const { t } = useTranslation();
  const className = classNames('reservation-movements', classNameProp);
  const { moveIn, moveOut } = useReservationMovement(
    room,
    r => r.status!.code as ReservationStatus,
    { noTimeLabel: true }
  );

  const hasReservationMovement = moveIn || moveOut;
  const hasSingleDayUseReservationMovement =
    hasReservationMovement &&
    !!(moveIn ? !moveOut : moveOut) &&
    (moveIn || moveOut)?.isDayUse;
  const showReservationsMovement =
    hasReservationMovement && !hasSingleDayUseReservationMovement;
  const showMoveInMovement = showReservationsMovement && moveIn;
  const showMoveOutMovement = showReservationsMovement && moveOut;

  const useDayProps = useMemo(
    () => ({
      timeLabel: <DayUseBadge />,
      className: 'has-day-use'
    }),
    []
  );

  if (!hasSingleDayUseReservationMovement && !showReservationsMovement) {
    return null;
  }

  return (
    <div className={className}>
      {hasSingleDayUseReservationMovement && (
        <>
          <AcFormRow className="ac-spacing-top-s">
            <DayUseBadge />
            <DatetimeFormElement
              smallText={true}
              smallLabel={true}
              timeLabel={t('GLOBAL.ETA')}
              variant={DatetimeVariant.onlyTime}
              datetime={(moveIn || moveOut)?.eta || ''}
              flatAndJustify={JustifyContent.flexStart}
            />
            <DatetimeFormElement
              smallText={true}
              smallLabel={true}
              timeLabel={t('GLOBAL.ETD')}
              variant={DatetimeVariant.onlyTime}
              datetime={(moveIn || moveOut)?.etd || ''}
              flatAndJustify={JustifyContent.flexStart}
            />
          </AcFormRow>
        </>
      )}
      {showReservationsMovement && (
        <>
          <ReservationsMovementsInfo
            small={true}
            className="ac-spacing-top-sm"
            testSelector={testSelector}
          />
          <AcFormRow className="ac-spacing-top-s">
            {showMoveOutMovement && (
              <AcBox size={6}>
                <DatetimeFormElement
                  {...moveOut!}
                  label={
                    <ReservationsMovementsLabel
                      label={moveOut?.label ?? ''}
                      isEtaEtdGuaranteed={moveOut?.isEtaEtdGuaranteed ?? false}
                    />
                  }
                  smallLabel={true}
                  smallText={true}
                  testSelector={testSelector}
                  {...(moveOut!.isDayUse ? useDayProps : {})}
                />
              </AcBox>
            )}
            {showMoveInMovement && (
              <AcBox size={6}>
                <DatetimeFormElement
                  {...moveIn!}
                  label={
                    <ReservationsMovementsLabel
                      label={moveIn?.label ?? ''}
                      isEtaEtdGuaranteed={moveIn?.isEtaEtdGuaranteed ?? false}
                    />
                  }
                  smallLabel={true}
                  smallText={true}
                  testSelector={testSelector}
                  {...(moveIn!.isDayUse ? useDayProps : {})}
                />
              </AcBox>
            )}
          </AcFormRow>
        </>
      )}
    </div>
  );
};
