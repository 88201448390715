import React, { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/Menu/domain/actions';
import { MenuBehaviour } from '@hkm/components/Menu/domain/interfaces';
import {
  selectMenuBehaviour,
  selectMenuState
} from '@hkm/components/Menu/domain/selectors';
import SidebarMenu from '@hkm/components/Menu/SidebarMenu/SidebarMenu';
import classNames from 'classnames';

import { useBodyClass } from '@ac/mobile-components/dist/hooks';

import './MenuContainer.css';

const MENU_OPENED = 'menu-opened';
export const disabledMenuSwipeClass = 'disable-menu-swap';

const MenuContainer: FC = () => {
  const dispatch = useDispatch();

  const behaviour = useSelector(selectMenuBehaviour);
  const state = useSelector(selectMenuState);
  const isOpen = behaviour === MenuBehaviour.Force ? true : state;

  useBodyClass(MENU_OPENED, !isOpen);

  const isEventNotBlocked = (event: any) =>
    !document.body.classList.contains('ac-modal-global-open') &&
    !event.target.closest('.' + disabledMenuSwipeClass) &&
    behaviour === MenuBehaviour.Enable;

  const showMenu = (event: any) => {
    if (isEventNotBlocked(event)) {
      dispatch(actions.setMenuState(true));
    }
  };

  const hideMenu = (event: any) => {
    if (isEventNotBlocked(event)) {
      dispatch(actions.setMenuState(false));
    }
  };

  useEffect(() => {
    document.addEventListener('swiped-left', hideMenu);
    document.addEventListener('swiped-right', showMenu);

    return () => {
      document.removeEventListener('swiped-left', hideMenu);
      document.removeEventListener('swiped-right', showMenu);
    };
  });

  const className = classNames(
    'menu-container',
    behaviour === MenuBehaviour.Force && 'menu-container-forced'
  );

  return (
    <div className={className}>
      <SidebarMenu />
    </div>
  );
};

export default memo(MenuContainer);
