import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DictionaryEntry } from '@hkm/components/App/domain/interfaces/DictionariesStore';
import {
  AdvancedFiltersMultiSelectConfig,
  ProcessedAdvancedFiltersMultiSelectConfig
} from '@hkm/components/shared/AdvancedFilters/Multiselects/advancedFiltersMultiSelectsConfig';
import {
  DictionaryLabelLength,
  extractDictionaryEntryLabel
} from '@hkm/shared/dictionaries/dictionaryItemsLabelExtractor';

import { AcMultiSelect } from '@ac/mobile-components/dist/components/multiselect';
import {
  Childless,
  Styleable
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { genericMemo } from '@ac/mobile-components/dist/utils';
import { formFieldFactory, FormRenderProps } from '@ac/react-infrastructure';

interface AdvancedFiltersMultiSelectsProps<T> extends Childless, Styleable {
  configs: Array<AdvancedFiltersMultiSelectConfig<T>>;
  testSelectorPrefix: string;
  formProps: FormRenderProps<T>;
}

const MINIMUM_ITEMS_FOR_SEARCH: number = 5;

function AdvancedFiltersMultiSelects<T>(
  props: AdvancedFiltersMultiSelectsProps<T>
): ReactElement {
  const { t } = useTranslation();
  const FormField = useMemo(() => formFieldFactory<T>(), []);

  const getItemLabel = useCallback((field: keyof T, item: DictionaryEntry) => {
    if (field === 'guestServiceStatuses') {
      return t(`GLOBAL.GUEST_SERVICE_STATUS.VALUES.${item.code}`);
    }

    return extractDictionaryEntryLabel(item, DictionaryLabelLength.Full);
  }, []);

  const getInputLabel = useCallback((field: keyof T, item: DictionaryEntry) => {
    if (field === 'guestServiceStatuses') {
      return t(`GLOBAL.GUEST_SERVICE_STATUS.VALUES.${item.code}`);
    }

    return extractDictionaryEntryLabel(item, DictionaryLabelLength.Short);
  }, []);

  const multiSelects: Array<ProcessedAdvancedFiltersMultiSelectConfig<
    T
  >> = useMemo(
    () =>
      props.configs.map(config => ({
        ...config,
        selectOptions: config.items.map(item => ({
          value: item.code!,
          itemLabel: getItemLabel(config.field, item),
          inputLabel: getInputLabel(config.field, item)
        }))
      })),
    [props.configs]
  );

  function changeMultiSelection(field: keyof T, value: string[]) {
    props.formProps.form.change(
      field as any,
      value.length === 0 ? (undefined as any) : value
    );
  }

  const translations = {
    searchPlaceholderTranslation: t('GLOBAL.FILL'),
    searchLabelTranslation: t('GLOBAL.SEARCH_LABEL'),
    itemNotFoundTranslation: t('GLOBAL.NO_CONTENT.TITLE'),
    confirmButtonTranslation: t('GLOBAL.CONFIRM'),
    checkAllButtonTranslation: t('GLOBAL.CHECK_ALL'),
    clearAllButtonTranslation: t('GLOBAL.CLEAR_ALL'),
    clearButtonTranslation: t('GLOBAL.CLEAR')
  };

  return (
    <>
      {multiSelects.map(config => {
        return (
          <FormField
            key={config.field as string}
            valuePath={config.field as any}
          >
            {fieldRenderProps => (
              <AcMultiSelect
                className={props.className}
                style={props.style}
                placeholder={t('GLOBAL.SELECT')}
                label={config.label}
                showInputs={true}
                itemsList={
                  config.filter
                    ? config.filter(props.formProps, config.selectOptions!)
                    : config.selectOptions!
                }
                selectedItem={(fieldRenderProps.input.value || []) as string[]}
                /* tslint:disable-next-line:jsx-no-lambda */
                onChange={data => changeMultiSelection(config.field, data)}
                testSelector={`${props.testSelectorPrefix}-${config.field}`}
                showSearch={
                  config.selectOptions!.length >= MINIMUM_ITEMS_FOR_SEARCH
                }
                translations={translations}
              />
            )}
          </FormField>
        );
      })}
    </>
  );
}

export default genericMemo(AdvancedFiltersMultiSelects);
