export interface ActionResult<T> {
  error?: any;
  item: T;
}

export enum ResultStatus {
  Indefinite = 'Indefinite',
  Success = 'Success',
  Failed =  'Failed'
}

export function getFailedActions<T>(items: Array<ActionResult<T>>): Array<ActionResult<T>> {
  return items.filter(item => !!item.error);
}

export function getSucceedActions<T>(items: Array<ActionResult<T>>): Array<ActionResult<T>> {
  return items.filter(item => !item.error);
}

export function hasFailedAction(items: Array<ActionResult<any>>): boolean {
  return !!items.find(item => !!item.error);
}

export function checkActionsStatus(items: Array<ActionResult<any>> | null): ResultStatus {
  if (items) {
    return hasFailedAction(items) ? ResultStatus.Failed : ResultStatus.Success;
  }

  return ResultStatus.Indefinite;
}
