import { PageResponse } from '@ac/library-api';
import { ActionCreator } from '@ac/library-utils/dist/declarations/reduxActions';
import { createAction } from '@ac/library-utils/dist/redux-utils';

export interface PaginationActionSet<RawT, ItemT, SortT, FilterT> {
  fetchPage: ActionCreator<number>;
  fetchedPage: ActionCreator<PageResponse<RawT, ItemT>>;
  refreshPage: ActionCreator<undefined>;
  clearPage: ActionCreator<undefined>;
  clearLastPageNumber: ActionCreator<undefined>;
  validPageReceived: ActionCreator<PageResponse<RawT, ItemT>>;
  error: ActionCreator<undefined>;
  setFilters: ActionCreator<FilterT>;
  setSort: ActionCreator<SortT>;
  enableAutoRefresh: ActionCreator<undefined>;
  disableAutoRefresh: ActionCreator<undefined>;
  failureCounter: ActionCreator<number>;
}

export function createPaginationActions<RawT, ItemT, SortT, FilterT>(prefix: string): PaginationActionSet<RawT, ItemT, SortT, FilterT> {
  return {
    fetchPage: createAction(`${prefix}/pagination/fetchPage`),
    fetchedPage: createAction(`${prefix}/pagination/fetchedPage`),
    refreshPage: createAction(`${prefix}/pagination/refreshPage`),
    clearPage: createAction(`${prefix}/pagination/clearPage`),
    clearLastPageNumber: createAction(`${prefix}/pagination/clearLastPageNumber`),
    validPageReceived: createAction<PageResponse<RawT, ItemT>>(`${prefix}/pagination/pageReceived`),
    error: createAction(`${prefix}/pagination/error`),
    setFilters: createAction<FilterT>(`${prefix}/pagination/setFilters`),
    setSort: createAction<SortT>(`${prefix}/pagination/setSort`),
    enableAutoRefresh: createAction(`${prefix}/pagination/enableAutoRefresh`),
    disableAutoRefresh: createAction(`${prefix}/pagination/disableAutoRefresh`),
    failureCounter: createAction(`${prefix}/pagination/failureCounter`)
  };
}
