import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';

import { MaintenanceFile } from '@ac/library-api';
import {
  createAction,
  getListAction,
  getOneAction
} from '@ac/library-utils/dist/redux-utils';

export const initialize = createAction<string>(
  'maintenance/details/initialize'
);
export const fetchMaintenanceRoom = getOneAction<undefined, MaintenanceRoom>(
  'maintenance/details/fetchMaintenanceRoom'
);
export const fetchAttachments = getListAction<undefined, MaintenanceFile[]>(
  'maintenance/details/fetchAttachments'
);
export const setMaintenanceRoomFromDashboard = createAction<MaintenanceRoom>(
  'maintenance/details/setMaintenanceRoomFromDashboard'
);
export const reset = createAction('maintenance/details/reset');
