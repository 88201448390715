import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSheetGroupedRooms } from '@hkm/components/Attendant/shared/domain/selectors';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';

import { AttendantTaskProgressType } from '@ac/library-api';

/**
 * get next room id that should be shown for attendant based on
 * bypassHKAttendantSummary property
 */
export function getNextRoomId(): string | undefined {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const groupedRooms = useSelector(selectSheetGroupedRooms);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, react-hooks/rules-of-hooks
  const effectiveValues = useSelector(selectEffectiveValues)!;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    if (effectiveValues.bypassHKAttendantSummary) {
      const groupName = Array.from(groupedRooms.keys()).find(
        (taskType: AttendantTaskProgressType) => {
          return (
            taskType !== AttendantTaskProgressType.Completed &&
            groupedRooms.get(taskType)!.length > 0
          );
        }
      );

      return groupName ? groupedRooms.get(groupName)![0].id : undefined;
    }

    return undefined;
  }, [groupedRooms, effectiveValues.bypassHKAttendantSummary]);
}
