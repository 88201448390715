import { AttendantAssignmentDetails } from '@ac/library-api';
import { AttendantAssignmentApi } from '@ac/library-api/dist/api/v0/housekeeping';

interface GetSheetNameById {sheetId: string; }

export async function getSheetNameById(parameters: GetSheetNameById) {
  const { sheetId: id } = parameters;
  try {
    const currentAttendantAssignment = (await AttendantAssignmentApi.getCurrent()) as AttendantAssignmentDetails;
    const sheetById = currentAttendantAssignment.sheets?.find(
      sheet => sheet.id === id
    );

    return sheetById?.sheetName ?? id;
  } catch (e) {
    return id;
  }
}
