import React, { FC, memo, PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectEffectiveValues,
  selectPropertyDateFormats
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { taskProgressColorMap } from '@hkm/components/shared/AttendantTaskProgressTypeBadge/AttendantTaskProgressTypeBadge';
import {
  getAllVipAndMemberBadges,
  hasReservationsDisabilities
} from '@hkm/components/shared/Templates/Reservation/utils/reservationUtils';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { createTimeWithTimezone } from '@hkm/utils/dateHelper';
import { uniqBy } from 'lodash';

import { AttendantTaskProgressType } from '@ac/library-api';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import {
  AcModal,
  AcModalBody
} from '@ac/mobile-components/dist/components/modal';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  Color,
  FlexDirection,
  Icon,
  IconSize,
  JustifyContent,
  TextSize,
  TextWeight
} from '@ac/mobile-components/dist/enums';
import { ChildRequired } from '@ac/mobile-components/dist/interfaces/componentProps';

import './AttendantRoomItemHeader.css';

interface AttendantRoomItemHeaderProps extends ChildRequired {
  room: AttendantRoom;
  taskType: AttendantTaskProgressType;
}

/* tslint:disable:jsx-no-lambda */
const AttendantRoomItemHeader: FC<PropsWithChildren<
  AttendantRoomItemHeaderProps
>> = (props: PropsWithChildren<AttendantRoomItemHeaderProps>) => {
  const { t } = useTranslation();

  const prefix = 'attendant-room-item-header';
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);

  const allReservation = props.room.currentReservations || [];
  const { isInPendingRoomQueue, serviceRequested } = props.room;
  const hasAnyDisabilities = hasReservationsDisabilities(allReservation);
  const allVipAndMemberships = uniqBy(
    getAllVipAndMemberBadges(allReservation),
    'color'
  );
  const hasAnyVipAndMemberships = allVipAndMemberships.length > 0;
  function openModal(): void {
    setModalVisibility(true);
  }

  function closeModal(): void {
    setModalVisibility(false);
  }
  const desiredTime =
    props.room.queueRoomEntries && props.room.queueRoomEntries[0]?.desiredTime
      ? props.room.queueRoomEntries[0]?.desiredTime
      : undefined;
  const formats = useSelector(selectPropertyDateFormats);
  const effectiveValues = useSelector(selectEffectiveValues)!;
  const formattedDesiredTime =
    desiredTime && createTimeWithTimezone(desiredTime).format(formats.time);

  const isDesiredTimeEnabled = effectiveValues.enabledReadyBy;

  const headerText = useMemo(() => {
    if (isInPendingRoomQueue && serviceRequested) {
      return `${t('GLOBAL.QUEUE_ROOM')}, ${t(
        'GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceRequested'
      )}`;
    }

    if (isInPendingRoomQueue && !serviceRequested) {
      return t('GLOBAL.QUEUE_ROOM');
    }

    if (!isInPendingRoomQueue && serviceRequested) {
      return t('GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceRequested');
    }

    return undefined;
  }, [t, isInPendingRoomQueue, serviceRequested]);

  return (
    <>
      <AcFlex
        className={
          isInPendingRoomQueue || serviceRequested
            ? `${prefix} is-alert-bar`
            : ''
        }
        alignItems={AlignItems.center}
        justifyContent={JustifyContent.spaceBetween}
      >
        {!isInPendingRoomQueue && !serviceRequested ? (
          <div className={`${prefix}-room-number`}>
            <AcText
              color={Color.Black}
              weight={TextWeight.Semibold}
              size={TextSize.Main1}
            >
              <AcIcon
                size={IconSize.Small}
                color={taskProgressColorMap.get(props.taskType)}
                icon={Icon.Dot}
                className="ac-spacing-right-sm"
              />
              {props.children}
            </AcText>
          </div>
        ) : (
          <AcFlex
            className={`${prefix}-room-number`}
            alignItems={AlignItems.center}
          >
            <AcFlex className={`${prefix}-room-number-wrapper`}>
              <AcIcon
                className="ac-spacing-right-sm ac-spacing-left-sm"
                icon={
                  isInPendingRoomQueue && !serviceRequested
                    ? Icon.Queue
                    : Icon.Todo
                }
                color={Color.Warning}
                size={IconSize.Medium}
              />
              <AcFlex direction={FlexDirection.column}>
                <AcText size={TextSize.Main2}>{headerText}</AcText>
                <AcText
                  color={Color.Black}
                  weight={TextWeight.Semibold}
                  size={TextSize.Main1}
                >
                  {props.children}
                </AcText>
              </AcFlex>
            </AcFlex>
            {isDesiredTimeEnabled && desiredTime && (
              <AcFlex
                direction={FlexDirection.column}
                className="ready-by-time-room-dashboard"
              >
                <AcText size={TextSize.Main2}>
                  {t('ATTENDANT_ASSIGNMENTS.DESIRED_TIME')}
                </AcText>
                <AcText
                  color={Color.Black}
                  weight={TextWeight.Semibold}
                  size={TextSize.Main1}
                >
                  {formattedDesiredTime}
                </AcText>
              </AcFlex>
            )}
          </AcFlex>
        )}
        <AcText
          color={Color.Black}
          size={TextSize.Main1}
          className={`align-right ${
            isInPendingRoomQueue || serviceRequested
              ? 'ac-spacing-right-sm'
              : ''
          }`}
        >
          {hasAnyDisabilities && (
            <AcIndicator
              onClick={openModal}
              icon={Icon.Disability}
              backgroundColor={Color.Alert}
              className="ac-spacing-left-sm"
            />
          )}
          {hasAnyVipAndMemberships &&
            allVipAndMemberships!.map(item => (
              <AcIndicator
                key={item.color}
                onClick={openModal}
                icon={Icon.Vip}
                backgroundColor={item.color}
                className="ac-spacing-left-sm"
              />
            ))}
        </AcText>
      </AcFlex>

      <AcModal
        className="attendant-room-item-modal"
        isOpen={isModalVisible}
        onClose={closeModal}
      >
        <AcModalBody className="attendant-room-item-modal-body">
          {hasAnyDisabilities && (
            <AcFlex
              alignItems={AlignItems.center}
              className="ac-spacing-bottom-lg"
            >
              <AcIndicator
                className="ac-spacing-right-sm"
                icon={Icon.Disability}
                backgroundColor={Color.Alert}
              />
              <AcText>
                {t('ATTENDANT_ASSIGNMENTS.ROOM_LIST.GUEST_WITH_SPECIAL_NEEDS')}
              </AcText>
            </AcFlex>
          )}
          {hasAnyVipAndMemberships &&
            allVipAndMemberships!.map((item, key) => (
              <AcFlex
                key={item.color}
                alignItems={AlignItems.center}
                className={
                  key === allVipAndMemberships!.length - 1
                    ? ''
                    : 'ac-spacing-bottom-lg'
                }
              >
                <AcIndicator
                  className="ac-spacing-right-sm"
                  backgroundColor={item.color}
                  icon={Icon.Vip}
                />
                <AcText>{item!.description}</AcText>
              </AcFlex>
            ))}
        </AcModalBody>
      </AcModal>
    </>
  );
};

export default memo(AttendantRoomItemHeader);
