import { PaginationActionSet } from '@hkm/store/pagination/paginationActionsFactory';
import { uid } from '@hkm/utils/uid';
import { ReducerMap } from 'redux-actions';

import { PageResponse } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';

export function createPaginationReducers<StateT, RawT, ItemT, SortT, FilterT>(
  field: keyof StateT,
  actionSet: PaginationActionSet<RawT, ItemT, SortT, FilterT>
): ReducerMap<StateT, unknown> {
  return {
    [actionSet.fetchPage]: state => ({
      ...state,
      [field]: { ...state[field], hasError: false, isLoading: true }
    }),
    [actionSet.clearPage]: state => ({
      ...state,
      [field]: {
        ...state[field],
        hasError: false,
        isLoading: false,
        page: undefined
      }
    }),
    [actionSet.clearLastPageNumber]: state => ({
      ...state,
      [field]: { ...state[field], lastPageNumber: undefined }
    }),
    [actionSet.error]: state => ({
      ...state,
      [field]: { ...state[field], hasError: true, isLoading: false }
    }),
    [actionSet.validPageReceived]: (
      state,
      action: Action<PageResponse<RawT, ItemT>>
    ) => ({
      ...state,
      [field]: {
        ...state[field],
        hasError: false,
        isLoading: false,
        page: action.payload,
        pageId: uid(),
        lastPageNumber: action.payload.paging.pageNumber
      }
    }),
    [actionSet.setFilters]: (state, action: Action<FilterT>) => ({
      ...state,
      [field]: { ...state[field], filters: action.payload }
    }),
    [actionSet.setSort]: (state, action: Action<SortT>) => ({
      ...state,
      [field]: { ...state[field], sort: action.payload }
    }),
    [actionSet.failureCounter]: (state, action: Action<number>) => {
      return {
        ...state,
        [field]: {
          ...state[field],
          failureCounter: action.payload
        }
      };
    }
  };
}
