import React, { FC, memo, useCallback } from 'react';
import { disabledMenuSwipeClass } from '@hkm/components/Menu/MenuContainer';
import { QuickFilterItem } from '@hkm/components/shared/QuickFilter/QuickFilterItem';
import classNames from 'classnames';

import {
  AcButton,
  AcButtonPattern,
  AcButtonShape
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { Icon } from '@ac/mobile-components/dist/enums';
import {
  Changeable,
  Childless,
  Styleable
} from '@ac/mobile-components/dist/interfaces/componentProps';

import './QuickFilter.css';

interface QuickFilterProps extends Styleable, Changeable<any[]>, Childless {
  items: Array<QuickFilterItem<any>>;
  active?: any[];
  disabled?: boolean;
}

const QuickFilter: FC<QuickFilterProps> = (props: QuickFilterProps) => {
  const activeSet = new Set(props.active || []);
  const className = classNames(
    'quick-filter',
    disabledMenuSwipeClass,
    props.className
  );

  const toggle = useCallback(
    (filter: any) => {
      activeSet.has(filter) ? activeSet.delete(filter) : activeSet.add(filter);
      if (props.onChange) {
        props.onChange(Array.from(activeSet));
      }
    },
    [props.onChange]
  );

  return (
    <div className={className} style={props.style}>
      <AcFlex className="quick-filter-scroll">
        {props.items.map(item => (
          <AcButton
            key={item.value}
            className="quick-filter-button ac-spacing-left-sm"
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => toggle(item.value)}
            pattern={
              activeSet.has(item.value)
                ? AcButtonPattern.LightSelected
                : AcButtonPattern.Light
            }
            disabled={props.disabled}
            testSelector={`quick-filter-${item.value}`}
            shape={AcButtonShape.Rectangle}
          >
            <AcButtonContent
              icon={activeSet.has(item.value) ? Icon.Check : undefined}
              text={item.label}
            />
          </AcButton>
        ))}
      </AcFlex>
    </div>
  );
};

export default memo(QuickFilter);
