import { BaseApi } from '@ac/library-api';

export function initUnauthorizationMiddleware(callback: () => void) {
  BaseApi.addRequestMiddlewares([
    params => {
      const isUnauthorized = params.response.status === 401;
      if (isUnauthorized) {
        callback();
      }
    }
  ]);
}
