import React, { FC, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@hkm/components/Menu/domain/actions';
import APP_ROUTES from '@hkm/constants/routing.constants';

import { AcIconButton } from '@ac/mobile-components/dist/components/icon-button';
import { AcWrappedLink } from '@ac/mobile-components/dist/components/wrapped-link';
import { Icon } from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';

const HeaderHelpButton: FC<Childless> = () => {
  const dispatch = useDispatch();

  const closeMenu = useCallback(() => {
    dispatch(actions.setMenuState(false));
  }, []);

  return (
    <AcWrappedLink link={APP_ROUTES.HELP}>
      <AcIconButton icon={Icon.Help} onClick={closeMenu} />
    </AcWrappedLink>
  );
};

export default memo(HeaderHelpButton);
