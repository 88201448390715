import { ImageResolutionType, ImagesStore } from '@hkm/components/App/imageQueue/domain/interfaces';
import { ImageResolutionName } from '@hkm/components/App/imageQueue/enum/imageResolutionName';
import { ImageSource } from '@hkm/components/App/imageQueue/enum/imageSource';
import { Store } from '@hkm/store/interfaces/store';
import { createSelector } from 'reselect';

export const selectImageStore = (state: Store): ImagesStore => {
  return state.images;
};

export const selectIndividualImages = createSelector(selectImageStore, (imageStore: ImagesStore) => {
  return imageStore ? imageStore[ImageSource.IndividualApi] : {};
});

export const selectSmallIndividualImagesMap = createSelector(selectIndividualImages, (imageResolutionType: ImageResolutionType) => {
  return imageResolutionType[ImageResolutionName.Resolution_38x38] ? imageResolutionType[ImageResolutionName.Resolution_38x38].imageUrlMap : {};
});

export const selectLargeIndividualImagesMap = createSelector(selectIndividualImages, (imageResolutionType: ImageResolutionType) => {
  return imageResolutionType[ImageResolutionName.Resolution_400x] ? imageResolutionType[ImageResolutionName.Resolution_400x].imageUrlMap : {};
});
