import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/App/imageQueue/domain/actions';
import {
  selectLargeIndividualImagesMap,
  selectSmallIndividualImagesMap
} from '@hkm/components/App/imageQueue/domain/selectors';
import { ImageResolutionName } from '@hkm/components/App/imageQueue/enum/imageResolutionName';
import { ImageResolution } from '@hkm/components/App/imageQueue/ImageResolution';
import { createPromiseLoadImage } from '@hkm/components/App/imageQueue/utils/createPromiseLoadImage';
import GuestAvatarTemplate from '@hkm/components/shared/Templates/Guest/GuestAvatar/GuestAvatarTemplate';
import { useExtractGuestFullName } from '@hkm/shared/hooks/useExtractGuestFullName';
import { UnifiedReservationGuestDetails } from '@hkm/types/reservation/models/UnifiedReservationGuestDetails';

import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader,
  AcModalPattern
} from '@ac/mobile-components/dist/components/modal';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { Color, TextSize } from '@ac/mobile-components/dist/enums';
import { Styleable } from '@ac/mobile-components/dist/interfaces/componentProps';

import './GuestAvatarTemplate.css';

interface GuestImageAvatarTemplateProps extends Styleable {
  guest: UnifiedReservationGuestDetails;
}

const ConnectGuestAvatarTemplate: FC<GuestImageAvatarTemplateProps> = (
  props: GuestImageAvatarTemplateProps
) => {
  const avatarId = props.guest.id;

  const dispatch = useDispatch();
  const smallIndividualImagesMap = useSelector(selectSmallIndividualImagesMap);
  const largeIndividualImagesMap = useSelector(selectLargeIndividualImagesMap);
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);

  const hasPersonalData = !!props.guest.personalData;
  const guestName =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    hasPersonalData && useExtractGuestFullName(props.guest.personalData!.name);

  useEffect(() => {
    if (!smallIndividualImagesMap[avatarId]) {
      dispatch(
        actions.fetchIndividualImages.trigger({
          imageIds: [avatarId],
          resolutionConfig: ImageResolution.getImageResolutionConfig(
            ImageResolutionName.Resolution_38x38
          )
        })
      );
    }
  }, []);

  const getAvatar = useMemo(() => {
    return smallIndividualImagesMap[avatarId]
      ? createPromiseLoadImage(smallIndividualImagesMap[avatarId]!)
      : undefined;
  }, [smallIndividualImagesMap]);

  function openModal(): void {
    if (smallIndividualImagesMap[avatarId]) {
      setModalVisibility(true);

      if (!largeIndividualImagesMap[avatarId]) {
        dispatch(
          actions.fetchIndividualImages.trigger({
            imageIds: [avatarId],
            resolutionConfig: ImageResolution.getImageResolutionConfig(
              ImageResolutionName.Resolution_400x
            )
          })
        );
      }
    }
  }

  function closeModal(): void {
    setModalVisibility(false);
  }

  return (
    <>
      <GuestAvatarTemplate
        onClick={openModal}
        getAvatar={getAvatar}
        guestPersonalData={props.guest.personalData}
        hasDisabilities={!!props.guest.disabilityStatusCodes?.length}
        className={props.className}
      />
      <AcModal
        className="avatar-modal"
        isOpen={isModalVisible}
        onClose={closeModal}
        pattern={AcModalPattern.Circle}
      >
        <AcModalHeader />
        <AcModalBody className="avatar-modal-body">
          <img
            src={
              largeIndividualImagesMap[avatarId] ||
              smallIndividualImagesMap[avatarId]
            }
            className="avatar-image"
          />
        </AcModalBody>
        <AcModalFooter>
          {guestName && (
            <AcText color={Color.White} size={TextSize.Main1}>
              {guestName}
            </AcText>
          )}
        </AcModalFooter>
      </AcModal>
    </>
  );
};

export default memo(ConnectGuestAvatarTemplate);
