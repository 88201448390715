import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGuestServiceValue } from '@hkm/components/Attendant/shared/utils/useGuestServiceValue';
import { selectActiveProperty } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import DatetimeFormElement, {
  DatetimeVariant
} from '@hkm/components/shared/DatetimeFormElement/DatetimeFormElement';
import { GuestServiceRoom } from '@hkm/components/shared/GuestService/GuestServiceField';
import { GuestServiceModalFormState } from '@hkm/components/shared/GuestService/modal/form/useFormState';
import { GuestServiceModalMode } from '@hkm/components/shared/GuestService/modal/GuestServiceModal';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';
import { useGuestServices } from '@hkm/shared/hooks/useGuestServices';
import { createLocalTimeFromTzTime } from '@hkm/utils/dateHelper';

import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcSelectField } from '@ac/mobile-components/dist/components/select';
import { Color } from '@ac/mobile-components/dist/enums';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

interface Props {
  isReadonly: boolean;
  room: GuestServiceRoom;
  testSelector: string | undefined;
  onOpenModal: (mode: GuestServiceModalMode) => void;
  disabled?: boolean;
  clearSelectedValue: (
    guestServiceModalFormState?: GuestServiceModalFormState
  ) => void;
}

export const GuestServiceRequestField = (props: Props) => {
  const { allAdHocGuestServices, isServiceActive } = useGuestServices();
  const { t } = useTranslation();
  const {
    isReadonly,
    room,
    testSelector,
    onOpenModal,
    clearSelectedValue,
    disabled
  } = props;
  const { getAdHocServiceValue } = useGuestServiceValue();
  const selectedService = getAdHocServiceValue(room);

  const property = useSelector(selectActiveProperty);
  const deferredTime = createLocalTimeFromTzTime(
    room.serviceDeferredUntil,
    property.timeZoneCode
  );

  const serviceRequestOptionalValueElement = useMemo(() => {
    if (
      deferredTime &&
      room.serviceDeferred &&
      allAdHocGuestServices
        .map(guestService => guestService.code)
        .includes(GuestServiceStatusType.ServiceDeferred)
    ) {
      return (
        <DatetimeFormElement
          color={Color.ServiceDeferred}
          datetime={deferredTime}
          variant={DatetimeVariant.onlyTime}
          timeLabel={t('GLOBAL.GUEST_SERVICE_REQUEST.CLEAN_AFTER')}
        />
      );
    }

    if (
      room.serviceRequestedInstruction &&
      room.serviceRequested &&
      allAdHocGuestServices
        .map(guestService => guestService.code)
        .includes(GuestServiceStatusType.ServiceRequested)
    ) {
      return (
        <AcFormElement
          label={t('GLOBAL.GUEST_SERVICE_REQUEST.INSTRUCTION')}
          testSelector={formatTestSelector(testSelector, 'instruction')}
        >
          {room.serviceRequestedInstruction}
        </AcFormElement>
      );
    }

    return null;
  }, [
    allAdHocGuestServices,
    room.serviceDeferred,
    room.serviceDeferredUntil,
    room.serviceRequested,
    room.serviceRequestedInstruction,
    t,
    testSelector
  ]);

  return (
    <>
      {allAdHocGuestServices.length > 0 &&
        (!isReadonly || selectedService) &&
        (isReadonly ? (
          <>
            <AcFormElement
              label={t('GLOBAL.GUEST_SERVICE_STATUS.TITLE')}
              testSelector={formatTestSelector(
                testSelector,
                'guestServiceStatus'
              )}
            >
              {selectedService?.name}
            </AcFormElement>
            {serviceRequestOptionalValueElement}
          </>
        ) : (
          <>
            <AcSelectField
              disabled={disabled}
              clearable={true}
              clearSelectedValue={() =>
                clearSelectedValue({
                  initialServiceType: selectedService?.code as GuestServiceStatusType
                })
              }
              onClick={() => onOpenModal(GuestServiceModalMode.AdHoc)}
              placeholder={t('GLOBAL.SELECT')}
              value={selectedService?.name || ''}
              label={t('GLOBAL.GUEST_SERVICE_REQUEST.TITLE')}
              testSelector={formatTestSelector(
                testSelector,
                'guestServiceStatus'
              )}
              validation={
                selectedService?.code &&
                !isServiceActive(selectedService.code as GuestServiceStatusType)
                  ? t('GLOBAL.GUEST_SERVICE_STATUS.SERVICE_INACTIVE', {
                      service: t(
                        `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${selectedService.code}`
                      )
                    })
                  : ''
              }
            />
            {serviceRequestOptionalValueElement}
          </>
        ))}
    </>
  );
};
