import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import { ObjectToComponentMap } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/constants';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTileShell } from '@ac/mobile-components/dist/components/tile';
import {
  FlexDirection,
  Icon,
  TextSize,
  TextWeight
} from '@ac/mobile-components/dist/enums';

import './LinkedObject.css';

const LinkedObject = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);

  const ObjectComponent = ObjectToComponentMap[linkedObject!.type];

  return (
    <AcTileShell className="task-area">
      <AcFlex direction={FlexDirection.column}>
        <AcFlex className="task-area-header">
          <AcIcon icon={Icon.LinkedRsv} className="ac-spacing-right-sm" />
          <AcText
            uppercase={true}
            weight={TextWeight.Semibold}
            size={TextSize.Main2}
            testSelector="task-area-title"
          >
            {t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.TITLE')}
          </AcText>
        </AcFlex>
        <ObjectComponent />
      </AcFlex>
    </AcTileShell>
  );
};

export default LinkedObject;
