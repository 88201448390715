import { MaintenanceReleaseFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceReleaseForm/models/maintenanceReleaseData';
import { createMaintenanceUpdateActions } from '@hkm/shared/domain/maintenanceUpdate/maintenanceUpdateActionsFactory';

import { createAction, updateAction } from '@ac/library-utils/dist/redux-utils';

export const notifyMaintenanceUpdate = createAction<undefined>(
  'maintenance/updateMaintenance/notifyMaintenanceUpdate'
);
export const releaseMaintenance = updateAction<
  MaintenanceReleaseFormData,
  undefined
>('maintenance/details/releaseMaintenance');

export const updateMaintenanceActionsSet = createMaintenanceUpdateActions(
  'maintenance/updateMaintenance',
  {
    notifyMaintenanceUpdate
  }
);
export const updateAttendantMaintenanceActionsSet = createMaintenanceUpdateActions(
  'attendant/maintenance/updateMaintenance',
  {
    notifyMaintenanceUpdate
  }
);
