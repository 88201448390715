import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReservationNotes } from '@hkm/components/App/domain/interfaces/BookingNotesStore';
import {
  getNoteTypesMap,
  selectNotes
} from '@hkm/components/App/domain/selectors';
import APP_CONSTANTS from '@hkm/constants/app.constants';

import { BookingNote, RawLocalizedString } from '@ac/library-api';

export interface GroupedBookingNotesType {
  description: string;
  bookingNotes: BookingNote[];
}

export type NotesTypeMap = Map<string, GroupedBookingNotesType>;
export type NotesReservationMap = Map<string, NotesTypeMap>;

/**
 * Hook can be use to get reservation notes from store and group
 * these notes by type
 * @param reservationIds
 */
export function useGroupedReservationNotes(
  reservationIds: string[]
): NotesReservationMap {
  const reservationNotes = useSelector(selectNotes).reservationNotes;
  const notesTypeDictionary = useSelector(getNoteTypesMap);

  const getNoteTypeDescription = useCallback(
    (noteType: string) => {
      if (notesTypeDictionary.has(noteType)) {
        const description = notesTypeDictionary
          .get(noteType)!
          .description.find(
            (localizedString: RawLocalizedString) =>
              localizedString.languageCode.toLowerCase() ===
              APP_CONSTANTS.DATA_LANGUAGE
          );

        return description ? description.content : '';
      }

      return '';
    },
    [notesTypeDictionary]
  );

  return useMemo(() => {
    const notesReservationMap: NotesReservationMap = new Map<
      string,
      NotesTypeMap
    >();
    reservationNotes
      .filter(
        (reservationNote: ReservationNotes) =>
          reservationIds.includes(reservationNote.reservationId) &&
          reservationNote.notes
      )
      .forEach((reservationNote: ReservationNotes) => {
        const notesTypeMap: NotesTypeMap = new Map<
          string,
          GroupedBookingNotesType
        >();

        reservationNote.notes!.forEach((bookingNote: BookingNote) => {
          if (notesTypeMap.has(bookingNote.typeCode!)) {
            notesTypeMap
              .get(bookingNote.typeCode!)!
              .bookingNotes.push(bookingNote);
          } else {
            const noteType: GroupedBookingNotesType = {
              description: getNoteTypeDescription(bookingNote.typeCode!),
              bookingNotes: [bookingNote]
            };
            notesTypeMap.set(bookingNote.typeCode!, noteType);
          }
        });

        notesReservationMap.set(reservationNote.reservationId, notesTypeMap);
      });

    return notesReservationMap;
  }, [reservationIds, reservationNotes]);
}
