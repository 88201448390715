import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { AttendantTaskProgressType } from '@ac/library-api';
import {
  AcDotBadge,
  AcDotBadgeProps
} from '@ac/mobile-components/dist/components/dot-badge';
import { Color } from '@ac/mobile-components/dist/enums';

interface Props extends AcDotBadgeProps {
  status: AttendantTaskProgressType;
  label?: string;
}

export const taskProgressColorMap = new Map<AttendantTaskProgressType, Color>()
  .set(AttendantTaskProgressType.InProgress, Color.AttendantTaskInProgress)
  .set(AttendantTaskProgressType.Skipped, Color.AttendantTaskSkipped)
  .set(AttendantTaskProgressType.Paused, Color.AttendantTaskPaused)
  .set(AttendantTaskProgressType.Completed, Color.AttendantTaskCompleted)
  .set(AttendantTaskProgressType.Pending, Color.AttendantTaskPending);

const AttendantTaskProgressTypeBadge: FC<Props> = ({
  status,
  label,
  className: classNameProp,
  ...rest
}) => {
  const { t } = useTranslation();

  const color: Color = taskProgressColorMap.get(status) || Color.Gray4;
  const labelText = label || t(`GLOBAL.TASK_PROGRESS_TYPE.${status}`);

  const className = classNames('attendant-task-type-badge', classNameProp);

  return (
    <AcDotBadge className={className} {...rest} color={color}>
      {labelText}
    </AcDotBadge>
  );
};

export default memo(AttendantTaskProgressTypeBadge);
