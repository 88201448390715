import { uid } from '@hkm/utils/uid';

import {
  CodeValue,
  CodeValueColor,
  IdCodeDescription,
  IdCodeDescriptionColor
} from '@ac/library-api';

export function mapIdCodeDescription(codeValue: CodeValue): IdCodeDescription;
export function mapIdCodeDescription(
  codeValue: CodeValueColor
): IdCodeDescriptionColor;
export function mapIdCodeDescription(
  codeValue: CodeValueColor | CodeValue
): IdCodeDescriptionColor | IdCodeDescription {
  return Object.assign(codeValue, {
    id: uid()
  });
}
