import { HousekeepingPagination } from '@hkm/components/Housekeeping/Dashboard/domain/interfaces';
import { SelectedRoom } from '@hkm/components/Housekeeping/shared/models/selectedRoom';
import { DashboardMode } from '@hkm/shared/enum/dashboardMode';
import { Store } from '@hkm/store/interfaces/store';
import { createSelector } from 'reselect';

export const selectHousekeepingDashboardPagination = (state: Store): HousekeepingPagination => state.housekeepingDashboard.paginationState;
export const selectHousekeepingDashboardSort = createSelector(selectHousekeepingDashboardPagination, state => state.sort!);
export const selectHousekeepingDashboardFilters = createSelector(selectHousekeepingDashboardPagination, state => state.filters!);
export const selectHousekeepingDashboardIsLoading = createSelector(selectHousekeepingDashboardPagination, state => !!state.isLoading);

export const selectHousekeepingMode = (state: Store): DashboardMode => state.housekeepingDashboard.selectedMode;
export const selectHousekeepingSelectedRooms = (state: Store): SelectedRoom[] => state.housekeepingDashboard.selectedRooms;
