export const reduceToUnique = <ResultItem, OriginalItem>(
  array: OriginalItem[],
  compareFn: (originalItem: OriginalItem, resultItem: ResultItem) => boolean,
  createNewFn: (item: OriginalItem) => ResultItem,
  updateExistingFn: (item: ResultItem, originalItem: OriginalItem) => ResultItem
): ResultItem[] => {
  return array.reduce<ResultItem[]>((acc, item) => {
    const existingItemIndex = acc.findIndex(existingItem =>
      compareFn(item, existingItem)
    );

    if (existingItemIndex < 0) {
      return acc.concat(createNewFn(item));
    }

    acc[existingItemIndex] = updateExistingFn(acc[existingItemIndex], item);

    return acc;
  }, []);
};
