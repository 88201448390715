import { OriginatorSource } from '@ac/library-api';
import { Icon } from '@ac/mobile-components/dist/enums';

const originatorSourceIconMap: Map<OriginatorSource, Icon> = new Map();
originatorSourceIconMap.set(OriginatorSource.TravelAgent, Icon.Travel);
originatorSourceIconMap.set(OriginatorSource.Cashiering, Icon.Cashiering);
originatorSourceIconMap.set(OriginatorSource.Company, Icon.Company);
originatorSourceIconMap.set(OriginatorSource.Individual, Icon.Profile);
originatorSourceIconMap.set(OriginatorSource.ReservationGroup, Icon.Group);
originatorSourceIconMap.set(OriginatorSource.Ar, Icon.AccountsReceivable);
originatorSourceIconMap.set(OriginatorSource.Reservation, Icon.Reservations);
originatorSourceIconMap.set(OriginatorSource.MeetingsAndEventsBooking, Icon.Conferences);

export default originatorSourceIconMap;
