import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MaintenanceManagePermissionsConfig } from '@hkm/components/Maintenance/config/maintenanceManagePermissionsConfig';
import * as maintenanceAttachmentsActions from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/domain/actions';
import { AttachmentMangeAccessLevel } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/enum/attachmentMangeAccessLevel';
import { MaintenanceAttachmentData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/maintenanceAttachmentData';
import MaintenanceAttachmentsTile from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/MaintenanceAttachmentsTile';
import MaintenanceDetailsTile from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/MaintenanceDetailsTile';
import * as updateMaintenanceActions from '@hkm/components/shared/Templates/Maintenance/shared/domain/actions';
import {
  AddMaintenanceAttachmentData,
  RemoveMaintenanceAttachmentData,
  UpdateMaintenanceAttachmentData
} from '@hkm/shared/domain/maintenanceAttachment/uploadAttachement/models/maintenanceUploadAttachmentData';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';

import {
  RoomMaintenancesAttachmentPathParam,
  UpdateMaintenances
} from '@ac/library-api';
import {
  AcFormElement,
  AcFormGroup
} from '@ac/mobile-components/dist/components/form-element';
import {
  AcTile,
  AcTileGroup
} from '@ac/mobile-components/dist/components/tile';
import { Icon } from '@ac/mobile-components/dist/enums';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

export interface MaintenanceDetailsBodyProps {
  maintenanceRoom: MaintenanceRoom;
  attachments: MaintenanceAttachmentData[];
}

/* tslint:disable:jsx-no-lambda */
const MaintenanceDetailsBody: FC<MaintenanceDetailsBodyProps> = (
  props: MaintenanceDetailsBodyProps
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const testSelectorPrefix = 'maintenance-details';
  const hasRoomFloor = !!props.maintenanceRoom.roomFloor;
  const hasMaintenanceManagePermission = usePermission(
    ...MaintenanceManagePermissionsConfig
  );
  const maintenanceAttachmentPermission: AttachmentMangeAccessLevel = hasMaintenanceManagePermission
    ? AttachmentMangeAccessLevel.All
    : AttachmentMangeAccessLevel.None;

  const onMaintenanceUpdate = useCallback(
    (updateMaintenances: UpdateMaintenances) => {
      dispatch(
        updateMaintenanceActions.updateMaintenanceActionsSet.updateMaintenance.trigger(
          {
            data: updateMaintenances,
            roomId: props.maintenanceRoom.unifiedRoomDetails.id,
            roomNumber: props.maintenanceRoom.unifiedRoomDetails.roomNumber!,
            housekeepingRoomVersion: props.maintenanceRoom.unifiedRoomDetails
              .housekeepingRoomVersion!
          }
        )
      );
    },
    [props.maintenanceRoom]
  );

  const onMaintenanceAttachmentAdd = useCallback(
    (data: AddMaintenanceAttachmentData) =>
      dispatch(
        maintenanceAttachmentsActions.uploadAttachmentActionsSet.addAttachment.trigger(
          data
        )
      ),
    []
  );

  const onMaintenanceAttachmentRemove = useCallback(
    (data: RemoveMaintenanceAttachmentData) =>
      dispatch(
        maintenanceAttachmentsActions.uploadAttachmentActionsSet.removeAttachment.trigger(
          data
        )
      ),
    []
  );

  const onMaintenanceAttachmentUpdate = useCallback(
    (data: UpdateMaintenanceAttachmentData) =>
      dispatch(
        maintenanceAttachmentsActions.uploadAttachmentActionsSet.updateAttachment.trigger(
          data
        )
      ),
    []
  );

  const onMaintenanceAttachmentRequest = useCallback(
    (data: RoomMaintenancesAttachmentPathParam) =>
      dispatch(
        maintenanceAttachmentsActions.uploadAttachmentActionsSet.getAttachmentFile.trigger(
          data
        )
      ),
    []
  );

  return (
    <AcTileGroup>
      <AcTile title={t('ROOM_DETAILS.ROOM_INFORMATION')} icon={Icon.Room}>
        <AcFormGroup>
          <AcFormElement
            label={t('GLOBAL.ROOM_NUMBER.LONG')}
            testSelector={formatTestSelector(testSelectorPrefix, 'roomNumber')}
          >
            {props.maintenanceRoom.room!.code}
          </AcFormElement>

          {hasRoomFloor && (
            <AcFormElement
              label={t('GLOBAL.FLOOR.LONG')}
              testSelector={formatTestSelector(testSelectorPrefix, 'roomFloor')}
            >
              {props.maintenanceRoom.roomFloor!.code}
            </AcFormElement>
          )}

          <AcFormElement
            label={t('GLOBAL.ROOM_TYPE.LONG')}
            testSelector={formatTestSelector(testSelectorPrefix, 'roomType')}
          >
            {t('ROOM_DETAILS.ROOM_TYPE_FORMAT', {
              code: props.maintenanceRoom.roomType!.code,
              name: props.maintenanceRoom.roomType!.description
            })}
          </AcFormElement>
        </AcFormGroup>
      </AcTile>

      <MaintenanceDetailsTile
        onSubmit={onMaintenanceUpdate}
        readonly={!hasMaintenanceManagePermission}
        maintenanceDetails={props.maintenanceRoom}
        unifiedRoomDetails={props.maintenanceRoom.unifiedRoomDetails}
      />

      <MaintenanceAttachmentsTile
        attachments={props.attachments}
        maintenanceId={props.maintenanceRoom.id}
        roomId={props.maintenanceRoom.room!.id}
        testSelector="maintenanceAttachmentsTile"
        roomVersionId={props.maintenanceRoom.housekeepingRoomVersion}
        onAdd={onMaintenanceAttachmentAdd}
        onUpdate={onMaintenanceAttachmentUpdate}
        onFileRequest={onMaintenanceAttachmentRequest}
        onRemove={onMaintenanceAttachmentRemove}
        manageAccessLevel={maintenanceAttachmentPermission}
      />
    </AcTileGroup>
  );
};

export default memo(MaintenanceDetailsBody);
