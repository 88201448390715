import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGuestServiceValue } from '@hkm/components/Attendant/shared/utils/useGuestServiceValue';
import { selectActiveProperty } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import DatetimeFormElement, {
  DatetimeVariant
} from '@hkm/components/shared/DatetimeFormElement/DatetimeFormElement';
import { GuestServiceRoom } from '@hkm/components/shared/GuestService/GuestServiceField';
import { GuestServiceModalFormState } from '@hkm/components/shared/GuestService/modal/form/useFormState';
import { GuestServiceModalMode } from '@hkm/components/shared/GuestService/modal/GuestServiceModal';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';
import { useGuestServices } from '@hkm/shared/hooks/useGuestServices';
import { createLocalTimeFromTzTime } from '@hkm/utils/dateHelper';

import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcSelectField } from '@ac/mobile-components/dist/components/select';
import { Color } from '@ac/mobile-components/dist/enums';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

interface Props {
  isReadonly: boolean;
  room: GuestServiceRoom;
  testSelector: string | undefined;
  onOpenModal: (mode: GuestServiceModalMode) => void;
  disabled?: boolean;
  clearSelectedValue: (
    guestServiceModalFormState?: GuestServiceModalFormState
  ) => void;
}

export const GuestServiceStatusField = (props: Props) => {
  const { t } = useTranslation();
  const {
    isReadonly,
    room,
    testSelector,
    onOpenModal,
    clearSelectedValue,
    disabled
  } = props;
  const { allPlannedGuestServices, isServiceActive } = useGuestServices();
  const { getPlannedServiceValue } = useGuestServiceValue();
  const selectedService = getPlannedServiceValue(room);

  const property = useSelector(selectActiveProperty);
  const preferredTime = createLocalTimeFromTzTime(
    room.servicePreferredAfterTime,
    property.timeZoneCode
  );

  const serviceRequestOptionalValueElement = useMemo(() => {
    if (
      room.servicePreferredAfterTime &&
      preferredTime &&
      allPlannedGuestServices
        .map(guestService => guestService.code)
        .includes(GuestServiceStatusType.ServicePreferred)
    ) {
      return (
        <DatetimeFormElement
          color={Color.ServiceDeferred}
          datetime={preferredTime}
          variant={DatetimeVariant.onlyTime}
          timeLabel={t('GLOBAL.GUEST_SERVICE_STATUS.PREFERRED_TIME')}
        />
      );
    }
    return null;
  }, [
    allPlannedGuestServices,
    room.servicePreferred,
    room.servicePreferredAfterTime,
    t
  ]);

  return (
    <>
      {allPlannedGuestServices.length > 0 &&
        (!isReadonly || selectedService) &&
        (isReadonly ? (
          <>
            <AcFormElement
              label={t('GLOBAL.GUEST_SERVICE_STATUS.TITLE')}
              testSelector={formatTestSelector(
                props.testSelector,
                'guestServiceStatus'
              )}
            >
              {selectedService?.name}
            </AcFormElement>
            {serviceRequestOptionalValueElement}
          </>
        ) : (
          <>
            <AcSelectField
              disabled={disabled}
              clearable={true}
              clearSelectedValue={() =>
                clearSelectedValue({
                  initialServiceType: selectedService?.code as GuestServiceStatusType
                })
              }
              onClick={() => onOpenModal(GuestServiceModalMode.Planned)}
              placeholder={t('GLOBAL.SELECT')}
              value={selectedService?.name || ''}
              label={t('GLOBAL.GUEST_SERVICE_STATUS.TITLE')}
              testSelector={formatTestSelector(
                testSelector,
                'guestServiceStatus'
              )}
              validation={
                selectedService?.code &&
                !isServiceActive(selectedService.code as GuestServiceStatusType)
                  ? t('GLOBAL.GUEST_SERVICE_STATUS.SERVICE_INACTIVE', {
                      service: t(
                        `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${selectedService.code}`
                      )
                    })
                  : ''
              }
            />
            {serviceRequestOptionalValueElement}
          </>
        ))}
    </>
  );
};
