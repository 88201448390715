import { LoginService } from '@ac/library-utils/dist/services';
import { getGlobalACPNamespace } from '@ac/library-utils/dist/utils';

const initializeAuthService = (): void => {
  getGlobalACPNamespace().container = {
    getAccessToken: LoginService.accessToken,
    getIdToken: LoginService.idToken,
    logTokenInfo: () => {
      const data = LoginService.authData();
      // tslint:disable-next-line: no-console
      console.log(
        `Token exists: ${!!data?.token}, expires at: ${
          data?.expiresAt ? new Date(data?.expiresAt * 1000).toUTCString() : '-'
        }`
      );
    }
  };
};

export default initializeAuthService;
