import { MaintenanceReleaseFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceReleaseForm/models/maintenanceReleaseData';
import { SourceModuleType } from '@hkm/shared/enum/SourceModuleType';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';

import { RoomStatus } from '@ac/library-api';

export function mapToMaintenanceReleaseFormData(
  data: MaintenanceRoom,
  dashboardType: SourceModuleType
): MaintenanceReleaseFormData {
  return {
    roomNumber: data.room!.code,
    maintenanceId: data.id,
    comment: data.comment,
    returnStatusCode: data.returnRoomStatus!.code as RoomStatus,
    state: data.state!.code,
    dashboardType,
    housekeepingRoomVersion: data.housekeepingRoomVersion,
    statusCode: data.status!.code
  } as MaintenanceReleaseFormData;
}
