import React, { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPropertyDateFormats } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import originatorSourceIconMap from '@hkm/components/shared/Templates/Reservation/BookingNotesAccordion/originatorSourceIconMap';
import { dayjs } from '@hkm/utils/dayjs-extended';

import { BookingNote } from '@ac/library-api';
import { getSafeExternalContent } from '@ac/library-utils/dist/utils';
import {
  AcAccordionClampContent,
  AcAccordionHeader,
  AcAccordionItem
} from '@ac/mobile-components/dist/components/accordion';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  Color,
  FlexDirection,
  IconSize,
  TextSize
} from '@ac/mobile-components/dist/enums';

interface BookingNotesAccordionItemProps {
  id: string;
  isExpanded: boolean;
  bookingNote: BookingNote;
}

const BookingNotesAccordionItem: FC<BookingNotesAccordionItemProps> = (
  props: BookingNotesAccordionItemProps
) => {
  const prefix = 'notes-accordion-item';
  const formats = useSelector(selectPropertyDateFormats);
  const { content, created, originatorSource } = props.bookingNote;

  const icon = originatorSourceIconMap.has(originatorSource)
    ? originatorSourceIconMap.get(originatorSource)
    : undefined;
  const noteText = useMemo(() => getSafeExternalContent(content || '') ?? '', [
    content
  ]);

  return (
    <AcAccordionItem id={props.id} className={prefix}>
      <AcAccordionHeader>
        <AcFlex direction={FlexDirection.row} alignItems={AlignItems.center}>
          {icon && (
            <AcIcon
              icon={icon}
              className="ac-spacing-right-s"
              size={IconSize.Small}
            />
          )}
          {created && (
            <AcText color={Color.Black} size={TextSize.Main2}>
              {dayjs(created.dateTime).format(formats.shortDate)}
            </AcText>
          )}
        </AcFlex>
      </AcAccordionHeader>
      <AcAccordionClampContent minVisibleLines={1}>
        <div
          className={`${prefix}-content`}
          dangerouslySetInnerHTML={{ __html: noteText }}
        />
      </AcAccordionClampContent>
    </AcAccordionItem>
  );
};

export default memo(BookingNotesAccordionItem);
