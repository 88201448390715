import { MaintenanceFetchAttachmentActions } from '@hkm/shared/domain/maintenanceAttachment/fetchAttachement/maintenanceFetchAttachmentActions';

import { MaintenanceFile } from '@ac/library-api';
import { getListAction } from '@ac/library-utils/dist/redux-utils';

export function createMaintenanceFetchAttachmentActions(
  prefixResourceName: string
): MaintenanceFetchAttachmentActions {
  return {
    fetchAttachments: getListAction<undefined, MaintenanceFile[]>(
      `${prefixResourceName}/attachments/fetchAttachments`
    )
  };
}
