import { isReservationInActiveStatus } from '@hkm/shared/reservations/isReservationInActiveStatus';
import {
  ReservedKindMaintenance,
  ReservedKindReservation
} from '@hkm/shared/reservedKind/groupReservedKinds';
import { ReservedKind } from '@hkm/shared/reservedKind/reservedKind';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { ReservationStatus, RoomMaintenanceState } from '@ac/library-api';

export interface ReservedKindProps {
  reservation?: ReservedKindReservation;
  maintenance?: ReservedKindMaintenance;
  previousReservedKind?: ReservedKind;
  futureReservations?: UnifiedReservationDetails[];
}

export function getReservedKind({
  reservation,
  maintenance,
  previousReservedKind,
  futureReservations
}: ReservedKindProps): ReservedKind {
  const isActiveReservation = isReservationInActiveStatus(reservation?.status);

  if (reservation?.status === ReservationStatus.CO && !maintenance) {
    return ReservedKind.None;
  }

  if (
    reservation &&
    isActiveReservation &&
    ((previousReservedKind !== ReservedKind.Reservation && !maintenance) ||
      !maintenance ||
      reservation?.status === ReservationStatus.IH ||
      reservation?.status === ReservationStatus.DO)
  ) {
    return ReservedKind.Reservation;
  }

  if (
    maintenance &&
    maintenance.state === RoomMaintenanceState.Active &&
    (!isActiveReservation ||
      previousReservedKind === ReservedKind.Reservation ||
      reservation?.status === ReservationStatus.CO)
  ) {
    return ReservedKind.Maintenance;
  }

  if (futureReservations?.length) {
    return ReservedKind.Reservation;
  }

  return ReservedKind.None;
}
