import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import RoomStatusBadge from '@hkm/components/shared/RoomStatusBadge/RoomStatusBadge';
import { MaintenanceReleaseFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceReleaseForm/models/maintenanceReleaseData';
import {
  DictionaryLabelLength,
  extractDictionaryEntryLabel
} from '@hkm/shared/dictionaries/dictionaryItemsLabelExtractor';
import { useEnabledRoomStates } from '@hkm/shared/hooks/useEnabledRoomStates';
import { getFieldErrorMessage } from '@hkm/shared/validation/errorProvider';
import { UnifiedRoomDetails } from '@hkm/types/room/model/UnifiedRoomDetails';

import {
  DictionaryEntity,
  RoomMaintenanceState,
  RoomStatus
} from '@ac/library-api';
import {
  AcFormElement,
  AcFormGroup
} from '@ac/mobile-components/dist/components/form-element';
import {
  AcSelect,
  AcSelectValue
} from '@ac/mobile-components/dist/components/select';
import { AcTextarea } from '@ac/mobile-components/dist/components/textarea';
import {
  AcTile,
  AcTileGroup
} from '@ac/mobile-components/dist/components/tile';
import { Icon } from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { formFieldFactory, FormRenderProps } from '@ac/react-infrastructure';

export interface MaintenanceReleaseBodyProps extends Childless {
  roomDetails: UnifiedRoomDetails;
  formProps: FormRenderProps<MaintenanceReleaseFormData>;
}

const FormField = formFieldFactory<MaintenanceReleaseFormData>();

/* tslint:disable:jsx-no-lambda */
const MaintenanceReleaseBody: FC<MaintenanceReleaseBodyProps> = (
  props: MaintenanceReleaseBodyProps
) => {
  const { t } = useTranslation();
  const prefix = 'maintenance-release-body';

  const states = useEnabledRoomStates();
  const roomStates: AcSelectValue[] = states.map((state: DictionaryEntity) => ({
    value: state.code!,
    itemLabel: extractDictionaryEntryLabel(state, DictionaryLabelLength.Long)
  }));

  function onReturnStatusChange(newStatus: RoomStatus) {
    props.formProps.form.change('returnStatusCode', newStatus);
  }

  return (
    <AcTileGroup className={prefix}>
      <AcTile title={t('ROOM_DETAILS.ROOM_INFORMATION')} icon={Icon.Room}>
        <AcFormGroup>
          <AcFormElement
            label={t('GLOBAL.ROOM_NUMBER.LONG')}
            testSelector={formatTestSelector(prefix, 'roomNumber')}
          >
            {props.roomDetails.roomNumber}
          </AcFormElement>
          <AcFormElement
            label={t('GLOBAL.ROOM_TYPE.LONG')}
            testSelector={formatTestSelector(prefix, 'roomType')}
          >
            {t('ROOM_DETAILS.ROOM_TYPE_FORMAT', {
              code: props.roomDetails.roomType?.code,
              name: props.roomDetails.roomType?.description
            })}
          </AcFormElement>
        </AcFormGroup>
      </AcTile>

      <AcTile title={t('ROOM_DETAILS.MAINTENANCE')} icon={Icon.Eod}>
        <AcFormGroup>
          {props.formProps.values.state === RoomMaintenanceState.Active && (
            <FormField valuePath="returnStatusCode">
              {fieldRenderProps => (
                <AcSelect
                  className="ac-spacing-top-md"
                  showInputs={true}
                  label={t('GLOBAL.RETURN_STATUS.LONG')}
                  onChange={onReturnStatusChange}
                  itemsList={roomStates}
                  selectedItem={fieldRenderProps.input.value as string}
                  testSelector={`${prefix}-return-status`}
                  itemTemplate={item => (
                    <RoomStatusBadge
                      status={item.value as RoomStatus}
                      showLabel={true}
                    />
                  )}
                  validation={getFieldErrorMessage(fieldRenderProps)}
                  required={true}
                  placeholder={t('GLOBAL.SELECT')}
                />
              )}
            </FormField>
          )}

          <FormField valuePath="comment">
            {fieldRenderProps => (
              <AcTextarea
                {...fieldRenderProps.input}
                placeholder={t('GLOBAL.FILL')}
                testSelector={`${prefix}-comment`}
                validation={getFieldErrorMessage(fieldRenderProps)}
                label={t('MAINTENANCE_RELEASE.COMMENT')}
                maxlength={255}
                required={true}
              />
            )}
          </FormField>
        </AcFormGroup>
      </AcTile>
    </AcTileGroup>
  );
};

export default memo(MaintenanceReleaseBody);
