import {
  ReservedKindMaintenance,
  ReservedKindReservation
} from '@hkm/shared/reservedKind/groupReservedKinds';
import { ReservedKind } from '@hkm/shared/reservedKind/reservedKind';
import { dayjs } from '@hkm/utils/dayjs-extended';

interface Props {
  maintenance?: ReservedKindMaintenance;
  reservation?: ReservedKindReservation;
}

/**
 * TODO should be imported from domain in housekeeping repository
 */
export function getNextReservedKind({
  maintenance,
  reservation
}: Props): ReservedKind {
  if (maintenance || reservation) {
    if (maintenance && !reservation) {
      return ReservedKind.Maintenance;
    }
    if (reservation && !maintenance) {
      return ReservedKind.Reservation;
    }
    if (
      dayjs(maintenance!.fromTime).isSameOrBefore(
        dayjs(reservation!.arrivalDate)
      )
    ) {
      return ReservedKind.Maintenance;
    }
    return ReservedKind.Reservation;
  }

  return ReservedKind.None;
}
