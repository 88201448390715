import { MaintenanceUploadAttachmentActions } from '@hkm/shared/domain/maintenanceAttachment/uploadAttachement/maintenanceUploadAttachmentActions';
import {
  AddMaintenanceAttachmentData,
  MaintenanceAttachmentsTileLoadedFile,
  RemoveMaintenanceAttachmentData,
  UpdateMaintenanceAttachmentData
} from '@hkm/shared/domain/maintenanceAttachment/uploadAttachement/models/maintenanceUploadAttachmentData';

import { RoomMaintenancesAttachmentPathParam } from '@ac/library-api';
import {
  createInApiAction,
  deleteAction,
  getOneAction,
  updateAction
} from '@ac/library-utils/dist/redux-utils';

export function createMaintenanceAttachmentActions(
  prefixResourceName: string
): MaintenanceUploadAttachmentActions {
  return {
    addAttachment: createInApiAction<AddMaintenanceAttachmentData, string>(
      `${prefixResourceName}/attachments/addAttachment`
    ),
    removeAttachment: deleteAction<RemoveMaintenanceAttachmentData, string>(
      `${prefixResourceName}/attachments/removeAttachment`
    ),
    updateAttachment: updateAction<UpdateMaintenanceAttachmentData, string>(
      `${prefixResourceName}/attachments/updateAttachment`
    ),
    getAttachmentFile: getOneAction<
      RoomMaintenancesAttachmentPathParam,
      MaintenanceAttachmentsTileLoadedFile
    >(`${prefixResourceName}/attachments/getAttachmentFile`)
  };
}
