import APP_CONSTANTS from '@hkm/constants/app.constants';

import { SessionService } from '@ac/library-utils/dist/services';

export const trySavePropertyIdFromURL = () => {
  const search = window.location.search;
  const searchElements = search.split('&');
  const newPropertyIdSearchElement = searchElements.find(item =>
    item.includes(APP_CONSTANTS.NEW_PROPERTY_ID)
  );

  if (newPropertyIdSearchElement) {
    const newPropertyId = newPropertyIdSearchElement.split('=')[1];
    SessionService.setPropertyId(newPropertyId);
    window.location.replace(window.location.pathname);
  }
};
