import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import * as appActions from '@hkm/components/App/domain/actions';
import { ChangeRoomStatusResult } from '@hkm/components/Housekeeping/Batch/domain/interfaces';
import BatchStatusResultBody from '@hkm/components/Housekeeping/Batch/statusResult/Body/BatchStatusResultBody';
import BatchStatusResultSubHeader from '@hkm/components/Housekeeping/Batch/statusResult/SubHeader/BatchStatusResultSubHeader';
import ConnectedFooter from '@hkm/components/shared/LayoutComponents/ConnectedFooter';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { getFailedActions } from '@hkm/shared/helpers/actionResult';

import { HousekeepingRoomDetailsDto } from '@ac/library-api';
import { AcButton } from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AlignItems,
  FlexDirection,
  JustifyContent
} from '@ac/mobile-components/dist/enums';

interface BatchStatusResultProps {
  result: ChangeRoomStatusResult[];
}

const BatchStatusResult: FC<BatchStatusResultProps> = (
  props: BatchStatusResultProps
) => {
  const notChangedRooms = getFailedActions<HousekeepingRoomDetailsDto>(
    props.result
  );

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const prefix = 'batch-status-result';
  const errorSize = notChangedRooms.length;
  const successSize = props.result.length - notChangedRooms.length;

  function onCancelChangeStatus(): void {
    navigate(APP_ROUTES.HOUSEKEEPING.DASHBOARD);
    dispatch(
      appActions.displayWarning(t('HOUSEKEEPING_BATCH.NOT_ALL_ROOMS_CHANGED'))
    );
  }

  const subheader = useMemo(
    () => (
      <BatchStatusResultSubHeader
        errorSize={errorSize}
        successSize={successSize}
      />
    ),
    [errorSize, successSize]
  );

  return (
    <>
      <ConnectedHeader close={onCancelChangeStatus} testSelector={prefix}>
        {t('HOUSEKEEPING_BATCH.STATUS_RESULT.TITLE')}
      </ConnectedHeader>
      <BatchStatusResultBody
        changeRoomResults={notChangedRooms}
        subheader={subheader}
      />
      <ConnectedFooter className={`${prefix}-footer`}>
        <AcFlex
          direction={FlexDirection.column}
          justifyContent={JustifyContent.center}
          alignItems={AlignItems.center}
          className="ac-spacing-top-sm ac-spacing-bottom-sm"
        >
          <AcButton
            onClick={onCancelChangeStatus}
            fullwidth={true}
            testSelector={`${prefix}-cancel-button`}
          >
            <AcButtonContent text={t('GLOBAL.CLOSE')} />
          </AcButton>
        </AcFlex>
      </ConnectedFooter>
    </>
  );
};

export default memo(BatchStatusResult);
