import React, { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import { TaskQuickFilterItem } from '@hkm/components/TaskManagement/domain/interfaces/taskQuickFilterItem';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';

import {
  AcButton,
  AcButtonPattern,
  AcButtonShape
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { Icon } from '@ac/mobile-components/dist/enums';

import '@hkm/components/shared//QuickFilter/QuickFilter.css';

interface DashboardQuickFilterItemProps {
  quickFilter: TaskQuickFilterItem;
  applyQuickFilter: (quickFilter: TaskQuickFilter) => void;
  appliedQuickFilter?: TaskQuickFilter;
}

const DashboardQuickFilterItem: FC<DashboardQuickFilterItemProps> = ({
  quickFilter,
  applyQuickFilter,
  appliedQuickFilter
}: DashboardQuickFilterItemProps) => {
  const relations = useSelector(getRelationsState);
  const isActive: boolean = useMemo(
    () => quickFilter.value === appliedQuickFilter,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appliedQuickFilter]
  );

  const toggleQuickFilter = useCallback(
    () => applyQuickFilter(quickFilter.value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appliedQuickFilter, relations.currentUser]
  );

  return (
    <AcButton
      className="quick-filter-button ac-spacing-left-sm"
      onClick={toggleQuickFilter}
      pattern={isActive ? AcButtonPattern.LightSelected : AcButtonPattern.Light}
      testSelector={`task-quick-filter-${quickFilter.value}`}
      shape={AcButtonShape.Rectangle}
    >
      <AcButtonContent
        icon={isActive ? Icon.Check : undefined}
        text={quickFilter.label}
      />
    </AcButton>
  );
};

export default memo(DashboardQuickFilterItem);
