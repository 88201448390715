import * as actions from '@hkm/components/Maintenance/Statistics/domain/actions';
import { OOOStatisticsStateData } from '@hkm/components/Maintenance/Statistics/domain/interfaces';
import {
  all,
  cancel,
  delay,
  put,
  race,
  take,
  takeLatest
} from '@redux-saga/core/effects';
import { Task } from '@redux-saga/types';

import { HousekeepingViewsApi } from '@ac/library-api/dist/api/v0/housekeeping';

const refreshCooldown: number = 20 * 1000;

function* refreshData() {
  // Repeat all the time
  while (true) {
    const target: { [key in keyof OOOStatisticsStateData]: Promise<any> } = {
      maintenanceSummary: HousekeepingViewsApi.getMaintenanceSummary()
    };

    // Resolve the object
    try {
      const resolved: OOOStatisticsStateData = yield all(target as any);
      yield put(actions.fetch.success(resolved));
    } catch (e) {
      yield put(actions.fetch.failure(e));
    }

    // Wait for delay or if someone flushed the data
    yield race([take(actions.flush), delay(refreshCooldown)]);
  }
}

function* handleStart() {
  // Clean page at start
  yield put(actions.flush());

  // Initialize the page refresher and restart it with each trigger
  const refreshTask: Task = yield takeLatest(
    actions.fetch.trigger,
    refreshData
  );

  // Fetch first page
  yield put(actions.fetch.trigger());

  // Wait for refresh disable or a failed request ...
  yield race([take(actions.disableRefresh), take(actions.fetch.failure)]);

  // .. and then cancel the refresher task. Now we wait for another start.
  yield cancel(refreshTask);
}

export default function* oooStatisticsSagas() {
  yield takeLatest(actions.enableRefresh, handleStart);
}
