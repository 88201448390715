import { Dictionary } from '@hkm/shared/dictionaries/dictionary';

export const AttendantAssignmentsDictionaries: Set<Dictionary> = new Set<
  Dictionary
>()
  .add(Dictionary.CleanType)
  .add(Dictionary.RoomStatus)
  .add(Dictionary.HousekeepingStatus)
  .add(Dictionary.NoteTypes)
  .add(Dictionary.FrontDeskStatus)
  .add(Dictionary.Attribute)
  .add(Dictionary.RoomCondition);
