import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OOOStatisticsStateData } from '@hkm/components/Maintenance/Statistics/domain/interfaces';
import StatisticsSection from '@hkm/components/shared/StatisticsSection/StatisticsSection';
import { StatisticsSectionData } from '@hkm/components/shared/StatisticsSection/StatisticsSectionData';

import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';

interface MaintenanceStatisticsBodyProps extends Childless {
  data: OOOStatisticsStateData;
}

const MaintenanceStatisticsBody: FC<MaintenanceStatisticsBodyProps> = (
  props: MaintenanceStatisticsBodyProps
) => {
  const { t } = useTranslation();
  const maintenanceSummary = props.data.maintenanceSummary;

  const allSummary: StatisticsSectionData | undefined = useMemo(
    () =>
      maintenanceSummary
        ? {
            id: 'all',
            title: t('MAINTENANCE_STATISTICS.ALL'),
            subtitle: '',
            mainValue: maintenanceSummary.current,
            items: [
              {
                label: t('MAINTENANCE_STATISTICS.OUT_OF_ORDER'),
                value: maintenanceSummary.currentOutOfOrder
              },
              {
                label: t('MAINTENANCE_STATISTICS.OUT_OF_SERVICE'),
                value: maintenanceSummary.currentOutOfService
              }
            ]
          }
        : undefined,
    [
      maintenanceSummary?.current,
      maintenanceSummary?.currentOutOfService,
      maintenanceSummary?.currentOutOfOrder
    ]
  );

  const pendingSummary: StatisticsSectionData | undefined = useMemo(
    () =>
      maintenanceSummary
        ? {
            id: 'pending',
            title: t('MAINTENANCE_STATISTICS.PENDING'),
            subtitle: '',
            mainValue: maintenanceSummary.pending,
            items: [
              {
                label: t('MAINTENANCE_STATISTICS.OUT_OF_ORDER'),
                value: maintenanceSummary.pendingOutOfOrder
              },
              {
                label: t('MAINTENANCE_STATISTICS.OUT_OF_SERVICE'),
                value: maintenanceSummary.pendingOutOfService
              }
            ]
          }
        : undefined,
    [
      maintenanceSummary?.pending,
      maintenanceSummary?.pendingOutOfService,
      maintenanceSummary?.pendingOutOfOrder
    ]
  );

  const activeSummary: StatisticsSectionData | undefined = useMemo(
    () =>
      maintenanceSummary
        ? {
            id: 'active',
            title: t('MAINTENANCE_STATISTICS.ACTIVE'),
            subtitle: '',
            mainValue: maintenanceSummary.active,
            items: [
              {
                label: t('MAINTENANCE_STATISTICS.OUT_OF_ORDER'),
                value: maintenanceSummary.activeOutOfOrder
              },
              {
                label: t('MAINTENANCE_STATISTICS.OUT_OF_SERVICE'),
                value: maintenanceSummary.activeOutOfService
              }
            ]
          }
        : undefined,
    [
      maintenanceSummary?.active,
      maintenanceSummary?.activeOutOfService,
      maintenanceSummary?.activeOutOfOrder
    ]
  );

  const displayWidgets = [allSummary, pendingSummary, activeSummary].filter(
    Boolean
  ) as StatisticsSectionData[];

  return useMemo(
    () => (
      <AcBody>
        {displayWidgets.map(item => (
          <StatisticsSection data={item} key={item.id} />
        ))}
      </AcBody>
    ),
    [...displayWidgets]
  );
};

export default memo(MaintenanceStatisticsBody);
