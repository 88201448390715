import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TasksTravelAgentProfile } from '@hkm/components/TaskManagement/models/profiles';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import CommunicationChannels from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Profile/CommunicationChannels/CommunicationChannels';
import { getProfileAddress } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Profile/helpers';

import { ProfilesDashboardAddressDto } from '@ac/library-api';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { FlexDirection } from '@ac/mobile-components/dist/enums';

const CompanyProfile = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const profile = linkedObject!.object as TasksTravelAgentProfile;

  const profileAddress = useMemo(
    () => getProfileAddress(profile.addresses as ProfilesDashboardAddressDto[]),
    [profile.addresses]
  );

  return (
    <AcFlex direction={FlexDirection.column}>
      <AcFormElement
        className="ac-spacing-top-sm ac-spacing-bottom-sm"
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.TRAVEL_AGENT')}
        testSelector="task-profile-name"
      >
        {profile.displayName}
      </AcFormElement>
      <CommunicationChannels
        additonalInfo={
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.PROFILE.ADDRESS')}
            testSelector="task-profile-address"
          >
            {profileAddress ?? '-'}
          </AcFormElement>
        }
      />
    </AcFlex>
  );
};

export default CompanyProfile;
