import { uniq } from 'lodash';

/**
 * First element in the queue is the older one
 * All new elements are moved to the end of the queue
 * If element in newQueue is already cached then move it to end of the queue as well
 * If we break the limit then remove redundant elements from start of the queue
 *
 * @param cachedQueue
 * @param newQueue
 * @param limit Means how many images we can cache
 */
export function refreshImagesQueue(cachedQueue: string[], newQueue: string[], limit: number = 0): string[] {
  const freshImageQueue = uniq(cachedQueue.concat(newQueue).reverse()).reverse();
  const sliceFrom = freshImageQueue.length - limit;

  return freshImageQueue.slice(sliceFrom > 0 ? sliceFrom : 0);
}
