import React, { FC, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getRoomStatesMap } from '@hkm/components/App/domain/selectors';
import * as actionBatchStatus from '@hkm/components/Housekeeping/Batch/domain/actions';
import { ChangeRoomStatusResult } from '@hkm/components/Housekeeping/Batch/domain/interfaces';
import RoomStatusBadge from '@hkm/components/shared/RoomStatusBadge/RoomStatusBadge';
import {
  DictionaryLabelLength,
  extractDictionaryEntryLabel
} from '@hkm/shared/dictionaries/dictionaryItemsLabelExtractor';
import { useEnabledRoomStates } from '@hkm/shared/hooks/useEnabledRoomStates';

import { DictionaryEntity, RoomStatus } from '@ac/library-api';
import { AcBox } from '@ac/mobile-components/dist/components/box';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { AcSelect } from '@ac/mobile-components/dist/components/select';
import { AcSelectValue } from '@ac/mobile-components/dist/components/select/interfaces/AcSelectValue';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  Color,
  FlexDirection,
  Icon,
  IndicatorSize,
  JustifyContent,
  TextSize,
  TextWeight
} from '@ac/mobile-components/dist/enums';

import './BatchStatusResultBody.css';

interface BatchStatusResultBody {
  changeRoomResults: ChangeRoomStatusResult[];
  subheader: ReactNode;
}

/* tslint:disable:jsx-no-lambda */
const BatchStatusResultBody: FC<BatchStatusResultBody> = (
  props: BatchStatusResultBody
) => {
  const prefix = 'batch-status-result';
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const roomStatesMap = useSelector(getRoomStatesMap);
  const roomStates: AcSelectValue[] = useEnabledRoomStates().map(
    (state: DictionaryEntity) => ({
      value: state.code!,
      itemLabel: extractDictionaryEntryLabel(state, DictionaryLabelLength.Long)
    })
  );

  const onRoomStatusChange = (result: ChangeRoomStatusResult) => {
    return (newStatus: RoomStatus) => {
      dispatch(
        actionBatchStatus.changeSingleRoomStatus.trigger({
          ...result,
          statusValue: newStatus
        })
      );
    };
  };

  return (
    <AcBody className={`${prefix}-body`} subheader={props.subheader}>
      {props.changeRoomResults.map(result => {
        const selectedRoomState: string = result.item.roomStatus!.code!;
        const selectedRoomStateObject = roomStatesMap.get(selectedRoomState);

        return (
          <AcFlex
            justifyContent={JustifyContent.center}
            alignItems={AlignItems.flexStart}
            className="room-row"
            key={result.item.id}
            testSelector={`${prefix}-item`}
          >
            <AcBox size={1}>
              <AcIndicator
                icon={Icon.Exclamation}
                backgroundColor={Color.Warning}
                size={IndicatorSize.Small}
                className="ac-spacing-top-s"
              />
            </AcBox>
            <AcBox size={5}>
              <AcFlex
                direction={FlexDirection.column}
                className="ac-spacing-top-xxs"
              >
                <AcText
                  color={Color.Gray1}
                  size={TextSize.Main2}
                  weight={TextWeight.Semibold}
                >
                  {t('GLOBAL.ROOM_NUMBER.LONG')}
                </AcText>
                <AcText color={Color.Black} size={TextSize.Main1}>
                  {result.item.roomNumber!.code}
                </AcText>
              </AcFlex>
            </AcBox>
            <AcBox size={6}>
              <AcSelect
                showInputs={true}
                label={t('GLOBAL.ROOM_STATUS.LONG')}
                onChange={onRoomStatusChange(result)}
                itemsList={roomStates}
                selectedItem={result.item.roomStatus!.code!}
                unlistedValueLabel={extractDictionaryEntryLabel(
                  selectedRoomStateObject,
                  DictionaryLabelLength.Long
                )}
                itemTemplate={item => (
                  <RoomStatusBadge
                    status={item.value as RoomStatus}
                    showLabel={true}
                  />
                )}
                required={true}
              />
            </AcBox>
          </AcFlex>
        );
      })}
    </AcBody>
  );
};

export default memo(BatchStatusResultBody);
