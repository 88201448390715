import camelCase from 'lodash/camelCase';

import {
  ApiErrorDetail,
  EmptyInterface,
  ErrorDetail,
  ErrorReport
} from '@ac/library-api';

import { getItemWithSuffix } from '../helpers/getItemWithSuffix';
import { getShortDateTime } from '../helpers/getShortDateTime';
import { isISODate } from '../helpers/isValidDate';

import { ErrorParameterHandler } from './errorHandlers';

export function groupErrorsByType(
  errorReport: ErrorReport
): Map<string, ErrorDetail[]> {
  return errorReport.details.reduce((map, report) => {
    if (report.code) {
      map.has(report.code)
        ? map.get(report.code)!.push(report)
        : map.set(report.code, [report]);
    }

    return map;
  }, new Map<string, ErrorDetail[]>());
}

export function extractParamFromErrorsDetails(
  errorsDetail: Array<ErrorDetail | ApiErrorDetail>,
  paramName: string
): any[] {
  return errorsDetail
    .map(errorDetail => {
      const errorParameter = Array.isArray(errorDetail.parameters)
        ? errorDetail.parameters.find(param => param.key === paramName)
        : errorDetail.parameters[paramName];

      return errorParameter ? errorParameter.value : null;
    })
    .filter(Boolean);
}

export async function handleErrorParameters(
  handlerMap: Map<string, ErrorParameterHandler>,
  parameters: EmptyInterface
) {
  return Object.entries(parameters).reduce(
    async (parameterObjectPromise, [parameterKey, parameterValue]) => {
      const parameterObject = await parameterObjectPromise;
      const handlerKey = getItemWithSuffix(
        Array.from(handlerMap.keys()),
        parameterKey
      );
      if (!handlerKey) {
        const simpleParameterValue = isISODate(parameterValue)
          ? getShortDateTime(parameterValue)
          : parameterValue;

        return {
          [camelCase(parameterKey)]: simpleParameterValue,
          ...parameterObject
        };
      }
      const handler = handlerMap.get(handlerKey)!;
      const parsedParameterValue = await handler(parameters);

      return { [handlerKey]: parsedParameterValue, ...parameterObject };
    },
    {}
  );
}
