import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UsersSelectorProvider } from '@hkm/components/shared/features/UsersSelector/store/Provider';
import { UsersSelector } from '@hkm/components/shared/features/UsersSelector/UsersSelector';
import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';

import { TaskUser } from '@ac/library-api';
import {
  AcButton,
  AcButtonPattern
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader,
  AcModalPattern
} from '@ac/mobile-components/dist/components/modal';
import { FlexDirection, Icon } from '@ac/mobile-components/dist/enums';

import './../TaskModals.css';

export interface ComponentProps {
  isVisible: boolean;
  onChange: (user: TaskUser) => void;
  onClose: () => void;
}

const TaskAssignToUserModal: FC<ComponentProps> = (props: ComponentProps) => {
  const { t } = useTranslation();
  const { isVisible, onClose, onChange } = props;
  const [selectedUser, setSelectedUser] = useState<
    MappedConfigurationUser | undefined
  >();

  const clearModal = useCallback(() => {
    setSelectedUser(undefined);
  }, []);

  const closeModal = useCallback(() => {
    clearModal();
    onClose();
  }, [clearModal, onClose]);

  const selectUser = useCallback(
    (selectedUsers?: MappedConfigurationUser[]) => {
      if (!selectedUsers?.length) {
        setSelectedUser(undefined);
        return;
      }
      setSelectedUser(selectedUsers[0]);
    },
    []
  );

  const onUserAssign = useCallback(() => {
    if (!selectedUser) {
      return;
    }
    onChange({
      firstName: selectedUser.personalData?.firstName || '',
      lastName: selectedUser.personalData?.lastName || '',
      id: selectedUser.id || '',
      userName: selectedUser.userName || ''
    });
    clearModal();
  }, [clearModal, onChange, selectedUser]);

  return (
    <AcModal
      className="task-modal"
      isOpen={isVisible}
      onClose={closeModal}
      pattern={AcModalPattern.Drawer}
    >
      <AcModalHeader showCloseButton className="task-modal-header">
        {t('TASK_MANAGEMENT.DASHBOARD.OPTIONS.ASSIGN_TO_USER')}
      </AcModalHeader>
      <AcModalBody className="modal-body">
        <UsersSelectorProvider>
          <UsersSelector
            label={t('TASK_MANAGEMENT.MODALS.SEARCH_FOR_USER')}
            selectedUserIds={selectedUser?.id ? [selectedUser.id] : undefined}
            onSelectUsers={selectUser}
          />
        </UsersSelectorProvider>
      </AcModalBody>
      <AcModalFooter className="task-modal-footer">
        <AcFlex
          className="full-width footer-buttons"
          direction={FlexDirection.column}
          grow={true}
        >
          <AcButton className="full-width" onClick={onUserAssign}>
            <AcButtonContent text={t('TASK_MANAGEMENT.MODALS.ASSIGN')} />
          </AcButton>
          <AcButton
            pattern={AcButtonPattern.Tertiary}
            className="full-width"
            onClick={closeModal}
          >
            <AcButtonContent icon={Icon.Cancel} text={t('GLOBAL.CANCEL')} />
          </AcButton>
        </AcFlex>
      </AcModalFooter>
    </AcModal>
  );
};

export default memo(TaskAssignToUserModal);
