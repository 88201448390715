import { PostingViewState } from '@hkm/components/Posting/PostingView/domain/interfaces';
import { Store } from '@hkm/store/interfaces/store';
import { createSelector } from 'reselect';

export const selectPostingViewState = (state: Store): PostingViewState => state.postingView;

export const selectPostingTransactionCodesState = createSelector(selectPostingViewState, state => {
  return state.transactionCodeState;
});

export const selectPostingReservationChargedState = createSelector(selectPostingViewState, state => {
  return state.reservationChargedState;
});

export const selectIsLoadingPostingView = createSelector(selectPostingViewState, state => {
  return state.reservationChargesPostingState.isPosting
    || state.transactionCodeState.isChanging || state.transactionCodeState.isFetching
    || state.reservationChargedState.isChanging || state.reservationChargedState.isFetching;
});
