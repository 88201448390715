import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import { AcSubheader } from '@ac/mobile-components/dist/components/layout';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  Color,
  Icon,
  IndicatorSize,
  JustifyContent,
  TextSize
} from '@ac/mobile-components/dist/enums';

import './BatchStatusResultSubHeader.css';

interface BatchStatusResultSubHeaderProps {
  successSize: number;
  errorSize: number;
}

const BatchStatusResultSubHeader: FC<BatchStatusResultSubHeaderProps> = (
  props: BatchStatusResultSubHeaderProps
) => {
  const prefix = 'batch-status-result';

  const { t } = useTranslation();

  return (
    <AcSubheader className={`${prefix}-subheader`}>
      <AcFlex
        className="ac-spacing-sm"
        justifyContent={JustifyContent.flexStart}
        alignItems={AlignItems.center}
      >
        <AcIndicator
          icon={Icon.Check}
          backgroundColor={Color.Good}
          className="ac-spacing-right-sm"
          size={IndicatorSize.Small}
        />
        <AcText size={TextSize.Main2}>
          {t('HOUSEKEEPING_BATCH.STATUS_RESULT.ITEMS_CHANGED', {
            items: props.successSize
          })}
        </AcText>
      </AcFlex>
      <AcFlex
        className="ac-spacing-sm"
        justifyContent={JustifyContent.flexStart}
        alignItems={AlignItems.center}
      >
        <AcIndicator
          icon={Icon.Exclamation}
          backgroundColor={Color.Warning}
          className="ac-spacing-right-sm"
          size={IndicatorSize.Small}
        />
        <AcText size={TextSize.Main2}>
          {t('HOUSEKEEPING_BATCH.STATUS_RESULT.ITEMS_NOT_CHANGED', {
            items: props.errorSize
          })}
        </AcText>
      </AcFlex>
    </AcSubheader>
  );
};

export default memo(BatchStatusResultSubHeader);
