import React, {
  FC,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as postingRoomActions from '@hkm//components/Posting/shared/domain/actions';
import * as actions from '@hkm/components/Posting/Dashboard/domain/actions';
import {
  selectPostingDashboardFilters,
  selectPostingDashboardPagination
} from '@hkm/components/Posting/Dashboard/domain/selectors';
import PostingDashboardRoomItem from '@hkm/components/Posting/Dashboard/RoomItem/PostingDashboardRoomItem';
import PostingDashboardSubHeader from '@hkm/components/Posting/Dashboard/SubHeader/PostingDashboardSubHeader';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import PaginatedTileList from '@hkm/components/shared/PaginatedList/PaginatedTileList';
import { HousekeepingNotificationEnvelopeContent } from '@hkm/services/signalRClient/envelopeContent/HousekeepingNotificationEnvelopeContent';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { useNotification } from '@hkm/shared/hooks/useNotification';

import { NotificationEnvelope } from '@ac/library-api/dist/types/entities/signalr/notificationEnvelope';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

const HousekeepingPostingDashboard: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filters = useSelector(selectPostingDashboardFilters);
  const pagination = useSelector(selectPostingDashboardPagination);

  const bodyScrollRef = useRef<HTMLDivElement>(null);
  const hasFilters = !!Object.values(filters).filter(Boolean).length;

  const onFiltersReset = useCallback(() => {
    dispatch(actions.pagination.setFilters({}));
  }, []);

  const onPageRequest = useCallback(
    (pageNumber: number) => {
      dispatch(actions.pagination.fetchPage(pageNumber));
    },
    [pagination.page]
  );

  const notificationCallback = useCallback(
    (data: NotificationEnvelope<HousekeepingNotificationEnvelopeContent>) => {
      dispatch(actions.patchChangedRoom(data.notification?.roomId));
    },
    []
  );

  useNotification(
    SignalREventType.HousekeepingRoomChanged,
    notificationCallback
  );

  useLayoutEffect(() => {
    if (pagination.pageId) {
      // Scroll table to top if we fetched a new page
      bodyScrollRef.current!.scrollTo(0, 0);
    }
  }, [bodyScrollRef.current, pagination.pageId]);

  useEffect(() => {
    dispatch(actions.pagination.enableAutoRefresh());
    dispatch(postingRoomActions.reset());

    return () => {
      dispatch(actions.pagination.disableAutoRefresh());
    };
  }, []);

  const roomItems: ReactNode[] = useMemo(
    () =>
      (pagination.page?.results || []).map(room => {
        const testSelector = formatTestSelector(
          'posting-dashboard-room',
          room.roomNumber?.code
        );

        return (
          <PostingDashboardRoomItem
            room={room}
            testSelector={testSelector}
            key={room.id}
          />
        );
      }),
    [pagination.page?.results]
  );

  const subheader = useMemo(() => <PostingDashboardSubHeader />, []);

  return (
    <>
      <ConnectedHeader showHelpButton={true}>
        {t('POSTING.DASHBOARD.TITLE')}
      </ConnectedHeader>
      <AcBody
        subheader={subheader}
        scrollRef={bodyScrollRef}
        showSpinner={pagination.isLoading}
        className="posting-dashboard-body"
      >
        <PaginatedTileList
          className="posting-dashboard-list"
          noContentTitle={t('ROOM_LIST.NO_CONTENT_TITLE')}
          isLoading={pagination.isLoading}
          hasFilters={hasFilters}
          onFiltersReset={onFiltersReset}
          onPageRequest={onPageRequest}
          paging={pagination.page?.paging}
        >
          {roomItems}
        </PaginatedTileList>
      </AcBody>
    </>
  );
};

export default memo(HousekeepingPostingDashboard);
