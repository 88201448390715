import APP_CONSTANTS from '@hkm/constants/app.constants';
import { ApiHeaders } from '@hkm/shared/enum/Headers';

import { Config, ConfigBuilder } from '@ac/library-api';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';

export interface StaticConfigurationOptions {
  propertyId?: string;
  headers?: { [key: string]: any };
  noCacheSkip?: boolean;
}

export function createDefaultConfigProvider(
  options?: StaticConfigurationOptions
): () => Promise<Config<any>> {
  return () => {
    options = options || {};
    const { propertyId, headers, noCacheSkip } = options;

    const combinedHeaders: any = headers ? { ...headers } : {};

    combinedHeaders['Accept-Language'] =
      SessionService.getLanguage()?.toUpperCase() ||
      APP_CONSTANTS.DATA_LANGUAGE;

    const authData = LoginService.authData();
    if (authData) {
      combinedHeaders.Authorization = `Bearer ${authData.token}`;
    }

    if (propertyId) {
      combinedHeaders['AC-Property-ID'] = propertyId;
    }

    const config = new ConfigBuilder()
      .setHeaders(combinedHeaders)
      .setSkipCache(!noCacheSkip)
      .getConfig();

    return new Promise<Config<any>>(resolve => resolve(config));
  };
}

export const getConfigurationWithLanguage = (
  header?: Config['headers'],
  skipCache: boolean = true
): Config => {
  const languageHeader = {
    [ApiHeaders.acceptLanguage]:
      SessionService.getLanguage()?.toUpperCase() || APP_CONSTANTS.DATA_LANGUAGE
  };

  return new ConfigBuilder()
    .setHeaders({
      ...header,
      ...languageHeader
    })
    .setSkipCache(skipCache)
    .getConfig();
};
