import { ReservedKindMaintenance } from '@hkm/shared/reservedKind/groupReservedKinds';
import { dayjs } from '@hkm/utils/dayjs-extended';

import { RoomMaintenanceState } from '@ac/library-api';

interface CurrentAndCurrentMaintenance {
  currentMaintenance?: ReservedKindMaintenance;
  nextMaintenance?: ReservedKindMaintenance;
}

export function getCurrentAndNextMaintenances(
  sortedMaintenances: ReservedKindMaintenance[],
  businessDate: string
): CurrentAndCurrentMaintenance {
  const mainMaintenance = sortedMaintenances.find(
    maintenance => maintenance.state === RoomMaintenanceState.Active
  );
  const nextMaintenance = sortedMaintenances.find(
    maintenance =>
      maintenance !== mainMaintenance &&
      (!mainMaintenance ||
        dayjs(maintenance.fromTime).isSameOrAfter(mainMaintenance.fromTime)) &&
      dayjs(maintenance.fromTime).isSameOrAfter(businessDate)
  );

  return { currentMaintenance: mainMaintenance, nextMaintenance };
}
