import React, { FC, memo, RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DashboardItemActionButton from '@hkm/components/TaskManagement/Dashboard/DashboardItem/DashboardItemActionButton/DashboardItemActionButton';
import { getPropertyShortDateFormat } from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import { TaskDashboardItemWithFlag } from '@hkm/components/TaskManagement/domain/interfaces/taskDashboardItemWithFlag';
import EscalatedFlag from '@hkm/components/TaskManagement/shared/components/EscalatedFlag';
import OverdueFlag from '@hkm/components/TaskManagement/shared/components/OverdueFlag';
import { TaskPreviewDetails } from '@hkm/components/TaskManagement/TaskPreview/domain/interfaces';
import DateTimeHelpers from '@hkm/components/TaskManagement/utils/dateTime';

import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  Color,
  FlexDirection,
  Icon,
  JustifyContent,
  TextSize,
  TextWeight
} from '@ac/mobile-components/dist/enums';

import './TaskOverview.css';

interface ComponentProps {
  task: TaskDashboardItemWithFlag | TaskPreviewDetails;
  index: number;
  dashboardView?: boolean;
  actionsButtonRef?: RefObject<HTMLDivElement>;
}

const DashboardItem: FC<ComponentProps> = props => {
  const { task, index, actionsButtonRef } = props;
  const { t } = useTranslation();
  const shortDateFormat = useSelector(getPropertyShortDateFormat);

  const separateTimeDate =
    task.dueDateTime && DateTimeHelpers.separateTimeDate(task.dueDateTime);
  const dueTime = separateTimeDate && separateTimeDate.dueTime;

  const dueDate = useMemo((): string | undefined => {
    if (!shortDateFormat) {
      return '';
    }

    return (
      separateTimeDate &&
      separateTimeDate.dueDate &&
      DateTimeHelpers.formatDateForField(
        separateTimeDate.dueDate,
        shortDateFormat.toUpperCase()!
      )
    );
  }, [shortDateFormat]);

  return (
    <AcFlex direction={FlexDirection.column}>
      <AcFlex
        className="task-header"
        justifyContent={JustifyContent.spaceBetween}
        alignItems={AlignItems.center}
      >
        {props.dashboardView ? (
          <>
            <AcText
              className="task-id-and-name"
              size={TextSize.Main2}
              color={Color.Gray1}
              weight={TextWeight.Semibold}
              testSelector={`task-item-id-and-name-${index}`}
            >
              {task.number} {task.name}
            </AcText>
            <DashboardItemActionButton
              task={task}
              index={index}
              actionsButtonRef={actionsButtonRef}
            />
          </>
        ) : (
          <AcFlex>
            <AcIcon icon={Icon.Activities} className="ac-spacing-right-sm" />
            <AcText
              uppercase={true}
              weight={TextWeight.Semibold}
              size={TextSize.Main2}
            >
              {t('GLOBAL.OVERVIEW')}
            </AcText>
          </AcFlex>
        )}
      </AcFlex>
      {!props.dashboardView && (
        <AcFormElement
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.TASK_NAME')}
          className="ac-spacing-top-md task-name"
        >
          {task.name}
        </AcFormElement>
      )}
      <div className="task-info-wrapper">
        <AcFlex
          direction={FlexDirection.column}
          className="ac-spacing-bottom-sm"
        >
          <AcText
            size={TextSize.Main2}
            color={Color.Gray1}
            weight={TextWeight.Semibold}
          >
            {t('TASK_MANAGEMENT.DASHBOARD.LABELS.STATUS')}
          </AcText>
          <AcBadge
            badgeText={task.taskStatus.code}
            backgroundColor={task.taskStatus.color || Color.Black}
            badgeTextSize={TextSize.Main2}
            label={task.taskStatusDescription}
            testSelector={`task-item-status-${index}`}
          />
        </AcFlex>

        <AcFlex
          direction={FlexDirection.column}
          className="ac-spacing-bottom-s"
        >
          <AcText
            size={TextSize.Main2}
            color={Color.Gray1}
            weight={TextWeight.Semibold}
          >
            {t('TASK_MANAGEMENT.DASHBOARD.LABELS.PRIORITY')}
          </AcText>
          <AcBadge
            badgeText={task.taskPriority.code}
            backgroundColor={task.taskPriority.color || Color.Black}
            badgeTextSize={TextSize.Main2}
            testSelector={`task-item-priority-${index}`}
          />
        </AcFlex>
        <AcFlex
          direction={FlexDirection.column}
          className="ac-spacing-bottom-s"
        >
          <AcText
            size={TextSize.Main2}
            color={Color.Gray1}
            weight={TextWeight.Semibold}
          >
            {t('TASK_MANAGEMENT.DASHBOARD.LABELS.DUE_DATE')}
          </AcText>
          <AcText size={TextSize.Main1} testSelector={`task-item-due-date`}>
            {dueDate}
          </AcText>
        </AcFlex>
        <AcFlex
          direction={FlexDirection.column}
          className="ac-spacing-bottom-s"
        >
          <AcText
            size={TextSize.Main2}
            color={Color.Gray1}
            weight={TextWeight.Semibold}
          >
            {t('TASK_MANAGEMENT.DASHBOARD.LABELS.DUE_TIME')}
          </AcText>
          <AcText size={TextSize.Main1} testSelector={`task-item-due-time`}>
            {dueTime}
          </AcText>
        </AcFlex>
        {task.isOverdue && (
          <OverdueFlag indexId={`task-management-task-${index}`} />
        )}
        {task.isEscalated && (
          <EscalatedFlag indexId={`task-management-task-${index}`} />
        )}
      </div>
    </AcFlex>
  );
};

export default memo(DashboardItem);
