import { createHousekeepingApiFilter } from '@hkm/components/Housekeeping/shared/filters/housekeepingApiFilterFactory';
import { HousekeepingFiltersData } from '@hkm/components/Housekeeping/shared/models/housekeepingFiltersData';
import * as actions from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/actions';
import { SetMaintenanceAttendantProcess } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/process/SetMaintenanceAttendantProcess';
import { SetMaintenanceHousekeepingProcess } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/process/SetMaintenanceHousekeepingProcess';
import {
  createMaintenanceCreateSaga,
  MaintenanceCreateSagaApi
} from '@hkm/shared/domain/maintenanceCreate/maintenanceCreateSagaFactory';
import { MaintenanceCreateData } from '@hkm/shared/domain/maintenanceCreate/models/maintenanceCreateData';
import {
  mapAttendantReservationDetails,
  mapHousekeepingReservationDetails
} from '@hkm/types/reservation/mappers/mapReservationDetails';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { fork } from 'redux-saga/effects';

import {
  AttendantAssignmentReservationDetails,
  AttendantAssignmentRoomDetails,
  AttendantAssignmentSheetDetails,
  ConflictDetails,
  HousekeepingRoomDetailsDto,
  HousekeepingRoomReservationDetailsDto,
  LibraryApiResponse,
  LongRunningProcess,
  RawPageResponse
} from '@ac/library-api';
import {
  HousekeepingViewsApi,
  MobileAttendantApi
} from '@ac/library-api/dist/api/v0/housekeeping';
import { HousekeepingRoomsApiV1 } from '@ac/library-api/dist/api/v1/housekeeping';

const attendantMaintenanceCreateApi: MaintenanceCreateSagaApi = {
  createMaintenanceProcess: (
    maintenanceCreateData: MaintenanceCreateData
  ): LongRunningProcess<any, any> => {
    const {
      data: { reasonCode, comment, allowOverbooking, statusCode }
    } = maintenanceCreateData;

    return new SetMaintenanceAttendantProcess(
      {
        reasonCode,
        comment,
        allowOverbooking,
        statusCode
      },
      maintenanceCreateData.roomId!,
      maintenanceCreateData.housekeepingRoomVersion!
    );
  },
  fetchReservation: async (
    roomsIds: string[]
  ): Promise<UnifiedReservationDetails[]> => {
    const attendantSheet: AttendantAssignmentSheetDetails = (await MobileAttendantApi.getCurrent()) as AttendantAssignmentSheetDetails;

    return attendantSheet.roomAssignments
      .filter((room: AttendantAssignmentRoomDetails) =>
        roomsIds.includes(room.id)
      )
      .map((room: AttendantAssignmentRoomDetails) => room.reservations)
      .flat()
      .map((reservation: AttendantAssignmentReservationDetails) =>
        mapAttendantReservationDetails(reservation)
      );
  },
  maintenanceValidation: async (
    maintenanceCreateData: MaintenanceCreateData
  ): Promise<ConflictDetails[]> => {
    const {
      data: { reasonCode, comment, allowOverbooking, statusCode },
      roomId
    } = maintenanceCreateData;

    return (await MobileAttendantApi.postSetMaintenancesValidation({
      data: { reasonCode, comment, allowOverbooking, statusCode },
      pathParams: { roomId: roomId! },
      ctor: ConflictDetails
    })) as ConflictDetails[];
  }
};

const housekeepingMaintenanceCreateApi: MaintenanceCreateSagaApi = {
  createMaintenanceProcess: (
    maintenanceCreateData: MaintenanceCreateData
  ): LongRunningProcess<any, any> => {
    return new SetMaintenanceHousekeepingProcess(maintenanceCreateData.data);
  },
  fetchReservation: async (
    roomsIds: string[]
  ): Promise<UnifiedReservationDetails[]> => {
    const filters: HousekeepingFiltersData = {
      roomRanges: roomsIds.join(',')
    };

    const page: LibraryApiResponse<RawPageResponse<
      HousekeepingRoomDetailsDto
    >> = (await HousekeepingViewsApi.getHousekeepingDashboard({
      queryParams: {
        filter: filters ? createHousekeepingApiFilter(filters) : undefined,
        pageNumber: 1
      }
    })) as LibraryApiResponse<RawPageResponse<HousekeepingRoomDetailsDto>>;

    return page.data.results
      .map((result: HousekeepingRoomDetailsDto) => result.reservations)
      .flat()
      .map((reservation: HousekeepingRoomReservationDetailsDto) =>
        mapHousekeepingReservationDetails(reservation)
      );
  },
  maintenanceValidation: async (
    maintenanceCreateData: MaintenanceCreateData
  ): Promise<ConflictDetails[]> => {
    const { data } = maintenanceCreateData;

    return (await HousekeepingRoomsApiV1.postSetMaintenancesValidation({
      data,
      ctor: ConflictDetails
    })) as ConflictDetails[];
  }
};

export default function* housekeepingBatchStatusSagas() {
  yield fork(
    createMaintenanceCreateSaga(
      actions.setAttendantMaintenanceActionsSet,
      attendantMaintenanceCreateApi
    )
  );
  yield fork(
    createMaintenanceCreateSaga(
      actions.setMaintenanceActionsSet,
      housekeepingMaintenanceCreateApi,
      true
    )
  );
}
