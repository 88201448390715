import { MaintenanceFiltersData } from '@hkm/components/Maintenance/shared/models/maintenanceFiltersData';

export const MaintenanceAdvancedFiltersOrder: Array<keyof MaintenanceFiltersData> = [
  'roomRanges',
  'roomCategories',
  'roomTypes',
  'floors',
  'locations',
  'returnStates',
  'maintenanceReasons',
  'validOn',
  'startsOn',
  'endsOn',
  'inoperationStates',
  'extraMaintenanceStates'
];
