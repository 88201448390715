import React, { FC, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AppRoutes from '@hkm/components/App/AppRoutes';
import { selectCurrentCustomer } from '@hkm/components/App/domain/selectors';
import { EnvironmentType } from '@hkm/shared/enum/environmentType';
import { applyClass } from '@hkm/utils/applyClass';
import { preventDoubleTapsZoom } from '@hkm/utils/doubleTaps';

const AppContent: FC = () => {
  const customer = useSelector(selectCurrentCustomer);

  useEffect(() => {
    document.addEventListener('touchend', preventDoubleTapsZoom, false);

    return () => {
      document.removeEventListener('touchend', preventDoubleTapsZoom);
    };
  }, []);

  useEffect(() => {
    if (!!customer && customer.nonProduction) {
      applyClass('add', document.body, `app-env-${EnvironmentType.Test}`);
    }
  }, [customer]);

  return <AppRoutes />;
};

export default memo(AppContent);
