import React, { FC, memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Navigate, useLocation, useParams } from 'react-router';
import * as detailsActions from '@hkm/components/Maintenance/Details/domain/actions';
import { selectMaintenanceDetailsState } from '@hkm/components/Maintenance/Details/domain/selectors';
import MaintenanceReleaseForm from '@hkm/components/shared/Templates/Maintenance/MaintenanceReleaseForm/MaintenanceReleaseForm';
import { isRoomReleasable } from '@hkm/components/shared/Templates/Maintenance/MaintenanceReleaseForm/utils/utils';
import { getMaintenanceState } from '@hkm/components/shared/Templates/Maintenance/shared/utils/getMaintenanceState';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { SourceModuleType } from '@hkm/shared/enum/SourceModuleType';

import { AcSpinnerCover } from '@ac/mobile-components/dist/components/spinner';

const ReleaseMaintenance: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const { id } = params;
  const roomState = useSelector(selectMaintenanceDetailsState);
  const maintenanceState = getMaintenanceState(roomState.room);

  useEffect(() => {
    dispatch(detailsActions.reset());
    dispatch(detailsActions.initialize(id!));
  }, []);

  const returnPath = useMemo(() => {
    const path = location.state?.returnPath;

    return path ? path : generatePath(APP_ROUTES.MAINTENANCE.DETAILS, { id });
  }, []);

  // Navigate to dashboard if we initialized, but no room is available
  if (
    (!roomState.room && roomState.didInitialize) ||
    (roomState.room && !isRoomReleasable(maintenanceState))
  ) {
    return <Navigate to={APP_ROUTES.MAINTENANCE.DASHBOARD} />;
  }

  const showSpinner =
    !roomState.didInitialize ||
    !roomState.didInitializeAttachments ||
    roomState.isChanging ||
    roomState.isFetching;

  return (
    <>
      {roomState.room && (
        <MaintenanceReleaseForm
          returnPath={returnPath}
          maintenanceRoom={roomState.room}
          dashboardType={SourceModuleType.Maintenance}
        />
      )}
      <AcSpinnerCover
        coverScreen={true}
        testSelector="maintenance-release-spinner"
        disabled={!showSpinner}
      />
    </>
  );
};

export default memo(ReleaseMaintenance);
