import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  TasksCompanyProfile,
  TasksIndividualProfile,
  TasksTravelAgentProfile
} from '@hkm/components/TaskManagement/models/profiles';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import { ProfileTypeToComponentMap } from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/constants';

const Profile = () => {
  const linkedObject = useSelector(getLinkedObject);
  const profile = linkedObject!.object as
    | TasksIndividualProfile
    | TasksCompanyProfile
    | TasksTravelAgentProfile;

  const Component = useMemo(
    () => ProfileTypeToComponentMap[profile.profileRole],
    [profile]
  );

  return <Component />;
};

export default Profile;
