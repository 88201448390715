import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionFormData } from '@hkm/components/Posting/PostingView/Body/PostingDetailsBody';
import APP_CONSTANTS from '@hkm/constants/app.constants';

import { TransactionCode2 } from '@ac/library-api';
import { AcDivider } from '@ac/mobile-components/dist/components/divider';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTile } from '@ac/mobile-components/dist/components/tile';
import {
  AlignItems,
  Icon,
  JustifyContent,
  TextSize,
  TextWeight
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { FormRenderProps } from '@ac/react-infrastructure';

import './PostingSummary.css';

export interface PostingSummaryProps extends Testable {
  formProps: FormRenderProps<TransactionFormData>;
  transactionCodesMap: Map<string, TransactionCode2>;
}

const PostingSummary: FC<PostingSummaryProps> = (
  props: PostingSummaryProps
) => {
  const { t } = useTranslation();
  const prefix = 'posting-view-summary-item';
  const { values } = props.formProps;

  const summaryTransactionCodes = useMemo(
    () =>
      Array.from(props.transactionCodesMap.values()).filter(
        (transactionCode: TransactionCode2) =>
          // eslint-disable-next-line no-prototype-builtins
          values.hasOwnProperty(transactionCode.id) &&
          values[transactionCode.id] > 0
      ),
    [props.formProps, props.transactionCodesMap]
  );

  const totalItems = useMemo(
    () =>
      Object.values(values).reduce((value: number, sum: number) => {
        return sum + value;
      }, 0),
    [props.formProps]
  );

  return (
    <AcTile
      icon={Icon.Billing}
      className="posting-view-summary"
      title={t('POSTING.POSTING_DETAILS.POSTING_SUMMARY')}
    >
      {!!totalItems && (
        <>
          {summaryTransactionCodes.map((transactionCode: TransactionCode2) => (
            <AcFlex
              key={transactionCode.id}
              alignItems={AlignItems.center}
              justifyContent={JustifyContent.spaceBetween}
              className="ac-spacing-bottom-sm"
            >
              <AcFlex
                justifyContent={JustifyContent.flexStart}
                alignItems={AlignItems.center}
                className={`${prefix}-name`}
              >
                <AcText
                  size={TextSize.Main2}
                  ellipsis={true}
                  testSelector={`${props.testSelector}-name`}
                >
                  {transactionCode.getSubCodeDescription(
                    APP_CONSTANTS.DATA_LANGUAGE
                  )}
                </AcText>
              </AcFlex>

              <AcFlex
                justifyContent={JustifyContent.flexEnd}
                alignItems={AlignItems.center}
                className={`${prefix}-count`}
              >
                <AcText
                  size={TextSize.Main2}
                  weight={TextWeight.Semibold}
                  testSelector={`${props.testSelector}-value`}
                >
                  {t('POSTING.POSTING_DETAILS.SUMMARY_ITEM', {
                    count: values[transactionCode.id]
                  })}
                </AcText>
              </AcFlex>
            </AcFlex>
          ))}

          <AcDivider className="ac-spacing-bottom-sm ac-spacing-top-sm" />

          <AcFlex
            alignItems={AlignItems.center}
            justifyContent={JustifyContent.spaceBetween}
          >
            <AcText size={TextSize.Main2}>
              {t('POSTING.POSTING_DETAILS.TOTAL')}
            </AcText>
            <AcText
              size={TextSize.Main2}
              weight={TextWeight.Semibold}
              testSelector={`${props.testSelector}-total-value`}
            >
              {t('POSTING.POSTING_DETAILS.SUMMARY_ITEM', {
                count: totalItems
              })}
            </AcText>
          </AcFlex>
        </>
      )}

      {!totalItems && (
        <AcFlex justifyContent={JustifyContent.center}>
          <AcText
            size={TextSize.Main2}
            testSelector={`${props.testSelector}-no-items`}
          >
            {t('POSTING.POSTING_DETAILS.SUMMARY_NO_ITEMS')}
          </AcText>
        </AcFlex>
      )}
    </AcTile>
  );
};

export default memo(PostingSummary);
