import { createMaintenanceApiFilter } from '@hkm/components/Maintenance/shared/models/factories/maintenanceApiFilterFactory';
import { MaintenanceFiltersData } from '@hkm/components/Maintenance/shared/models/maintenanceFiltersData';
import { RoomsNotFoundException } from '@hkm/shared/errors/ErrorException';
import { createMaintenanceRoom } from '@hkm/types/maintenance/factory/createMaintenanceRoom';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';

import {
  PageResponse,
  QueryQueryParams,
  RawRoomAssignmentMaintenanceDetails,
  RoomAssignmentMaintenanceDetails,
  RoomMaintenanceState
} from '@ac/library-api';
import { HousekeepingViewsApi } from '@ac/library-api/dist/api/v0/housekeeping';

// todo change to proper endpoint when it'll be available, for now we filter dashboard endpoint
export async function getMaintenanceRoomById(
  id: string
): Promise<MaintenanceRoom> {
  const filters: MaintenanceFiltersData = {
    id: [id],
    extraMaintenanceStates: [
      RoomMaintenanceState.Canceled,
      RoomMaintenanceState.Released
    ]
  };

  const page = (await HousekeepingViewsApi.getMaintenanceDashboard({
    queryParams: {
      filter: createMaintenanceApiFilter(filters),
      pageSize: 1
    } as QueryQueryParams
  })) as PageResponse<
    RawRoomAssignmentMaintenanceDetails,
    RoomAssignmentMaintenanceDetails
  >;

  if (page.results.length === 0) {
    throw new RoomsNotFoundException<string>(id);
  }

  return createMaintenanceRoom(page.results[0]);
}
