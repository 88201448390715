import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppContent from '@hkm/components/App/AppContent';
import NotificationConsumerManager from '@hkm/components/App/notificationConsumer/NotificationConsumerManager';
import { selectSystemUser } from '@hkm/components/Login/domain/selectors';
import AppSpinner from '@hkm/components/shared/AppSpinner/AppSpinner';
import {
  AppSpinnerContext,
  AppSpinnerContextData
} from '@hkm/components/shared/AppSpinner/appSpinnerContext';
import ConnectedOverlayMessageContainer from '@hkm/components/shared/OverlayMessage/ConnectedOverlayMessageContainer';
import { SystemUser } from '@hkm/shared/entities/SystemUser';

import { SessionDataHost } from '@ac/library-utils/dist/services';
import { AcSpinnerCover } from '@ac/mobile-components/dist/components/spinner';

import { userLogin } from '../Login/domain/actions';

const AppContainer: FC = () => {
  const dispatch = useDispatch();
  const systemUser: SystemUser | null = useSelector(selectSystemUser);
  const userResolved = !!systemUser && systemUser.type >= 0;
  const [appSpinnerRequests, setAppSpinnerRequests] = useState(
    new Set<string>()
  );

  const registerLoading = useCallback((id: string) => {
    setAppSpinnerRequests(set => new Set(set).add(id));
  }, []);

  const unregisterLoading = useCallback((id: string) => {
    setAppSpinnerRequests(set => {
      const newSet = new Set(set);
      newSet.delete(id);

      return newSet;
    });
  }, []);

  const appSpinnerContextData: AppSpinnerContextData = useMemo(
    () => ({ registerLoading, unregisterLoading }),
    []
  );

  const showAppSpinner = appSpinnerRequests.size > 0 || !userResolved;

  useEffect(() => {
    dispatch(userLogin.trigger());

    return SessionDataHost.startSessionDataUpdater();
  }, []);

  return (
    <NotificationConsumerManager>
      <AppSpinnerContext.Provider value={appSpinnerContextData}>
        <ConnectedOverlayMessageContainer />
        <React.Suspense fallback={<AppSpinner />}>
          {userResolved && <AppContent />}
        </React.Suspense>
        <AcSpinnerCover coverScreen={true} disabled={!showAppSpinner} />
      </AppSpinnerContext.Provider>
    </NotificationConsumerManager>
  );
};

export default memo(AppContainer);
