import * as actions from '@hkm/components/Posting/PostingView/domain/actions';
import { createTransactionCodesApiFilter } from '@hkm/components/Posting/PostingView/filters/TransactionCodesApiFilterFactory';
import { transactionCodesDefaultFilterValues } from '@hkm/components/Posting/PostingView/filters/TransactionCodesDefaultFilterValues';
import APP_CONSTANTS from '@hkm/constants/app.constants';
import { createReservationChargesSaga } from '@hkm/shared/domain/reservationCharges/reservationChargesSagaFactory';
import { fork, put, takeLatest } from 'redux-saga/effects';

import {
  PageResponse,
  RawTransactionCode2,
  TransactionCode2
} from '@ac/library-api';
import { TransactionCodeApi2 } from '@ac/library-api/dist/api/v0/configuration/handcraftedEntities';
import {
  HousekeepingReservationApi,
  MobileAttendantApi
} from '@ac/library-api/dist/api/v0/housekeeping';

function* fetchTransactionCodes() {
  try {
    const transactionCodesPage: PageResponse<
      RawTransactionCode2,
      TransactionCode2
    > = yield TransactionCodeApi2.getList({
      queryParams: {
        pageNumber: 1,
        pageSize: APP_CONSTANTS.MAX_FETCH_SIZE,
        filter: createTransactionCodesApiFilter(
          transactionCodesDefaultFilterValues
        )
      }
    }) as Promise<PageResponse<RawTransactionCode2, TransactionCode2>>;

    yield put(
      actions.fetchTransactionCodes.success(transactionCodesPage.results)
    );
  } catch (e) {
    yield put(actions.fetchTransactionCodes.failure(e));
  }
}

export default function* postingViewSagas() {
  yield fork(
    createReservationChargesSaga(
      actions.attendantReservationChargesActionsSet,
      MobileAttendantApi
    )
  );
  yield fork(
    createReservationChargesSaga(
      actions.housekeepingReservationChargesActionsSet,
      HousekeepingReservationApi
    )
  );
  yield takeLatest(
    actions.fetchTransactionCodes.trigger,
    fetchTransactionCodes
  );
}
