import { createMaintenanceCreateActions } from '@hkm/shared/domain/maintenanceCreate/maintenanceCreateActionsFactory';

import { createAction } from '@ac/library-utils/dist/redux-utils';

export const clearConflicts = createAction<undefined>(
  'maintenance/create/clearConflicts'
);

export const setMaintenanceActionsSet = createMaintenanceCreateActions(
  'maintenance/setMaintenance',
  {
    clearConflicts
  }
);
export const setAttendantMaintenanceActionsSet = createMaintenanceCreateActions(
  'attendant/maintenance/setMaintenance',
  {
    clearConflicts
  }
);
