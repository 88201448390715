import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { PermissionConjunction } from '@hkm/shared/permissions/enum/PermissionConjunction';
import { PermissionsConfig } from '@hkm/shared/permissions/types/permissionsConfig';

export const HousekeepingViewPermissions = [
  Permission.HousekeepingViewRooms
] as const;

export const HousekeepingViewPermissionsConfig: PermissionsConfig = [
  HousekeepingViewPermissions,
  PermissionConjunction.All
];
