import * as appActions from '@hkm/components/App/domain/actions';
import * as actions from '@hkm/components/TaskManagement/Dashboard/domain/actions';
import { DashboardSort } from '@hkm/components/TaskManagement/Dashboard/domain/enums';
import {
  Filters,
  SuccessDashboardFetch,
  TaskDashboardState,
  UpdateFiltersAndSearchPayload,
  UpdatePagingAndFiltersParams
} from '@hkm/components/TaskManagement/Dashboard/domain/interfaces';
import { mapDashboardItem } from '@hkm/components/TaskManagement/Dashboard/mappers';
import { TaskQuickFilter } from '@hkm/components/TaskManagement/domain/enums/TaskQuickFilter';
import { LinkObjectDependentSettings } from '@hkm/components/TaskManagement/domain/interfaces/linkObjectDependentSettings';
import { TaskStatusDictionary } from '@hkm/components/TaskManagement/relations/domain/interfaces';
import { ReducerMap } from 'redux-actions';

import { TaskDashboardItem, TaskStatus, TaskStatusRole } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';
import { LoginService } from '@ac/library-utils/dist/services';

const initialState: TaskDashboardState = {
  list: {
    results: [],
    paging: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0
    }
  },
  selectedFilters: {},
  sort: DashboardSort.DUE_DATE_ASC,
  isLoading: false,
  quickFilterName: TaskQuickFilter.MY_TASKS,
  linkObjectDependentSettings: undefined
};

const reducerMap: ReducerMap<TaskDashboardState, unknown> = {
  [appActions.clearAllViews]: () => ({ ...initialState }),
  [actions.clearFilteringState]: (
    state,
    action: Action<TaskStatusDictionary[]>
  ) => {
    const nonCompletedTaskStatuseIds: string[] = (action.payload || [])
      .filter(
        ({ role }: TaskStatus) =>
          role !== TaskStatusRole.Cancellation &&
          role !== TaskStatusRole.Completion
      )
      .map((status: TaskStatus) => status.id);

    const assigneeId = LoginService.authData()?.userId;
    const assignees = assigneeId ? [assigneeId] : undefined;

    return {
      ...state,
      isLoading: true,
      list: {
        results: [],
        paging: {
          pageNumber: 1,
          pageSize: 10,
          totalCount: 0
        }
      },
      selectedFilters: { assignees, taskStatuses: nonCompletedTaskStatuseIds },
      sort: DashboardSort.DUE_DATE_ASC,
      quickFilterName: TaskQuickFilter.MY_TASKS,
      searchValue: undefined
    };
  },
  [actions.fetchDashboard.trigger]: state => ({
    ...state,
    isLoading: true
  }),
  [actions.fetchDashboard.success]: (
    state,
    action: Action<SuccessDashboardFetch>
  ) => {
    const { list, propertyTimeZone } = action.payload;
    const mappedListResult = list.results.map((task: TaskDashboardItem) =>
      mapDashboardItem(task, propertyTimeZone)
    );

    return {
      ...state,
      list: {
        results: mappedListResult,
        paging: list.paging
      },
      isLoading: false
    };
  },
  [actions.fetchDashboard.failure]: state => ({
    ...state,
    isLoading: false
  }),
  [actions.updatePaginationPage]: (state, action: Action<number>) => ({
    ...state,
    isLoading: true,
    list: {
      ...state.list,
      paging: {
        ...state.list.paging!,
        pageNumber: action.payload
      }
    }
  }),
  [actions.fetchLinkObjectDependentSettings.trigger]: (
    state,
    action: Action<LinkObjectDependentSettings>
  ) => ({
    ...state,
    linkObjectDependentSettings: action.payload
  }),
  [actions.updateSelectedFilters]: (state, action: Action<Filters>) => ({
    ...state,
    isLoading: true,
    selectedFilters: action.payload
  }),
  [actions.updateSort]: (state, action: Action<DashboardSort>) => ({
    ...state,
    isLoading: true,
    sort: action.payload
  }),
  [actions.updatePagingAndFilters]: (
    state,
    action: Action<UpdatePagingAndFiltersParams>
  ) => ({
    ...state,
    isLoading: true,
    list: {
      ...state.list,
      paging: {
        ...state.list.paging!,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize
          ? action.payload.pageSize
          : state.list.paging.pageSize
      }
    },
    selectedFilters: {
      ...state.selectedFilters,
      ...action.payload.filters
    }
  }),
  [actions.updateQuickFilter]: (
    state,
    action: Action<TaskQuickFilter | undefined>
  ) => ({
    ...state,
    quickFilterName: action.payload
  }),
  [actions.updateSearchString]: (
    state,
    action: Action<string | undefined>
  ) => ({
    ...state,
    isLoading: true,
    searchValue: action.payload
  }),
  [actions.updateFiltersAndSearch]: (
    state,
    action: Action<UpdateFiltersAndSearchPayload>
  ) => ({
    ...state,
    isLoading: true,
    searchValue: action.payload.search?.searchValue,
    selectedFilters: {
      ...action.payload.filters
    }
  })
};

export default handleActions(reducerMap, initialState);
