import Account from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Account/Account';
import ArAccount from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/ArAccount/ArAccount';
import Group from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Group/Group';
import CompanyProfile from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Profile/CompanyProfile/CompanyProfile';
import IndividualProfile from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Profile/IndividualProfile/IndividualProfile';
import Profile from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Profile/Profile';
import TravelAgentProfile from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Profile/TravelAgentProfile/TravelAgentProfile';
import Reservation from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Reservation/Reservation';
import Room from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/Room/Room';

import { ProfileRole, TaskObjectType } from '@ac/library-api';

export const ObjectToComponentMap = {
  [TaskObjectType.ARAccount]: ArAccount,
  [TaskObjectType.Account]: Account,
  [TaskObjectType.Group]: Group,
  [TaskObjectType.Profile]: Profile,
  [TaskObjectType.Reservation]: Reservation,
  [TaskObjectType.Room]: Room
};

export const ProfileTypeToComponentMap = {
  [ProfileRole.CMP]: CompanyProfile,
  [ProfileRole.IND]: IndividualProfile,
  [ProfileRole.TA]: TravelAgentProfile
};
