import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoomConditions } from '@hkm/components/App/domain/selectors';
import classNames from 'classnames';

import { IdCodeDescriptionDto } from '@ac/library-api';
import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AlignItems, Color } from '@ac/mobile-components/dist/enums';
import {
  Childless,
  Styleable,
  Testable
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorProp } from '@ac/mobile-components/dist/utils';

import './RoomConditionsBadge.css';

interface RoomConditionsBadgeProps extends Styleable, Childless, Testable {
  roomConditions?: IdCodeDescriptionDto<string>[];
}

export const RoomConditionsBadge = ({
  roomConditions,
  testSelector,
  className,
  style
}: RoomConditionsBadgeProps): JSX.Element | null => {
  const { t } = useTranslation();
  const classNameProps = classNames('room-conditions-badge', className);
  const roomConditionDictionary = useSelector(getRoomConditions);
  const inactiveRoomConditions = useMemo(() => {
    return roomConditionDictionary
      ?.filter(item => !item.isActive)
      .map(room => room.code);
  }, [roomConditionDictionary]);

  const badgeText = useCallback(
    (room: IdCodeDescriptionDto<string>): string | undefined => {
      return inactiveRoomConditions?.find(code => code === room.code)
        ? t('GLOBAL.ROOM_CONDITIONS.INACTIVE', { code: room.code })
        : room.code;
    },
    [t, inactiveRoomConditions]
  );

  if (!roomConditions) {
    return null;
  }

  return (
    <AcFlex wrapped alignItems={AlignItems.center} className={classNameProps}>
      {roomConditions.map(room => (
        <AcBadge
          style={style}
          key={room.id}
          borderColor={Color.Gray4}
          backgroundColor={Color.White}
          className="room-conditions-badge-element"
          {...getTestSelectorProp(testSelector, 'room-conditions')}
          badgeText={badgeText(room)}
        />
      ))}
    </AcFlex>
  );
};
