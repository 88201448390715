import { EmptyInterface } from '@ac/library-api';

import { getAttendantCodeById } from '../helpers/api/getAttendantCodeById';
import { getCommunicationChannelModeById } from '../helpers/api/getCommunicationChannelMode';
import { getMaintenanceStatusCodeById } from '../helpers/api/getMaintenanceStatusCodeById';
import { getProfileFullNameById } from '../helpers/api/getProfileFullNameById';
import { getPropertyNameById } from '../helpers/api/getPropertyNameById';
import { getReservationConfirmationNumberById } from '../helpers/api/getReservationConfirmationNumberById';
import { getRoomCodeById } from '../helpers/api/getRoomCodeById';
import { getSheetNameById } from '../helpers/api/getSheetNameById';
import { getTransactionCodeById } from '../helpers/api/getTransactionCodeById';
import { listExpectedStatuses } from '../helpers/api/listExpectedStatuses';
import {
  withExtractedKeys,
  withKeysMappedToSuffix
} from '../helpers/objectUtils';

export enum API_ERROR_PARAMETER_NAMES {
  ROOM_ID = 'roomId',
  ATTENDANT_ID = 'attendantId',
  PROPERTY_ID = 'propertyId',
  TASK_PROGRESSES = 'taskProgresses',
  ROOM_STATUSES = 'roomStatuses',
  RESERVATION_ID = 'reservationId',
  RESERVATION_STATUSES = 'reservationStatuses',
  PROFILE_ID = 'profileId',
  SHEET_ID = 'sheetId',
  TRANSACTION_CODE_ID = 'transactionCodeId',
  COMMUNICATION_CHANNEL_ID = 'communicationChannelId',
  COMMUNICATION_MODES = 'expectedCommunicationMode',
  MAINTENANCE_STATUS_STATES = 'maintenanceStatusStates',
  MAINTENANCE_ID = 'maintenanceId',
  EXPECTED_TASK_PROGRESSES = 'expectedTaskProgresses'
}

export type ErrorParameterHandler = (
  parameters: EmptyInterface
) => Promise<string> | string;

export const ErrorParameterHandlers = new Map<string, ErrorParameterHandler>()
  .set(
    API_ERROR_PARAMETER_NAMES.ROOM_ID,
    withKeysMappedToSuffix(getRoomCodeById, [API_ERROR_PARAMETER_NAMES.ROOM_ID])
  )
  .set(
    API_ERROR_PARAMETER_NAMES.ATTENDANT_ID,
    withKeysMappedToSuffix(getAttendantCodeById, [
      API_ERROR_PARAMETER_NAMES.ATTENDANT_ID
    ])
  )
  .set(
    API_ERROR_PARAMETER_NAMES.PROPERTY_ID,
    withKeysMappedToSuffix(getPropertyNameById, [
      API_ERROR_PARAMETER_NAMES.PROPERTY_ID
    ])
  )
  .set(
    API_ERROR_PARAMETER_NAMES.RESERVATION_ID,
    withKeysMappedToSuffix(getReservationConfirmationNumberById, [
      API_ERROR_PARAMETER_NAMES.RESERVATION_ID
    ])
  )
  .set(
    API_ERROR_PARAMETER_NAMES.RESERVATION_STATUSES,
    withKeysMappedToSuffix(listExpectedStatuses, [
      API_ERROR_PARAMETER_NAMES.RESERVATION_STATUSES
    ])
  )
  .set(
    API_ERROR_PARAMETER_NAMES.PROFILE_ID,
    withKeysMappedToSuffix(getProfileFullNameById, [
      API_ERROR_PARAMETER_NAMES.PROFILE_ID
    ])
  )
  .set(
    API_ERROR_PARAMETER_NAMES.SHEET_ID,
    withKeysMappedToSuffix(getSheetNameById, [
      API_ERROR_PARAMETER_NAMES.SHEET_ID
    ])
  )
  .set(
    API_ERROR_PARAMETER_NAMES.TRANSACTION_CODE_ID,
    withKeysMappedToSuffix(getTransactionCodeById, [
      API_ERROR_PARAMETER_NAMES.TRANSACTION_CODE_ID
    ])
  )
  .set(
    API_ERROR_PARAMETER_NAMES.COMMUNICATION_CHANNEL_ID,
    withKeysMappedToSuffix(getCommunicationChannelModeById, [
      API_ERROR_PARAMETER_NAMES.PROFILE_ID,
      API_ERROR_PARAMETER_NAMES.COMMUNICATION_CHANNEL_ID
    ])
  )
  .set(
    API_ERROR_PARAMETER_NAMES.COMMUNICATION_MODES,
    withExtractedKeys(
      listExpectedStatuses,
      API_ERROR_PARAMETER_NAMES.COMMUNICATION_MODES
    )
  )
  .set(
    API_ERROR_PARAMETER_NAMES.EXPECTED_TASK_PROGRESSES,
    withExtractedKeys(
      listExpectedStatuses,
      API_ERROR_PARAMETER_NAMES.EXPECTED_TASK_PROGRESSES
    )
  )
  .set(
    API_ERROR_PARAMETER_NAMES.TASK_PROGRESSES,
    withExtractedKeys(
      listExpectedStatuses,
      API_ERROR_PARAMETER_NAMES.TASK_PROGRESSES
    )
  )
  .set(
    API_ERROR_PARAMETER_NAMES.ROOM_STATUSES,
    withExtractedKeys(
      listExpectedStatuses,
      API_ERROR_PARAMETER_NAMES.ROOM_STATUSES
    )
  )
  .set(
    API_ERROR_PARAMETER_NAMES.MAINTENANCE_STATUS_STATES,
    withExtractedKeys(
      listExpectedStatuses,
      API_ERROR_PARAMETER_NAMES.MAINTENANCE_STATUS_STATES
    )
  )
  .set(
    API_ERROR_PARAMETER_NAMES.MAINTENANCE_ID,
    withKeysMappedToSuffix(getMaintenanceStatusCodeById, [
      API_ERROR_PARAMETER_NAMES.MAINTENANCE_ID
    ])
  );
