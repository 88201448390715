import { getRoomsByIdMap } from '@hkm/components/App/domain/selectors';
import { getStore } from '@hkm/store/configureStore';

import { Room } from '@ac/library-api';
import { RoomApi } from '@ac/library-api/dist/api/v0/configuration/handcraftedEntities';

interface GetRoomCodeById {
  roomId: string;
}

export async function getRoomCodeById(parameters: GetRoomCodeById) {
  const { roomId: id } = parameters;
  try {
    const roomMap = getRoomsByIdMap(getStore());
    if (roomMap.has(id)) {
      return roomMap.get(id)!.code;
    }

    const roomById = (await RoomApi.getById({ pathParams: { id } })) as Room;

    return roomById.code ?? id;
  } catch (e) {
    return id;
  }
}
