import getTimestamp from '@hkm/shared/helpers/getTimestamp';

let lastTouchEnd = 0;

export function preventDoubleTapsZoom(event: { preventDefault: () => void; }): void {
  const now = getTimestamp();
  if (now - lastTouchEnd <= 300) {
    event.preventDefault();
  }
  lastTouchEnd = now;
}
