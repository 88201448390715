import * as appActions from '@hkm/components/App/domain/actions';
import * as actions from '@hkm/components/Posting/PostingView/domain/actions';
import { PostingViewState } from '@hkm/components/Posting/PostingView/domain/interfaces';
import { combineActions, ReducerMap } from 'redux-actions';

import {
  HousekeepingReservationCharges,
  TransactionCode2
} from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: PostingViewState = {
  reservationChargedState: {},
  transactionCodeState: {},
  reservationChargesPostingState: {}
};

const reducerMap: ReducerMap<PostingViewState, unknown> = {
  [appActions.clearAllViews]: () => ({ ...initialState }),
  [actions.fetchTransactionCodes.trigger]: state => ({
    ...state,
    transactionCodeState: {
      ...state.transactionCodeState,
      isFetching: true
    }
  }),
  [actions.fetchTransactionCodes.success]: (
    state,
    action: Action<TransactionCode2[]>
  ) => ({
    ...state,
    transactionCodeState: {
      ...state.transactionCodeState,
      isFetching: false,
      didInitialize: true,
      data: action.payload
    }
  }),
  [actions.fetchTransactionCodes.failure]: state => ({
    ...state,
    transactionCodeState: {
      ...state.transactionCodeState,
      isFetching: false,
      didInitialize: true,
      data: []
    }
  }),
  [combineActions(
    actions.attendantReservationChargesActionsSet.fetchReservationRecentCharges
      .trigger,
    actions.housekeepingReservationChargesActionsSet
      .fetchReservationRecentCharges.trigger
  ) as any]: state => ({
    ...state,
    reservationChargedState: {
      ...state.reservationChargedState,
      isFetching: true
    }
  }),
  [combineActions(
    actions.attendantReservationChargesActionsSet.fetchReservationRecentCharges
      .success,
    actions.housekeepingReservationChargesActionsSet
      .fetchReservationRecentCharges.success
  ) as any]: (state, action: Action<HousekeepingReservationCharges>) => ({
    ...state,
    reservationChargedState: {
      ...state.reservationChargedState,
      isFetching: false,
      didInitialize: true,
      data: action.payload
    }
  }),
  [combineActions(
    actions.attendantReservationChargesActionsSet.fetchReservationRecentCharges
      .failure,
    actions.housekeepingReservationChargesActionsSet
      .fetchReservationRecentCharges.failure
  ) as any]: state => ({
    ...state,
    reservationChargedState: {
      ...state.reservationChargedState,
      isFetching: false,
      didInitialize: true,
      data: undefined
    }
  }),
  [combineActions(
    actions.attendantReservationChargesActionsSet.postReservationCharges
      .trigger,
    actions.housekeepingReservationChargesActionsSet.postReservationCharges
      .trigger
  ) as any]: state => ({
    ...state,
    reservationChargesPostingState: {
      isPosting: true
    }
  }),
  [combineActions(
    actions.attendantReservationChargesActionsSet.postReservationCharges
      .failure,
    actions.attendantReservationChargesActionsSet.postReservationCharges
      .success,
    actions.housekeepingReservationChargesActionsSet.postReservationCharges
      .failure,
    actions.housekeepingReservationChargesActionsSet.postReservationCharges
      .success
  ) as any]: state => ({
    ...state,
    reservationChargesPostingState: {
      isPosting: false
    }
  }),
  [actions.resetReservationRecentCharges]: state => ({
    ...state,
    reservationChargedState: initialState.reservationChargedState
  })
};

export default handleActions(reducerMap, initialState);
