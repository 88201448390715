import { matchPath } from 'react-router-dom';
import {
  clearDictionaries,
  fetchDictionaries
} from '@hkm/components/App/domain/actions';
import * as actions from '@hkm/components/Menu/domain/actions';
import { APP_ROUTE_DICTIONARY_MAP } from '@hkm/constants/routing.constants';
import { createDefaultConfigProvider } from '@hkm/utils/api';
import i18n from 'i18next';
import { put, take, takeLatest } from 'redux-saga/effects';

import { BaseApi } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { SessionService } from '@ac/library-utils/dist/services';

import { LanguageDefinition } from './interfaces/languageDefinition';

function* handleLanguageSelect(action: Action<LanguageDefinition>) {
  const { languageCode, pathname } = action.payload;
  const requiredDictionaryKey =
    pathname &&
    Object.keys(APP_ROUTE_DICTIONARY_MAP).find(path =>
      matchPath(pathname, path)
    );
  const propertyId = SessionService.getPropertyId();

  i18n.changeLanguage(languageCode);

  SessionService.setLanguage(languageCode);

  BaseApi.defaultConfig = createDefaultConfigProvider({ propertyId });
  BaseApi.clearAllCache();

  if (requiredDictionaryKey) {
    yield put(clearDictionaries({ force: true }));
    yield put(
      fetchDictionaries.trigger(APP_ROUTE_DICTIONARY_MAP[requiredDictionaryKey])
    );
    yield take(fetchDictionaries.success);
  }
  yield put(actions.languageSelect.success(true));
}

export default function* languageSagas() {
  yield takeLatest(actions.languageSelect.trigger, handleLanguageSelect);
}
