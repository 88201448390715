import { ReservationItem } from '@ac/library-api';
import { ReservationApi } from '@ac/library-api/dist/api/v0/booking';

interface GetReservationConfirmationNumberById {reservationId: string; }

export async function getReservationConfirmationNumberById(parameters: GetReservationConfirmationNumberById) {
  const { reservationId: id } = parameters;
  try {
    const reservationById = (await ReservationApi.getById({
      pathParams: { id }
    })) as ReservationItem;

    return reservationById.confirmationNumber ?? id;
  } catch (e) {
    return id;
  }
}
