import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TasksGroup } from '@hkm/components/TaskManagement/models/groups';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Accordion/Accordion';
import AreaGrid from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/AreaGrid/AreaGrid';

import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { Color, FlexDirection } from '@ac/mobile-components/dist/enums';

const Group = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const { groupStatuses } = useSelector(getRelationsState);
  const group = linkedObject!.object as TasksGroup;

  const statusColor = useMemo(() => {
    return group.status
      ? groupStatuses.find(({ code }) => code === group.status?.code)?.color
      : Color.Black;
  }, [groupStatuses, group.status]);

  const roomNights = useMemo(() => {
    const roomNightsKeys = group.roomNights
      ? Object.keys(group.roomNights)
      : [];
    const filteredRoomNights = roomNightsKeys.filter(
      key => group.roomNights![key] > 0
    );

    if (filteredRoomNights.length) {
      return filteredRoomNights
        .map(
          key => `
            ${t(
              `TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.ROOM_NIGHTS.${key.toUpperCase()}`
            )} (${group.roomNights![key]})`
        )
        .join(', ');
    }

    return '-';
  }, [group.roomNights]);

  return (
    <AcFlex direction={FlexDirection.column}>
      {group.name && (
        <AcFormElement
          className="ac-spacing-top-sm ac-spacing-bottom-sm"
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.NAME')}
          testSelector="task-group-name"
        >
          {group.name}
        </AcFormElement>
      )}
      <Accordion
        header={
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.STATUS')}
          >
            {group.status ? (
              <AcBadge
                badgeText={group.status.code!}
                backgroundColor={statusColor}
                testSelector="task-group-status"
              />
            ) : (
              '-'
            )}
          </AcFormElement>
        }
        id={group.id}
      >
        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.CODE')}
          testSelector="task-group-code"
        >
          {group.key ?? '-'}
        </AcFormElement>
        <AreaGrid>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.START_DATE')}
            testSelector="task-group-start-date"
          >
            {group.startDate}
          </AcFormElement>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.COMMON.END_DATE')}
            testSelector="task-group-end-date"
          >
            {group.endDate}
          </AcFormElement>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.ROOM_NIGHTS.TITLE'
            )}
            testSelector="task-group-room-nights"
          >
            {roomNights}
          </AcFormElement>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.CUTOFF')}
            testSelector="task-group-cut-off"
          >
            {group.cutoffDate ?? '-'}
          </AcFormElement>
          <AcFormElement
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.DECISION')}
            testSelector="task-group-decision-date"
          >
            {group.decisionDate ?? '-'}
          </AcFormElement>
          <AcFormElement
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.GROUP.FOLLOW_UP')}
            testSelector="task-group-follow-up"
          >
            {group.nextStepDueDate ?? '-'}
          </AcFormElement>
        </AreaGrid>
      </Accordion>
    </AcFlex>
  );
};

export default Group;
