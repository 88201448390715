import React from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from '@hkm/shared/helpers/noop';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIcon } from '@ac/mobile-components/dist/components/icon';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  Color,
  FlexDirection,
  Icon,
  IconSize,
  JustifyContent,
  TextSize
} from '@ac/mobile-components/dist/enums';

import './AlertBarContent.sass';

interface Props {
  queueRoomsCount: number;
  serviceRequestedRoomsCount: number;
  showLink?: boolean;
  onShow?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const MultiElementAlertBar: React.FC<Props> = ({
  queueRoomsCount,
  serviceRequestedRoomsCount,
  showLink,
  onShow = noop
}) => {
  const { t } = useTranslation();

  return (
    <AcFlex
      className="alert-bar-content"
      justifyContent={JustifyContent.spaceBetween}
    >
      <AcFlex>
        <AcText size={TextSize.Main2}>
          <AcIcon
            className="ac-spacing-right-md"
            icon={Icon.Todo}
            color={Color.Warning}
            size={IconSize.Medium}
          />
        </AcText>
        <AcFlex
          justifyContent={JustifyContent.flexStart}
          direction={FlexDirection.column}
        >
          <AcText size={TextSize.Main2}>
            {t('ATTENDANT_ASSIGNMENTS.SHARED.ALERT_BAR.QUEUE_ROOMS', {
              count: queueRoomsCount
            })}
          </AcText>

          <AcText size={TextSize.Main2}>
            {t('ATTENDANT_ASSIGNMENTS.SHARED.ALERT_BAR.SERVICES_REQUESTED', {
              count: serviceRequestedRoomsCount
            })}
          </AcText>
        </AcFlex>
      </AcFlex>

      {showLink && (
        <a href="#" onClick={onShow} className="link">
          <AcText size={TextSize.Main2}>{t('GLOBAL.SHOW')}</AcText>
        </a>
      )}
    </AcFlex>
  );
};
