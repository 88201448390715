import * as appActions from '@hkm/components/App/domain/actions';
import { AreaRelations } from '@hkm/components/TaskManagement/constants/Areas';
import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';
import * as actions from '@hkm/components/TaskManagement/relations/domain/actions';
import {
  SuccessTasksRelationsFetch,
  TasksRelationsState
} from '@hkm/components/TaskManagement/relations/domain/interfaces';
import { ReducerMap } from 'redux-actions';

import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: TasksRelationsState = {
  activeTaskStatuses: [],
  activeTaskPriorities: [],
  responsibleDepartments: [],
  currentUser: undefined,
  taskStatuses: [],
  taskPriorities: [],
  departments: [],
  membershipLevels: [],
  groupStatuses: [],
  locations: [],
  profileTypes: [],
  guestStatuses: [],
  attributes: [],
  taskTags: [],
  taskTypes: [],
  areas: AreaRelations,
  areRelationsLoaded: false,
  isLoadingRelations: false
};

const reducerMap: ReducerMap<TasksRelationsState, unknown> = {
  [appActions.clearAllViews]: () => ({ ...initialState }),
  [actions.fetchTasksRelations.trigger]: state => ({
    ...state,
    isLoadingRelations: true
  }),
  [actions.fetchTasksRelations.failure]: state => ({
    ...state,
    isLoadingRelations: false
  }),
  [actions.fetchTasksRelations.success]: (
    state,
    action: Action<SuccessTasksRelationsFetch>
  ) => {
    const {
      taskStatus,
      taskPriority,
      responsibleDepartment,
      department,
      membershipLevel,
      groupStatus,
      location,
      profileType,
      guestStatus,
      attribute,
      taskTag,
      taskType
    } = action.payload.aggregatedRelations;
    return {
      ...state,
      responsibleDepartments: responsibleDepartment,
      departments: department,
      taskStatuses: taskStatus,
      membershipLevels: membershipLevel,
      groupStatuses: groupStatus,
      locations: location,
      profileTypes: profileType,
      guestStatuses: guestStatus,
      attributes: attribute,
      taskTags: taskTag,
      taskTypes: taskType,
      activeTaskStatuses: taskStatus.filter(
        (status: { isActive: boolean }) => status.isActive
      ),
      taskPriorities: taskPriority,
      activeTaskPriorities: taskPriority.filter(
        (status: { isActive: boolean }) => status.isActive
      ),
      areRelationsLoaded: true,
      currentUser: action.payload.currentUser,
      isLoadingRelations: false
    };
  },
  [actions.setTasksSelectedFilterUser.success]: (
    state,
    action: Action<MappedConfigurationUser[] | undefined>
  ) => ({
    ...state,
    assignees: action.payload
  })
};

export default handleActions(reducerMap, initialState);
