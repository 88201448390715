import { ImageBlob, ImagesResolutionTypeQueue } from '@hkm/components/App/imageQueue/domain/interfaces';

export function createImagesQueueFromBlobs(imagesBlob: ImageBlob[]): ImagesResolutionTypeQueue {
  const newImagesQueue: ImagesResolutionTypeQueue = { imageUrlMap: {}, imageQueue: [] };

  return imagesBlob.reduce((newImagesQueueTmp: ImagesResolutionTypeQueue, imageBlob: ImageBlob) => {
    newImagesQueueTmp.imageQueue.push(imageBlob.imageId);
    newImagesQueueTmp.imageUrlMap[imageBlob.imageId] = imageBlob.data
      ? URL.createObjectURL(imageBlob.data)
      : undefined;

    return newImagesQueueTmp;
  }, newImagesQueue);
}
