import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import {
  selectHousekeepingDashboardIsLoading,
  selectHousekeepingDashboardSort
} from '@hkm/components/Housekeeping/Dashboard/domain/selectors';
import { HousekeepingSort } from '@hkm/components/Housekeeping/shared/enum/housekeepingSort';
import { toCapitalSnakeCase } from '@hkm/shared/helpers/toCapitalSnakeCase';

import {
  AcSelect,
  AcSelectPattern,
  AcSelectValue
} from '@ac/mobile-components/dist/components/select';

const sorts: HousekeepingSort[] = [
  HousekeepingSort.Sequence,
  HousekeepingSort.RoomNumber,
  HousekeepingSort.RoomType,
  HousekeepingSort.FrontDeskStatus,
  HousekeepingSort.HousekeepingStatus,
  HousekeepingSort.RoomStatus
];

const HousekeepingDashboardSortSelect: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeSort = useSelector(selectHousekeepingDashboardSort);
  const isLoading = useSelector(selectHousekeepingDashboardIsLoading);

  const selectItems: AcSelectValue[] = sorts.map(sort => {
    const translationTag = toCapitalSnakeCase(sort);

    return {
      value: sort as string,
      itemLabel: t(`ROOM_LIST.SORT.LIST.${translationTag}`),
      inputLabel: t(`ROOM_LIST.SORT.INPUT.${translationTag}`)
    };
  });

  const onChange = useCallback((selectValue: HousekeepingSort) => {
    dispatch(actions.pagination.setSort(selectValue));
  }, []);

  return (
    <AcSelect
      label={t('COMMON.SORT_BY')}
      showInputs={true}
      onChange={onChange}
      disabled={isLoading}
      itemsList={selectItems}
      selectedItem={activeSort}
      pattern={AcSelectPattern.Dropdown}
      testSelector="housekeepingDashboard-sort"
    />
  );
};

export default memo(HousekeepingDashboardSortSelect);
