import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as dashboardActions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import {
  selectHousekeepingDashboardFilters,
  selectHousekeepingMode,
  selectHousekeepingSelectedRooms
} from '@hkm/components/Housekeeping/Dashboard/domain/selectors';
import HousekeepingFiltersChips from '@hkm/components/Housekeeping/shared/HousekeepingFiltersChips/HousekeepingFiltersChips';
import { HousekeepingFiltersData } from '@hkm/components/Housekeeping/shared/models/housekeepingFiltersData';
import ConnectedFooter from '@hkm/components/shared/LayoutComponents/ConnectedFooter';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { DashboardMode } from '@hkm/shared/enum/dashboardMode';
import { parseNumberOrTextToNumberRangeOrText } from '@hkm/shared/numberRange/numberRangesParser';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';

import {
  AcButton,
  AcButtonPattern
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AlignItems,
  FlexDirection,
  Icon,
  JustifyContent
} from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';

import './HousekeepingDashboardFooter.css';

export interface HousekeepingDetailsFooterProps extends Childless {
  rooms: HousekeepingRoom[];
}

const HousekeepingDashboardFooter: FC<HousekeepingDetailsFooterProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardMode = useSelector(selectHousekeepingMode);
  const selectedRooms = useSelector(selectHousekeepingSelectedRooms);

  const filters = useSelector(selectHousekeepingDashboardFilters);
  const prefix = 'housekeeping-dashboard-footer';

  function onFiltersChange(newFilters: HousekeepingFiltersData) {
    dispatch(dashboardActions.pagination.setFilters(newFilters));
  }

  function onSelectRoomHandler(): void {
    const roomsCodes = selectedRooms.map(
      item => item.numericRoomNumber || item.roomNumber
    );
    dispatch(dashboardActions.clearHousekeepingSelection());
    dispatch(dashboardActions.setHousekeepingMode(DashboardMode.Default));

    navigate(APP_ROUTES.HOUSEKEEPING.BATCH, {
      state: {
        roomsCodes: parseNumberOrTextToNumberRangeOrText(roomsCodes)
      }
    });
  }

  function onCancelSelectHandler(): void {
    dispatch(dashboardActions.clearHousekeepingSelection());
    dispatch(dashboardActions.setHousekeepingMode(DashboardMode.Default));
  }

  return (
    <ConnectedFooter className={prefix} noPadding={true}>
      {dashboardMode === DashboardMode.Default && (
        <HousekeepingFiltersChips
          deleteKeys={true}
          filters={filters}
          className={`${prefix}-chips`}
          onChange={onFiltersChange}
        />
      )}

      {dashboardMode === DashboardMode.Selection && (
        <AcFlex
          direction={FlexDirection.column}
          justifyContent={JustifyContent.center}
          alignItems={AlignItems.center}
          className="ac-spacing-top-sm"
        >
          <AcButton
            onClick={onSelectRoomHandler}
            fullwidth={true}
            testSelector={`${prefix}-select-room-button`}
            disabled={selectedRooms.length === 0}
          >
            {t('ROOM_LIST.FOOTER.SELECT_ROOM_BUTTON')}
          </AcButton>
          <AcButton
            onClick={onCancelSelectHandler}
            pattern={AcButtonPattern.Tertiary}
            testSelector={`${prefix}-cancel-selection-button`}
          >
            <AcButtonContent icon={Icon.Cancel} text={t('GLOBAL.CANCEL')} />
          </AcButton>
        </AcFlex>
      )}
    </ConnectedFooter>
  );
};

export default memo(HousekeepingDashboardFooter);
