import * as appActions from '@hkm/components/App/domain/actions';
import { AttendantAssignmentDataState } from '@hkm/components/Attendant/shared/domain/interfaces/assignmentData';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { AttendantSheet } from '@hkm/types/attendant/models/AttendantSheet';
import { combineActions, ReducerMap } from 'redux-actions';

import { MaintenanceFile } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

import * as actions from './actions';

const initialState: AttendantAssignmentDataState = {
  assignedSheet: {},
  staleRoomsIds: []
};

const reducerMap: ReducerMap<AttendantAssignmentDataState, unknown> = {
  [appActions.clearAllViews]: () => ({ ...initialState }),

  [actions.setViewingAttendantRoom]: (
    state,
    action: Action<AttendantRoom | undefined>
  ) => ({
    ...state,
    viewingAttendantRoom: action.payload
  }),
  [actions.addStaleRoomIds]: (state, action: Action<string[]>) => ({
    ...state,
    staleRoomsIds: [...state.staleRoomsIds, ...action.payload]
  }),
  [actions.setStaleSheetVersionId]: (state, action: Action<number>) => ({
    ...state,
    staleSheetVersionId: action.payload
  }),
  [actions.clearAttendantStaleData]: state => ({
    ...state,
    staleRoomsIds: [],
    staleSheetVersionId: undefined
  }),
  [actions.fetchAssignedSheet.trigger]: state => ({
    ...state,
    assignedSheet: {
      ...state.assignedSheet,
      isFetching: true
    }
  }),
  [actions.fetchAssignedSheet.failure]: state => ({
    ...state,
    assignedSheet: {
      ...state.assignedSheet,
      isFetching: false,
      didInitialize: true,
      isWaitingForRefresh: false
    }
  }),
  [actions.fetchAssignedSheet.success]: (
    state,
    action: Action<AttendantSheet>
  ) => ({
    ...state,
    assignedSheet: {
      ...state.assignedSheet,
      isFetching: false,
      didInitialize: true,
      data: action.payload,
      isWaitingForRefresh: false
    }
  }),
  [actions.runAttendantTask.trigger]: state => ({
    ...state,
    assignedSheet: {
      ...state.assignedSheet,
      isTaskChanging: true
    }
  }),
  [actions.runAttendantTask.success]: state => ({
    ...state,
    assignedSheet: {
      ...state.assignedSheet,
      isWaitingForRefresh: true,
      isTaskChanging: false
    }
  }),
  [actions.runAttendantTask.failure]: state => ({
    ...state,
    assignedSheet: {
      ...state.assignedSheet,
      isWaitingForRefresh: false,
      isTaskChanging: false
    }
  }),
  [actions.maintenanceAttachment.fetchAttachments.trigger]: state => ({
    ...state,
    isFetchingAttachments: true
  }),
  [actions.maintenanceAttachment.fetchAttachments.failure]: state => ({
    ...state,
    isFetchingAttachments: false,
    didInitializeAttachments: true
  }),
  [actions.maintenanceAttachment.fetchAttachments.success]: (
    state,
    action: Action<MaintenanceFile[]>
  ) => ({
    ...state,
    attachments: action.payload,
    isFetchingAttachments: false,
    didInitializeAttachments: true
  }),
  [combineActions(
    actions.roomOccupancy.setRoomOccupancy.trigger,
    actions.roomOccupancy.removeRoomOccupancy.trigger
  ) as any]: state => ({
    ...state,
    assignedSheet: {
      ...state.assignedSheet,
      isChanging: true
    }
  }),
  [combineActions(
    actions.greenService.toggleGreenService.trigger,
    actions.guestService.changeGuestServiceStatus.trigger,
    actions.roomConditions.changeRoomConditions.trigger
  ) as any]: state => ({
    ...state,
    assignedSheet: {
      ...state.assignedSheet,
      isChanging: true
    }
  }),
  [combineActions(
    actions.greenService.toggleGreenService.success,
    actions.greenService.toggleGreenService.failure,
    actions.roomConditions.changeRoomConditions.success,
    actions.roomConditions.changeRoomConditions.failure,
    actions.guestService.changeGuestServiceStatus.success,
    actions.guestService.changeGuestServiceStatus.failure,
    actions.roomOccupancy.setRoomOccupancy.success,
    actions.roomOccupancy.setRoomOccupancy.failure,
    actions.roomOccupancy.removeRoomOccupancy.success,
    actions.roomOccupancy.removeRoomOccupancy.failure
  ) as any]: state => ({
    ...state,
    assignedSheet: {
      ...state.assignedSheet,
      isChanging: false
    }
  })
};

export default handleActions(reducerMap, initialState);
