import React, { FC, memo, PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MenuBehaviour } from '@hkm/components/Menu/domain/interfaces';
import { selectMenuBehaviour } from '@hkm/components/Menu/domain/selectors';
import HeaderCloseButton from '@hkm/components/shared/LayoutComponents/HeaderCloseButton';
import HeaderHelpButton from '@hkm/components/shared/LayoutComponents/HeaderHelpButton';
import HeaderMenuButton from '@hkm/components/shared/LayoutComponents/HeaderMenuButton';
import { splitLinkOrCallback } from '@hkm/shared/helpers/splitLinkOrCallback';
import { LinkOrCallback } from '@hkm/shared/interfaces/linkOrCallback';

import {
  AcHeader,
  AcHeaderProps
} from '@ac/mobile-components/dist/components/layout';

interface Props extends AcHeaderProps {
  forceHideMenu?: boolean;
  close?: LinkOrCallback;
  showHelpButton?: boolean;
}

const ConnectedHeader: FC<PropsWithChildren<Props>> = ({
  children,
  left,
  right,
  close,
  showHelpButton,
  forceHideMenu,
  ...divProps
}) => {
  const showMenuButton =
    useSelector(selectMenuBehaviour) !== MenuBehaviour.Disable &&
    !forceHideMenu;
  const closeProps = splitLinkOrCallback(close);

  const extendedLeft = useMemo(
    () => (
      <>
        {showMenuButton && <HeaderMenuButton />}
        {left}
      </>
    ),
    [left, showMenuButton]
  );

  const extendedRight = useMemo(
    () => (
      <>
        {right}
        {showHelpButton && <HeaderHelpButton />}
        {close && (
          <HeaderCloseButton
            link={closeProps.link}
            onClick={closeProps.callback}
          />
        )}
      </>
    ),
    [right, showHelpButton, close]
  );

  return (
    <AcHeader left={extendedLeft} right={extendedRight} {...divProps}>
      {children}
    </AcHeader>
  );
};

export default memo(ConnectedHeader);
