import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { MaintenanceManagePermissionsConfig } from '@hkm/components/Maintenance/config/maintenanceManagePermissionsConfig';
import { isRoomReleasable } from '@hkm/components/shared/Templates/Maintenance/MaintenanceReleaseForm/utils/utils';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { ReturnPathLocationStates } from '@hkm/shared/interfaces/locationStates';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';

import { RoomMaintenanceState } from '@ac/library-api';
import { AcDropdownItem } from '@ac/mobile-components/dist/components/dropdown-list';
import {
  Childless,
  Testable
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

interface MaintenanceTableItemDropdownListProps extends Childless, Testable {
  room: MaintenanceRoom;
}

const MaintenanceDashboardRoomDropdownItems: FC<MaintenanceTableItemDropdownListProps> = props => {
  const { t } = useTranslation();
  const maintenanceStateCode = props.room.state!.code as RoomMaintenanceState;
  const id = props.room.id;
  const isReleasable = isRoomReleasable(maintenanceStateCode);
  const hasManagePermission = usePermission(
    ...MaintenanceManagePermissionsConfig
  );

  return (
    <>
      <AcDropdownItem
        link={generatePath(APP_ROUTES.MAINTENANCE.DETAILS, { id })}
        testSelector={formatTestSelector(props.testSelector, 'details')}
      >
        {t('MAINTENANCE_DETAILS.TITLE')}
      </AcDropdownItem>

      {isReleasable && hasManagePermission && (
        <AcDropdownItem
          link={{
            pathname: generatePath(APP_ROUTES.MAINTENANCE.RELEASE, { id }),
            state: {
              returnPath: APP_ROUTES.MAINTENANCE.DASHBOARD
            } as ReturnPathLocationStates
          }}
          testSelector={formatTestSelector(
            props.testSelector,
            maintenanceStateCode
          )}
        >
          {t(`MAINTENANCE_RELEASE.${maintenanceStateCode}.TITLE.SHORT`)}
        </AcDropdownItem>
      )}

      {hasManagePermission && (
        <AcDropdownItem
          link={generatePath(APP_ROUTES.MAINTENANCE.COPY, { id })}
          testSelector={formatTestSelector(props.testSelector, 'copy>')}
        >
          {t('MAINTENANCE_COPY.TITLE')}
        </AcDropdownItem>
      )}
    </>
  );
};

export default memo(MaintenanceDashboardRoomDropdownItems);
