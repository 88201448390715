import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';

import { CodeValue, ReservationDashboardMembershipDto } from '@ac/library-api';
import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { Color } from '@ac/mobile-components/dist/enums';

interface ComponentProps {
  vipStatusCode?: CodeValue;
  memberships?: Array<ReservationDashboardMembershipDto | CodeValue>;
}

const VipAndMember = (props: ComponentProps) => {
  const { membershipLevels, guestStatuses } = useSelector(getRelationsState);

  const memberships = useMemo(
    () =>
      (props.memberships || []).map(membership => {
        const membershipInLevels = membershipLevels.find(level => {
          if ((membership as ReservationDashboardMembershipDto).levelCode) {
            return (
              level.code ===
              (membership as ReservationDashboardMembershipDto).levelCode
            );
          }

          return level.code === (membership as CodeValue).code;
        });

        return {
          id: membershipInLevels?.id,
          text: membershipInLevels?.code,
          color: membershipInLevels?.color
        };
      }),
    [props.memberships, membershipLevels]
  );

  const vipStatus = useMemo(
    () =>
      props.vipStatusCode
        ? guestStatuses.find(({ code }) => code === props.vipStatusCode?.code)
        : undefined,
    [props.vipStatusCode, guestStatuses]
  );

  return (
    <>
      {vipStatus && (
        <AcBadge
          className="ac-spacing-right-sm ac-spacing-bottom-sm"
          badgeText={vipStatus.code}
          backgroundColor={vipStatus.color || Color.Warning}
          testSelector="task-area-vip"
        />
      )}
      {!!memberships.length &&
        memberships.map((membership, index) => (
          <AcBadge
            key={membership.id}
            className="ac-spacing-right-sm ac-spacing-bottom-sm"
            badgeText={membership.text}
            backgroundColor={membership.color || Color.Alert}
            testSelector={`task-area-membership-${index}`}
          />
        ))}
    </>
  );
};

export default VipAndMember;
