import React, { FC, memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { MaintenanceManagePermissionsConfig } from '@hkm/components/Maintenance/config/maintenanceManagePermissionsConfig';
import MaintenanceDetailsBody from '@hkm/components/Maintenance/Details/Body/MaintenanceDetailsBody';
import * as detailsActions from '@hkm/components/Maintenance/Details/domain/actions';
import { selectMaintenanceDetailsState } from '@hkm/components/Maintenance/Details/domain/selectors';
import MaintenanceDetailsFooter from '@hkm/components/Maintenance/Details/Footer/MaintenanceDetailsFooter';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import { selectMaintenanceAttachmentsState } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/domain/selectors';
import { createMaintenanceAttachmentDataFromResponse } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/factories/maintenanceAttachmentDataFactory';
import { MaintenanceAttachmentData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/maintenanceAttachmentData';
import { selectMaintenanceUpdateState } from '@hkm/components/shared/Templates/Maintenance/shared/domain/selectors';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { MaintenanceNotificationEnvelopeContent } from '@hkm/services/signalRClient/envelopeContent/MaintenanceNotificationEnvelopeContent';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { useNotification } from '@hkm/shared/hooks/useNotification';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';

import { NotificationEnvelope } from '@ac/library-api/dist/types/entities/signalr/notificationEnvelope';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { useComponentDidUpdateEffect } from '@ac/mobile-components/dist/hooks';

const MaintenanceDetailsContainer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const roomState = useSelector(selectMaintenanceDetailsState);
  const maintenanceUpdateState = useSelector(selectMaintenanceUpdateState);
  const maintenanceAttachmentsState = useSelector(
    selectMaintenanceAttachmentsState
  );

  const attachments: MaintenanceAttachmentData[] = (
    roomState.attachments || []
  ).map(file => createMaintenanceAttachmentDataFromResponse(file));

  const canEditMaintenance = usePermission(
    ...MaintenanceManagePermissionsConfig
  );

  useEffect(() => {
    dispatch(detailsActions.reset());
    dispatch(detailsActions.initialize(params.id!));
  }, []);

  const notificationCallback = useCallback(
    (data: NotificationEnvelope<MaintenanceNotificationEnvelopeContent>) => {
      if (data.notification?.maintenanceId === params.id!) {
        dispatch(detailsActions.fetchMaintenanceRoom.trigger());
      }
    },
    []
  );

  useNotification(
    SignalREventType.RoomMaintenanceChanged,
    notificationCallback
  );

  useComponentDidUpdateEffect(() => {
    dispatch(detailsActions.fetchMaintenanceRoom.trigger());
  }, [maintenanceAttachmentsState.version]);

  const showSpinner =
    !roomState.didInitialize ||
    !roomState.didInitializeAttachments ||
    roomState.isChanging ||
    roomState.isFetching ||
    roomState.isFetchingAttachments ||
    maintenanceUpdateState.isPending ||
    maintenanceAttachmentsState.isPending;

  // Navigate to dashboard if we initialized, but no room is available
  if (!roomState.room && roomState.didInitialize) {
    return <Navigate to={APP_ROUTES.MAINTENANCE.DASHBOARD} />;
  }

  return (
    <>
      <ConnectedHeader close={APP_ROUTES.MAINTENANCE.DASHBOARD}>
        {t('MAINTENANCE_DETAILS.TITLE')}
      </ConnectedHeader>
      <AcBody testSelector="maintenanceDetails" showSpinner={showSpinner}>
        {roomState.room && roomState.attachments && (
          <MaintenanceDetailsBody
            maintenanceRoom={roomState.room}
            attachments={attachments}
          />
        )}
      </AcBody>
      {canEditMaintenance && roomState.room && (
        <MaintenanceDetailsFooter maintenanceRoom={roomState.room} />
      )}
    </>
  );
};

export default memo(MaintenanceDetailsContainer);
