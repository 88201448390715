import { HousekeepingAttendant } from '@ac/library-api';
import { HousekeepingAttendantApi } from '@ac/library-api/dist/api/v0/configuration/genericEntities';

interface GetAttendantCodeById {attendantId: string; }

export async function getAttendantCodeById(parameters: GetAttendantCodeById) {
  const { attendantId: id } = parameters;
  try {
    const attendantById = (await HousekeepingAttendantApi.getById({
      pathParams: { id }
    })) as HousekeepingAttendant;

    return attendantById.code ?? id;
  } catch (e) {
    return id;
  }
}
