import * as appActions from '@hkm/components/App/domain/actions';
import { getRooms, getRoomsMap } from '@hkm/components/App/domain/selectors';
import { MaintenanceCreateActions } from '@hkm/shared/domain/maintenanceCreate/maintenanceCreateActions';
import { MaintenanceCreateData } from '@hkm/shared/domain/maintenanceCreate/models/maintenanceCreateData';
import {
  RoomsNotFoundException,
  SetMaintenanceException
} from '@hkm/shared/errors/ErrorException';
import { ErrorTypes } from '@hkm/shared/errors/ErrorTypes';
import {
  extractParamFromErrorsDetails,
  groupErrorsByType
} from '@hkm/shared/errors/utils';
import { getMaintenanceRoomById } from '@hkm/shared/helpers/api/getMaintenanceRoomById';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { takeLatest } from '@redux-saga/core/effects';
import { Task } from '@redux-saga/types';
import i18n from 'i18next';
import { difference } from 'lodash';
import { call, cancel, fork, put, select, take } from 'redux-saga/effects';
import { race } from 'redux-saga-test-plan/matchers';

import {
  ApiError,
  ConflictDetails,
  HousekeepingOperationExecutionDetails,
  LongRunningProcess,
  Room
} from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { repeatableCall } from '@ac/library-utils/dist/utils';

export interface MaintenanceCreateSagaApi {
  createMaintenanceProcess(
    maintenanceCreateData: MaintenanceCreateData
  ): LongRunningProcess<any, any>;
  maintenanceValidation(
    maintenanceCreateData: MaintenanceCreateData
  ): Promise<ConflictDetails[]>;
  fetchReservation(roomsIds: string[]): Promise<UnifiedReservationDetails[]>;
}

const updateCheckLimit: number = 5;

export function createMaintenanceCreateSaga(
  actions: MaintenanceCreateActions,
  maintenanceCreateSagaApi: MaintenanceCreateSagaApi,
  waitForMaintenanceDashboard?: boolean
) {
  function* checkRoomNumbersExistence(roomNumbers: string[]) {
    const roomsMap: Map<string, Room> = yield select(getRoomsMap);
    const notExistingList = difference(roomNumbers, [...roomsMap.keys()]);

    if (notExistingList.length > 0) {
      throw new RoomsNotFoundException<string[]>(notExistingList);
    }
  }

  function* cancelableValidationLoop() {
    while (true) {
      const validateTask: Task = yield takeLatest(
        actions.validateMaintenance.trigger,
        validateMaintenance
      );
      yield take(actions.clearConflicts);
      yield cancel(validateTask);
    }
  }

  function* validateMaintenance(action: Action<MaintenanceCreateData>) {
    try {
      const { data } = action.payload;

      yield call(checkRoomNumbersExistence, data.roomNumbers);

      const conflicts: ConflictDetails[] = yield maintenanceCreateSagaApi.maintenanceValidation(
        action.payload
      );

      // need to display information about reservation guest
      const roomIds: string[] = conflicts
        .map((conflict: ConflictDetails) => {
          return conflict.overlapingReservation
            ? conflict.roomNumber
            : undefined;
        })
        .filter(Boolean) as string[];

      if (roomIds.length > 0) {
        yield put(actions.fetchReservations.trigger(roomIds));
        yield race([
          take(actions.fetchReservations.success),
          take(actions.fetchReservations.failure)
        ]);
      }

      yield put(actions.validateMaintenance.success(conflicts));
    } catch (e) {
      if (e instanceof RoomsNotFoundException) {
        yield call(displayRoomsNotFoundExceptionMessage, e);
      } else {
        yield put(appActions.displayExtractedError(e));
      }
      yield put(actions.validateMaintenance.failure(e));
    }
  }

  function* setMaintenanceSaga(action: Action<MaintenanceCreateData>) {
    const { data } = action.payload;

    try {
      yield call(checkRoomNumbersExistence, data.roomNumbers);

      const process: LongRunningProcess<
        unknown,
        unknown
      > = maintenanceCreateSagaApi.createMaintenanceProcess(action.payload);
      yield process.runProcess();

      const responseData = process.responseData as HousekeepingOperationExecutionDetails;
      if (responseData.error) {
        throw new SetMaintenanceException(responseData.error);
      }

      if (waitForMaintenanceDashboard) {
        // wait until data on backend will be up to date
        // If we batch create 100 rooms, then only validate the last N rooms one by one - if N are done, then we assume all are done
        const idsToValidate: string[] = responseData.results
          .map(result => result.location.split('/').pop()!)
          .slice(-updateCheckLimit)
          .reverse();

        for (const idToValidate of idsToValidate) {
          const checkForExistence = (room: MaintenanceRoom | undefined) =>
            !!room;
          yield repeatableCall(
            () => getMaintenanceRoomById(idToValidate).catch(() => undefined),
            checkForExistence
          );
        }
      }

      yield put(actions.createMaintenance.success());
      yield call(displaySuccessMessage, data.roomNumbers);
    } catch (e) {
      if (e instanceof SetMaintenanceException) {
        yield call(displaySetMaintenanceExceptionMessage, e, data.roomNumbers);
      } else if (e instanceof RoomsNotFoundException) {
        yield call(displayRoomsNotFoundExceptionMessage, e);
      } else {
        yield put(appActions.displayExtractedError(e));
      }

      yield put(actions.createMaintenance.failure(e));
    }
  }

  function* displaySuccessMessage(rooms: string[]) {
    yield put(
      appActions.displaySuccess(
        i18n.t('MAINTENANCE_CREATE.SUCCESS', {
          rooms: rooms.join(',')
        })
      )
    );
  }

  function* displayRoomsNotFoundExceptionMessage(
    e: RoomsNotFoundException<string[]>
  ) {
    yield put(
      appActions.displayError(
        i18n.t(e.message, {
          rooms: e.data!.join(','),
          count: e.data!.length
        })
      )
    );
  }

  function* displaySetMaintenanceExceptionMessage(
    e: SetMaintenanceException,
    existentList: string[]
  ) {
    // will group any kind of errors by type
    const groupedErrorTypes = groupErrorsByType(e.data!);

    // right now we handle only SelectedRoomIsAlreadyOccupied error type
    // for rest just display general error
    if (groupedErrorTypes.has(ErrorTypes.SelectedRoomIsAlreadyOccupied)) {
      const rooms: Room[] = yield select(getRooms);
      const groupedErrors = groupedErrorTypes.get(
        ErrorTypes.SelectedRoomIsAlreadyOccupied
      )!;
      const roomsId = extractParamFromErrorsDetails(groupedErrors, 'roomId');

      const roomsCodes = rooms.reduce(
        (map: Map<string, string>, next: Room) => {
          map.set(next.id, next.code);

          return map;
        },
        new Map<string, string>()
      );
      const notUpdatedRoomsCode = roomsId.map(item => roomsCodes.get(item));
      const updatedRoomsCode = difference(existentList, notUpdatedRoomsCode);

      // error message came from BE with roomsId instead of roomCode so it needs to be replaced
      const errorMessage = groupedErrors[0].message!.replace(
        roomsId[0],
        notUpdatedRoomsCode.join(',')
      );
      yield put(appActions.displayError(errorMessage));

      // despite the error if any ooo/oos object was created then display success for them
      if (updatedRoomsCode.length > 0) {
        yield call(displaySuccessMessage, updatedRoomsCode as string[]);
      }
    } else {
      yield put(appActions.displayExtractedError(e.data! as ApiError));
    }

    yield put(actions.createMaintenance.failure(e));
  }

  function* fetchReservation(action: Action<string[]>) {
    try {
      const housekeepingReservations: UnifiedReservationDetails[] = yield maintenanceCreateSagaApi.fetchReservation(
        action.payload
      );

      yield put(actions.fetchReservations.success(housekeepingReservations));
    } catch (e) {
      yield put(actions.fetchReservations.failure(e));
    }
  }

  return function*() {
    yield takeLatest(actions.createMaintenance.trigger, setMaintenanceSaga);
    yield takeLatest(actions.fetchReservations.trigger, fetchReservation);
    yield fork(cancelableValidationLoop);
  };
}
