import { activeReservationStates } from '@hkm/shared/reservations/activeReservationStatuses';
import { dayjs } from '@hkm/utils/dayjs-extended';

import { ReservationStatus } from '@ac/library-api';

interface Reservation {
  status: ReservationStatus;
  arrivalDate: string;
}

// TODO: Check why reservations are mapped to "ReservedKindReservation"???
export function getCurrentMainReservation<T extends Reservation>(
  sortedReservations: T[],
  businessDate: string
): T | undefined {
  const inHouseReservation = sortedReservations.find(
    reservation =>
      reservation.status === ReservationStatus.IH &&
      dayjs(reservation.arrivalDate).isSameOrBefore(businessDate, 'day')
  );

  return (
    inHouseReservation ||
    sortedReservations.find(
      reservation =>
        activeReservationStates.has(reservation.status) &&
        dayjs(reservation.arrivalDate).isSameOrBefore(businessDate, 'day')
    )
  );
}
