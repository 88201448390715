import { getTransactionCodeByIdMap } from '@hkm/components/App/domain/selectors';
import { getStore } from '@hkm/store/configureStore';

import {
  buildFIQLFilter,
  FIQLOperators,
  PageResponse,
  RawTransactionCode2,
  TransactionCode2
} from '@ac/library-api';
import { TransactionCodeApi2 } from '@ac/library-api/dist/api/v0/configuration/handcraftedEntities';

interface GetTransactionCodeById {
  transactionCodeId: string;
}

export async function getTransactionCodeById(
  parameters: GetTransactionCodeById
) {
  const { transactionCodeId: id } = parameters;
  try {
    const transactionCodeMap = getTransactionCodeByIdMap(getStore());
    if (transactionCodeMap.has(id)) {
      return transactionCodeMap.get(id)!.code;
    }

    const getByIdFilter = buildFIQLFilter('id', FIQLOperators.equal, id);
    const { results } = (await TransactionCodeApi2.getList({
      queryParams: {
        filter: getByIdFilter
      }
    })) as PageResponse<RawTransactionCode2, TransactionCode2>;

    return results[0].code ?? id;
  } catch (e) {
    return id;
  }
}
