import { ErrorReport } from '@ac/library-api';

interface ErrorExceptionProps<DataT> extends Error {
  code?: string;
  messageKey?: string;
  data?: DataT;
}

/* tslint:disable:max-classes-per-file */
export class ErrorException<DataT> implements ErrorExceptionProps<DataT>{
  public readonly name: string;
  public readonly message: string;
  public readonly data?: DataT;

  constructor(data?: DataT) {
    this.data = data;
  }
}

export class RoomsNotFoundException<DataT> extends ErrorException<DataT> {
  public readonly name = 'RoomsNotFound';
  public readonly message = 'GLOBAL.ERRORS.ROOMS_NOT_FOUND';
}

export class SetMaintenanceException extends ErrorException<ErrorReport> {}
