import { getCustomConfig } from '@hkm/utils/getCustomConfig';

import {
  ApiOperation,
  HousekeepingOperationExecutionDetails,
  LongRunningProcess,
  OperationStatus,
  RawHousekeepingOperationExecutionDetails,
  ReleaseMaintenances,
  RoomNumberPathParam
} from '@ac/library-api';
import { HousekeepingRoomsApiV1 } from '@ac/library-api/dist/api/v1/housekeeping';

export class ReleaseMaintenanceProcess extends LongRunningProcess<
  RoomNumberPathParam,
  ReleaseMaintenances
> {
  constructor(
    pathParams: RoomNumberPathParam,
    payload: ReleaseMaintenances,
    version?: number
  ) {
    super({
      action: HousekeepingRoomsApiV1.postReleaseMaintenance,
      pathParams,
      payload,
      customHeaders: getCustomConfig({ version }).headers,
      statusResponseHandler: (
        response: ApiOperation<
          RawHousekeepingOperationExecutionDetails,
          HousekeepingOperationExecutionDetails
        >
      ) => this._statusResponseHandler(response)
    });
  }
  private _statusResponseHandler(
    response: ApiOperation<
      RawHousekeepingOperationExecutionDetails,
      HousekeepingOperationExecutionDetails
    >
  ) {
    return (
      !!response.status &&
      response.status.operationStatus === OperationStatus.Completed
    );
  }
}
