import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useExtractGuestFullName } from '@hkm/shared/hooks/useExtractGuestFullName';

import { IncognitoDetails } from '@ac/library-api';
import {
  AcModal,
  AcModalBody,
  AcModalHeader
} from '@ac/mobile-components/dist/components/modal';

interface Props {
  isOpen: boolean;
  details: IncognitoDetails;
  onClose: () => void;
}
const IncognitoModal: FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const fullName = useExtractGuestFullName({
    firstName: props.details?.firstName,
    lastName: props.details?.lastName
  });

  return (
    <AcModal isOpen={props.isOpen} onClose={props.onClose}>
      <AcModalHeader showCloseButton={true}>
        {t('GLOBAL.INCOGNITO.MODAL_TITLE')}
      </AcModalHeader>
      <AcModalBody>{fullName}</AcModalBody>
    </AcModal>
  );
};

export default memo(IncognitoModal);
