import { AttendantAssignmentsDictionaries } from '@hkm/components/Attendant/shared/config/attendantDictionaries';
import { HousekeepingDetailsDictionaries } from '@hkm/components/Housekeeping/Details/config/housekeepingDetailsDictionaries';
import { HousekeepingDashboardDictionaries } from '@hkm/components/Housekeeping/shared/config/housekeepingDashboardDictionaries';
import { HousekeepingAdvancedFiltersDictionaries } from '@hkm/components/Housekeeping/shared/filters/housekeepingAdvancedFiltersDictionaries';
import { HousekeepingStatisticsDictionaries } from '@hkm/components/Housekeeping/Statistics/config/housekeepingStatisticsDictionaries';
import { MaintenanceDashboardDictionaries } from '@hkm/components/Maintenance/shared/config/maintenanceDashboardDictionaries';
import { MaintenanceAdvancedFiltersDictionaries } from '@hkm/components/Maintenance/shared/filters/maintenanceAdvancedFiltersDictionaries';
import { PostingDictionaries } from '@hkm/components/Posting/shared/config/postingDictionaries';

const housekeepingPostingRoute = '/housekeeping-posting';
const attendantPostingRoute = '/attendant-posting';
const maintenanceRoute = '/maintenance';
const housekeepingRoute = '/housekeeping';
const attendantRoute = '/attendant-assignment';
const taskManagementRoute = '/task-management';

const APP_ROUTES = {
  HOME: '/',
  HOUSEKEEPING: {
    DASHBOARD: `${housekeepingRoute}/dashboard`,
    DETAILS: `${housekeepingRoute}/details/:id`,
    FILTERS: `${housekeepingRoute}/filters`,
    STATISTICS: `${housekeepingRoute}/statistics`,
    BATCH: `${housekeepingRoute}/batch-status-change`,
    ADD_MAINTENANCE: `${housekeepingRoute}/add-maintenance/:roomId`,
    RELEASE_MAINTENANCE: `${housekeepingRoute}/close-maintenance/:id`
  },
  MAINTENANCE: {
    DASHBOARD: `${maintenanceRoute}/dashboard`,
    STATISTICS: `${maintenanceRoute}/statistics`,
    FILTERS: `${maintenanceRoute}/filters`,
    DETAILS: `${maintenanceRoute}/details/:id`,
    ADD: `${maintenanceRoute}/add`,
    COPY: `${maintenanceRoute}/copy/:id`,
    RELEASE: `${maintenanceRoute}/release/:id`
  },
  ATTENDANT_ASSIGNMENTS: {
    SHEET_SUMMARY: attendantRoute,
    ROOM_LIST: `${attendantRoute}/room-list`,
    ROOM_DETAILS: `${attendantRoute}/room-details/:roomId`,
    ADD_MAINTENANCE: `${attendantRoute}/add-maintenance/:roomId`
  },
  MOBILE_POSTING: {
    HOUSEKEEPING: {
      DASHBOARD: housekeepingPostingRoute,
      RESERVATIONS: `${housekeepingPostingRoute}/:roomId`,
      POSTING_VIEW: `${housekeepingPostingRoute}/:roomId/:reservationId`
    },
    ATTENDANT: {
      DASHBOARD: attendantPostingRoute,
      RESERVATIONS: `${attendantPostingRoute}/:roomId`,
      POSTING_VIEW: `${attendantPostingRoute}/:roomId/:reservationId`
    }
  },
  TASK_MANAGEMENT: {
    DASHBOARD: `${taskManagementRoute}/dashboard`,
    FILTERS: `${taskManagementRoute}/filters`,
    TASK_PREVIEW: `${taskManagementRoute}/task-preview/:taskId`
  },
  HELP: '/help',
  LOGIN: '/login',
  ACCESS_DENIED: '/403'
};

export const APP_ROUTE_DICTIONARY_MAP = {
  [APP_ROUTES.HOUSEKEEPING.DASHBOARD]: HousekeepingDashboardDictionaries,
  [APP_ROUTES.HOUSEKEEPING.BATCH]: HousekeepingDetailsDictionaries,
  [APP_ROUTES.HOUSEKEEPING.DETAILS]: HousekeepingDetailsDictionaries,
  [APP_ROUTES.HOUSEKEEPING.FILTERS]: HousekeepingAdvancedFiltersDictionaries,
  [APP_ROUTES.HOUSEKEEPING.STATISTICS]: HousekeepingStatisticsDictionaries,
  [APP_ROUTES.HOUSEKEEPING.ADD_MAINTENANCE]: MaintenanceDashboardDictionaries,
  [APP_ROUTES.MAINTENANCE.DASHBOARD]: MaintenanceDashboardDictionaries,
  [APP_ROUTES.MAINTENANCE.ADD]: MaintenanceDashboardDictionaries,
  [APP_ROUTES.MAINTENANCE.COPY]: MaintenanceDashboardDictionaries,
  [APP_ROUTES.MAINTENANCE.FILTERS]: MaintenanceAdvancedFiltersDictionaries,
  [APP_ROUTES.MAINTENANCE.DETAILS]: HousekeepingDetailsDictionaries,
  [APP_ROUTES.MAINTENANCE.RELEASE]: MaintenanceDashboardDictionaries,
  [APP_ROUTES.MAINTENANCE.STATISTICS]: HousekeepingDetailsDictionaries,
  [APP_ROUTES.ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY]: AttendantAssignmentsDictionaries,
  [APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_LIST]: AttendantAssignmentsDictionaries,
  [APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_DETAILS]: AttendantAssignmentsDictionaries,
  [APP_ROUTES.ATTENDANT_ASSIGNMENTS.ADD_MAINTENANCE]: MaintenanceDashboardDictionaries,
  [APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.DASHBOARD]: PostingDictionaries,
  [APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.RESERVATIONS]: PostingDictionaries,
  [APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.POSTING_VIEW]: PostingDictionaries,
  [APP_ROUTES.MOBILE_POSTING.ATTENDANT.RESERVATIONS]: PostingDictionaries,
  [APP_ROUTES.MOBILE_POSTING.ATTENDANT.POSTING_VIEW]: PostingDictionaries,
  [APP_ROUTES.HELP]: HousekeepingDetailsDictionaries
};

export default APP_ROUTES;
