import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { selectAttendantGuestNamesShow } from '@hkm/components/Attendant/shared/domain/selectors';
import PostingReservationListBody from '@hkm/components/Posting/PostingReservation/Body/PostingReservationListBody';
import * as actions from '@hkm/components/Posting/shared/domain/actions';
import {
  selectAttendantIsLoadingRoom,
  selectAttendantPostingRoomState
} from '@hkm/components/Posting/shared/domain/selectors';
import { getPostingHousekeepingReservations } from '@hkm/components/Posting/shared/utils/getPostingReservation';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import HeaderTwoLineTitle from '@hkm/components/shared/LayoutComponents/HeaderTwoLineTitle';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { useFetchAssignedSheetOnNotification } from '@hkm/shared/hooks/useFetchAssignedSheetOnNotification';
import { PostChargeLocationState } from '@hkm/shared/interfaces/locationStates';

import { AcBody } from '@ac/mobile-components/dist/components/layout';

const AttendantPostingReservations: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const roomState = useSelector(selectAttendantPostingRoomState);
  const showSpinner = useSelector(selectAttendantIsLoadingRoom);
  const showGuestNames = useSelector(selectAttendantGuestNamesShow);

  const roomId = params.roomId;

  useFetchAssignedSheetOnNotification();

  useEffect(() => {
    if (!roomState.didInitialize || roomState.roomId !== roomId) {
      dispatch(actions.reset());
      dispatch(actions.initializeAttendantPostingRoom(params.roomId!));
    }
  }, []);

  const reservations = useMemo(
    () =>
      getPostingHousekeepingReservations(roomState.room?.currentReservations),
    [roomState]
  );

  const closePathHandler = useCallback(() => {
    const locationState = location.state || ({} as PostChargeLocationState);

    navigate(
      generatePath(APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_DETAILS, {
        roomId
      }),
      {
        state: locationState
      }
    );
  }, []);

  // hack to not be redirected accidentally during swapping room data in store
  if (roomState.roomId !== roomId) {
    return null;
  }

  if (
    // Navigate to dashboard if we initialized, but no room is available
    (!roomState.room && roomState.didInitialize) ||
    // or there is no reservations with specific status in the room
    (roomState.room && reservations.length === 0)
  ) {
    return (
      <Navigate
        to={generatePath(APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_DETAILS, {
          roomId
        })}
        state={location.state || {}}
      />
    );
  } else if (reservations.length === 1) {
    // if there is only one reservation with specific status go straight to the posting view
    return (
      <Navigate
        to={generatePath(APP_ROUTES.MOBILE_POSTING.ATTENDANT.POSTING_VIEW, {
          roomId,
          reservationId: reservations[0].id
        })}
        state={location.state || {}}
      />
    );
  }

  return (
    <>
      <ConnectedHeader showHelpButton={true} close={closePathHandler}>
        {roomState.room && roomState.room.roomNumber && (
          <HeaderTwoLineTitle
            upperText={t('POSTING.RESERVATION_LIST.TITLE')}
            lowerText={roomState.room.roomNumber || '\u00A0'}
          />
        )}
        {!roomState.room && t('POSTING.RESERVATION_LIST.TITLE')}
      </ConnectedHeader>
      <AcBody
        className="reservation-list-dashboard-body"
        showSpinner={showSpinner}
      >
        {roomState.room && (
          <PostingReservationListBody
            reservations={reservations}
            roomId={roomId!}
            showGuestNames={showGuestNames}
          />
        )}
      </AcBody>
    </>
  );
};

export default memo(AttendantPostingReservations);
