import * as appActions from '@hkm/components/App/domain/actions';
import { SubscribeNotificationData } from '@hkm/components/App/notificationConsumer/domain/interface';
import { NotificationConsumer } from '@hkm/components/App/notificationConsumer/NotificationConsumer';
import * as propertyActions from '@hkm/components/Menu/PropertySelector/domain/actions';
import { selectActiveProperty } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import {
  signalrSubscribe,
  signalrUnsubscribe
} from '@hkm/services/signalRClient/signalRClient';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { TakeableChannel } from '@redux-saga/core';
import { actionChannel, call, take, takeEvery } from '@redux-saga/core/effects';
import i18n from 'i18next';
import { put, select } from 'redux-saga/effects';

import { PropertyDetails } from '@ac/library-api';
import { NotificationEnvelope } from '@ac/library-api/dist/types/entities/signalr/notificationEnvelope';
import { Action } from '@ac/library-utils/dist/declarations';

import * as actions from './actions';

const NOTIFICATION_TIMEOUT: number = 15 * 1000;
const TIMEOUTS = new Map<SignalREventType, NodeJS.Timeout>();
const CONSUMERS_CALLBACK_MAP = new Map<string, () => void>();
const SIGNAL_R_WARNING_TIMEOUT = 3000;
let showSignalRWarning = true;

function* subscribeNotification(
  subscribeNotificationData: SubscribeNotificationData
) {
  try {
    const {
      notificationConsumers,
      signalREventType,
      propertyId
    } = subscribeNotificationData;

    const consumerKey = getConsumerKey(propertyId, signalREventType);

    const consumersCall = (envelope: NotificationEnvelope) => {
      notificationConsumers.forEach(
        (channelCorrespondent: NotificationConsumer) =>
          channelCorrespondent.actionAfterConsume(envelope)
      );
      clearNotificationTimeout(signalREventType);
    };
    CONSUMERS_CALLBACK_MAP.set(consumerKey, consumersCall as any);

    yield signalrUnsubscribe(signalREventType);
    if (notificationConsumers.length > 0) {
      yield signalrSubscribe(signalREventType, consumersCall);
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.warn(e);

    if (showSignalRWarning) {
      yield put(
        appActions.displayWarning(
          i18n.t('GLOBAL.ERRORS.SIGNAL_R_ERROR') as string
        )
      );
      showSignalRWarning = false;
      setTimeout(() => (showSignalRWarning = true), SIGNAL_R_WARNING_TIMEOUT);
    }
  }
}

function getConsumerKey(propertyId: string, signal: SignalREventType): string {
  return `${propertyId}, ${signal}`;
}

function* handleExpectNotification(data: Action<SignalREventType>) {
  const signal = data.payload;
  clearNotificationTimeout(signal);
  const details: PropertyDetails = yield select(selectActiveProperty);
  const propertyId = details.id;
  const consumerKey = getConsumerKey(propertyId, signal);

  const timeout = setTimeout(() => {
    const consumersCallback = CONSUMERS_CALLBACK_MAP.get(consumerKey);
    consumersCallback?.();
    clearNotificationTimeout(signal);
  }, NOTIFICATION_TIMEOUT);
  TIMEOUTS.set(signal, timeout);
}

function clearNotificationTimeout(signal: SignalREventType) {
  const timeout = TIMEOUTS.get(signal);
  if (timeout) {
    clearTimeout(timeout);
    TIMEOUTS.delete(signal);
  }
}

function clearAllNotificationTimeouts() {
  const signals = TIMEOUTS.keys();
  for (const signal of signals) {
    clearNotificationTimeout(signal);
  }
}

export default function*() {
  yield takeEvery(actions.expectNotification, handleExpectNotification);
  yield takeEvery(
    propertyActions.property.success,
    clearAllNotificationTimeouts
  );

  const channel: TakeableChannel<unknown> = yield actionChannel(
    actions.subscribeNotification
  );
  while (true) {
    const { payload } = yield take(channel);
    yield call(subscribeNotification, payload);
  }
}
