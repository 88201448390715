import { ActivePropertyData } from '@hkm/components/Menu/PropertySelector/domain/interfaces';

import { UnitAssignmentListItem } from '@ac/library-api';
import {
  createAction,
  getListAction,
  getOneAction
} from '@ac/library-utils/dist/redux-utils';

export const properties = getListAction<undefined, UnitAssignmentListItem[]>(
  'properties'
);
export const property = getOneAction<string, ActivePropertyData>('property');
export const businessDate = getOneAction<undefined, string>('businessDate');
export const propertySelect = createAction<UnitAssignmentListItem>(
  'propertySelect'
);
