import i18n from 'i18next';

import { PagingInfo } from '@ac/library-api';
import { AcPaginatorProps } from '@ac/mobile-components/dist/components/paginator';

export function createPaginatorProps(
  paging: PagingInfo,
  onPageRequest: (pageNumber: number) => void
): AcPaginatorProps {
  return {
    ...paging,
    onPageRequest,
    pageTextFactory: (current, total) =>
      i18n.t('GLOBAL.PAGINATOR.PAGE', { current, total }),
    itemsTextFactory: count => i18n.t('GLOBAL.PAGINATOR.ITEMS', { count })
  };
}
