import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TasksAccount } from '@hkm/components/TaskManagement/models/cashiering';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import { getLinkedObject } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/Accordion/Accordion';
import AreaGrid from '@hkm/components/TaskManagement/TaskPreview/LinkedObject/shared/AreaGrid/AreaGrid';
import BadgeHelpers from '@hkm/components/TaskManagement/utils/badgeHelpers';

import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { Color, FlexDirection } from '@ac/mobile-components/dist/enums';

const Account = () => {
  const { t } = useTranslation();
  const linkedObject = useSelector(getLinkedObject);
  const account = linkedObject!.object as TasksAccount;
  const { guestStatuses } = useSelector(getRelationsState);

  const vipStatus = useMemo(
    () =>
      account.profileDetails?.vipStatusCode
        ? guestStatuses.find(
            ({ code }) => code === account.profileDetails?.vipStatusCode.code
          )
        : undefined,
    [account.profileDetails?.vipStatusCode, guestStatuses]
  );

  return (
    <AcFlex direction={FlexDirection.column}>
      <AcFormElement
        className="ac-spacing-top-sm ac-spacing-bottom-sm"
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ACCOUNT.NAME')}
        testSelector="task-account-name"
      >
        {account.profileName ?? '-'}
      </AcFormElement>
      <Accordion
        header={
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ACCOUNT.STATUS')}
            testSelector="task-account-status"
          >
            {account.accountStatusCode?.code ? (
              <AcBadge
                badgeText={account.accountStatusCode.description}
                backgroundColor={BadgeHelpers.getAccountStatusColor(
                  account.accountStatusCode.code!
                )}
              />
            ) : (
              '-'
            )}
          </AcFormElement>
        }
        id={account.id}
      >
        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ACCOUNT.VIP')}
        >
          {vipStatus ? (
            <AcBadge
              badgeText={vipStatus.code}
              backgroundColor={vipStatus.color || Color.Warning}
              testSelector="task-account-vip"
            />
          ) : (
            '-'
          )}
        </AcFormElement>
        <AcFormElement
          className="ac-spacing-bottom-sm"
          label={t(
            'TASK_MANAGEMENT.TASK_PREVIEW.AREA.ACCOUNT.MEMBERSHIP_NUMBER'
          )}
        >
          {account.membership ? (
            <AcBadge
              backgroundColor={account.membership.membershipLevelColor}
              badgeText={account.membership.membershipTypeCode.code}
              testSelector="task-account-membership"
            />
          ) : (
            '-'
          )}
        </AcFormElement>
        <AreaGrid>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ACCOUNT.ROOM_NUMBER')}
            testSelector="account-reservation-room-number"
          >
            {account.reservationDetails?.roomNumber ?? '-'}
          </AcFormElement>
          <AcFormElement
            className="ac-spacing-bottom-sm"
            label={t(
              'TASK_MANAGEMENT.TASK_PREVIEW.AREA.ACCOUNT.ACCOUNT_NUMBER'
            )}
            testSelector="task-account-number"
          >
            {account.accountNumber ?? '-'}
          </AcFormElement>
          <AcFormElement
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ACCOUNT.BALANCE', {
              currency: account.currencyCode?.code
            })}
            testSelector="task-account-balance"
          >
            {account.balance && account.balance > 0 ? account.balance : '-'}
          </AcFormElement>
          <AcFormElement
            label={t('TASK_MANAGEMENT.TASK_PREVIEW.AREA.ACCOUNT.AUTH_AMOUNT', {
              currency: account.currencyCode?.code
            })}
            testSelector="task-account-authorization-amount"
          >
            {account.authorizationAmount ?? '-'}
          </AcFormElement>
        </AreaGrid>
      </Accordion>
    </AcFlex>
  );
};

export default Account;
