import * as appActions from '@hkm/components/App/domain/actions';
import { MaintenancePagination } from '@hkm/components/Maintenance/Dashboard/domain/interfaces';
import {
  selectMaintenanceDashboardFilters,
  selectMaintenanceDashboardPagination,
  selectMaintenanceDashboardSort
} from '@hkm/components/Maintenance/Dashboard/domain/selectors';
import { MaintenanceSort } from '@hkm/components/Maintenance/shared/enum/maintenanceSort';
import { createMaintenanceApiFilter } from '@hkm/components/Maintenance/shared/models/factories/maintenanceApiFilterFactory';
import { MaintenanceFiltersData } from '@hkm/components/Maintenance/shared/models/maintenanceFiltersData';
import { getMaintenanceRoomById } from '@hkm/shared/helpers/api/getMaintenanceRoomById';
import { getHousekeepingRoomVersionId } from '@hkm/shared/services/getHousekeepingRoomVersionId';
import { PAGINATION_PAGE_SIZE } from '@hkm/store/pagination/paginationConfig';
import { createPaginationSaga } from '@hkm/store/pagination/paginationSagaFactory';
import { createMaintenanceRoom } from '@hkm/types/maintenance/factory/createMaintenanceRoom';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';
import { Task } from '@redux-saga/types';
import {
  cancel,
  fork,
  put,
  select,
  take,
  takeLatest
} from 'redux-saga/effects';

import {
  PageResponse,
  QueryQueryParams,
  RawRoomAssignmentMaintenanceDetails,
  RoomAssignmentMaintenanceDetails
} from '@ac/library-api';
import { HousekeepingViewsApi } from '@ac/library-api/dist/api/v0/housekeeping';
import { Action } from '@ac/library-utils/dist/declarations';
import { repeatableCall } from '@ac/library-utils/dist/utils';

import * as actions from './actions';

const DEFAULT_SORT: MaintenanceSort = MaintenanceSort.StartTime;

function* fetchMaintenanceDashboardPage(action: Action<number>) {
  try {
    const filters: MaintenanceFiltersData = yield select(
      selectMaintenanceDashboardFilters
    );
    const sort: MaintenanceSort = yield select(selectMaintenanceDashboardSort);
    const sorts: string =
      sort === DEFAULT_SORT ? sort : `${sort},${DEFAULT_SORT}`;
    const preset: string | undefined =
      filters &&
      (!filters.extraMaintenanceStates ||
        filters.extraMaintenanceStates!.length === 0)
        ? 'Current'
        : undefined;

    const page: PageResponse<
      RawRoomAssignmentMaintenanceDetails,
      RoomAssignmentMaintenanceDetails
    > = yield HousekeepingViewsApi.getMaintenanceDashboard({
      queryParams: {
        sort: sorts,
        filter: filters ? createMaintenanceApiFilter(filters) : undefined,
        pageSize: PAGINATION_PAGE_SIZE,
        pageNumber: action.payload,
        preset
      } as QueryQueryParams
    }) as Promise<
      PageResponse<
        RawRoomAssignmentMaintenanceDetails,
        RoomAssignmentMaintenanceDetails
      >
    >;

    const maintenanceItemsPage: PageResponse<
      RawRoomAssignmentMaintenanceDetails,
      MaintenanceRoom
    > = {
      ...page,
      results: page.results.map(result => createMaintenanceRoom(result))
    };

    yield put(actions.pagination.fetchedPage(maintenanceItemsPage));
  } catch {
    yield put(actions.pagination.error());
  }
}

function* patchChangedMaintenance(action: Action<string>) {
  // todo replace with fetch by id once endpoint exists, no need to check for versionId after we change the endpoint
  try {
    const id = action.payload;
    const pagination: MaintenancePagination = yield select(
      selectMaintenanceDashboardPagination
    );
    const currentMaintenance = pagination.page?.results?.find(
      maintenance => maintenance.id === id
    );

    if (!currentMaintenance) {
      return;
    }

    const currentVersion: number = yield getHousekeepingRoomVersionId(
      currentMaintenance.room!.id!
    );

    const checkNewForNewVersion = (maintenanceRoomToCheck: MaintenanceRoom) => {
      return maintenanceRoomToCheck.housekeepingRoomVersion >= currentVersion;
    };

    const maintenanceRoom: MaintenanceRoom = yield repeatableCall(
      () => getMaintenanceRoomById(id),
      checkNewForNewVersion
    );

    yield put(actions.replaceChangedMaintenance(maintenanceRoom));
  } catch (e) {
    console.error(e);
  }
}

function* dashboardSaga() {
  yield fork(
    createPaginationSaga(
      actions.pagination,
      selectMaintenanceDashboardPagination
    )
  );
  yield takeLatest(actions.pagination.fetchPage, fetchMaintenanceDashboardPage);
  yield takeLatest(actions.patchChangedMaintenance, patchChangedMaintenance);
}

export default function* maintenanceDashboardSagas() {
  while (true) {
    const task: Task = yield fork(dashboardSaga);
    yield take(appActions.clearAllViews);
    yield cancel(task);
  }
}
